import calibri1 from './assets/fonts/calibri1.png';
import calibri2 from './assets/fonts/calibri2.png';
import { BMFont } from './bmfont';

export const calibri = new BMFont(
  `
info face="Calibri" size=72 bold=0 italic=0 charset="" unicode=0 stretchH=100 smooth=1 aa=1 padding=5,5,5,5 spacing=-2,-2
common lineHeight=96 base=54 scaleW=1024 scaleH=1024 pages=2 packed=0
page id=0 file="calibri1.png"
page id=1 file="calibri2.png"
chars count=508
char id=0       x=950  y=148  width=45   height=70   xoffset=-5   yoffset=3    xadvance=45   page=0    chnl=0 
char id=13      x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=8    page=0    chnl=0 
char id=33      x=997  y=489  width=18   height=60   xoffset=4    yoffset=0    xadvance=31   page=0    chnl=0 
char id=35      x=494  y=728  width=44   height=57   xoffset=-4   yoffset=3    xadvance=44   page=0    chnl=0 
char id=36      x=102  y=0    width=40   height=73   xoffset=-2   yoffset=-5   xadvance=45   page=0    chnl=0 
char id=37      x=743  y=550  width=57   height=59   xoffset=-3   yoffset=2    xadvance=59   page=0    chnl=0 
char id=38      x=800  y=550  width=57   height=59   xoffset=-5   yoffset=1    xadvance=57   page=0    chnl=0 
char id=40      x=142  y=0    width=24   height=73   xoffset=-1   yoffset=-1   xadvance=30   page=0    chnl=0 
char id=41      x=166  y=0    width=24   height=73   xoffset=-1   yoffset=-1   xadvance=30   page=0    chnl=0 
char id=47      x=566  y=0    width=38   height=72   xoffset=-5   yoffset=-3   xadvance=36   page=0    chnl=0 
char id=48      x=407  y=611  width=42   height=58   xoffset=-3   yoffset=2    xadvance=45   page=0    chnl=0 
char id=49      x=917  y=900  width=37   height=56   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=50      x=538  y=728  width=39   height=57   xoffset=-1   yoffset=2    xadvance=45   page=0    chnl=0 
char id=51      x=449  y=611  width=40   height=58   xoffset=-2   yoffset=2    xadvance=45   page=0    chnl=0 
char id=52      x=577  y=728  width=44   height=57   xoffset=-4   yoffset=3    xadvance=45   page=0    chnl=0 
char id=53      x=621  y=728  width=40   height=57   xoffset=-2   yoffset=3    xadvance=45   page=0    chnl=0 
char id=54      x=489  y=611  width=41   height=58   xoffset=-2   yoffset=2    xadvance=45   page=0    chnl=0 
char id=55      x=661  y=728  width=41   height=57   xoffset=-2   yoffset=3    xadvance=45   page=0    chnl=0 
char id=56      x=530  y=611  width=42   height=58   xoffset=-3   yoffset=2    xadvance=45   page=0    chnl=0 
char id=57      x=572  y=611  width=41   height=58   xoffset=-3   yoffset=2    xadvance=45   page=0    chnl=0 
char id=59      x=995  y=900  width=22   height=54   xoffset=-3   yoffset=16   xadvance=27   page=0    chnl=0 
char id=63      x=159  y=550  width=37   height=60   xoffset=-1   yoffset=0    xadvance=41   page=0    chnl=0 
char id=64      x=505  y=358  width=69   height=66   xoffset=-5   yoffset=2    xadvance=72   page=0    chnl=0 
char id=65      x=702  y=728  width=50   height=57   xoffset=-4   yoffset=3    xadvance=50   page=0    chnl=0 
char id=66      x=954  y=900  width=41   height=56   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=67      x=613  y=611  width=44   height=58   xoffset=-2   yoffset=2    xadvance=46   page=0    chnl=0 
char id=68      x=0    y=957  width=46   height=56   xoffset=1    yoffset=3    xadvance=52   page=0    chnl=0 
char id=69      x=46   y=957  width=36   height=56   xoffset=1    yoffset=3    xadvance=43   page=0    chnl=0 
char id=70      x=752  y=728  width=34   height=57   xoffset=1    yoffset=3    xadvance=41   page=0    chnl=0 
char id=71      x=657  y=611  width=48   height=58   xoffset=-2   yoffset=2    xadvance=53   page=0    chnl=0 
char id=72      x=786  y=728  width=43   height=57   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=73      x=829  y=728  width=17   height=57   xoffset=1    yoffset=3    xadvance=26   page=0    chnl=0 
char id=74      x=846  y=728  width=28   height=57   xoffset=-5   yoffset=3    xadvance=31   page=0    chnl=0 
char id=75      x=874  y=728  width=40   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=76      x=82   y=957  width=34   height=56   xoffset=1    yoffset=3    xadvance=38   page=0    chnl=0 
char id=77      x=914  y=728  width=60   height=57   xoffset=1    yoffset=3    xadvance=70   page=0    chnl=0 
char id=78      x=974  y=728  width=45   height=57   xoffset=1    yoffset=3    xadvance=54   page=0    chnl=0 
char id=79      x=705  y=611  width=52   height=58   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=80      x=0    y=786  width=38   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=81      x=725  y=358  width=58   height=64   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=82      x=38   y=786  width=41   height=57   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=83      x=757  y=611  width=39   height=58   xoffset=-3   yoffset=2    xadvance=41   page=0    chnl=0 
char id=84      x=79   y=786  width=45   height=57   xoffset=-5   yoffset=3    xadvance=43   page=0    chnl=0 
char id=85      x=124  y=786  width=46   height=57   xoffset=0    yoffset=3    xadvance=54   page=0    chnl=0 
char id=86      x=170  y=786  width=49   height=57   xoffset=-4   yoffset=3    xadvance=49   page=0    chnl=0 
char id=87      x=219  y=786  width=70   height=57   xoffset=-3   yoffset=3    xadvance=72   page=0    chnl=0 
char id=88      x=289  y=786  width=45   height=57   xoffset=-4   yoffset=3    xadvance=45   page=0    chnl=0 
char id=89      x=334  y=786  width=43   height=57   xoffset=-4   yoffset=3    xadvance=43   page=0    chnl=0 
char id=90      x=116  y=957  width=41   height=56   xoffset=-3   yoffset=3    xadvance=42   page=0    chnl=0 
char id=91      x=604  y=0    width=23   height=72   xoffset=1    yoffset=-1   xadvance=30   page=0    chnl=0 
char id=92      x=627  y=0    width=38   height=72   xoffset=-5   yoffset=-3   xadvance=36   page=0    chnl=0 
char id=93      x=665  y=0    width=23   height=72   xoffset=-2   yoffset=-1   xadvance=30   page=0    chnl=0 
char id=98      x=196  y=550  width=40   height=60   xoffset=0    yoffset=0    xadvance=46   page=0    chnl=0 
char id=100     x=236  y=550  width=40   height=60   xoffset=-2   yoffset=0    xadvance=46   page=0    chnl=0 
char id=102     x=204  y=427  width=32   height=61   xoffset=-4   yoffset=-1   xadvance=30   page=0    chnl=0 
char id=103     x=796  y=611  width=41   height=58   xoffset=-4   yoffset=14   xadvance=42   page=0    chnl=0 
char id=104     x=276  y=550  width=38   height=60   xoffset=0    yoffset=0    xadvance=46   page=0    chnl=0 
char id=105     x=837  y=611  width=18   height=58   xoffset=-1   yoffset=2    xadvance=25   page=0    chnl=0 
char id=106     x=995  y=148  width=26   height=70   xoffset=-7   yoffset=2    xadvance=25   page=0    chnl=0 
char id=107     x=314  y=550  width=37   height=60   xoffset=0    yoffset=0    xadvance=41   page=0    chnl=0 
char id=108     x=1002 y=358  width=17   height=60   xoffset=0    yoffset=0    xadvance=25   page=0    chnl=0 
char id=112     x=855  y=611  width=40   height=58   xoffset=0    yoffset=14   xadvance=46   page=0    chnl=0 
char id=113     x=895  y=611  width=40   height=58   xoffset=-2   yoffset=14   xadvance=46   page=0    chnl=0 
char id=121     x=377  y=786  width=41   height=57   xoffset=-4   yoffset=15   xadvance=41   page=0    chnl=0 
char id=123     x=688  y=0    width=28   height=72   xoffset=-2   yoffset=-1   xadvance=31   page=0    chnl=0 
char id=124     x=16   y=0    width=17   height=74   xoffset=8    yoffset=-2   xadvance=41   page=0    chnl=0 
char id=125     x=716  y=0    width=28   height=72   xoffset=-1   yoffset=-1   xadvance=31   page=0    chnl=0 
char id=160     x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=24   page=0    chnl=0 
char id=161     x=351  y=550  width=18   height=60   xoffset=1    yoffset=6    xadvance=31   page=0    chnl=0 
char id=162     x=418  y=786  width=36   height=57   xoffset=1    yoffset=3    xadvance=44   page=0    chnl=0 
char id=163     x=454  y=786  width=42   height=57   xoffset=-3   yoffset=2    xadvance=45   page=0    chnl=0 
char id=165     x=496  y=786  width=44   height=57   xoffset=-4   yoffset=3    xadvance=45   page=0    chnl=0 
char id=166     x=0    y=0    width=16   height=76   xoffset=10   yoffset=-3   xadvance=44   page=0    chnl=0 
char id=167     x=574  y=358  width=38   height=66   xoffset=-1   yoffset=1    xadvance=44   page=0    chnl=0 
char id=169     x=236  y=427  width=58   height=61   xoffset=1    yoffset=-1   xadvance=68   page=0    chnl=0 
char id=181     x=540  y=786  width=44   height=57   xoffset=0    yoffset=15   xadvance=48   page=0    chnl=0 
char id=182     x=682  y=358  width=43   height=65   xoffset=-2   yoffset=3    xadvance=50   page=0    chnl=0 
char id=188     x=857  y=550  width=52   height=59   xoffset=-3   yoffset=2    xadvance=54   page=0    chnl=0 
char id=189     x=909  y=550  width=53   height=59   xoffset=-3   yoffset=2    xadvance=56   page=0    chnl=0 
char id=190     x=962  y=550  width=55   height=59   xoffset=-3   yoffset=2    xadvance=57   page=0    chnl=0 
char id=191     x=369  y=550  width=37   height=60   xoffset=-3   yoffset=6    xadvance=41   page=0    chnl=0 
char id=192     x=154  y=76   width=50   height=71   xoffset=-4   yoffset=-11  xadvance=50   page=0    chnl=0 
char id=193     x=204  y=76   width=50   height=71   xoffset=-4   yoffset=-11  xadvance=50   page=0    chnl=0 
char id=194     x=254  y=76   width=50   height=71   xoffset=-4   yoffset=-11  xadvance=50   page=0    chnl=0 
char id=195     x=790  y=219  width=50   height=69   xoffset=-4   yoffset=-9   xadvance=50   page=0    chnl=0 
char id=196     x=840  y=219  width=50   height=69   xoffset=-4   yoffset=-9   xadvance=50   page=0    chnl=0 
char id=197     x=744  y=0    width=50   height=72   xoffset=-4   yoffset=-12  xadvance=50   page=0    chnl=0 
char id=198     x=584  y=786  width=65   height=57   xoffset=-7   yoffset=3    xadvance=63   page=0    chnl=0 
char id=199     x=0    y=219  width=44   height=70   xoffset=-2   yoffset=2    xadvance=46   page=0    chnl=0 
char id=200     x=44   y=219  width=40   height=70   xoffset=-3   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=201     x=84   y=219  width=36   height=70   xoffset=1    yoffset=-11  xadvance=43   page=0    chnl=0 
char id=202     x=120  y=219  width=40   height=70   xoffset=-3   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=203     x=122  y=358  width=39   height=68   xoffset=-2   yoffset=-9   xadvance=43   page=0    chnl=0 
char id=204     x=304  y=76   width=35   height=71   xoffset=-11  yoffset=-11  xadvance=26   page=0    chnl=0 
char id=205     x=339  y=76   width=30   height=71   xoffset=-4   yoffset=-11  xadvance=26   page=0    chnl=0 
char id=206     x=369  y=76   width=47   height=71   xoffset=-12  yoffset=-11  xadvance=26   page=0    chnl=0 
char id=207     x=890  y=219  width=43   height=69   xoffset=-11  yoffset=-9   xadvance=26   page=0    chnl=0 
char id=208     x=157  y=957  width=52   height=56   xoffset=-5   yoffset=3    xadvance=53   page=0    chnl=0 
char id=209     x=933  y=219  width=45   height=69   xoffset=1    yoffset=-9   xadvance=54   page=0    chnl=0 
char id=210     x=416  y=76   width=52   height=71   xoffset=-2   yoffset=-11  xadvance=56   page=0    chnl=0 
char id=211     x=468  y=76   width=52   height=71   xoffset=-2   yoffset=-11  xadvance=56   page=0    chnl=0 
char id=212     x=520  y=76   width=52   height=71   xoffset=-2   yoffset=-11  xadvance=56   page=0    chnl=0 
char id=213     x=0    y=289  width=52   height=69   xoffset=-2   yoffset=-9   xadvance=56   page=0    chnl=0 
char id=214     x=52   y=289  width=52   height=69   xoffset=-2   yoffset=-9   xadvance=56   page=0    chnl=0 
char id=216     x=190  y=0    width=52   height=73   xoffset=-2   yoffset=-5   xadvance=56   page=0    chnl=0 
char id=217     x=572  y=76   width=46   height=71   xoffset=0    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=218     x=618  y=76   width=46   height=71   xoffset=0    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=219     x=664  y=76   width=46   height=71   xoffset=0    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=220     x=104  y=289  width=46   height=69   xoffset=0    yoffset=-9   xadvance=54   page=0    chnl=0 
char id=221     x=710  y=76   width=43   height=71   xoffset=-4   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=222     x=649  y=786  width=38   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=223     x=294  y=427  width=41   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=224     x=335  y=427  width=37   height=61   xoffset=-2   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=225     x=372  y=427  width=37   height=61   xoffset=-2   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=226     x=409  y=427  width=37   height=61   xoffset=-2   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=227     x=0    y=611  width=39   height=59   xoffset=-4   yoffset=1    xadvance=42   page=0    chnl=0 
char id=228     x=935  y=611  width=37   height=58   xoffset=-2   yoffset=2    xadvance=42   page=0    chnl=0 
char id=229     x=880  y=358  width=37   height=62   xoffset=-2   yoffset=-2   xadvance=42   page=0    chnl=0 
char id=231     x=972  y=611  width=36   height=58   xoffset=-2   yoffset=14   xadvance=38   page=0    chnl=0 
char id=232     x=446  y=427  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=233     x=486  y=427  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=234     x=526  y=427  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=235     x=0    y=670  width=40   height=58   xoffset=-2   yoffset=2    xadvance=44   page=0    chnl=0 
char id=236     x=566  y=427  width=32   height=61   xoffset=-10  yoffset=-1   xadvance=25   page=0    chnl=0 
char id=237     x=598  y=427  width=27   height=61   xoffset=-4   yoffset=-1   xadvance=25   page=0    chnl=0 
char id=238     x=625  y=427  width=40   height=61   xoffset=-11  yoffset=-1   xadvance=25   page=0    chnl=0 
char id=239     x=40   y=670  width=37   height=58   xoffset=-10  yoffset=2    xadvance=25   page=0    chnl=0 
char id=240     x=406  y=550  width=41   height=60   xoffset=-2   yoffset=0    xadvance=46   page=0    chnl=0 
char id=241     x=39   y=611  width=40   height=59   xoffset=-2   yoffset=1    xadvance=46   page=0    chnl=0 
char id=242     x=665  y=427  width=42   height=61   xoffset=-2   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=243     x=707  y=427  width=42   height=61   xoffset=-2   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=244     x=749  y=427  width=42   height=61   xoffset=-2   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=245     x=79   y=611  width=42   height=59   xoffset=-2   yoffset=1    xadvance=46   page=0    chnl=0 
char id=246     x=77   y=670  width=42   height=58   xoffset=-2   yoffset=2    xadvance=46   page=0    chnl=0 
char id=248     x=209  y=957  width=42   height=56   xoffset=-2   yoffset=9    xadvance=46   page=0    chnl=0 
char id=249     x=791  y=427  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=250     x=829  y=427  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=251     x=867  y=427  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=252     x=119  y=670  width=38   height=58   xoffset=0    yoffset=2    xadvance=46   page=0    chnl=0 
char id=253     x=242  y=0    width=41   height=73   xoffset=-4   yoffset=-1   xadvance=41   page=0    chnl=0 
char id=254     x=794  y=0    width=40   height=72   xoffset=0    yoffset=0    xadvance=46   page=0    chnl=0 
char id=255     x=160  y=219  width=41   height=70   xoffset=-4   yoffset=2    xadvance=41   page=0    chnl=0 
char id=256     x=277  y=358  width=50   height=67   xoffset=-4   yoffset=-7   xadvance=50   page=0    chnl=0 
char id=257     x=687  y=786  width=37   height=57   xoffset=-2   yoffset=3    xadvance=42   page=0    chnl=0 
char id=258     x=753  y=76   width=50   height=71   xoffset=-4   yoffset=-11  xadvance=50   page=0    chnl=0 
char id=259     x=905  y=427  width=37   height=61   xoffset=-2   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=260     x=150  y=289  width=51   height=69   xoffset=-4   yoffset=3    xadvance=50   page=0    chnl=0 
char id=261     x=157  y=670  width=39   height=58   xoffset=-2   yoffset=14   xadvance=42   page=0    chnl=0 
char id=262     x=803  y=76   width=44   height=71   xoffset=-2   yoffset=-11  xadvance=46   page=0    chnl=0 
char id=263     x=942  y=427  width=36   height=61   xoffset=-2   yoffset=-1   xadvance=38   page=0    chnl=0 
char id=264     x=847  y=76   width=44   height=71   xoffset=-2   yoffset=-11  xadvance=46   page=0    chnl=0 
char id=265     x=978  y=427  width=36   height=61   xoffset=-2   yoffset=-1   xadvance=38   page=0    chnl=0 
char id=266     x=978  y=219  width=44   height=69   xoffset=-2   yoffset=-9   xadvance=46   page=0    chnl=0 
char id=267     x=196  y=670  width=36   height=58   xoffset=-2   yoffset=2    xadvance=38   page=0    chnl=0 
char id=268     x=891  y=76   width=44   height=71   xoffset=-2   yoffset=-11  xadvance=46   page=0    chnl=0 
char id=269     x=0    y=489  width=36   height=61   xoffset=-2   yoffset=-1   xadvance=38   page=0    chnl=0 
char id=270     x=201  y=219  width=47   height=70   xoffset=0    yoffset=-11  xadvance=52   page=0    chnl=0 
char id=271     x=36   y=489  width=55   height=61   xoffset=-2   yoffset=-1   xadvance=49   page=0    chnl=0 
char id=272     x=251  y=957  width=52   height=56   xoffset=-5   yoffset=3    xadvance=53   page=0    chnl=0 
char id=273     x=447  y=550  width=46   height=60   xoffset=-2   yoffset=0    xadvance=48   page=0    chnl=0 
char id=274     x=612  y=358  width=39   height=66   xoffset=-2   yoffset=-7   xadvance=43   page=0    chnl=0 
char id=275     x=724  y=786  width=40   height=57   xoffset=-2   yoffset=3    xadvance=44   page=0    chnl=0 
char id=276     x=248  y=219  width=37   height=70   xoffset=0    yoffset=-11  xadvance=43   page=0    chnl=0 
char id=277     x=91   y=489  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=278     x=161  y=358  width=36   height=68   xoffset=1    yoffset=-9   xadvance=43   page=0    chnl=0 
char id=279     x=232  y=670  width=40   height=58   xoffset=-2   yoffset=2    xadvance=44   page=0    chnl=0 
char id=280     x=201  y=289  width=37   height=69   xoffset=1    yoffset=3    xadvance=43   page=0    chnl=0 
char id=281     x=272  y=670  width=40   height=58   xoffset=-2   yoffset=14   xadvance=44   page=0    chnl=0 
char id=282     x=285  y=219  width=40   height=70   xoffset=-3   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=283     x=131  y=489  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=284     x=935  y=76   width=48   height=71   xoffset=-2   yoffset=-11  xadvance=53   page=0    chnl=0 
char id=285     x=283  y=0    width=41   height=73   xoffset=-4   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=286     x=0    y=148  width=48   height=71   xoffset=-2   yoffset=-11  xadvance=53   page=0    chnl=0 
char id=287     x=324  y=0    width=41   height=73   xoffset=-4   yoffset=-1   xadvance=42   page=0    chnl=0 
char id=288     x=238  y=289  width=48   height=69   xoffset=-2   yoffset=-9   xadvance=53   page=0    chnl=0 
char id=289     x=325  y=219  width=41   height=70   xoffset=-4   yoffset=2    xadvance=42   page=0    chnl=0 
char id=290     x=366  y=219  width=48   height=70   xoffset=-2   yoffset=2    xadvance=53   page=0    chnl=0 
char id=291     x=834  y=0    width=41   height=72   xoffset=-4   yoffset=0    xadvance=42   page=0    chnl=0 
char id=292     x=48   y=148  width=43   height=71   xoffset=1    yoffset=-11  xadvance=53   page=0    chnl=0 
char id=293     x=91   y=148  width=48   height=71   xoffset=-8   yoffset=-11  xadvance=46   page=0    chnl=0 
char id=294     x=764  y=786  width=56   height=57   xoffset=-4   yoffset=3    xadvance=55   page=0    chnl=0 
char id=295     x=171  y=489  width=45   height=61   xoffset=-6   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=296     x=286  y=289  width=49   height=69   xoffset=-12  yoffset=-9   xadvance=26   page=0    chnl=0 
char id=297     x=121  y=611  width=48   height=59   xoffset=-13  yoffset=1    xadvance=25   page=0    chnl=0 
char id=298     x=327  y=358  width=45   height=67   xoffset=-11  yoffset=-7   xadvance=26   page=0    chnl=0 
char id=299     x=820  y=786  width=40   height=57   xoffset=-11  yoffset=3    xadvance=25   page=0    chnl=0 
char id=300     x=983  y=76   width=38   height=71   xoffset=-10  yoffset=-11  xadvance=26   page=0    chnl=0 
char id=301     x=216  y=489  width=38   height=61   xoffset=-10  yoffset=-1   xadvance=25   page=0    chnl=0 
char id=302     x=335  y=289  width=24   height=69   xoffset=-4   yoffset=3    xadvance=26   page=0    chnl=0 
char id=303     x=414  y=219  width=24   height=70   xoffset=-5   yoffset=2    xadvance=25   page=0    chnl=0 
char id=304     x=359  y=289  width=22   height=69   xoffset=-4   yoffset=-9   xadvance=26   page=0    chnl=0 
char id=306     x=860  y=786  width=40   height=57   xoffset=1    yoffset=3    xadvance=49   page=0    chnl=0 
char id=307     x=438  y=219  width=36   height=70   xoffset=-1   yoffset=2    xadvance=42   page=0    chnl=0 
char id=308     x=139  y=148  width=42   height=71   xoffset=-8   yoffset=-11  xadvance=31   page=0    chnl=0 
char id=309     x=365  y=0    width=40   height=73   xoffset=-11  yoffset=-1   xadvance=25   page=0    chnl=0 
char id=310     x=381  y=289  width=40   height=69   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=311     x=875  y=0    width=37   height=72   xoffset=0    yoffset=0    xadvance=41   page=0    chnl=0 
char id=313     x=474  y=219  width=35   height=70   xoffset=0    yoffset=-11  xadvance=38   page=0    chnl=0 
char id=314     x=33   y=0    width=31   height=74   xoffset=-4   yoffset=-14  xadvance=25   page=0    chnl=0 
char id=315     x=421  y=289  width=34   height=69   xoffset=1    yoffset=3    xadvance=38   page=0    chnl=0 
char id=316     x=912  y=0    width=20   height=72   xoffset=-3   yoffset=0    xadvance=25   page=0    chnl=0 
char id=317     x=312  y=670  width=34   height=58   xoffset=1    yoffset=1    xadvance=38   page=0    chnl=0 
char id=318     x=254  y=489  width=31   height=61   xoffset=0    yoffset=-1   xadvance=27   page=0    chnl=0 
char id=319     x=303  y=957  width=39   height=56   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=320     x=493  y=550  width=30   height=60   xoffset=0    yoffset=0    xadvance=35   page=0    chnl=0 
char id=321     x=342  y=957  width=41   height=56   xoffset=-5   yoffset=3    xadvance=39   page=0    chnl=0 
char id=322     x=523  y=550  width=27   height=60   xoffset=-5   yoffset=0    xadvance=26   page=0    chnl=0 
char id=323     x=181  y=148  width=45   height=71   xoffset=1    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=324     x=285  y=489  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=325     x=455  y=289  width=45   height=69   xoffset=1    yoffset=3    xadvance=54   page=0    chnl=0 
char id=326     x=346  y=670  width=38   height=58   xoffset=0    yoffset=14   xadvance=46   page=0    chnl=0 
char id=327     x=226  y=148  width=45   height=71   xoffset=1    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=328     x=323  y=489  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=329     x=361  y=489  width=54   height=61   xoffset=-9   yoffset=-1   xadvance=50   page=0    chnl=0 
char id=330     x=500  y=289  width=44   height=69   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=331     x=384  y=670  width=38   height=58   xoffset=0    yoffset=14   xadvance=46   page=0    chnl=0 
char id=332     x=372  y=358  width=52   height=67   xoffset=-2   yoffset=-7   xadvance=56   page=0    chnl=0 
char id=333     x=900  y=786  width=42   height=57   xoffset=-2   yoffset=3    xadvance=46   page=0    chnl=0 
char id=334     x=271  y=148  width=52   height=71   xoffset=-2   yoffset=-11  xadvance=56   page=0    chnl=0 
char id=335     x=415  y=489  width=42   height=61   xoffset=-2   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=336     x=323  y=148  width=52   height=71   xoffset=-2   yoffset=-11  xadvance=56   page=0    chnl=0 
char id=337     x=550  y=550  width=43   height=60   xoffset=-2   yoffset=0    xadvance=46   page=0    chnl=0 
char id=338     x=422  y=670  width=66   height=58   xoffset=-2   yoffset=2    xadvance=70   page=0    chnl=0 
char id=340     x=375  y=148  width=41   height=71   xoffset=1    yoffset=-11  xadvance=47   page=0    chnl=0 
char id=341     x=457  y=489  width=29   height=61   xoffset=0    yoffset=-1   xadvance=33   page=0    chnl=0 
char id=342     x=544  y=289  width=41   height=69   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=343     x=488  y=670  width=31   height=58   xoffset=-2   yoffset=14   xadvance=33   page=0    chnl=0 
char id=344     x=416  y=148  width=44   height=71   xoffset=-2   yoffset=-11  xadvance=47   page=0    chnl=0 
char id=345     x=486  y=489  width=34   height=61   xoffset=-5   yoffset=-1   xadvance=33   page=0    chnl=0 
char id=346     x=460  y=148  width=39   height=71   xoffset=-3   yoffset=-11  xadvance=41   page=0    chnl=0 
char id=347     x=520  y=489  width=34   height=61   xoffset=-3   yoffset=-1   xadvance=36   page=0    chnl=0 
char id=348     x=499  y=148  width=40   height=71   xoffset=-4   yoffset=-11  xadvance=41   page=0    chnl=0 
char id=349     x=554  y=489  width=36   height=61   xoffset=-5   yoffset=-1   xadvance=36   page=0    chnl=0 
char id=350     x=509  y=219  width=39   height=70   xoffset=-3   yoffset=2    xadvance=41   page=0    chnl=0 
char id=351     x=519  y=670  width=34   height=58   xoffset=-3   yoffset=14   xadvance=36   page=0    chnl=0 
char id=352     x=539  y=148  width=40   height=71   xoffset=-4   yoffset=-11  xadvance=41   page=0    chnl=0 
char id=353     x=590  y=489  width=36   height=61   xoffset=-5   yoffset=-1   xadvance=36   page=0    chnl=0 
char id=354     x=585  y=289  width=45   height=69   xoffset=-5   yoffset=3    xadvance=43   page=0    chnl=0 
char id=355     x=651  y=358  width=31   height=66   xoffset=-4   yoffset=6    xadvance=32   page=0    chnl=0 
char id=356     x=579  y=148  width=45   height=71   xoffset=-5   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=357     x=783  y=358  width=35   height=63   xoffset=-4   yoffset=-3   xadvance=33   page=0    chnl=0 
char id=358     x=942  y=786  width=45   height=57   xoffset=-5   yoffset=3    xadvance=43   page=0    chnl=0 
char id=360     x=630  y=289  width=46   height=69   xoffset=0    yoffset=-9   xadvance=54   page=0    chnl=0 
char id=361     x=169  y=611  width=40   height=59   xoffset=-2   yoffset=1    xadvance=46   page=0    chnl=0 
char id=362     x=424  y=358  width=46   height=67   xoffset=0    yoffset=-7   xadvance=54   page=0    chnl=0 
char id=363     x=0    y=843  width=38   height=57   xoffset=0    yoffset=3    xadvance=46   page=0    chnl=0 
char id=364     x=624  y=148  width=46   height=71   xoffset=0    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=365     x=626  y=489  width=38   height=61   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=366     x=932  y=0    width=46   height=72   xoffset=0    yoffset=-12  xadvance=54   page=0    chnl=0 
char id=367     x=917  y=358  width=38   height=62   xoffset=0    yoffset=-2   xadvance=46   page=0    chnl=0 
char id=368     x=670  y=148  width=46   height=71   xoffset=0    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=369     x=593  y=550  width=41   height=60   xoffset=0    yoffset=0    xadvance=46   page=0    chnl=0 
char id=370     x=676  y=289  width=46   height=69   xoffset=0    yoffset=3    xadvance=54   page=0    chnl=0 
char id=371     x=38   y=843  width=40   height=57   xoffset=0    yoffset=15   xadvance=46   page=0    chnl=0 
char id=372     x=716  y=148  width=70   height=71   xoffset=-3   yoffset=-11  xadvance=72   page=0    chnl=0 
char id=373     x=664  y=489  width=59   height=61   xoffset=-4   yoffset=-1   xadvance=59   page=0    chnl=0 
char id=374     x=786  y=148  width=43   height=71   xoffset=-4   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=375     x=405  y=0    width=41   height=73   xoffset=-4   yoffset=-1   xadvance=41   page=0    chnl=0 
char id=376     x=722  y=289  width=43   height=69   xoffset=-4   yoffset=-9   xadvance=43   page=0    chnl=0 
char id=377     x=548  y=219  width=41   height=70   xoffset=-3   yoffset=-11  xadvance=42   page=0    chnl=0 
char id=378     x=634  y=550  width=33   height=60   xoffset=-2   yoffset=-1   xadvance=36   page=0    chnl=0 
char id=379     x=197  y=358  width=41   height=68   xoffset=-3   yoffset=-9   xadvance=42   page=0    chnl=0 
char id=380     x=987  y=786  width=33   height=57   xoffset=-2   yoffset=2    xadvance=36   page=0    chnl=0 
char id=381     x=589  y=219  width=42   height=70   xoffset=-4   yoffset=-11  xadvance=42   page=0    chnl=0 
char id=382     x=667  y=550  width=36   height=60   xoffset=-5   yoffset=-1   xadvance=36   page=0    chnl=0 
char id=383     x=723  y=489  width=29   height=61   xoffset=0    yoffset=-1   xadvance=25   page=0    chnl=0 
char id=894     x=993  y=957  width=22   height=54   xoffset=-3   yoffset=16   xadvance=27   page=0    chnl=0 
char id=902     x=78   y=843  width=50   height=57   xoffset=-4   yoffset=3    xadvance=50   page=0    chnl=0 
char id=904     x=383  y=957  width=36   height=56   xoffset=1    yoffset=3    xadvance=43   page=0    chnl=0 
char id=905     x=128  y=843  width=43   height=57   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=906     x=171  y=843  width=17   height=57   xoffset=1    yoffset=3    xadvance=26   page=0    chnl=0 
char id=908     x=553  y=670  width=52   height=58   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=910     x=188  y=843  width=43   height=57   xoffset=-4   yoffset=3    xadvance=43   page=0    chnl=0 
char id=911     x=231  y=843  width=52   height=57   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=912     x=765  y=289  width=43   height=69   xoffset=-11  yoffset=-9   xadvance=26   page=0    chnl=0 
char id=914     x=419  y=957  width=41   height=56   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=915     x=283  y=843  width=34   height=57   xoffset=1    yoffset=3    xadvance=38   page=0    chnl=0 
char id=916     x=460  y=957  width=47   height=56   xoffset=-3   yoffset=3    xadvance=49   page=0    chnl=0 
char id=918     x=507  y=957  width=41   height=56   xoffset=-3   yoffset=3    xadvance=42   page=0    chnl=0 
char id=920     x=605  y=670  width=52   height=58   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=922     x=317  y=843  width=40   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=923     x=357  y=843  width=49   height=57   xoffset=-4   yoffset=3    xadvance=49   page=0    chnl=0 
char id=924     x=406  y=843  width=60   height=57   xoffset=1    yoffset=3    xadvance=70   page=0    chnl=0 
char id=925     x=466  y=843  width=45   height=57   xoffset=1    yoffset=3    xadvance=54   page=0    chnl=0 
char id=926     x=548  y=957  width=41   height=56   xoffset=-3   yoffset=3    xadvance=43   page=0    chnl=0 
char id=928     x=511  y=843  width=43   height=57   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=929     x=554  y=843  width=38   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=931     x=589  y=957  width=38   height=56   xoffset=-3   yoffset=3    xadvance=41   page=0    chnl=0 
char id=932     x=592  y=843  width=45   height=57   xoffset=-5   yoffset=3    xadvance=43   page=0    chnl=0 
char id=934     x=818  y=358  width=62   height=63   xoffset=-5   yoffset=0    xadvance=63   page=0    chnl=0 
char id=935     x=637  y=843  width=45   height=57   xoffset=-4   yoffset=3    xadvance=45   page=0    chnl=0 
char id=936     x=682  y=843  width=54   height=57   xoffset=0    yoffset=3    xadvance=62   page=0    chnl=0 
char id=939     x=808  y=289  width=43   height=69   xoffset=-4   yoffset=-9   xadvance=43   page=0    chnl=0 
char id=940     x=955  y=358  width=47   height=62   xoffset=-2   yoffset=-2   xadvance=49   page=0    chnl=0 
char id=941     x=0    y=427  width=37   height=62   xoffset=-2   yoffset=-2   xadvance=41   page=0    chnl=0 
char id=942     x=64   y=0    width=38   height=74   xoffset=0    yoffset=-2   xadvance=47   page=0    chnl=0 
char id=943     x=37   y=427  width=32   height=62   xoffset=-6   yoffset=-2   xadvance=28   page=0    chnl=0 
char id=946     x=446  y=0    width=40   height=73   xoffset=0    yoffset=-1   xadvance=46   page=0    chnl=0 
char id=947     x=736  y=843  width=40   height=57   xoffset=-5   yoffset=15   xadvance=40   page=0    chnl=0 
char id=948     x=752  y=489  width=44   height=61   xoffset=-5   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=950     x=851  y=289  width=34   height=69   xoffset=-3   yoffset=3    xadvance=33   page=0    chnl=0 
char id=951     x=657  y=670  width=38   height=58   xoffset=0    yoffset=14   xadvance=47   page=0    chnl=0 
char id=952     x=796  y=489  width=42   height=61   xoffset=-2   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=955     x=838  y=489  width=44   height=61   xoffset=-5   yoffset=-1   xadvance=41   page=0    chnl=0 
char id=956     x=776  y=843  width=44   height=57   xoffset=0    yoffset=15   xadvance=48   page=0    chnl=0 
char id=958     x=486  y=0    width=35   height=73   xoffset=-3   yoffset=-1   xadvance=35   page=0    chnl=0 
char id=961     x=695  y=670  width=40   height=58   xoffset=-1   yoffset=14   xadvance=45   page=0    chnl=0 
char id=962     x=735  y=670  width=35   height=58   xoffset=-2   yoffset=14   xadvance=38   page=0    chnl=0 
char id=966     x=0    y=76   width=52   height=72   xoffset=-3   yoffset=0    xadvance=55   page=0    chnl=0 
char id=967     x=820  y=843  width=42   height=57   xoffset=-5   yoffset=15   xadvance=39   page=0    chnl=0 
char id=968     x=52   y=76   width=51   height=72   xoffset=0    yoffset=0    xadvance=59   page=0    chnl=0 
char id=970     x=770  y=670  width=40   height=58   xoffset=-10  yoffset=2    xadvance=28   page=0    chnl=0 
char id=971     x=810  y=670  width=40   height=58   xoffset=-1   yoffset=2    xadvance=47   page=0    chnl=0 
char id=972     x=69   y=427  width=42   height=62   xoffset=-2   yoffset=-2   xadvance=46   page=0    chnl=0 
char id=973     x=111  y=427  width=39   height=62   xoffset=0    yoffset=-2   xadvance=47   page=0    chnl=0 
char id=974     x=150  y=427  width=54   height=62   xoffset=-2   yoffset=-2   xadvance=58   page=0    chnl=0 
char id=1024    x=631  y=219  width=40   height=70   xoffset=-3   yoffset=-11  xadvance=43   page=0    chnl=0 
char id=1025    x=238  y=358  width=39   height=68   xoffset=-2   yoffset=-9   xadvance=43   page=0    chnl=0 
char id=1026    x=862  y=843  width=52   height=57   xoffset=-5   yoffset=3    xadvance=53   page=0    chnl=0 
char id=1027    x=829  y=148  width=35   height=71   xoffset=1    yoffset=-11  xadvance=39   page=0    chnl=0 
char id=1028    x=850  y=670  width=44   height=58   xoffset=-2   yoffset=2    xadvance=47   page=0    chnl=0 
char id=1029    x=894  y=670  width=39   height=58   xoffset=-3   yoffset=2    xadvance=41   page=0    chnl=0 
char id=1030    x=914  y=843  width=17   height=57   xoffset=1    yoffset=3    xadvance=26   page=0    chnl=0 
char id=1031    x=885  y=289  width=43   height=69   xoffset=-11  yoffset=-9   xadvance=26   page=0    chnl=0 
char id=1032    x=931  y=843  width=28   height=57   xoffset=-5   yoffset=3    xadvance=31   page=0    chnl=0 
char id=1033    x=0    y=900  width=71   height=57   xoffset=-5   yoffset=3    xadvance=71   page=0    chnl=0 
char id=1034    x=71   y=900  width=66   height=57   xoffset=1    yoffset=3    xadvance=71   page=0    chnl=0 
char id=1035    x=959  y=843  width=52   height=57   xoffset=-5   yoffset=3    xadvance=53   page=0    chnl=0 
char id=1036    x=864  y=148  width=41   height=71   xoffset=1    yoffset=-11  xadvance=47   page=0    chnl=0 
char id=1037    x=905  y=148  width=45   height=71   xoffset=1    yoffset=-11  xadvance=54   page=0    chnl=0 
char id=1038    x=671  y=219  width=48   height=70   xoffset=-5   yoffset=-10  xadvance=46   page=0    chnl=0 
char id=1039    x=928  y=289  width=43   height=69   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=1040    x=137  y=900  width=50   height=57   xoffset=-4   yoffset=3    xadvance=50   page=0    chnl=0 
char id=1041    x=627  y=957  width=41   height=56   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=1042    x=668  y=957  width=41   height=56   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=1043    x=187  y=900  width=35   height=57   xoffset=1    yoffset=3    xadvance=39   page=0    chnl=0 
char id=1044    x=0    y=358  width=55   height=69   xoffset=-5   yoffset=3    xadvance=54   page=0    chnl=0 
char id=1045    x=709  y=957  width=36   height=56   xoffset=1    yoffset=3    xadvance=43   page=0    chnl=0 
char id=1046    x=222  y=900  width=63   height=57   xoffset=-3   yoffset=3    xadvance=66   page=0    chnl=0 
char id=1047    x=933  y=670  width=39   height=58   xoffset=-3   yoffset=2    xadvance=42   page=0    chnl=0 
char id=1048    x=285  y=900  width=45   height=57   xoffset=1    yoffset=3    xadvance=54   page=0    chnl=0 
char id=1049    x=719  y=219  width=45   height=70   xoffset=1    yoffset=-10  xadvance=54   page=0    chnl=0 
char id=1050    x=330  y=900  width=41   height=57   xoffset=1    yoffset=3    xadvance=47   page=0    chnl=0 
char id=1051    x=371  y=900  width=48   height=57   xoffset=-5   yoffset=3    xadvance=52   page=0    chnl=0 
char id=1052    x=419  y=900  width=60   height=57   xoffset=1    yoffset=3    xadvance=70   page=0    chnl=0 
char id=1053    x=479  y=900  width=43   height=57   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=1054    x=0    y=728  width=52   height=58   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=1055    x=522  y=900  width=43   height=57   xoffset=1    yoffset=3    xadvance=53   page=0    chnl=0 
char id=1056    x=565  y=900  width=38   height=57   xoffset=1    yoffset=3    xadvance=45   page=0    chnl=0 
char id=1057    x=972  y=670  width=44   height=58   xoffset=-2   yoffset=2    xadvance=46   page=0    chnl=0 
char id=1058    x=603  y=900  width=45   height=57   xoffset=-5   yoffset=3    xadvance=43   page=0    chnl=0 
char id=1059    x=648  y=900  width=48   height=57   xoffset=-5   yoffset=3    xadvance=46   page=0    chnl=0 
char id=1060    x=209  y=611  width=57   height=59   xoffset=-5   yoffset=1    xadvance=58   page=0    chnl=0 
char id=1061    x=696  y=900  width=45   height=57   xoffset=-4   yoffset=3    xadvance=45   page=0    chnl=0 
char id=1062    x=971  y=289  width=49   height=69   xoffset=1    yoffset=3    xadvance=54   page=0    chnl=0 
char id=1063    x=741  y=900  width=40   height=57   xoffset=-1   yoffset=3    xadvance=48   page=0    chnl=0 
char id=1064    x=745  y=957  width=61   height=56   xoffset=1    yoffset=3    xadvance=71   page=0    chnl=0 
char id=1065    x=55   y=358  width=67   height=69   xoffset=1    yoffset=3    xadvance=72   page=0    chnl=0 
char id=1066    x=806  y=957  width=53   height=56   xoffset=-5   yoffset=3    xadvance=52   page=0    chnl=0 
char id=1067    x=781  y=900  width=53   height=57   xoffset=1    yoffset=3    xadvance=63   page=0    chnl=0 
char id=1068    x=859  y=957  width=41   height=56   xoffset=1    yoffset=3    xadvance=46   page=0    chnl=0 
char id=1069    x=52   y=728  width=43   height=58   xoffset=-2   yoffset=2    xadvance=47   page=0    chnl=0 
char id=1070    x=95   y=728  width=64   height=58   xoffset=1    yoffset=2    xadvance=71   page=0    chnl=0 
char id=1071    x=834  y=900  width=42   height=57   xoffset=-3   yoffset=3    xadvance=48   page=0    chnl=0 
char id=1073    x=882  y=489  width=45   height=61   xoffset=-5   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=1076    x=900  y=957  width=50   height=56   xoffset=-5   yoffset=15   xadvance=48   page=0    chnl=0 
char id=1081    x=703  y=550  width=40   height=60   xoffset=-1   yoffset=0    xadvance=47   page=0    chnl=0 
char id=1088    x=159  y=728  width=40   height=58   xoffset=0    yoffset=14   xadvance=46   page=0    chnl=0 
char id=1091    x=876  y=900  width=41   height=57   xoffset=-4   yoffset=15   xadvance=41   page=0    chnl=0 
char id=1092    x=103  y=76   width=51   height=72   xoffset=-3   yoffset=0    xadvance=53   page=0    chnl=0 
char id=1094    x=950  y=957  width=43   height=56   xoffset=0    yoffset=15   xadvance=47   page=0    chnl=0 
char id=1104    x=927  y=489  width=40   height=61   xoffset=-2   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=1105    x=199  y=728  width=40   height=58   xoffset=-2   yoffset=2    xadvance=44   page=0    chnl=0 
char id=1106    x=521  y=0    width=45   height=73   xoffset=-6   yoffset=-1   xadvance=47   page=0    chnl=0 
char id=1107    x=967  y=489  width=30   height=61   xoffset=0    yoffset=-1   xadvance=33   page=0    chnl=0 
char id=1110    x=239  y=728  width=18   height=58   xoffset=-1   yoffset=2    xadvance=25   page=0    chnl=0 
char id=1111    x=257  y=728  width=37   height=58   xoffset=-10  yoffset=2    xadvance=25   page=0    chnl=0 
char id=1112    x=764  y=219  width=26   height=70   xoffset=-7   yoffset=2    xadvance=25   page=0    chnl=0 
char id=1115    x=0    y=550  width=45   height=61   xoffset=-6   yoffset=-1   xadvance=46   page=0    chnl=0 
char id=1116    x=45   y=550  width=37   height=61   xoffset=0    yoffset=-1   xadvance=41   page=0    chnl=0 
char id=1117    x=82   y=550  width=39   height=61   xoffset=0    yoffset=-1   xadvance=47   page=0    chnl=0 
char id=1118    x=978  y=0    width=41   height=72   xoffset=-4   yoffset=0    xadvance=41   page=0    chnl=0 
char id=1122    x=294  y=728  width=52   height=58   xoffset=-5   yoffset=1    xadvance=53   page=0    chnl=0 
char id=1138    x=346  y=728  width=52   height=58   xoffset=-2   yoffset=2    xadvance=56   page=0    chnl=0 
char id=1140    x=398  y=728  width=52   height=58   xoffset=-4   yoffset=2    xadvance=50   page=0    chnl=0 
char id=1168    x=470  y=358  width=35   height=67   xoffset=1    yoffset=-7   xadvance=39   page=0    chnl=0 
char id=8204    x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=8    page=0    chnl=0 
char id=8225    x=121  y=550  width=38   height=61   xoffset=-1   yoffset=-1   xadvance=44   page=0    chnl=0 
char id=8240    x=266  y=611  width=81   height=59   xoffset=-3   yoffset=2    xadvance=83   page=0    chnl=0 
char id=8260    x=347  y=611  width=60   height=59   xoffset=-12  yoffset=2    xadvance=32   page=0    chnl=0 
char id=8364    x=450  y=728  width=44   height=58   xoffset=-4   yoffset=2    xadvance=45   page=0    chnl=0 
char id=34      x=369  y=147  width=30   height=30   xoffset=-1   yoffset=-1   xadvance=37   page=1    chnl=0 
char id=39      x=399  y=147  width=17   height=30   xoffset=-1   yoffset=-1   xadvance=24   page=1    chnl=0 
char id=42      x=260  y=147  width=36   height=37   xoffset=0    yoffset=-1   xadvance=44   page=1    chnl=0 
char id=43      x=829  y=102  width=42   height=44   xoffset=-3   yoffset=12   xadvance=44   page=1    chnl=0 
char id=44      x=494  y=147  width=23   height=29   xoffset=-4   yoffset=41   xadvance=26   page=1    chnl=0 
char id=45      x=104  y=187  width=28   height=16   xoffset=-3   yoffset=28   xadvance=30   page=1    chnl=0 
char id=46      x=0    y=187  width=18   height=19   xoffset=0    yoffset=41   xadvance=26   page=1    chnl=0 
char id=58      x=1002 y=56   width=18   height=44   xoffset=1    yoffset=16   xadvance=27   page=1    chnl=0 
char id=60      x=401  y=56   width=41   height=45   xoffset=-3   yoffset=11   xadvance=44   page=1    chnl=0 
char id=61      x=416  y=147  width=40   height=30   xoffset=-2   yoffset=19   xadvance=44   page=1    chnl=0 
char id=62      x=442  y=56   width=41   height=45   xoffset=-2   yoffset=11   xadvance=44   page=1    chnl=0 
char id=94      x=296  y=147  width=39   height=37   xoffset=-2   yoffset=3    xadvance=44   page=1    chnl=0 
char id=95      x=245  y=187  width=47   height=15   xoffset=-6   yoffset=57   xadvance=44   page=1    chnl=0 
char id=96      x=758  y=147  width=27   height=27   xoffset=-5   yoffset=-1   xadvance=29   page=1    chnl=0 
char id=97      x=355  y=0    width=37   height=46   xoffset=-2   yoffset=14   xadvance=42   page=1    chnl=0 
char id=99      x=392  y=0    width=36   height=46   xoffset=-2   yoffset=14   xadvance=38   page=1    chnl=0 
char id=101     x=428  y=0    width=40   height=46   xoffset=-2   yoffset=14   xadvance=44   page=1    chnl=0 
char id=109     x=468  y=0    width=58   height=46   xoffset=0    yoffset=14   xadvance=66   page=1    chnl=0 
char id=110     x=526  y=0    width=38   height=46   xoffset=0    yoffset=14   xadvance=46   page=1    chnl=0 
char id=111     x=564  y=0    width=42   height=46   xoffset=-2   yoffset=14   xadvance=46   page=1    chnl=0 
char id=114     x=606  y=0    width=29   height=46   xoffset=0    yoffset=14   xadvance=33   page=1    chnl=0 
char id=115     x=635  y=0    width=34   height=46   xoffset=-3   yoffset=14   xadvance=36   page=1    chnl=0 
char id=116     x=96   y=0    width=31   height=54   xoffset=-4   yoffset=6    xadvance=32   page=1    chnl=0 
char id=117     x=483  y=56   width=38   height=45   xoffset=0    yoffset=15   xadvance=46   page=1    chnl=0 
char id=118     x=521  y=56   width=41   height=45   xoffset=-4   yoffset=15   xadvance=41   page=1    chnl=0 
char id=119     x=562  y=56   width=59   height=45   xoffset=-4   yoffset=15   xadvance=59   page=1    chnl=0 
char id=120     x=621  y=56   width=39   height=45   xoffset=-4   yoffset=15   xadvance=39   page=1    chnl=0 
char id=122     x=871  y=102  width=33   height=44   xoffset=-2   yoffset=15   xadvance=36   page=1    chnl=0 
char id=126     x=843  y=147  width=42   height=24   xoffset=-3   yoffset=9    xadvance=44   page=1    chnl=0 
char id=164     x=313  y=0    width=42   height=50   xoffset=-3   yoffset=9    xadvance=44   page=1    chnl=0 
char id=168     x=885  y=147  width=34   height=24   xoffset=-5   yoffset=2    xadvance=36   page=1    chnl=0 
char id=170     x=904  y=102  width=30   height=44   xoffset=-1   yoffset=3    xadvance=37   page=1    chnl=0 
char id=171     x=0    y=147  width=37   height=40   xoffset=-1   yoffset=16   xadvance=45   page=1    chnl=0 
char id=172     x=919  y=147  width=41   height=23   xoffset=-3   yoffset=26   xadvance=44   page=1    chnl=0 
char id=174     x=222  y=147  width=38   height=38   xoffset=-1   yoffset=-1   xadvance=45   page=1    chnl=0 
char id=175     x=960  y=147  width=34   height=23   xoffset=-5   yoffset=3    xadvance=36   page=1    chnl=0 
char id=176     x=785  y=147  width=31   height=27   xoffset=-5   yoffset=1    xadvance=32   page=1    chnl=0 
char id=177     x=271  y=0    width=42   height=52   xoffset=-3   yoffset=7    xadvance=44   page=1    chnl=0 
char id=178     x=139  y=147  width=30   height=39   xoffset=-3   yoffset=-4   xadvance=32   page=1    chnl=0 
char id=179     x=169  y=147  width=30   height=39   xoffset=-3   yoffset=-4   xadvance=32   page=1    chnl=0 
char id=180     x=816  y=147  width=27   height=27   xoffset=-5   yoffset=-1   xadvance=29   page=1    chnl=0 
char id=183     x=18   y=187  width=18   height=19   xoffset=0    yoffset=24   xadvance=26   page=1    chnl=0 
char id=184     x=994  y=147  width=24   height=23   xoffset=-1   yoffset=49   xadvance=30   page=1    chnl=0 
char id=185     x=199  y=147  width=23   height=39   xoffset=-4   yoffset=-4   xadvance=26   page=1    chnl=0 
char id=186     x=660  y=56   width=34   height=45   xoffset=-2   yoffset=2    xadvance=38   page=1    chnl=0 
char id=187     x=37   y=147  width=37   height=40   xoffset=1    yoffset=16   xadvance=45   page=1    chnl=0 
char id=215     x=74   y=147  width=40   height=40   xoffset=-2   yoffset=14   xadvance=44   page=1    chnl=0 
char id=230     x=669  y=0    width=60   height=46   xoffset=-2   yoffset=14   xadvance=64   page=1    chnl=0 
char id=247     x=729  y=0    width=43   height=46   xoffset=-4   yoffset=11   xadvance=44   page=1    chnl=0 
char id=305     x=1000 y=0    width=17   height=45   xoffset=0    yoffset=15   xadvance=25   page=1    chnl=0 
char id=312     x=694  y=56   width=37   height=45   xoffset=0    yoffset=15   xadvance=41   page=1    chnl=0 
char id=339     x=772  y=0    width=65   height=46   xoffset=-2   yoffset=14   xadvance=69   page=1    chnl=0 
char id=359     x=127  y=0    width=31   height=54   xoffset=-4   yoffset=6    xadvance=33   page=1    chnl=0 
char id=884     x=456  y=147  width=19   height=30   xoffset=-2   yoffset=0    xadvance=26   page=1    chnl=0 
char id=885     x=475  y=147  width=19   height=30   xoffset=-2   yoffset=38   xadvance=26   page=1    chnl=0 
char id=900     x=691  y=147  width=26   height=28   xoffset=-5   yoffset=-2   xadvance=31   page=1    chnl=0 
char id=901     x=717  y=147  width=41   height=28   xoffset=-5   yoffset=-2   xadvance=44   page=1    chnl=0 
char id=903     x=36   y=187  width=18   height=19   xoffset=0    yoffset=11   xadvance=26   page=1    chnl=0 
char id=945     x=837  y=0    width=47   height=46   xoffset=-2   yoffset=14   xadvance=49   page=1    chnl=0 
char id=949     x=884  y=0    width=37   height=46   xoffset=-2   yoffset=14   xadvance=41   page=1    chnl=0 
char id=953     x=731  y=56   width=25   height=45   xoffset=0    yoffset=15   xadvance=28   page=1    chnl=0 
char id=954     x=756  y=56   width=37   height=45   xoffset=0    yoffset=15   xadvance=41   page=1    chnl=0 
char id=957     x=793  y=56   width=40   height=45   xoffset=-4   yoffset=15   xadvance=40   page=1    chnl=0 
char id=959     x=921  y=0    width=42   height=46   xoffset=-2   yoffset=14   xadvance=46   page=1    chnl=0 
char id=960     x=833  y=56   width=48   height=45   xoffset=-4   yoffset=15   xadvance=48   page=1    chnl=0 
char id=963     x=881  y=56   width=44   height=45   xoffset=-2   yoffset=15   xadvance=46   page=1    chnl=0 
char id=964     x=925  y=56   width=38   height=45   xoffset=-5   yoffset=15   xadvance=36   page=1    chnl=0 
char id=965     x=963  y=56   width=39   height=45   xoffset=0    yoffset=15   xadvance=47   page=1    chnl=0 
char id=969     x=0    y=102  width=54   height=45   xoffset=-2   yoffset=15   xadvance=58   page=1    chnl=0 
char id=1072    x=963  y=0    width=37   height=46   xoffset=-2   yoffset=14   xadvance=42   page=1    chnl=0 
char id=1074    x=54   y=102  width=37   height=45   xoffset=0    yoffset=15   xadvance=42   page=1    chnl=0 
char id=1075    x=91   y=102  width=30   height=45   xoffset=0    yoffset=15   xadvance=33   page=1    chnl=0 
char id=1077    x=0    y=56   width=40   height=46   xoffset=-2   yoffset=14   xadvance=44   page=1    chnl=0 
char id=1078    x=121  y=102  width=56   height=45   xoffset=-3   yoffset=15   xadvance=58   page=1    chnl=0 
char id=1079    x=40   y=56   width=36   height=46   xoffset=-3   yoffset=14   xadvance=38   page=1    chnl=0 
char id=1080    x=177  y=102  width=39   height=45   xoffset=0    yoffset=15   xadvance=47   page=1    chnl=0 
char id=1082    x=216  y=102  width=37   height=45   xoffset=0    yoffset=15   xadvance=41   page=1    chnl=0 
char id=1083    x=253  y=102  width=42   height=45   xoffset=-5   yoffset=15   xadvance=45   page=1    chnl=0 
char id=1084    x=295  y=102  width=53   height=45   xoffset=-2   yoffset=15   xadvance=57   page=1    chnl=0 
char id=1085    x=348  y=102  width=39   height=45   xoffset=0    yoffset=15   xadvance=47   page=1    chnl=0 
char id=1086    x=76   y=56   width=42   height=46   xoffset=-2   yoffset=14   xadvance=46   page=1    chnl=0 
char id=1087    x=387  y=102  width=38   height=45   xoffset=0    yoffset=15   xadvance=45   page=1    chnl=0 
char id=1089    x=118  y=56   width=36   height=46   xoffset=-2   yoffset=14   xadvance=38   page=1    chnl=0 
char id=1090    x=425  y=102  width=38   height=45   xoffset=-5   yoffset=15   xadvance=36   page=1    chnl=0 
char id=1093    x=463  y=102  width=39   height=45   xoffset=-4   yoffset=15   xadvance=39   page=1    chnl=0 
char id=1095    x=502  y=102  width=37   height=45   xoffset=-3   yoffset=15   xadvance=42   page=1    chnl=0 
char id=1096    x=934  y=102  width=53   height=44   xoffset=0    yoffset=15   xadvance=60   page=1    chnl=0 
char id=1097    x=0    y=0    width=58   height=56   xoffset=0    yoffset=15   xadvance=62   page=1    chnl=0 
char id=1098    x=539  y=102  width=47   height=45   xoffset=-5   yoffset=15   xadvance=47   page=1    chnl=0 
char id=1099    x=586  y=102  width=48   height=45   xoffset=0    yoffset=15   xadvance=56   page=1    chnl=0 
char id=1100    x=634  y=102  width=37   height=45   xoffset=0    yoffset=15   xadvance=42   page=1    chnl=0 
char id=1101    x=154  y=56   width=37   height=46   xoffset=-3   yoffset=14   xadvance=40   page=1    chnl=0 
char id=1102    x=191  y=56   width=54   height=46   xoffset=0    yoffset=14   xadvance=60   page=1    chnl=0 
char id=1103    x=671  y=102  width=38   height=45   xoffset=-4   yoffset=15   xadvance=42   page=1    chnl=0 
char id=1108    x=245  y=56   width=37   height=46   xoffset=-2   yoffset=14   xadvance=40   page=1    chnl=0 
char id=1109    x=282  y=56   width=34   height=46   xoffset=-3   yoffset=14   xadvance=36   page=1    chnl=0 
char id=1113    x=709  y=102  width=62   height=45   xoffset=-5   yoffset=15   xadvance=62   page=1    chnl=0 
char id=1114    x=771  y=102  width=58   height=45   xoffset=0    yoffset=15   xadvance=63   page=1    chnl=0 
char id=1119    x=58   y=0    width=38   height=56   xoffset=0    yoffset=15   xadvance=46   page=1    chnl=0 
char id=1123    x=158  y=0    width=45   height=54   xoffset=-5   yoffset=6    xadvance=45   page=1    chnl=0 
char id=1139    x=316  y=56   width=42   height=46   xoffset=-2   yoffset=14   xadvance=46   page=1    chnl=0 
char id=1141    x=358  y=56   width=43   height=46   xoffset=-4   yoffset=14   xadvance=42   page=1    chnl=0 
char id=1169    x=241  y=0    width=30   height=53   xoffset=0    yoffset=7    xadvance=33   page=1    chnl=0 
char id=8211    x=132  y=187  width=48   height=16   xoffset=-6   yoffset=26   xadvance=44   page=1    chnl=0 
char id=8212    x=180  y=187  width=65   height=16   xoffset=0    yoffset=26   xadvance=73   page=1    chnl=0 
char id=8213    x=180  y=187  width=65   height=16   xoffset=0    yoffset=26   xadvance=73   page=1    chnl=0 
char id=8216    x=517  y=147  width=23   height=29   xoffset=-1   yoffset=-1   xadvance=26   page=1    chnl=0 
char id=8217    x=540  y=147  width=23   height=29   xoffset=-4   yoffset=-1   xadvance=26   page=1    chnl=0 
char id=8218    x=563  y=147  width=23   height=29   xoffset=-4   yoffset=41   xadvance=26   page=1    chnl=0 
char id=8220    x=586  y=147  width=35   height=29   xoffset=-1   yoffset=-1   xadvance=38   page=1    chnl=0 
char id=8221    x=621  y=147  width=35   height=29   xoffset=-4   yoffset=-1   xadvance=38   page=1    chnl=0 
char id=8222    x=656  y=147  width=35   height=29   xoffset=-4   yoffset=41   xadvance=38   page=1    chnl=0 
char id=8224    x=203  y=0    width=38   height=54   xoffset=-1   yoffset=-1   xadvance=44   page=1    chnl=0 
char id=8226    x=335  y=147  width=34   height=33   xoffset=1    yoffset=15   xadvance=44   page=1    chnl=0 
char id=8230    x=54   y=187  width=50   height=19   xoffset=0    yoffset=41   xadvance=58   page=1    chnl=0 
char id=8249    x=987  y=102  width=24   height=40   xoffset=-1   yoffset=16   xadvance=32   page=1    chnl=0 
char id=8250    x=114  y=147  width=25   height=40   xoffset=1    yoffset=16   xadvance=32   page=1    chnl=0 
kernings count=9670
kerning first=8221 second=65 amount=-9
kerning first=219 second=192 amount=-2
kerning first=8222 second=193 amount=1
kerning first=200 second=194 amount=-1
kerning first=201 second=195 amount=-1
kerning first=47 second=196 amount=-2
kerning first=44 second=256 amount=1
kerning first=338 second=197 amount=-1
kerning first=8222 second=260 amount=1
kerning first=221 second=67 amount=-2
kerning first=46 second=264 amount=-1
kerning first=342 second=268 amount=-1
kerning first=89 second=266 amount=-2
kerning first=82 second=199 amount=-1
kerning first=8222 second=374 amount=-8
kerning first=310 second=71 amount=-3
kerning first=372 second=284 amount=-1
kerning first=8222 second=286 amount=-1
kerning first=200 second=288 amount=-1
kerning first=80 second=308 amount=-5
kerning first=193 second=87 amount=-3
kerning first=374 second=119 amount=-2
kerning first=201 second=79 amount=-1
kerning first=46 second=210 amount=-1
kerning first=8221 second=211 amount=-3
kerning first=372 second=213 amount=-1
kerning first=84 second=214 amount=-2
kerning first=8222 second=332 amount=-2
kerning first=342 second=334 amount=-1
kerning first=315 second=372 amount=-4
kerning first=8221 second=338 amount=-3
kerning first=280 second=346 amount=-1
kerning first=376 second=348 amount=-1
kerning first=276 second=352 amount=-1
kerning first=280 second=350 amount=-1
kerning first=46 second=84 amount=-5
kerning first=272 second=356 amount=-1
kerning first=8221 second=354 amount=1
kerning first=194 second=85 amount=-1
kerning first=65 second=217 amount=-1
kerning first=46 second=218 amount=-1
kerning first=8222 second=219 amount=-1
kerning first=194 second=360 amount=-1
kerning first=65 second=220 amount=-1
kerning first=310 second=362 amount=-1
kerning first=197 second=364 amount=-1
kerning first=194 second=366 amount=-1
kerning first=76 second=368 amount=-2
kerning first=192 second=370 amount=-1
kerning first=334 second=86 amount=-1
kerning first=340 second=87 amount=-1
kerning first=270 second=88 amount=-1
kerning first=260 second=89 amount=-5
kerning first=315 second=221 amount=-6
kerning first=214 second=374 amount=-2
kerning first=352 second=376 amount=-1
kerning first=211 second=90 amount=-1
kerning first=211 second=377 amount=-1
kerning first=66 second=381 amount=-1
kerning first=214 second=379 amount=-1
kerning first=284 second=373 amount=-1
kerning first=89 second=45 amount=-4
kerning first=260 second=375 amount=-1
kerning first=310 second=67 amount=-3
kerning first=87 second=224 amount=-2
kerning first=118 second=225 amount=-1
kerning first=118 second=226 amount=-1
kerning first=8217 second=227 amount=-5
kerning first=8221 second=228 amount=-5
kerning first=265 second=257 amount=-1
kerning first=374 second=259 amount=-5
kerning first=8221 second=230 amount=-5
kerning first=70 second=213 amount=-1
kerning first=8216 second=99 amount=-3
kerning first=121 second=263 amount=-1
kerning first=372 second=265 amount=-3
kerning first=122 second=267 amount=-2
kerning first=282 second=231 amount=-1
kerning first=378 second=100 amount=-2
kerning first=1027 second=1141 amount=-5
kerning first=119 second=232 amount=-1
kerning first=8220 second=233 amount=-3
kerning first=378 second=234 amount=-2
kerning first=274 second=283 amount=-1
kerning first=278 second=235 amount=-1
kerning first=122 second=275 amount=-2
kerning first=378 second=277 amount=-2
kerning first=198 second=279 amount=-1
kerning first=343 second=281 amount=-1
kerning first=198 second=102 amount=-2
kerning first=82 second=354 amount=-1
kerning first=103 second=103 amount=1
kerning first=46 second=285 amount=1
kerning first=81 second=287 amount=2
kerning first=341 second=289 amount=-1
kerning first=89 second=291 amount=-5
kerning first=271 second=104 amount=1
kerning first=89 second=237 amount=-2
kerning first=377 second=297 amount=4
kerning first=87 second=299 amount=2
kerning first=356 second=301 amount=3
kerning first=84 second=305 amount=-4
kerning first=81 second=106 amount=3
kerning first=259 second=355 amount=-1
kerning first=75 second=309 amount=1
kerning first=297 second=107 amount=1
kerning first=271 second=108 amount=1
kerning first=271 second=314 amount=1
kerning first=271 second=316 amount=1
kerning first=313 second=284 amount=-2
kerning first=354 second=110 amount=-4
kerning first=221 second=324 amount=-3
kerning first=75 second=241 amount=-1
kerning first=86 second=331 amount=-2
kerning first=376 second=111 amount=-5
kerning first=201 second=242 amount=-1
kerning first=282 second=243 amount=-1
kerning first=253 second=244 amount=-1
kerning first=8221 second=245 amount=-5
kerning first=87 second=333 amount=-3
kerning first=8216 second=335 amount=-2
kerning first=340 second=337 amount=-1
kerning first=355 second=248 amount=-1
kerning first=8221 second=339 amount=-5
kerning first=103 second=113 amount=-1
kerning first=75 second=114 amount=-1
kerning first=376 second=341 amount=-3
kerning first=84 second=345 amount=-4
kerning first=8221 second=343 amount=-2
kerning first=8220 second=115 amount=-2
kerning first=8220 second=347 amount=-2
kerning first=341 second=349 amount=-1
kerning first=312 second=351 amount=-1
kerning first=356 second=115 amount=-5
kerning first=374 second=357 amount=-2
kerning first=355 second=355 amount=-1
kerning first=8217 second=239 amount=2
kerning first=87 second=117 amount=-2
kerning first=87 second=249 amount=-2
kerning first=221 second=250 amount=-3
kerning first=75 second=251 amount=-1
kerning first=86 second=363 amount=-2
kerning first=75 second=365 amount=-1
kerning first=84 second=367 amount=-4
kerning first=8221 second=369 amount=-2
kerning first=354 second=371 amount=-4
kerning first=8222 second=118 amount=-5
kerning first=8217 second=120 amount=-2
kerning first=380 second=255 amount=-1
kerning first=8221 second=253 amount=-2
kerning first=379 second=375 amount=-1
kerning first=191 second=255 amount=-2
kerning first=353 second=122 amount=-1
kerning first=333 second=378 amount=-1
kerning first=253 second=382 amount=-1
kerning first=351 second=380 amount=-1
kerning first=354 second=255 amount=-3
kerning first=192 second=87 amount=-3
kerning first=192 second=336 amount=-1
kerning first=340 second=376 amount=-1
kerning first=201 second=255 amount=-2
kerning first=312 second=228 amount=-1
kerning first=376 second=106 amount=-2
kerning first=343 second=224 amount=-1
kerning first=256 second=262 amount=-1
kerning first=382 second=255 amount=-1
kerning first=1058 second=1103 amount=-5
kerning first=114 second=279 amount=-1
kerning first=68 second=256 amount=-1
kerning first=340 second=84 amount=-1
kerning first=47 second=902 amount=-3
kerning first=8222 second=916 amount=1
kerning first=920 second=918 amount=-1
kerning first=914 second=923 amount=-1
kerning first=902 second=908 amount=-1
kerning first=902 second=932 amount=-6
kerning first=902 second=910 amount=-5
kerning first=282 second=352 amount=-1
kerning first=8218 second=939 amount=-7
kerning first=268 second=8217 amount=3
kerning first=1084 second=1098 amount=-1
kerning first=8216 second=192 amount=-6
kerning first=278 second=279 amount=-1
kerning first=955 second=945 amount=-1
kerning first=954 second=940 amount=-2
kerning first=970 second=947 amount=-1
kerning first=950 second=949 amount=-1
kerning first=950 second=941 amount=-1
kerning first=118 second=248 amount=-1
kerning first=310 second=337 amount=-1
kerning first=375 second=233 amount=-1
kerning first=338 second=375 amount=-2
kerning first=950 second=953 amount=-2
kerning first=955 second=970 amount=-1
kerning first=102 second=380 amount=-1
kerning first=904 second=959 amount=-1
kerning first=932 second=972 amount=-6
kerning first=955 second=960 amount=-1
kerning first=947 second=961 amount=-1
kerning first=945 second=963 amount=-1
kerning first=954 second=962 amount=-2
kerning first=950 second=964 amount=-2
kerning first=950 second=965 amount=-1
kerning first=950 second=971 amount=-1
kerning first=949 second=966 amount=-1
kerning first=272 second=86 amount=-1
kerning first=945 second=974 amount=-1
kerning first=310 second=360 amount=-1
kerning first=375 second=227 amount=-1
kerning first=1058 second=1044 amount=-4
kerning first=341 second=118 amount=1
kerning first=1054 second=1047 amount=-1
kerning first=902 second=939 amount=-5
kerning first=221 second=248 amount=-5
kerning first=289 second=230 amount=-1
kerning first=1138 second=1051 amount=-2
kerning first=194 second=8216 amount=-6
kerning first=1027 second=1102 amount=-5
kerning first=1038 second=1054 amount=-2
kerning first=1040 second=1138 amount=-2
kerning first=203 second=266 amount=-1
kerning first=1025 second=1057 amount=-2
kerning first=1054 second=1058 amount=-2
kerning first=1069 second=1059 amount=-1
kerning first=1062 second=1038 amount=-1
kerning first=1168 second=1060 amount=-3
kerning first=1070 second=1061 amount=-1
kerning first=213 second=8216 amount=-1
kerning first=1068 second=1063 amount=-4
kerning first=1040 second=1066 amount=-4
kerning first=1054 second=1069 amount=-1
kerning first=1024 second=1028 amount=-2
kerning first=1043 second=1071 amount=-1
kerning first=1036 second=1026 amount=-1
kerning first=1041 second=1035 amount=-2
kerning first=280 second=118 amount=-2
kerning first=224 second=8216 amount=-1
kerning first=203 second=231 amount=-1
kerning first=281 second=8216 amount=-1
kerning first=199 second=8217 amount=3
kerning first=1078 second=1072 amount=-1
kerning first=1040 second=1073 amount=-1
kerning first=1059 second=1074 amount=-2
kerning first=1058 second=1075 amount=-5
kerning first=1059 second=1107 amount=-2
kerning first=1043 second=1169 amount=-5
kerning first=8218 second=1076 amount=1
kerning first=1059 second=1077 amount=-4
kerning first=1024 second=1104 amount=-2
kerning first=1036 second=1105 amount=-1
kerning first=1043 second=1079 amount=-5
kerning first=1043 second=1080 amount=-5
kerning first=1043 second=1081 amount=-5
kerning first=1060 second=1083 amount=-3
kerning first=1169 second=1113 amount=-3
kerning first=346 second=221 amount=-1
kerning first=1058 second=1114 amount=-5
kerning first=1043 second=1086 amount=-8
kerning first=1043 second=1087 amount=-5
kerning first=1043 second=1088 amount=-5
kerning first=1043 second=1109 amount=-7
kerning first=1038 second=1090 amount=-1
kerning first=1044 second=1091 amount=-1
kerning first=8218 second=1118 amount=-3
kerning first=1040 second=1141 amount=-2
kerning first=1061 second=1092 amount=-2
kerning first=1059 second=1093 amount=-2
kerning first=1043 second=1094 amount=-5
kerning first=1043 second=1119 amount=-5
kerning first=1040 second=1095 amount=-1
kerning first=276 second=226 amount=-1
kerning first=1043 second=1097 amount=-5
kerning first=1040 second=1098 amount=-3
kerning first=1043 second=1099 amount=-5
kerning first=1043 second=1100 amount=-5
kerning first=1043 second=1101 amount=-5
kerning first=1056 second=1108 amount=-1
kerning first=1038 second=1102 amount=-2
kerning first=1043 second=1103 amount=-6
kerning first=1123 second=8216 amount=-4
kerning first=1168 second=1057 amount=-2
kerning first=374 second=268 amount=-2
kerning first=255 second=281 amount=-1
kerning first=80 second=44 amount=-9
kerning first=372 second=59 amount=-5
kerning first=89 second=58 amount=-5
kerning first=218 second=46 amount=-1
kerning first=918 second=8217 amount=2
kerning first=80 second=224 amount=-2
kerning first=310 second=334 amount=-3
kerning first=1043 second=187 amount=-2
kerning first=178 second=47 amount=-10
kerning first=198 second=81 amount=-1
kerning first=274 second=253 amount=-2
kerning first=76 second=183 amount=-2
kerning first=81 second=41 amount=1
kerning first=354 second=192 amount=-6
kerning first=81 second=125 amount=1
kerning first=955 second=957 amount=-3
kerning first=231 second=337 amount=-1
kerning first=201 second=192 amount=-1
kerning first=354 second=369 amount=-4
kerning first=178 second=8260 amount=-7
kerning first=291 second=230 amount=-1
kerning first=102 second=174 amount=1
kerning first=258 second=71 amount=-1
kerning first=221 second=230 amount=-5
kerning first=47 second=256 amount=-2
kerning first=372 second=71 amount=-1
kerning first=274 second=332 amount=-1
kerning first=315 second=255 amount=-3
kerning first=79 second=65 amount=-1
kerning first=1024 second=1091 amount=-1
kerning first=334 second=381 amount=-1
kerning first=8221 second=301 amount=2
kerning first=364 second=65 amount=-2
kerning first=84 second=331 amount=-4
kerning first=1091 second=1104 amount=-1
kerning first=87 second=232 amount=-3
kerning first=121 second=8217 amount=2
kerning first=341 second=335 amount=-1
kerning first=8217 second=228 amount=-5
kerning first=47 second=185 amount=-7
kerning first=47 second=179 amount=-9
kerning first=75 second=79 amount=-3
kerning first=221 second=281 amount=-5
kerning first=67 second=286 amount=-1
kerning first=1075 second=1086 amount=-1
kerning first=82 second=286 amount=-1
kerning first=221 second=348 amount=-1
kerning first=376 second=286 amount=-2
kerning first=375 second=243 amount=-1
kerning first=291 second=281 amount=-1
kerning first=118 second=281 amount=-1
kerning first=197 second=221 amount=-5
kerning first=355 second=281 amount=-1
kerning first=119 second=267 amount=-1
kerning first=356 second=110 amount=-4
kerning first=1060 second=44 amount=-6
kerning first=378 second=275 amount=-2
kerning first=1027 second=1072 amount=-5
kerning first=114 second=337 amount=-1
kerning first=118 second=259 amount=-1
kerning first=233 second=120 amount=-1
kerning first=1036 second=1028 amount=-2
kerning first=121 second=97 amount=-1
kerning first=1092 second=1078 amount=-1
kerning first=310 second=346 amount=-1
kerning first=1038 second=1091 amount=-1
kerning first=1027 second=1074 amount=-5
kerning first=120 second=257 amount=-1
kerning first=75 second=199 amount=-3
kerning first=276 second=265 amount=-1
kerning first=376 second=333 amount=-5
kerning first=1079 second=1113 amount=-1
kerning first=373 second=347 amount=-1
kerning first=334 second=90 amount=-1
kerning first=378 second=291 amount=-1
kerning first=114 second=45 amount=-2
kerning first=950 second=940 amount=-2
kerning first=197 second=308 amount=1
kerning first=256 second=370 amount=-1
kerning first=954 second=974 amount=-2
kerning first=87 second=111 amount=-3
kerning first=280 second=264 amount=-1
kerning first=278 second=45 amount=-1
kerning first=88 second=116 amount=-1
kerning first=1027 second=1078 amount=-5
kerning first=345 second=246 amount=-1
kerning first=102 second=281 amount=-2
kerning first=8221 second=113 amount=-5
kerning first=311 second=248 amount=-2
kerning first=75 second=249 amount=-1
kerning first=940 second=969 amount=-1
kerning first=372 second=279 amount=-3
kerning first=1044 second=1026 amount=-1
kerning first=70 second=246 amount=-1
kerning first=954 second=961 amount=-1
kerning first=1027 second=1060 amount=-3
kerning first=8260 second=185 amount=-6
kerning first=374 second=267 amount=-6
kerning first=950 second=943 amount=-2
kerning first=198 second=248 amount=-1
kerning first=366 second=258 amount=-2
kerning first=269 second=339 amount=-1
kerning first=121 second=111 amount=-1
kerning first=210 second=258 amount=-1
kerning first=374 second=234 amount=-5
kerning first=8222 second=908 amount=-1
kerning first=47 second=308 amount=-1
kerning first=121 second=339 amount=-1
kerning first=285 second=235 amount=-1
kerning first=8217 second=305 amount=-2
kerning first=272 second=194 amount=-1
kerning first=372 second=46 amount=-7
kerning first=278 second=116 amount=-1
kerning first=336 second=46 amount=-1
kerning first=221 second=100 amount=-5
kerning first=350 second=194 amount=-1
kerning first=230 second=382 amount=-1
kerning first=88 second=235 amount=-1
kerning first=287 second=355 amount=-1
kerning first=193 second=211 amount=-1
kerning first=910 second=959 amount=-5
kerning first=119 second=382 amount=-1
kerning first=340 second=67 amount=-1
kerning first=90 second=67 amount=-1
kerning first=44 second=932 amount=-5
kerning first=122 second=118 amount=-1
kerning first=231 second=333 amount=-1
kerning first=910 second=58 amount=-5
kerning first=285 second=44 amount=1
kerning first=241 second=116 amount=-1
kerning first=46 second=366 amount=-1
kerning first=374 second=382 amount=-4
kerning first=1066 second=1061 amount=-1
kerning first=1044 second=44 amount=2
kerning first=376 second=115 amount=-4
kerning first=122 second=121 amount=-1
kerning first=102 second=265 amount=-2
kerning first=74 second=196 amount=-1
kerning first=274 second=288 amount=-1
kerning first=8217 second=242 amount=-5
kerning first=66 second=116 amount=-1
kerning first=374 second=116 amount=-2
kerning first=212 second=381 amount=-1
kerning first=381 second=262 amount=-1
kerning first=256 second=74 amount=1
kerning first=334 second=74 amount=-1
kerning first=280 second=269 amount=-1
kerning first=8218 second=121 amount=-5
kerning first=348 second=256 amount=-1
kerning first=8217 second=277 amount=-5
kerning first=256 second=8220 amount=-6
kerning first=208 second=260 amount=-1
kerning first=334 second=8220 amount=-1
kerning first=70 second=259 amount=-2
kerning first=272 second=377 amount=-1
kerning first=76 second=8221 amount=-5
kerning first=116 second=281 amount=-1
kerning first=114 second=349 amount=-1
kerning first=266 second=212 amount=-1
kerning first=256 second=290 amount=-1
kerning first=203 second=283 amount=-1
kerning first=198 second=357 amount=-1
kerning first=939 second=46 amount=-8
kerning first=260 second=290 amount=-1
kerning first=310 second=328 amount=-1
kerning first=8217 second=324 amount=-2
kerning first=118 second=100 amount=-1
kerning first=8221 second=265 amount=-5
kerning first=203 second=357 amount=-1
kerning first=1038 second=1113 amount=-6
kerning first=375 second=8221 amount=2
kerning first=278 second=346 amount=-1
kerning first=89 second=290 amount=-2
kerning first=1073 second=1113 amount=-1
kerning first=1065 second=1066 amount=-1
kerning first=1065 second=1141 amount=-1
kerning first=65 second=210 amount=-1
kerning first=958 second=972 amount=-1
kerning first=291 second=100 amount=-1
kerning first=356 second=59 amount=-4
kerning first=939 second=908 amount=-2
kerning first=212 second=195 amount=-1
kerning first=355 second=100 amount=-1
kerning first=8222 second=221 amount=-8
kerning first=65 second=81 amount=-1
kerning first=378 second=373 amount=-1
kerning first=76 second=210 amount=-2
kerning first=87 second=225 amount=-2
kerning first=8221 second=79 amount=-3
kerning first=278 second=334 amount=-1
kerning first=8221 second=334 amount=-3
kerning first=374 second=338 amount=-2
kerning first=1091 second=1108 amount=-1
kerning first=84 second=122 amount=-5
kerning first=366 second=260 amount=-2
kerning first=122 second=339 amount=-2
kerning first=344 second=346 amount=-1
kerning first=1061 second=1086 amount=-1
kerning first=202 second=116 amount=-1
kerning first=374 second=341 amount=-3
kerning first=8221 second=81 amount=-3
kerning first=264 second=211 amount=-1
kerning first=1070 second=1047 amount=-1
kerning first=914 second=932 amount=-2
kerning first=8221 second=232 amount=-5
kerning first=1070 second=1059 amount=-1
kerning first=379 second=71 amount=-1
kerning first=107 second=115 amount=-1
kerning first=229 second=8216 amount=-1
kerning first=1043 second=1096 amount=-5
kerning first=340 second=255 amount=-1
kerning first=195 second=376 amount=-5
kerning first=344 second=83 amount=-1
kerning first=8222 second=375 amount=-5
kerning first=89 second=328 amount=-3
kerning first=344 second=211 amount=-1
kerning first=377 second=211 amount=-1
kerning first=904 second=923 amount=-1
kerning first=195 second=368 amount=-1
kerning first=80 second=233 amount=-1
kerning first=951 second=947 amount=-1
kerning first=103 second=47 amount=3
kerning first=283 second=382 amount=-1
kerning first=1056 second=46 amount=-7
kerning first=219 second=193 amount=-2
kerning first=344 second=335 amount=-1
kerning first=89 second=99 amount=-6
kerning first=377 second=335 amount=-1
kerning first=193 second=121 amount=-1
kerning first=85 second=74 amount=-1
kerning first=90 second=255 amount=-1
kerning first=198 second=233 amount=-1
kerning first=289 second=113 amount=-1
kerning first=338 second=99 amount=-1
kerning first=288 second=119 amount=-1
kerning first=372 second=245 amount=-3
kerning first=8221 second=916 amount=-9
kerning first=80 second=269 amount=-2
kerning first=949 second=945 amount=-1
kerning first=1070 second=1063 amount=-1
kerning first=380 second=231 amount=-2
kerning first=198 second=269 amount=-1
kerning first=116 second=99 amount=-1
kerning first=276 second=232 amount=-1
kerning first=210 second=372 amount=-1
kerning first=276 second=193 amount=-1
kerning first=908 second=916 amount=-1
kerning first=65 second=372 amount=-3
kerning first=324 second=121 amount=-1
kerning first=339 second=102 amount=-1
kerning first=71 second=375 amount=-1
kerning first=1058 second=1079 amount=-5
kerning first=258 second=85 amount=-1
kerning first=344 second=87 amount=-1
kerning first=334 second=356 amount=-2
kerning first=8221 second=193 amount=-9
kerning first=311 second=97 amount=-1
kerning first=344 second=373 amount=-1
kerning first=260 second=360 amount=-1
kerning first=120 second=117 amount=-1
kerning first=256 second=356 amount=-6
kerning first=202 second=197 amount=-1
kerning first=282 second=102 amount=-2
kerning first=8218 second=355 amount=-2
kerning first=46 second=268 amount=-1
kerning first=1061 second=1072 amount=-1
kerning first=203 second=232 amount=-1
kerning first=311 second=353 amount=-1
kerning first=338 second=346 amount=-1
kerning first=326 second=8216 amount=-1
kerning first=376 second=229 amount=-5
kerning first=373 second=335 amount=-1
kerning first=1094 second=1105 amount=-1
kerning first=1169 second=1105 amount=-1
kerning first=1074 second=1076 amount=-1
kerning first=69 second=234 amount=-1
kerning first=45 second=1090 amount=-1
kerning first=75 second=343 amount=-1
kerning first=967 second=955 amount=-1
kerning first=354 second=241 amount=-4
kerning first=201 second=348 amount=-1
kerning first=255 second=246 amount=-1
kerning first=380 second=267 amount=-2
kerning first=908 second=910 amount=-2
kerning first=313 second=213 amount=-2
kerning first=122 second=224 amount=-1
kerning first=84 second=234 amount=-6
kerning first=313 second=217 amount=-2
kerning first=90 second=299 amount=2
kerning first=1075 second=224 amount=-2
kerning first=267 second=226 amount=-1
kerning first=351 second=118 amount=-1
kerning first=70 second=284 amount=-1
kerning first=374 second=228 amount=-5
kerning first=84 second=224 amount=-6
kerning first=313 second=220 amount=-2
kerning first=221 second=121 amount=-2
kerning first=262 second=336 amount=-1
kerning first=375 second=224 amount=-1
kerning first=199 second=332 amount=-1
kerning first=280 second=97 amount=-1
kerning first=328 second=116 amount=-1
kerning first=274 second=71 amount=-1
kerning first=1050 second=1054 amount=-2
kerning first=1116 second=1072 amount=-1
kerning first=331 second=8216 amount=-1
kerning first=380 second=228 amount=-1
kerning first=8217 second=267 amount=-5
kerning first=286 second=89 amount=-1
kerning first=75 second=113 amount=-1
kerning first=122 second=234 amount=-2
kerning first=1040 second=8217 amount=-3
kerning first=119 second=228 amount=-1
kerning first=8221 second=371 amount=-2
kerning first=211 second=89 amount=-2
kerning first=285 second=227 amount=-1
kerning first=351 second=382 amount=-1
kerning first=354 second=84 amount=1
kerning first=356 second=250 amount=-4
kerning first=382 second=111 amount=-2
kerning first=8218 second=910 amount=-7
kerning first=8216 second=287 amount=-3
kerning first=280 second=227 amount=-1
kerning first=1025 second=1073 amount=-1
kerning first=310 second=279 amount=-1
kerning first=255 second=248 amount=-1
kerning first=266 second=71 amount=-1
kerning first=382 second=287 amount=-1
kerning first=278 second=337 amount=-1
kerning first=342 second=352 amount=-1
kerning first=120 second=231 amount=-2
kerning first=86 second=214 amount=-1
kerning first=350 second=118 amount=-1
kerning first=1050 second=1063 amount=-1
kerning first=1044 second=1077 amount=-1
kerning first=341 second=227 amount=-1
kerning first=340 second=101 amount=-1
kerning first=87 second=266 amount=-1
kerning first=376 second=285 amount=-5
kerning first=46 second=920 amount=-1
kerning first=195 second=266 amount=-1
kerning first=293 second=253 amount=-1
kerning first=1043 second=1111 amount=4
kerning first=194 second=214 amount=-1
kerning first=375 second=229 amount=-1
kerning first=253 second=279 amount=-1
kerning first=76 second=118 amount=-3
kerning first=310 second=349 amount=-1
kerning first=47 second=97 amount=-1
kerning first=374 second=197 amount=-5
kerning first=310 second=45 amount=-1
kerning first=8216 second=263 amount=-3
kerning first=219 second=256 amount=-2
kerning first=278 second=373 amount=-1
kerning first=86 second=333 amount=-3
kerning first=194 second=218 amount=-1
kerning first=372 second=231 amount=-3
kerning first=376 second=305 amount=-3
kerning first=221 second=260 amount=-5
kerning first=1090 second=1077 amount=-1
kerning first=203 second=257 amount=-1
kerning first=1138 second=1038 amount=-1
kerning first=324 second=118 amount=-1
kerning first=372 second=212 amount=-1
kerning first=253 second=349 amount=-1
kerning first=376 second=250 amount=-3
kerning first=258 second=212 amount=-1
kerning first=87 second=101 amount=-3
kerning first=354 second=287 amount=-5
kerning first=1074 second=1098 amount=-1
kerning first=198 second=118 amount=-2
kerning first=353 second=116 amount=-1
kerning first=202 second=195 amount=-1
kerning first=375 second=263 amount=-1
kerning first=311 second=281 amount=-2
kerning first=47 second=227 amount=-1
kerning first=253 second=45 amount=-2
kerning first=210 second=86 amount=-1
kerning first=76 second=354 amount=-5
kerning first=904 second=45 amount=-1
kerning first=255 second=100 amount=-1
kerning first=257 second=253 amount=-1
kerning first=910 second=908 amount=-2
kerning first=201 second=263 amount=-1
kerning first=338 second=65 amount=-1
kerning first=272 second=354 amount=-1
kerning first=89 second=65 amount=-5
kerning first=354 second=263 amount=-6
kerning first=102 second=101 amount=-2
kerning first=104 second=357 amount=-1
kerning first=102 second=285 amount=-2
kerning first=1044 second=1092 amount=-1
kerning first=86 second=286 amount=-1
kerning first=372 second=337 amount=-3
kerning first=75 second=224 amount=-1
kerning first=1097 second=1092 amount=-1
kerning first=8221 second=192 amount=-9
kerning first=197 second=253 amount=-1
kerning first=1094 second=1118 amount=-1
kerning first=276 second=79 amount=-1
kerning first=194 second=286 amount=-1
kerning first=1057 second=1060 amount=-1
kerning first=253 second=285 amount=-1
kerning first=312 second=267 amount=-2
kerning first=47 second=339 amount=-1
kerning first=8216 second=959 amount=-2
kerning first=1168 second=1139 amount=-8
kerning first=1065 second=1139 amount=-1
kerning first=89 second=331 amount=-3
kerning first=87 second=67 amount=-1
kerning first=1041 second=1058 amount=-2
kerning first=103 second=97 amount=-1
kerning first=1062 second=1058 amount=-1
kerning first=75 second=301 amount=1
kerning first=8220 second=225 amount=-2
kerning first=88 second=199 amount=-2
kerning first=336 second=196 amount=-1
kerning first=372 second=196 amount=-3
kerning first=202 second=119 amount=-1
kerning first=1065 second=1089 amount=-1
kerning first=380 second=335 amount=-2
kerning first=122 second=375 amount=-1
kerning first=75 second=232 amount=-1
kerning first=220 second=308 amount=-1
kerning first=87 second=44 amount=-8
kerning first=285 second=259 amount=-1
kerning first=197 second=264 amount=-1
kerning first=8217 second=211 amount=-3
kerning first=84 second=375 amount=-3
kerning first=202 second=335 amount=-1
kerning first=69 second=375 amount=-2
kerning first=193 second=368 amount=-1
kerning first=75 second=263 amount=-1
kerning first=198 second=243 amount=-1
kerning first=8217 second=244 amount=-5
kerning first=289 second=8221 amount=3
kerning first=80 second=243 amount=-1
kerning first=80 second=8221 amount=2
kerning first=81 second=374 amount=-2
kerning first=85 second=198 amount=-1
kerning first=8217 second=335 amount=-5
kerning first=66 second=374 amount=-2
kerning first=1060 second=1113 amount=-3
kerning first=8217 second=249 amount=-2
kerning first=376 second=59 amount=-5
kerning first=311 second=259 amount=-1
kerning first=80 second=258 amount=-5
kerning first=955 second=963 amount=-1
kerning first=8217 second=255 amount=-2
kerning first=282 second=235 amount=-1
kerning first=74 second=46 amount=-1
kerning first=88 second=255 amount=-2
kerning first=378 second=229 amount=-1
kerning first=1047 second=1035 amount=-1
kerning first=972 second=44 amount=-1
kerning first=1070 second=1035 amount=-2
kerning first=107 second=275 amount=-2
kerning first=345 second=44 amount=-6
kerning first=198 second=194 amount=-1
kerning first=80 second=194 amount=-5
kerning first=84 second=338 amount=-2
kerning first=313 second=87 amount=-4
kerning first=354 second=257 amount=-6
kerning first=1107 second=229 amount=-2
kerning first=939 second=959 amount=-5
kerning first=1098 second=8220 amount=-4
kerning first=228 second=120 amount=-1
kerning first=1104 second=8220 amount=-1
kerning first=84 second=112 amount=-4
kerning first=923 second=8220 amount=-6
kerning first=362 second=44 amount=-1
kerning first=84 second=382 amount=-5
kerning first=97 second=116 amount=-1
kerning first=282 second=262 amount=-1
kerning first=89 second=367 amount=-3
kerning first=349 second=116 amount=-1
kerning first=8221 second=262 amount=-2
kerning first=75 second=235 amount=-1
kerning first=202 second=211 amount=-1
kerning first=70 second=44 amount=-7
kerning first=8217 second=195 amount=-9
kerning first=372 second=324 amount=-2
kerning first=276 second=256 amount=-1
kerning first=1036 second=1066 amount=-1
kerning first=46 second=338 amount=-1
kerning first=232 second=8220 amount=-1
kerning first=221 second=233 amount=-5
kerning first=8222 second=366 amount=-1
kerning first=86 second=238 amount=2
kerning first=202 second=244 amount=-1
kerning first=47 second=230 amount=-1
kerning first=193 second=116 amount=-2
kerning first=199 second=288 amount=-1
kerning first=313 second=116 amount=-1
kerning first=376 second=212 amount=-2
kerning first=1045 second=1098 amount=-1
kerning first=264 second=288 amount=-1
kerning first=225 second=255 amount=-1
kerning first=1169 second=259 amount=-2
kerning first=255 second=44 amount=-5
kerning first=270 second=260 amount=-1
kerning first=87 second=255 amount=-2
kerning first=75 second=262 amount=-3
kerning first=344 second=288 amount=-1
kerning first=8222 second=195 amount=1
kerning first=954 second=965 amount=-1
kerning first=208 second=256 amount=-1
kerning first=102 second=45 amount=-2
kerning first=210 second=194 amount=-1
kerning first=331 second=116 amount=-1
kerning first=351 second=255 amount=-1
kerning first=366 second=194 amount=-2
kerning first=69 second=244 amount=-1
kerning first=1168 second=1099 amount=-5
kerning first=89 second=46 amount=-8
kerning first=84 second=244 amount=-6
kerning first=46 second=290 amount=-1
kerning first=313 second=44 amount=1
kerning first=107 second=233 amount=-2
kerning first=295 second=8220 amount=-1
kerning first=310 second=366 amount=-1
kerning first=8216 second=232 amount=-3
kerning first=122 second=244 amount=-2
kerning first=348 second=8220 amount=-1
kerning first=315 second=354 amount=-5
kerning first=1043 second=1083 amount=-7
kerning first=356 second=354 amount=1
kerning first=8216 second=100 amount=-3
kerning first=375 second=275 amount=-1
kerning first=79 second=195 amount=-1
kerning first=1058 second=1100 amount=-5
kerning first=310 second=112 amount=-1
kerning first=278 second=257 amount=-1
kerning first=364 second=195 amount=-2
kerning first=280 second=210 amount=-1
kerning first=356 second=238 amount=2
kerning first=243 second=378 amount=-1
kerning first=90 second=283 amount=-1
kerning first=382 second=100 amount=-2
kerning first=193 second=338 amount=-1
kerning first=121 second=378 amount=-1
kerning first=46 second=211 amount=-1
kerning first=342 second=121 amount=-1
kerning first=1168 second=1109 amount=-7
kerning first=8222 second=360 amount=-1
kerning first=372 second=236 amount=1
kerning first=368 second=46 amount=-1
kerning first=70 second=334 amount=-1
kerning first=282 second=265 amount=-1
kerning first=345 second=118 amount=1
kerning first=8217 second=382 amount=-1
kerning first=81 second=44 amount=4
kerning first=329 second=357 amount=-1
kerning first=278 second=245 amount=-1
kerning first=285 second=226 amount=-1
kerning first=373 second=97 amount=-1
kerning first=255 second=235 amount=-1
kerning first=1070 second=1113 amount=-1
kerning first=212 second=377 amount=-1
kerning first=1038 second=1093 amount=-2
kerning first=202 second=83 amount=-1
kerning first=76 second=364 amount=-2
kerning first=276 second=259 amount=-1
kerning first=196 second=211 amount=-1
kerning first=114 second=245 amount=-1
kerning first=201 second=100 amount=-1
kerning first=197 second=332 amount=-1
kerning first=1073 second=1093 amount=-1
kerning first=8221 second=324 amount=-2
kerning first=84 second=290 amount=-2
kerning first=354 second=100 amount=-5
kerning first=1168 second=1072 amount=-5
kerning first=347 second=121 amount=-1
kerning first=8217 second=268 amount=-2
kerning first=381 second=242 amount=-1
kerning first=70 second=230 amount=-2
kerning first=310 second=99 amount=-1
kerning first=342 second=242 amount=-1
kerning first=199 second=8221 amount=3
kerning first=382 second=353 amount=-1
kerning first=87 second=369 amount=-2
kerning first=263 second=242 amount=-1
kerning first=8218 second=219 amount=-1
kerning first=86 second=74 amount=-3
kerning first=121 second=8221 amount=2
kerning first=213 second=74 amount=-1
kerning first=8216 second=353 amount=-2
kerning first=8222 second=334 amount=-2
kerning first=114 second=97 amount=-1
kerning first=44 second=374 amount=-5
kerning first=75 second=109 amount=-1
kerning first=69 second=228 amount=-1
kerning first=253 second=99 amount=-1
kerning first=194 second=74 amount=1
kerning first=84 second=228 amount=-6
kerning first=86 second=289 amount=-4
kerning first=372 second=117 amount=-2
kerning first=71 second=119 amount=-1
kerning first=8216 second=89 amount=2
kerning first=121 second=335 amount=-1
kerning first=119 second=230 amount=-1
kerning first=350 second=373 amount=-1
kerning first=269 second=335 amount=-1
kerning first=202 second=268 amount=-1
kerning first=342 second=356 amount=-1
kerning first=274 second=197 amount=-1
kerning first=356 second=363 amount=-4
kerning first=86 second=229 amount=-4
kerning first=1094 second=1108 amount=-1
kerning first=282 second=232 amount=-1
kerning first=218 second=197 amount=-2
kerning first=1086 second=1076 amount=-2
kerning first=352 second=374 amount=-1
kerning first=356 second=114 amount=-4
kerning first=253 second=283 amount=-1
kerning first=382 second=281 amount=-2
kerning first=107 second=269 amount=-2
kerning first=119 second=347 amount=-1
kerning first=354 second=353 amount=-5
kerning first=278 second=375 amount=-2
kerning first=313 second=336 amount=-2
kerning first=1075 second=228 amount=-2
kerning first=338 second=45 amount=-1
kerning first=1069 second=1061 amount=-1
kerning first=270 second=374 amount=-1
kerning first=282 second=333 amount=-1
kerning first=315 second=102 amount=-1
kerning first=260 second=45 amount=-1
kerning first=200 second=102 amount=-2
kerning first=47 second=103 amount=-1
kerning first=79 second=374 amount=-2
kerning first=313 second=355 amount=-1
kerning first=221 second=213 amount=-2
kerning first=203 second=211 amount=-1
kerning first=274 second=277 amount=-1
kerning first=47 second=111 amount=-1
kerning first=8220 second=197 amount=-6
kerning first=1105 second=1113 amount=-1
kerning first=192 second=121 amount=-1
kerning first=85 second=193 amount=-2
kerning first=1035 second=1059 amount=-2
kerning first=282 second=193 amount=-1
kerning first=354 second=79 amount=-2
kerning first=286 second=121 amount=-1
kerning first=315 second=198 amount=2
kerning first=1059 second=1138 amount=-2
kerning first=356 second=198 amount=-6
kerning first=107 second=230 amount=-1
kerning first=255 second=242 amount=-1
kerning first=297 second=316 amount=1
kerning first=374 second=224 amount=-5
kerning first=312 second=224 amount=-1
kerning first=256 second=8216 amount=-6
kerning first=270 second=65 amount=-1
kerning first=84 second=309 amount=2
kerning first=334 second=8216 amount=-1
kerning first=8221 second=326 amount=-2
kerning first=265 second=259 amount=-1
kerning first=70 second=336 amount=-1
kerning first=102 second=8220 amount=1
kerning first=110 second=375 amount=-1
kerning first=310 second=335 amount=-1
kerning first=227 second=8216 amount=-1
kerning first=1050 second=1060 amount=-3
kerning first=8221 second=122 amount=-1
kerning first=1168 second=1094 amount=-5
kerning first=192 second=213 amount=-1
kerning first=8222 second=1033 amount=1
kerning first=312 second=111 amount=-2
kerning first=376 second=351 amount=-4
kerning first=203 second=67 amount=-1
kerning first=120 second=111 amount=-2
kerning first=338 second=71 amount=-1
kerning first=379 second=111 amount=-1
kerning first=203 second=279 amount=-1
kerning first=340 second=266 amount=-1
kerning first=90 second=266 amount=-1
kerning first=89 second=71 amount=-2
kerning first=260 second=71 amount=-1
kerning first=44 second=356 amount=-5
kerning first=360 second=196 amount=-2
kerning first=70 second=199 amount=-1
kerning first=8216 second=281 amount=-3
kerning first=308 second=197 amount=-1
kerning first=310 second=228 amount=-1
kerning first=278 second=231 amount=-1
kerning first=65 second=214 amount=-1
kerning first=122 second=232 amount=-2
kerning first=354 second=299 amount=3
kerning first=379 second=337 amount=-1
kerning first=346 second=197 amount=-1
kerning first=311 second=225 amount=-1
kerning first=1058 second=58 amount=-3
kerning first=196 second=8217 amount=-3
kerning first=89 second=361 amount=-3
kerning first=280 second=81 amount=-1
kerning first=8222 second=1051 amount=1
kerning first=310 second=331 amount=-1
kerning first=343 second=227 amount=-1
kerning first=200 second=355 amount=-1
kerning first=350 second=221 amount=-1
kerning first=76 second=221 amount=-6
kerning first=203 second=101 amount=-1
kerning first=377 second=268 amount=-1
kerning first=272 second=221 amount=-1
kerning first=8218 second=199 amount=-1
kerning first=345 second=230 amount=-1
kerning first=65 second=118 amount=-1
kerning first=284 second=118 amount=-1
kerning first=8218 second=1033 amount=1
kerning first=910 second=8217 amount=1
kerning first=214 second=192 amount=-1
kerning first=46 second=87 amount=-5
kerning first=8222 second=119 amount=-5
kerning first=1101 second=1090 amount=-1
kerning first=280 second=121 amount=-2
kerning first=8216 second=299 amount=2
kerning first=274 second=350 amount=-1
kerning first=70 second=197 amount=-4
kerning first=1024 second=1057 amount=-2
kerning first=86 second=110 amount=-2
kerning first=282 second=286 amount=-1
kerning first=1033 second=8216 amount=-3
kerning first=69 second=195 amount=-1
kerning first=8218 second=256 amount=1
kerning first=104 second=120 amount=-1
kerning first=8222 second=1091 amount=-3
kerning first=354 second=281 amount=-6
kerning first=1168 second=1169 amount=-5
kerning first=373 second=99 amount=-1
kerning first=116 second=45 amount=-2
kerning first=70 second=79 amount=-1
kerning first=1025 second=1092 amount=-2
kerning first=1027 second=1099 amount=-5
kerning first=70 second=348 amount=-1
kerning first=310 second=367 amount=-1
kerning first=107 second=243 amount=-2
kerning first=201 second=281 amount=-1
kerning first=1138 second=1058 amount=-2
kerning first=193 second=266 amount=-1
kerning first=195 second=212 amount=-1
kerning first=955 second=964 amount=-3
kerning first=87 second=212 amount=-1
kerning first=1045 second=1141 amount=-1
kerning first=121 second=227 amount=-1
kerning first=1168 second=1028 amount=-2
kerning first=1098 second=1141 amount=-2
kerning first=1065 second=1028 amount=-1
kerning first=8221 second=353 amount=-4
kerning first=119 second=234 amount=-1
kerning first=114 second=257 amount=-1
kerning first=350 second=258 amount=-1
kerning first=203 second=255 amount=-2
kerning first=101 second=382 amount=-1
kerning first=258 second=334 amount=-1
kerning first=83 second=376 amount=-1
kerning first=68 second=90 amount=-1
kerning first=8218 second=213 amount=-2
kerning first=8218 second=79 amount=-2
kerning first=274 second=373 amount=-1
kerning first=356 second=233 amount=-6
kerning first=83 second=65 amount=-1
kerning first=255 second=231 amount=-1
kerning first=8220 second=297 amount=4
kerning first=272 second=308 amount=-1
kerning first=312 second=113 amount=-2
kerning first=340 second=248 amount=-1
kerning first=310 second=114 amount=-1
kerning first=902 second=920 amount=-1
kerning first=253 second=291 amount=-1
kerning first=90 second=248 amount=-1
kerning first=1046 second=1058 amount=-1
kerning first=200 second=233 amount=-1
kerning first=203 second=263 amount=-1
kerning first=289 second=291 amount=1
kerning first=221 second=343 amount=-3
kerning first=378 second=233 amount=-2
kerning first=260 second=366 amount=-1
kerning first=956 second=959 amount=-1
kerning first=341 second=339 amount=-1
kerning first=267 second=246 amount=-1
kerning first=76 second=308 amount=1
kerning first=343 second=267 amount=-1
kerning first=1059 second=1109 amount=-2
kerning first=355 second=246 amount=-1
kerning first=280 second=339 amount=-1
kerning first=75 second=246 amount=-1
kerning first=198 second=275 amount=-1
kerning first=232 second=122 amount=-1
kerning first=201 second=283 amount=-1
kerning first=336 second=88 amount=-2
kerning first=80 second=275 amount=-1
kerning first=8222 second=1060 amount=-4
kerning first=98 second=380 amount=-1
kerning first=345 second=339 amount=-1
kerning first=202 second=234 amount=-1
kerning first=1094 second=1139 amount=-1
kerning first=376 second=225 amount=-5
kerning first=225 second=120 amount=-1
kerning first=331 second=357 amount=-1
kerning first=1056 second=1046 amount=-1
kerning first=220 second=258 amount=-2
kerning first=351 second=120 amount=-1
kerning first=280 second=258 amount=-1
kerning first=8220 second=335 amount=-2
kerning first=270 second=46 amount=-2
kerning first=47 second=347 amount=-1
kerning first=256 second=286 amount=-1
kerning first=1036 second=1089 amount=-2
kerning first=372 second=367 amount=-2
kerning first=70 second=226 amount=-2
kerning first=82 second=243 amount=-1
kerning first=376 second=243 amount=-5
kerning first=86 second=265 amount=-4
kerning first=356 second=194 amount=-6
kerning first=115 second=118 amount=-1
kerning first=46 second=360 amount=-1
kerning first=382 second=224 amount=-1
kerning first=67 second=198 amount=1
kerning first=8221 second=250 amount=-2
kerning first=88 second=284 amount=-2
kerning first=276 second=262 amount=-1
kerning first=376 second=198 amount=-6
kerning first=8217 second=197 amount=-9
kerning first=118 second=44 amount=-6
kerning first=280 second=332 amount=-1
kerning first=349 second=382 amount=-1
kerning first=1091 second=46 amount=-2
kerning first=291 second=44 amount=1
kerning first=90 second=275 amount=-1
kerning first=971 second=941 amount=-1
kerning first=200 second=229 amount=-1
kerning first=1084 second=8220 amount=-2
kerning first=1168 second=1101 amount=-5
kerning first=340 second=281 amount=-1
kerning first=1060 second=1032 amount=-2
kerning first=8217 second=338 amount=-3
kerning first=226 second=116 amount=-1
kerning first=362 second=256 amount=-2
kerning first=1058 second=1081 amount=-5
kerning first=279 second=382 amount=-1
kerning first=70 second=235 amount=-1
kerning first=221 second=44 amount=-9
kerning first=70 second=256 amount=-4
kerning first=1059 second=1040 amount=-5
kerning first=8220 second=211 amount=-1
kerning first=80 second=339 amount=-1
kerning first=210 second=308 amount=-1
kerning first=277 second=380 amount=-1
kerning first=253 second=46 amount=-5
kerning first=374 second=244 amount=-5
kerning first=259 second=121 amount=-1
kerning first=259 second=8220 amount=-1
kerning first=84 second=195 amount=-6
kerning first=97 second=375 amount=-1
kerning first=347 second=380 amount=-1
kerning first=198 second=339 amount=-1
kerning first=341 second=275 amount=-1
kerning first=1070 second=1038 amount=-1
kerning first=119 second=244 amount=-1
kerning first=338 second=334 amount=-1
kerning first=69 second=346 amount=-1
kerning first=89 second=334 amount=-2
kerning first=335 second=122 amount=-1
kerning first=374 second=290 amount=-2
kerning first=1106 second=1083 amount=-1
kerning first=914 second=918 amount=-1
kerning first=75 second=217 amount=-1
kerning first=354 second=283 amount=-6
kerning first=339 second=8216 amount=-1
kerning first=382 second=232 amount=-2
kerning first=258 second=362 amount=-1
kerning first=84 second=328 amount=-4
kerning first=282 second=229 amount=-1
kerning first=221 second=380 amount=-4
kerning first=382 second=225 amount=-1
kerning first=263 second=333 amount=-1
kerning first=193 second=268 amount=-1
kerning first=8217 second=251 amount=-2
kerning first=197 second=210 amount=-1
kerning first=223 second=380 amount=-1
kerning first=1024 second=1066 amount=-1
kerning first=102 second=225 amount=-1
kerning first=354 second=225 amount=-6
kerning first=342 second=333 amount=-1
kerning first=254 second=378 amount=-1
kerning first=89 second=309 amount=2
kerning first=1076 second=8216 amount=-1
kerning first=280 second=373 amount=-1
kerning first=260 second=334 amount=-1
kerning first=76 second=81 amount=-2
kerning first=278 second=71 amount=-1
kerning first=75 second=326 amount=-1
kerning first=375 second=115 amount=-1
kerning first=310 second=245 amount=-1
kerning first=201 second=225 amount=-1
kerning first=350 second=372 amount=-1
kerning first=955 second=972 amount=-1
kerning first=76 second=372 amount=-4
kerning first=382 second=263 amount=-2
kerning first=343 second=45 amount=-2
kerning first=46 second=119 amount=-2
kerning first=196 second=116 amount=-2
kerning first=229 second=357 amount=-1
kerning first=274 second=211 amount=-1
kerning first=958 second=970 amount=-1
kerning first=8218 second=290 amount=-1
kerning first=102 second=41 amount=1
kerning first=282 second=242 amount=-1
kerning first=231 second=245 amount=-1
kerning first=121 second=277 amount=-1
kerning first=122 second=119 amount=-1
kerning first=274 second=335 amount=-1
kerning first=114 second=99 amount=-1
kerning first=74 second=194 amount=-1
kerning first=382 second=121 amount=-1
kerning first=45 second=102 amount=-1
kerning first=315 second=362 amount=-2
kerning first=191 second=102 amount=-2
kerning first=201 second=121 amount=-2
kerning first=84 second=119 amount=-3
kerning first=354 second=121 amount=-3
kerning first=69 second=119 amount=-1
kerning first=1045 second=1060 amount=-2
kerning first=376 second=74 amount=-4
kerning first=291 second=355 amount=-1
kerning first=342 second=232 amount=-1
kerning first=279 second=380 amount=-1
kerning first=299 second=8216 amount=1
kerning first=198 second=79 amount=-1
kerning first=347 second=116 amount=-1
kerning first=8217 second=341 amount=-2
kerning first=200 second=269 amount=-1
kerning first=66 second=87 amount=-1
kerning first=221 second=355 amount=-2
kerning first=76 second=44 amount=1
kerning first=378 second=269 amount=-2
kerning first=379 second=67 amount=-1
kerning first=260 second=374 amount=-5
kerning first=352 second=375 amount=-1
kerning first=310 second=375 amount=-3
kerning first=1046 second=1138 amount=-2
kerning first=374 second=239 amount=2
kerning first=1042 second=1061 amount=-1
kerning first=1073 second=8220 amount=-1
kerning first=376 second=102 amount=-2
kerning first=328 second=355 amount=-1
kerning first=278 second=99 amount=-1
kerning first=8221 second=109 amount=-2
kerning first=102 second=353 amount=-1
kerning first=373 second=8217 amount=2
kerning first=194 second=356 amount=-6
kerning first=1169 second=1108 amount=-1
kerning first=270 second=196 amount=-1
kerning first=352 second=196 amount=-1
kerning first=902 second=8217 amount=-3
kerning first=87 second=353 amount=-3
kerning first=958 second=969 amount=-1
kerning first=80 second=47 amount=-4
kerning first=203 second=111 amount=-1
kerning first=221 second=241 amount=-3
kerning first=377 second=8217 amount=2
kerning first=89 second=228 amount=-5
kerning first=1062 second=1095 amount=-1
kerning first=8216 second=213 amount=-1
kerning first=8217 second=920 amount=-2
kerning first=201 second=248 amount=-1
kerning first=343 second=242 amount=-1
kerning first=65 second=362 amount=-1
kerning first=287 second=228 amount=-1
kerning first=289 second=47 amount=3
kerning first=213 second=260 amount=-1
kerning first=274 second=228 amount=-1
kerning first=354 second=213 amount=-2
kerning first=379 second=246 amount=-1
kerning first=8217 second=224 amount=-5
kerning first=201 second=213 amount=-1
kerning first=315 second=284 amount=-2
kerning first=75 second=284 amount=-3
kerning first=192 second=355 amount=-2
kerning first=223 second=373 amount=-1
kerning first=46 second=908 amount=-1
kerning first=945 second=948 amount=-1
kerning first=270 second=376 amount=-1
kerning first=194 second=63 amount=-2
kerning first=84 second=267 amount=-6
kerning first=8221 second=255 amount=-2
kerning first=1076 second=1090 amount=-1
kerning first=372 second=285 amount=-2
kerning first=69 second=267 amount=-1
kerning first=372 second=99 amount=-3
kerning first=1027 second=1090 amount=-3
kerning first=213 second=356 amount=-2
kerning first=75 second=368 amount=-1
kerning first=1036 second=1057 amount=-2
kerning first=44 second=308 amount=1
kerning first=8216 second=260 amount=-6
kerning first=278 second=65 amount=-1
kerning first=195 second=84 amount=-6
kerning first=88 second=336 amount=-2
kerning first=1058 second=1077 amount=-6
kerning first=360 second=65 amount=-2
kerning first=345 second=279 amount=-1
kerning first=192 second=220 amount=-1
kerning first=221 second=252 amount=-3
kerning first=75 second=118 amount=-4
kerning first=46 second=939 amount=-6
kerning first=253 second=335 amount=-1
kerning first=260 second=8220 amount=-6
kerning first=380 second=224 amount=-1
kerning first=86 second=351 amount=-3
kerning first=8221 second=367 amount=-2
kerning first=116 second=228 amount=-1
kerning first=84 second=286 amount=-2
kerning first=8216 second=248 amount=-2
kerning first=379 second=279 amount=-1
kerning first=289 second=227 amount=-1
kerning first=89 second=285 amount=-5
kerning first=377 second=45 amount=-2
kerning first=264 second=8217 amount=3
kerning first=191 second=86 amount=-3
kerning first=8222 second=262 amount=-1
kerning first=84 second=192 amount=-6
kerning first=214 second=84 amount=-2
kerning first=86 second=291 amount=-4
kerning first=356 second=214 amount=-2
kerning first=202 second=224 amount=-1
kerning first=75 second=352 amount=-1
kerning first=8216 second=225 amount=-2
kerning first=200 second=214 amount=-1
kerning first=315 second=214 amount=-2
kerning first=354 second=248 amount=-6
kerning first=1059 second=1141 amount=-1
kerning first=201 second=266 amount=-1
kerning first=80 second=227 amount=-2
kerning first=1059 second=1075 amount=-2
kerning first=8218 second=923 amount=1
kerning first=120 second=229 amount=-1
kerning first=198 second=227 amount=-1
kerning first=1054 second=1083 amount=-1
kerning first=310 second=231 amount=-1
kerning first=82 second=86 amount=-1
kerning first=120 second=101 amount=-2
kerning first=196 second=334 amount=-1
kerning first=1025 second=1077 amount=-2
kerning first=1059 second=1094 amount=-2
kerning first=210 second=354 amount=-2
kerning first=378 second=118 amount=-1
kerning first=87 second=345 amount=-2
kerning first=1027 second=1119 amount=-5
kerning first=313 second=199 amount=-1
kerning first=65 second=354 amount=-6
kerning first=258 second=376 amount=-5
kerning first=200 second=118 amount=-2
kerning first=336 second=376 amount=-2
kerning first=89 second=112 amount=-3
kerning first=109 second=118 amount=-1
kerning first=315 second=118 amount=-3
kerning first=1059 second=1099 amount=-2
kerning first=356 second=118 amount=-3
kerning first=82 second=233 amount=-1
kerning first=83 second=255 amount=-1
kerning first=379 second=255 amount=-1
kerning first=102 second=263 amount=-2
kerning first=1027 second=1118 amount=-5
kerning first=354 second=345 amount=-4
kerning first=354 second=260 amount=-6
kerning first=114 second=46 amount=-7
kerning first=376 second=233 amount=-5
kerning first=225 second=357 amount=-1
kerning first=1065 second=1105 amount=-1
kerning first=87 second=263 amount=-3
kerning first=354 second=380 amount=-5
kerning first=1168 second=1105 amount=-7
kerning first=104 second=255 amount=-1
kerning first=939 second=923 amount=-5
kerning first=379 second=101 amount=-1
kerning first=351 second=357 amount=-1
kerning first=342 second=266 amount=-1
kerning first=66 second=195 amount=-1
kerning first=119 second=111 amount=-1
kerning first=374 second=195 amount=-5
kerning first=68 second=89 amount=-1
kerning first=372 second=349 amount=-3
kerning first=120 second=279 amount=-2
kerning first=203 second=337 amount=-1
kerning first=248 second=380 amount=-1
kerning first=203 second=192 amount=-1
kerning first=291 second=229 amount=-1
kerning first=8221 second=249 amount=-2
kerning first=356 second=305 amount=-4
kerning first=942 second=957 amount=-2
kerning first=929 second=45 amount=-2
kerning first=74 second=88 amount=-1
kerning first=378 second=243 amount=-2
kerning first=267 second=259 amount=-1
kerning first=310 second=236 amount=1
kerning first=1069 second=1026 amount=-2
kerning first=375 second=339 amount=-1
kerning first=107 second=333 amount=-2
kerning first=1061 second=1091 amount=-2
kerning first=381 second=286 amount=-1
kerning first=1025 second=1026 amount=-1
kerning first=356 second=243 amount=-6
kerning first=1090 second=1108 amount=-1
kerning first=932 second=959 amount=-6
kerning first=1040 second=1091 amount=-2
kerning first=195 second=357 amount=-2
kerning first=268 second=286 amount=-1
kerning first=88 second=79 amount=-2
kerning first=274 second=264 amount=-1
kerning first=331 second=255 amount=-1
kerning first=1038 second=1097 amount=-2
kerning first=1078 second=1086 amount=-1
kerning first=255 second=226 amount=-1
kerning first=230 second=8220 amount=-1
kerning first=8220 second=354 amount=3
kerning first=8222 second=116 amount=-2
kerning first=221 second=301 amount=2
kerning first=1036 second=1054 amount=-2
kerning first=1044 second=1104 amount=-1
kerning first=282 second=199 amount=-1
kerning first=67 second=214 amount=-1
kerning first=376 second=264 amount=-2
kerning first=311 second=257 amount=-1
kerning first=253 second=380 amount=-1
kerning first=82 second=264 amount=-1
kerning first=245 second=380 amount=-1
kerning first=103 second=275 amount=-1
kerning first=87 second=260 amount=-3
kerning first=1027 second=1083 amount=-7
kerning first=98 second=120 amount=-1
kerning first=1025 second=1033 amount=-1
kerning first=253 second=234 amount=-1
kerning first=263 second=246 amount=-1
kerning first=69 second=253 amount=-2
kerning first=1102 second=1078 amount=-1
kerning first=8220 second=279 amount=-3
kerning first=342 second=246 amount=-1
kerning first=86 second=114 amount=-2
kerning first=1038 second=1108 amount=-4
kerning first=916 second=63 amount=-2
kerning first=904 second=920 amount=-1
kerning first=381 second=246 amount=-1
kerning first=75 second=248 amount=-1
kerning first=274 second=267 amount=-1
kerning first=373 second=291 amount=-1
kerning first=255 second=113 amount=-1
kerning first=380 second=248 amount=-2
kerning first=354 second=301 amount=3
kerning first=354 second=343 amount=-4
kerning first=66 second=88 amount=-2
kerning first=198 second=242 amount=-1
kerning first=283 second=122 amount=-1
kerning first=107 second=339 amount=-2
kerning first=313 second=370 amount=-2
kerning first=274 second=243 amount=-1
kerning first=280 second=333 amount=-1
kerning first=1044 second=1141 amount=-1
kerning first=8220 second=267 amount=-3
kerning first=341 second=333 amount=-1
kerning first=221 second=79 amount=-2
kerning first=47 second=258 amount=-2
kerning first=271 second=98 amount=1
kerning first=376 second=194 amount=-5
kerning first=344 second=111 amount=-1
kerning first=211 second=379 amount=-1
kerning first=356 second=371 amount=-4
kerning first=8220 second=243 amount=-2
kerning first=76 second=286 amount=-2
kerning first=84 second=45 amount=-6
kerning first=75 second=220 amount=-1
kerning first=102 second=287 amount=-2
kerning first=69 second=45 amount=-1
kerning first=376 second=223 amount=-3
kerning first=80 second=351 amount=-1
kerning first=374 second=83 amount=-1
kerning first=1107 second=8220 amount=1
kerning first=382 second=259 amount=-1
kerning first=260 second=211 amount=-1
kerning first=46 second=45 amount=-4
kerning first=338 second=211 amount=-1
kerning first=89 second=211 amount=-2
kerning first=374 second=324 amount=-3
kerning first=211 second=44 amount=-2
kerning first=1040 second=45 amount=-1
kerning first=198 second=288 amount=-1
kerning first=1075 second=46 amount=-3
kerning first=274 second=116 amount=-1
kerning first=368 second=44 amount=-1
kerning first=1075 second=45 amount=-2
kerning first=8216 second=259 amount=-2
kerning first=297 second=8220 amount=1
kerning first=356 second=262 amount=-1
kerning first=192 second=44 amount=1
kerning first=200 second=262 amount=-1
kerning first=269 second=244 amount=-1
kerning first=368 second=256 amount=-2
kerning first=122 second=347 amount=-1
kerning first=84 second=58 amount=-5
kerning first=44 second=195 amount=1
kerning first=211 second=256 amount=-1
kerning first=315 second=8220 amount=-7
kerning first=121 second=244 amount=-1
kerning first=356 second=8220 amount=1
kerning first=84 second=347 amount=-5
kerning first=89 second=305 amount=-3
kerning first=258 second=67 amount=-1
kerning first=1040 second=1038 amount=-3
kerning first=372 second=67 amount=-1
kerning first=954 second=966 amount=-2
kerning first=258 second=366 amount=-1
kerning first=338 second=283 amount=-1
kerning first=84 second=239 amount=3
kerning first=8222 second=212 amount=-2
kerning first=372 second=230 amount=-2
kerning first=89 second=283 amount=-5
kerning first=8218 second=368 amount=-1
kerning first=87 second=328 amount=-2
kerning first=193 second=290 amount=-1
kerning first=372 second=263 amount=-3
kerning first=1065 second=1054 amount=-1
kerning first=116 second=245 amount=-1
kerning first=65 second=364 amount=-1
kerning first=80 second=259 amount=-2
kerning first=211 second=196 amount=-1
kerning first=368 second=196 amount=-2
kerning first=47 second=275 amount=-1
kerning first=246 second=120 amount=-1
kerning first=221 second=117 amount=-3
kerning first=116 second=283 amount=-1
kerning first=334 second=88 amount=-2
kerning first=376 second=265 amount=-6
kerning first=287 second=283 amount=-1
kerning first=1038 second=1095 amount=-1
kerning first=263 second=229 amount=-1
kerning first=342 second=373 amount=-1
kerning first=381 second=373 amount=-1
kerning first=199 second=210 amount=-1
kerning first=1139 second=1083 amount=-1
kerning first=352 second=195 amount=-1
kerning first=264 second=338 amount=-1
kerning first=311 second=235 amount=-2
kerning first=276 second=100 amount=-1
kerning first=374 second=349 amount=-4
kerning first=201 second=259 amount=-1
kerning first=354 second=259 amount=-6
kerning first=75 second=345 amount=-1
kerning first=8217 second=367 amount=-2
kerning first=376 second=8221 amount=1
kerning first=967 second=941 amount=-1
kerning first=1060 second=1040 amount=-3
kerning first=233 second=382 amount=-1
kerning first=1027 second=1081 amount=-5
kerning first=119 second=349 amount=-1
kerning first=214 second=8220 amount=-1
kerning first=67 second=8221 amount=3
kerning first=313 second=79 amount=-2
kerning first=89 second=245 amount=-5
kerning first=372 second=112 amount=-2
kerning first=338 second=245 amount=-1
kerning first=1065 second=1108 amount=-1
kerning first=8222 second=364 amount=-1
kerning first=214 second=89 amount=-2
kerning first=282 second=373 amount=-1
kerning first=310 second=341 amount=-1
kerning first=84 second=210 amount=-2
kerning first=203 second=230 amount=-1
kerning first=344 second=350 amount=-1
kerning first=339 second=122 amount=-1
kerning first=227 second=121 amount=-1
kerning first=195 second=89 amount=-5
kerning first=373 second=378 amount=-1
kerning first=202 second=290 amount=-1
kerning first=198 second=99 amount=-1
kerning first=1092 second=1083 amount=-1
kerning first=203 second=265 amount=-1
kerning first=1075 second=1083 amount=-3
kerning first=84 second=251 amount=-4
kerning first=76 second=198 amount=2
kerning first=256 second=121 amount=-1
kerning first=343 second=277 amount=-1
kerning first=1059 second=1085 amount=-2
kerning first=272 second=198 amount=-1
kerning first=75 second=362 amount=-1
kerning first=356 second=74 amount=-2
kerning first=315 second=74 amount=1
kerning first=192 second=89 amount=-5
kerning first=354 second=120 amount=-3
kerning first=379 second=45 amount=-2
kerning first=341 second=291 amount=-1
kerning first=1169 second=1076 amount=-4
kerning first=1094 second=1076 amount=2
kerning first=280 second=335 amount=-1
kerning first=255 second=232 amount=-1
kerning first=362 second=193 amount=-2
kerning first=260 second=85 amount=-1
kerning first=274 second=119 amount=-1
kerning first=308 second=256 amount=-1
kerning first=70 second=193 amount=-4
kerning first=8222 second=85 amount=-1
kerning first=310 second=283 amount=-1
kerning first=104 second=375 amount=-1
kerning first=255 second=227 amount=-1
kerning first=197 second=372 amount=-3
kerning first=310 second=299 amount=1
kerning first=344 second=374 amount=-1
kerning first=192 second=266 amount=-1
kerning first=82 second=356 amount=-1
kerning first=83 second=375 amount=-1
kerning first=192 second=362 amount=-1
kerning first=345 second=259 amount=-1
kerning first=8221 second=284 amount=-3
kerning first=200 second=212 amount=-1
kerning first=231 second=228 amount=-1
kerning first=341 second=115 amount=-1
kerning first=76 second=288 amount=-2
kerning first=82 second=283 amount=-1
kerning first=89 second=44 amount=-9
kerning first=84 second=324 amount=-4
kerning first=345 second=97 amount=-1
kerning first=376 second=269 amount=-6
kerning first=253 second=228 amount=-1
kerning first=115 second=378 amount=-1
kerning first=202 second=99 amount=-1
kerning first=340 second=213 amount=-1
kerning first=90 second=213 amount=-1
kerning first=87 second=290 amount=-1
kerning first=262 second=213 amount=-1
kerning first=86 second=233 amount=-4
kerning first=1113 second=1090 amount=-3
kerning first=344 second=334 amount=-1
kerning first=86 second=242 amount=-3
kerning first=312 second=226 amount=-1
kerning first=84 second=336 amount=-2
kerning first=1070 second=1069 amount=-1
kerning first=255 second=283 amount=-1
kerning first=87 second=361 amount=-2
kerning first=8222 second=939 amount=-7
kerning first=8217 second=79 amount=-3
kerning first=380 second=285 amount=-1
kerning first=193 second=360 amount=-1
kerning first=355 second=113 amount=-1
kerning first=1060 second=1058 amount=-3
kerning first=8216 second=288 amount=-1
kerning first=69 second=71 amount=-1
kerning first=114 second=111 amount=-1
kerning first=89 second=229 amount=-5
kerning first=336 second=65 amount=-1
kerning first=372 second=65 amount=-3
kerning first=202 second=266 amount=-1
kerning first=373 second=224 amount=-1
kerning first=278 second=248 amount=-1
kerning first=99 second=224 amount=-1
kerning first=114 second=234 amount=-1
kerning first=1059 second=1118 amount=-1
kerning first=255 second=46 amount=-5
kerning first=116 second=231 amount=-1
kerning first=258 second=84 amount=-6
kerning first=278 second=111 amount=-1
kerning first=324 second=8216 amount=-1
kerning first=89 second=232 amount=-5
kerning first=107 second=351 amount=-1
kerning first=336 second=84 amount=-2
kerning first=1038 second=1104 amount=-4
kerning first=1062 second=1073 amount=-1
kerning first=267 second=248 amount=-1
kerning first=86 second=226 amount=-4
kerning first=310 second=83 amount=-1
kerning first=84 second=279 amount=-6
kerning first=88 second=352 amount=-1
kerning first=69 second=279 amount=-1
kerning first=1114 second=8216 amount=-4
kerning first=231 second=335 amount=-1
kerning first=114 second=118 amount=1
kerning first=194 second=86 amount=-3
kerning first=69 second=197 amount=-1
kerning first=89 second=47 amount=-4
kerning first=1092 second=1090 amount=-1
kerning first=1076 second=1118 amount=-1
kerning first=226 second=118 amount=-1
kerning first=1168 second=1033 amount=-5
kerning first=195 second=372 amount=-3
kerning first=194 second=199 amount=-1
kerning first=355 second=333 amount=-1
kerning first=193 second=332 amount=-1
kerning first=213 second=86 amount=-1
kerning first=272 second=379 amount=-1
kerning first=89 second=231 amount=-6
kerning first=376 second=227 amount=-5
kerning first=338 second=231 amount=-1
kerning first=86 second=199 amount=-1
kerning first=324 second=253 amount=-1
kerning first=84 second=197 amount=-6
kerning first=1058 second=1111 amount=3
kerning first=1038 second=1096 amount=-2
kerning first=196 second=118 amount=-1
kerning first=76 second=214 amount=-2
kerning first=345 second=101 amount=-1
kerning first=88 second=111 amount=-1
kerning first=195 second=44 amount=1
kerning first=194 second=79 amount=-1
kerning first=282 second=81 amount=-1
kerning first=198 second=253 amount=-2
kerning first=376 second=363 amount=-3
kerning first=87 second=281 amount=-3
kerning first=89 second=345 amount=-3
kerning first=255 second=230 amount=-1
kerning first=101 second=378 amount=-1
kerning first=75 second=305 amount=-1
kerning first=1036 second=1086 amount=-1
kerning first=70 second=101 amount=-1
kerning first=1038 second=1075 amount=-2
kerning first=46 second=71 amount=-1
kerning first=374 second=334 amount=-2
kerning first=910 second=47 amount=-5
kerning first=102 second=100 amount=-2
kerning first=274 second=268 amount=-1
kerning first=87 second=337 amount=-3
kerning first=274 second=118 amount=-2
kerning first=338 second=195 amount=-1
kerning first=372 second=287 amount=-2
kerning first=89 second=195 amount=-5
kerning first=208 second=192 amount=-1
kerning first=8220 second=100 amount=-3
kerning first=255 second=101 amount=-1
kerning first=103 second=257 amount=-1
kerning first=374 second=365 amount=-3
kerning first=8217 second=248 amount=-5
kerning first=217 second=65 amount=-2
kerning first=379 second=236 amount=1
kerning first=196 second=354 amount=-6
kerning first=115 second=253 amount=-1
kerning first=203 second=281 amount=-1
kerning first=274 second=83 amount=-1
kerning first=203 second=65 amount=-1
kerning first=65 second=253 amount=-1
kerning first=284 second=253 amount=-1
kerning first=1075 second=1139 amount=-1
kerning first=1024 second=1086 amount=-1
kerning first=90 second=337 amount=-1
kerning first=343 second=231 amount=-1
kerning first=1101 second=1076 amount=-2
kerning first=70 second=65 amount=-4
kerning first=375 second=277 amount=-1
kerning first=375 second=333 amount=-1
kerning first=291 second=235 amount=-1
kerning first=312 second=349 amount=-1
kerning first=355 second=235 amount=-1
kerning first=1069 second=1058 amount=-2
kerning first=297 second=311 amount=1
kerning first=970 second=957 amount=-1
kerning first=8221 second=110 amount=-2
kerning first=201 second=357 amount=-1
kerning first=356 second=97 amount=-6
kerning first=200 second=97 amount=-1
kerning first=119 second=45 amount=-2
kerning first=81 second=285 amount=2
kerning first=291 second=257 amount=-1
kerning first=381 second=199 amount=-1
kerning first=118 second=257 amount=-1
kerning first=70 second=258 amount=-4
kerning first=84 second=212 amount=-2
kerning first=342 second=199 amount=-1
kerning first=1044 second=1095 amount=-1
kerning first=355 second=257 amount=-1
kerning first=374 second=58 amount=-5
kerning first=356 second=264 amount=-1
kerning first=278 second=234 amount=-1
kerning first=1107 second=97 amount=-2
kerning first=200 second=264 amount=-1
kerning first=315 second=264 amount=-1
kerning first=119 second=285 amount=-1
kerning first=8217 second=331 amount=-2
kerning first=221 second=257 amount=-5
kerning first=69 second=212 amount=-1
kerning first=278 second=67 amount=-1
kerning first=1038 second=1139 amount=-4
kerning first=75 second=259 amount=-1
kerning first=311 second=275 amount=-2
kerning first=47 second=335 amount=-1
kerning first=196 second=374 amount=-5
kerning first=258 second=375 amount=-1
kerning first=275 second=102 amount=-1
kerning first=378 second=339 amount=-2
kerning first=366 second=198 amount=-1
kerning first=200 second=265 amount=-1
kerning first=378 second=248 amount=-2
kerning first=210 second=198 amount=-1
kerning first=356 second=265 amount=-6
kerning first=122 second=285 amount=-1
kerning first=201 second=196 amount=-1
kerning first=356 second=339 amount=-6
kerning first=346 second=258 amount=-1
kerning first=1054 second=1044 amount=-2
kerning first=354 second=196 amount=-6
kerning first=308 second=258 amount=-1
kerning first=46 second=103 amount=1
kerning first=122 second=103 amount=-1
kerning first=1169 second=44 amount=-3
kerning first=1038 second=1089 amount=-4
kerning first=221 second=193 amount=-5
kerning first=198 second=286 amount=-1
kerning first=378 second=265 amount=-2
kerning first=8216 second=44 amount=-7
kerning first=376 second=289 amount=-5
kerning first=194 second=8221 amount=-3
kerning first=86 second=194 amount=-3
kerning first=213 second=194 amount=-1
kerning first=310 second=297 amount=3
kerning first=920 second=44 amount=-2
kerning first=269 second=243 amount=-1
kerning first=1054 second=1066 amount=-2
kerning first=86 second=8221 amount=2
kerning first=8217 second=297 amount=2
kerning first=248 second=44 amount=-1
kerning first=121 second=243 amount=-1
kerning first=332 second=381 amount=-1
kerning first=908 second=46 amount=-1
kerning first=65 second=262 amount=-1
kerning first=1027 second=1109 amount=-7
kerning first=293 second=116 amount=-1
kerning first=1070 second=46 amount=-1
kerning first=221 second=235 amount=-5
kerning first=354 second=44 amount=-7
kerning first=1024 second=1054 amount=-2
kerning first=69 second=83 amount=-1
kerning first=356 second=225 amount=-6
kerning first=233 second=380 amount=-1
kerning first=918 second=972 amount=-1
kerning first=79 second=46 amount=-1
kerning first=1054 second=1038 amount=-1
kerning first=66 second=192 amount=-1
kerning first=221 second=256 amount=-5
kerning first=1062 second=1141 amount=-1
kerning first=8218 second=1038 amount=-3
kerning first=1041 second=1090 amount=-1
kerning first=364 second=46 amount=-1
kerning first=200 second=339 amount=-1
kerning first=196 second=308 amount=1
kerning first=332 second=308 amount=-1
kerning first=8217 second=290 amount=-3
kerning first=248 second=120 amount=-1
kerning first=343 second=46 amount=-7
kerning first=79 second=197 amount=-1
kerning first=312 second=234 amount=-2
kerning first=198 second=214 amount=-1
kerning first=378 second=227 amount=-1
kerning first=353 second=8220 amount=-1
kerning first=122 second=246 amount=-2
kerning first=920 second=932 amount=-2
kerning first=69 second=334 amount=-1
kerning first=203 second=260 amount=-1
kerning first=194 second=362 amount=-1
kerning first=203 second=346 amount=-1
kerning first=8221 second=121 amount=-2
kerning first=381 second=81 amount=-1
kerning first=1168 second=1093 amount=-4
kerning first=281 second=8220 amount=-1
kerning first=344 second=89 amount=-1
kerning first=291 second=225 amount=-1
kerning first=87 second=259 amount=-2
kerning first=213 second=8220 amount=-1
kerning first=8216 second=932 amount=3
kerning first=378 second=289 amount=-1
kerning first=355 second=225 amount=-1
kerning first=256 second=44 amount=1
kerning first=84 second=255 amount=-3
kerning first=282 second=233 amount=-1
kerning first=929 second=923 amount=-5
kerning first=372 second=239 amount=2
kerning first=274 second=210 amount=-1
kerning first=196 second=268 amount=-1
kerning first=8222 second=71 amount=-1
kerning first=1059 second=1051 amount=-5
kerning first=1035 second=1038 amount=-2
kerning first=221 second=224 amount=-5
kerning first=313 second=255 amount=-3
kerning first=221 second=225 amount=-5
kerning first=82 second=242 amount=-1
kerning first=8220 second=269 amount=-3
kerning first=268 second=81 amount=-1
kerning first=340 second=246 amount=-1
kerning first=102 second=333 amount=-2
kerning first=1107 second=227 amount=-2
kerning first=84 second=334 amount=-2
kerning first=1042 second=1066 amount=-1
kerning first=196 second=338 amount=-1
kerning first=378 second=375 amount=-1
kerning first=265 second=230 amount=-1
kerning first=202 second=101 amount=-1
kerning first=8221 second=235 amount=-5
kerning first=8220 second=44 amount=-7
kerning first=80 second=198 amount=-5
kerning first=276 second=121 amount=-2
kerning first=344 second=375 amount=-1
kerning first=313 second=183 amount=-2
kerning first=84 second=277 amount=-6
kerning first=8222 second=87 amount=-5
kerning first=69 second=277 amount=-1
kerning first=290 second=373 amount=-1
kerning first=374 second=251 amount=-3
kerning first=114 second=283 amount=-1
kerning first=350 second=8216 amount=-1
kerning first=84 second=245 amount=-6
kerning first=76 second=8216 amount=-7
kerning first=965 second=949 amount=-1
kerning first=344 second=290 amount=-1
kerning first=377 second=290 amount=-1
kerning first=264 second=290 amount=-1
kerning first=122 second=245 amount=-2
kerning first=46 second=334 amount=-1
kerning first=8217 second=378 amount=-1
kerning first=375 second=8216 amount=1
kerning first=954 second=950 amount=-1
kerning first=313 second=368 amount=-2
kerning first=276 second=119 amount=-1
kerning first=346 second=119 amount=-1
kerning first=79 second=87 amount=-1
kerning first=210 second=74 amount=-1
kerning first=1070 second=1083 amount=-1
kerning first=291 second=232 amount=-1
kerning first=119 second=103 amount=-1
kerning first=366 second=74 amount=-1
kerning first=278 second=283 amount=-1
kerning first=221 second=232 amount=-5
kerning first=69 second=245 amount=-1
kerning first=81 second=103 amount=2
kerning first=1118 second=8216 amount=1
kerning first=65 second=74 amount=1
kerning first=228 second=375 amount=-1
kerning first=8220 second=210 amount=-1
kerning first=115 second=102 amount=-1
kerning first=8220 second=224 amount=-2
kerning first=372 second=369 amount=-2
kerning first=374 second=103 amount=-5
kerning first=8222 second=197 amount=1
kerning first=346 second=372 amount=-1
kerning first=372 second=375 amount=-2
kerning first=208 second=193 amount=-1
kerning first=8221 second=264 amount=-2
kerning first=276 second=248 amount=-1
kerning first=68 second=8220 amount=-1
kerning first=372 second=334 amount=-1
kerning first=354 second=249 amount=-4
kerning first=356 second=356 amount=1
kerning first=221 second=266 amount=-2
kerning first=315 second=356 amount=-5
kerning first=227 second=355 amount=-1
kerning first=1027 second=1116 amount=-5
kerning first=8221 second=246 amount=-5
kerning first=939 second=972 amount=-5
kerning first=202 second=332 amount=-1
kerning first=253 second=231 amount=-1
kerning first=372 second=241 amount=-2
kerning first=244 second=122 amount=-1
kerning first=8217 second=375 amount=-2
kerning first=1100 second=1090 amount=-3
kerning first=935 second=959 amount=-1
kerning first=195 second=213 amount=-1
kerning first=121 second=267 amount=-1
kerning first=1025 second=1090 amount=-1
kerning first=87 second=213 amount=-1
kerning first=312 second=99 amount=-2
kerning first=210 second=90 amount=-1
kerning first=256 second=217 amount=-1
kerning first=276 second=246 amount=-1
kerning first=256 second=355 amount=-2
kerning first=278 second=228 amount=-1
kerning first=70 second=192 amount=-4
kerning first=354 second=252 amount=-4
kerning first=256 second=220 amount=-1
kerning first=8217 second=332 amount=-3
kerning first=311 second=113 amount=-2
kerning first=374 second=309 amount=2
kerning first=354 second=109 amount=-4
kerning first=362 second=192 amount=-2
kerning first=1070 second=1032 amount=-1
kerning first=8218 second=1063 amount=-8
kerning first=310 second=345 amount=-1
kerning first=378 second=351 amount=-1
kerning first=74 second=65 amount=-1
kerning first=276 second=336 amount=-1
kerning first=1046 second=1063 amount=-1
kerning first=80 second=8217 amount=2
kerning first=198 second=333 amount=-1
kerning first=372 second=110 amount=-2
kerning first=193 second=45 amount=-1
kerning first=8221 second=248 amount=-5
kerning first=80 second=333 amount=-1
kerning first=76 second=218 amount=-2
kerning first=200 second=227 amount=-1
kerning first=356 second=351 amount=-5
kerning first=8221 second=336 amount=-3
kerning first=374 second=279 amount=-5
kerning first=8218 second=192 amount=1
kerning first=372 second=111 amount=-3
kerning first=70 second=352 amount=-1
kerning first=8216 second=229 amount=-2
kerning first=116 second=111 amount=-1
kerning first=8217 second=308 amount=-5
kerning first=338 second=279 amount=-1
kerning first=89 second=279 amount=-5
kerning first=1075 second=1077 amount=-1
kerning first=8218 second=220 amount=-1
kerning first=360 second=192 amount=-2
kerning first=338 second=111 amount=-1
kerning first=89 second=111 amount=-5
kerning first=914 second=939 amount=-2
kerning first=260 second=84 amount=-6
kerning first=193 second=71 amount=-1
kerning first=65 second=288 amount=-1
kerning first=70 second=266 amount=-1
kerning first=84 second=285 amount=-5
kerning first=356 second=8216 amount=1
kerning first=199 second=214 amount=-1
kerning first=315 second=8216 amount=-7
kerning first=69 second=231 amount=-1
kerning first=264 second=334 amount=-1
kerning first=118 second=101 amount=-1
kerning first=66 second=221 amount=-2
kerning first=84 second=231 amount=-6
kerning first=345 second=248 amount=-1
kerning first=8216 second=230 amount=-2
kerning first=122 second=231 amount=-2
kerning first=8221 second=242 amount=-5
kerning first=263 second=227 amount=-1
kerning first=297 second=8216 amount=1
kerning first=70 second=248 amount=-1
kerning first=315 second=81 amount=-2
kerning first=356 second=81 amount=-2
kerning first=1062 second=1060 amount=-1
kerning first=47 second=923 amount=-3
kerning first=293 second=118 amount=-1
kerning first=287 second=279 amount=-1
kerning first=1043 second=1077 amount=-7
kerning first=8216 second=301 amount=3
kerning first=221 second=101 amount=-5
kerning first=76 second=290 amount=-2
kerning first=378 second=253 amount=-1
kerning first=83 second=260 amount=-1
kerning first=197 second=268 amount=-1
kerning first=382 second=230 amount=-1
kerning first=46 second=288 amount=-1
kerning first=200 second=81 amount=-1
kerning first=346 second=118 amount=-1
kerning first=75 second=225 amount=-1
kerning first=291 second=101 amount=-1
kerning first=315 second=253 amount=-3
kerning first=8216 second=337 amount=-2
kerning first=356 second=253 amount=-3
kerning first=355 second=101 amount=-1
kerning first=212 second=88 amount=-2
kerning first=89 second=239 amount=2
kerning first=221 second=192 amount=-5
kerning first=342 second=86 amount=-1
kerning first=374 second=350 amount=-1
kerning first=377 second=309 amount=1
kerning first=201 second=337 amount=-1
kerning first=374 second=242 amount=-5
kerning first=201 second=230 amount=-1
kerning first=106 second=8217 amount=1
kerning first=1040 second=1057 amount=-2
kerning first=341 second=8217 amount=3
kerning first=354 second=230 amount=-6
kerning first=84 second=341 amount=-4
kerning first=65 second=8221 amount=-3
kerning first=8218 second=266 amount=-1
kerning first=310 second=251 amount=-1
kerning first=114 second=351 amount=-1
kerning first=354 second=337 amount=-6
kerning first=338 second=67 amount=-1
kerning first=200 second=253 amount=-2
kerning first=379 second=299 amount=2
kerning first=332 second=221 amount=-2
kerning first=382 second=337 amount=-2
kerning first=114 second=287 amount=-1
kerning first=379 second=281 amount=-1
kerning first=196 second=221 amount=-5
kerning first=1168 second=1054 amount=-2
kerning first=382 second=265 amount=-2
kerning first=119 second=115 amount=-1
kerning first=285 second=291 amount=1
kerning first=1043 second=1091 amount=-5
kerning first=310 second=212 amount=-3
kerning first=1045 second=1076 amount=-1
kerning first=375 second=97 amount=-1
kerning first=274 second=275 amount=-1
kerning first=950 second=973 amount=-1
kerning first=942 second=964 amount=-1
kerning first=47 second=243 amount=-1
kerning first=274 second=258 amount=-1
kerning first=256 second=199 amount=-1
kerning first=253 second=263 amount=-1
kerning first=8220 second=291 amount=-3
kerning first=197 second=354 amount=-6
kerning first=120 second=245 amount=-2
kerning first=88 second=71 amount=-2
kerning first=347 second=120 amount=-1
kerning first=46 second=376 amount=-7
kerning first=122 second=101 amount=-2
kerning first=1168 second=1051 amount=-5
kerning first=74 second=260 amount=-1
kerning first=1077 second=8216 amount=-1
kerning first=374 second=367 amount=-3
kerning first=69 second=338 amount=-1
kerning first=380 second=227 amount=-1
kerning first=122 second=229 amount=-1
kerning first=315 second=262 amount=-1
kerning first=65 second=264 amount=-1
kerning first=118 second=122 amount=-1
kerning first=354 second=210 amount=-2
kerning first=87 second=343 amount=-2
kerning first=114 second=263 amount=-1
kerning first=70 second=283 amount=-1
kerning first=218 second=308 amount=-1
kerning first=8216 second=79 amount=-1
kerning first=194 second=217 amount=-1
kerning first=86 second=246 amount=-3
kerning first=203 second=212 amount=-1
kerning first=376 second=339 amount=-5
kerning first=82 second=339 amount=-1
kerning first=79 second=88 amount=-2
kerning first=221 second=122 amount=-4
kerning first=218 second=258 amount=-2
kerning first=86 second=67 amount=-1
kerning first=65 second=376 amount=-5
kerning first=285 second=113 amount=-1
kerning first=260 second=376 amount=-5
kerning first=351 second=378 amount=-1
kerning first=75 second=110 amount=-1
kerning first=221 second=255 amount=-2
kerning first=8217 second=46 amount=-7
kerning first=88 second=113 amount=-2
kerning first=8222 second=65 amount=1
kerning first=328 second=255 amount=-1
kerning first=1059 second=1116 amount=-2
kerning first=8220 second=258 amount=-6
kerning first=282 second=194 amount=-1
kerning first=343 second=234 amount=-1
kerning first=8220 second=308 amount=-3
kerning first=208 second=379 amount=-1
kerning first=82 second=337 amount=-1
kerning first=85 second=194 amount=-2
kerning first=341 second=351 amount=-1
kerning first=342 second=233 amount=-1
kerning first=381 second=233 amount=-1
kerning first=261 second=255 amount=-1
kerning first=344 second=244 amount=-1
kerning first=197 second=116 amount=-2
kerning first=1040 second=1035 amount=-4
kerning first=377 second=244 amount=-1
kerning first=99 second=244 amount=-1
kerning first=1050 second=1118 amount=-2
kerning first=102 second=235 amount=-2
kerning first=340 second=290 amount=-1
kerning first=194 second=262 amount=-1
kerning first=373 second=244 amount=-1
kerning first=116 second=232 amount=-1
kerning first=194 second=364 amount=-1
kerning first=69 second=211 amount=-1
kerning first=121 second=291 amount=-1
kerning first=1024 second=1105 amount=-2
kerning first=346 second=194 amount=-1
kerning first=84 second=211 amount=-2
kerning first=929 second=46 amount=-9
kerning first=920 second=935 amount=-2
kerning first=235 second=382 amount=-1
kerning first=8218 second=357 amount=-2
kerning first=89 second=324 amount=-3
kerning first=1069 second=1046 amount=-1
kerning first=932 second=44 amount=-8
kerning first=197 second=288 amount=-1
kerning first=87 second=112 amount=-2
kerning first=87 second=235 amount=-3
kerning first=1059 second=1063 amount=-1
kerning first=312 second=347 amount=-1
kerning first=86 second=262 amount=-1
kerning first=8220 second=338 amount=-1
kerning first=1024 second=1113 amount=-1
kerning first=1027 second=8220 amount=1
kerning first=257 second=116 amount=-1
kerning first=102 second=44 amount=-4
kerning first=103 second=116 amount=-1
kerning first=370 second=46 amount=-1
kerning first=214 second=44 amount=-2
kerning first=223 second=116 amount=-1
kerning first=8220 second=275 amount=-3
kerning first=339 second=8220 amount=-1
kerning first=202 second=260 amount=-1
kerning first=1061 second=1104 amount=-2
kerning first=935 second=972 amount=-1
kerning first=263 second=226 amount=-1
kerning first=89 second=349 amount=-4
kerning first=372 second=195 amount=-3
kerning first=46 second=212 amount=-1
kerning first=87 second=257 amount=-2
kerning first=1075 second=1104 amount=-1
kerning first=107 second=265 amount=-2
kerning first=102 second=257 amount=-1
kerning first=121 second=269 amount=-1
kerning first=276 second=229 amount=-1
kerning first=354 second=117 amount=-4
kerning first=341 second=97 amount=-1
kerning first=266 second=332 amount=-1
kerning first=102 second=224 amount=-1
kerning first=221 second=259 amount=-5
kerning first=344 second=332 amount=-1
kerning first=923 second=910 amount=-5
kerning first=116 second=263 amount=-1
kerning first=336 second=195 amount=-1
kerning first=196 second=210 amount=-1
kerning first=291 second=259 amount=-1
kerning first=372 second=328 amount=-2
kerning first=90 second=236 amount=1
kerning first=122 second=279 amount=-2
kerning first=274 second=338 amount=-1
kerning first=355 second=259 amount=-1
kerning first=373 second=277 amount=-1
kerning first=75 second=288 amount=-3
kerning first=97 second=253 amount=-1
kerning first=89 second=263 amount=-6
kerning first=265 second=243 amount=-1
kerning first=46 second=85 amount=-1
kerning first=268 second=284 amount=-1
kerning first=68 second=193 amount=-1
kerning first=1033 second=1063 amount=-4
kerning first=76 second=376 amount=-6
kerning first=373 second=103 amount=-1
kerning first=65 second=212 amount=-1
kerning first=374 second=245 amount=-5
kerning first=356 second=269 amount=-6
kerning first=379 second=121 amount=-1
kerning first=348 second=193 amount=-1
kerning first=348 second=121 amount=-1
kerning first=1036 second=1108 amount=-1
kerning first=83 second=89 amount=-1
kerning first=315 second=86 amount=-5
kerning first=119 second=245 amount=-1
kerning first=260 second=214 amount=-1
kerning first=256 second=63 amount=-2
kerning first=196 second=290 amount=-1
kerning first=89 second=252 amount=-3
kerning first=374 second=277 amount=-5
kerning first=210 second=377 amount=-1
kerning first=89 second=251 amount=-3
kerning first=202 second=45 amount=-1
kerning first=197 second=219 amount=-1
kerning first=370 second=197 amount=-2
kerning first=212 second=197 amount=-1
kerning first=1044 second=59 amount=2
kerning first=914 second=8216 amount=-1
kerning first=119 second=277 amount=-1
kerning first=375 second=8217 amount=2
kerning first=242 second=120 amount=-1
kerning first=8216 second=290 amount=-1
kerning first=8217 second=334 amount=-3
kerning first=372 second=299 amount=2
kerning first=345 second=245 amount=-1
kerning first=1034 second=8220 amount=-3
kerning first=82 second=373 amount=-1
kerning first=372 second=283 amount=-3
kerning first=80 second=74 amount=-5
kerning first=376 second=373 amount=-2
kerning first=76 second=8217 amount=-5
kerning first=295 second=121 amount=-1
kerning first=279 second=378 amount=-1
kerning first=377 second=119 amount=-1
kerning first=285 second=232 amount=-1
kerning first=344 second=119 amount=-1
kerning first=1082 second=1072 amount=-1
kerning first=1043 second=58 amount=-3
kerning first=1027 second=1095 amount=-4
kerning first=221 second=326 amount=-3
kerning first=351 second=375 amount=-1
kerning first=1062 second=8216 amount=-1
kerning first=1043 second=45 amount=-2
kerning first=338 second=226 amount=-1
kerning first=1106 second=1091 amount=-2
kerning first=1107 second=8216 amount=1
kerning first=225 second=375 amount=-1
kerning first=1116 second=1092 amount=-1
kerning first=88 second=232 amount=-1
kerning first=336 second=374 amount=-2
kerning first=288 second=374 amount=-1
kerning first=287 second=8217 amount=3
kerning first=212 second=374 amount=-2
kerning first=210 second=356 amount=-2
kerning first=313 second=266 amount=-1
kerning first=87 second=375 amount=-2
kerning first=193 second=374 amount=-5
kerning first=372 second=353 amount=-3
kerning first=1056 second=1103 amount=-1
kerning first=80 second=335 amount=-1
kerning first=234 second=102 amount=-1
kerning first=291 second=285 amount=1
kerning first=80 second=193 amount=-5
kerning first=280 second=102 amount=-2
kerning first=256 second=336 amount=-1
kerning first=374 second=99 amount=-6
kerning first=200 second=242 amount=-1
kerning first=356 second=242 amount=-6
kerning first=201 second=234 amount=-1
kerning first=373 second=263 amount=-1
kerning first=191 second=373 amount=-3
kerning first=119 second=99 amount=-1
kerning first=203 second=213 amount=-1
kerning first=276 second=355 amount=-1
kerning first=1044 second=1059 amount=-1
kerning first=1066 second=1059 amount=-3
kerning first=121 second=347 amount=-1
kerning first=372 second=228 amount=-2
kerning first=381 second=284 amount=-1
kerning first=378 second=242 amount=-2
kerning first=287 second=234 amount=-1
kerning first=379 second=213 amount=-1
kerning first=45 second=1040 amount=-1
kerning first=1059 second=1072 amount=-2
kerning first=120 second=228 amount=-1
kerning first=197 second=8217 amount=-3
kerning first=255 second=122 amount=-1
kerning first=256 second=372 amount=-3
kerning first=86 second=284 amount=-1
kerning first=1058 second=1108 amount=-6
kerning first=75 second=355 amount=-1
kerning first=116 second=234 amount=-1
kerning first=89 second=67 amount=-2
kerning first=1059 second=1032 amount=-3
kerning first=1102 second=1090 amount=-1
kerning first=194 second=284 amount=-1
kerning first=8220 second=246 amount=-2
kerning first=333 second=122 amount=-1
kerning first=282 second=246 amount=-1
kerning first=202 second=277 amount=-1
kerning first=973 second=955 amount=-2
kerning first=221 second=237 amount=-2
kerning first=345 second=113 amount=-1
kerning first=89 second=234 amount=-5
kerning first=1168 second=1119 amount=-5
kerning first=338 second=234 amount=-1
kerning first=269 second=224 amount=-1
kerning first=80 second=97 amount=-2
kerning first=214 second=196 amount=-1
kerning first=192 second=79 amount=-1
kerning first=315 second=8221 amount=-5
kerning first=381 second=336 amount=-1
kerning first=122 second=99 amount=-2
kerning first=342 second=336 amount=-1
kerning first=87 second=285 amount=-2
kerning first=70 second=113 amount=-1
kerning first=268 second=336 amount=-1
kerning first=8218 second=370 amount=-1
kerning first=276 second=290 amount=-1
kerning first=121 second=224 amount=-1
kerning first=103 second=8217 amount=3
kerning first=356 second=8221 amount=2
kerning first=266 second=213 amount=-1
kerning first=221 second=249 amount=-3
kerning first=380 second=277 amount=-2
kerning first=265 second=228 amount=-1
kerning first=382 second=229 amount=-1
kerning first=87 second=288 amount=-1
kerning first=201 second=256 amount=-1
kerning first=201 second=111 amount=-1
kerning first=278 second=227 amount=-1
kerning first=119 second=231 amount=-1
kerning first=378 second=8221 amount=1
kerning first=270 second=84 amount=-1
kerning first=118 second=244 amount=-1
kerning first=253 second=337 amount=-1
kerning first=221 second=352 amount=-1
kerning first=374 second=71 amount=-2
kerning first=282 second=227 amount=-1
kerning first=253 second=111 amount=-1
kerning first=88 second=266 amount=-2
kerning first=1027 second=1073 amount=-2
kerning first=8216 second=198 amount=-7
kerning first=202 second=334 amount=-1
kerning first=1058 second=59 amount=-1
kerning first=191 second=253 amount=-2
kerning first=374 second=231 amount=-6
kerning first=45 second=253 amount=-1
kerning first=1043 second=1114 amount=-5
kerning first=197 second=214 amount=-1
kerning first=69 second=226 amount=-1
kerning first=1058 second=1071 amount=-1
kerning first=310 second=111 amount=-1
kerning first=311 second=101 amount=-2
kerning first=67 second=81 amount=-1
kerning first=47 second=115 amount=-1
kerning first=923 second=63 amount=-2
kerning first=285 second=225 amount=-1
kerning first=1050 second=1095 amount=-1
kerning first=343 second=279 amount=-1
kerning first=65 second=218 amount=-1
kerning first=88 second=248 amount=-1
kerning first=75 second=250 amount=-1
kerning first=1056 second=1077 amount=-1
kerning first=257 second=118 amount=-1
kerning first=82 second=81 amount=-1
kerning first=44 second=84 amount=-5
kerning first=376 second=81 amount=-2
kerning first=336 second=260 amount=-1
kerning first=8216 second=235 amount=-3
kerning first=278 second=263 amount=-1
kerning first=315 second=364 amount=-2
kerning first=274 second=244 amount=-1
kerning first=79 second=221 amount=-2
kerning first=197 second=118 amount=-1
kerning first=372 second=260 amount=-3
kerning first=278 second=195 amount=-1
kerning first=8216 second=256 amount=-6
kerning first=214 second=88 amount=-2
kerning first=1038 second=1028 amount=-2
kerning first=211 second=192 amount=-1
kerning first=82 second=253 amount=-1
kerning first=376 second=253 amount=-2
kerning first=256 second=86 amount=-3
kerning first=286 second=255 amount=-1
kerning first=70 second=239 amount=1
kerning first=192 second=255 amount=-1
kerning first=368 second=192 amount=-2
kerning first=342 second=213 amount=-1
kerning first=71 second=376 amount=-1
kerning first=87 second=236 amount=1
kerning first=75 second=100 amount=-1
kerning first=79 second=376 amount=-2
kerning first=382 second=235 amount=-2
kerning first=356 second=289 amount=-5
kerning first=1088 second=1091 amount=-1
kerning first=122 second=349 amount=-1
kerning first=74 second=193 amount=-1
kerning first=372 second=281 amount=-3
kerning first=311 second=337 amount=-2
kerning first=201 second=235 amount=-1
kerning first=245 second=382 amount=-1
kerning first=212 second=8220 amount=-1
kerning first=354 second=235 amount=-6
kerning first=374 second=331 amount=-3
kerning first=1168 second=1098 amount=-2
kerning first=1038 second=1105 amount=-4
kerning first=198 second=264 amount=-1
kerning first=1093 second=1092 amount=-1
kerning first=221 second=357 amount=-2
kerning first=1168 second=1113 amount=-7
kerning first=260 second=212 amount=-1
kerning first=1091 second=1105 amount=-1
kerning first=1169 second=257 amount=-2
kerning first=908 second=923 amount=-1
kerning first=347 second=255 amount=-1
kerning first=1025 second=1076 amount=-1
kerning first=8216 second=257 amount=-2
kerning first=261 second=357 amount=-1
kerning first=291 second=357 amount=-1
kerning first=382 second=257 amount=-1
kerning first=355 second=357 amount=-1
kerning first=374 second=369 amount=-3
kerning first=198 second=210 amount=-1
kerning first=949 second=952 amount=-1
kerning first=8222 second=923 amount=1
kerning first=119 second=291 amount=-1
kerning first=84 second=349 amount=-5
kerning first=90 second=301 amount=1
kerning first=332 second=258 amount=-1
kerning first=213 second=90 amount=-1
kerning first=276 second=199 amount=-1
kerning first=8217 second=365 amount=-2
kerning first=372 second=361 amount=-2
kerning first=89 second=212 amount=-2
kerning first=87 second=241 amount=-2
kerning first=382 second=101 amount=-2
kerning first=338 second=212 amount=-1
kerning first=1038 second=1141 amount=-1
kerning first=1107 second=1092 amount=-1
kerning first=201 second=257 amount=-1
kerning first=1062 second=1092 amount=-1
kerning first=80 second=265 amount=-2
kerning first=355 second=269 amount=-1
kerning first=76 second=264 amount=-1
kerning first=375 second=335 amount=-1
kerning first=1168 second=1097 amount=-5
kerning first=1058 second=1059 amount=1
kerning first=332 second=374 amount=-2
kerning first=195 second=366 amount=-1
kerning first=203 second=375 amount=-2
kerning first=199 second=211 amount=-1
kerning first=380 second=347 amount=-1
kerning first=76 second=102 amount=-1
kerning first=196 second=332 amount=-1
kerning first=349 second=378 amount=-1
kerning first=341 second=243 amount=-1
kerning first=916 second=910 amount=-5
kerning first=255 second=259 amount=-1
kerning first=200 second=286 amount=-1
kerning first=8217 second=347 amount=-4
kerning first=315 second=286 amount=-2
kerning first=343 second=337 amount=-1
kerning first=356 second=286 amount=-2
kerning first=47 second=291 amount=-1
kerning first=84 second=297 amount=5
kerning first=8216 second=101 amount=-3
kerning first=223 second=382 amount=-1
kerning first=70 second=238 amount=1
kerning first=1056 second=1044 amount=-4
kerning first=86 second=109 amount=-2
kerning first=334 second=194 amount=-1
kerning first=285 second=100 amount=-1
kerning first=280 second=116 amount=-1
kerning first=8216 second=265 amount=-3
kerning first=254 second=122 amount=-1
kerning first=310 second=263 amount=-1
kerning first=119 second=46 amount=-5
kerning first=1065 second=44 amount=2
kerning first=242 second=380 amount=-1
kerning first=1054 second=46 amount=-1
kerning first=376 second=262 amount=-2
kerning first=1168 second=44 amount=-6
kerning first=82 second=262 amount=-1
kerning first=103 second=355 amount=-1
kerning first=355 second=232 amount=-1
kerning first=338 second=83 amount=-1
kerning first=1169 second=1084 amount=-1
kerning first=221 second=120 amount=-2
kerning first=103 second=291 amount=1
kerning first=1054 second=1035 amount=-2
kerning first=89 second=83 amount=-1
kerning first=8221 second=120 amount=-2
kerning first=1062 second=1059 amount=-1
kerning first=90 second=235 amount=-1
kerning first=261 second=120 amount=-1
kerning first=1059 second=1081 amount=-2
kerning first=343 second=349 amount=-1
kerning first=328 second=120 amount=-1
kerning first=280 second=288 amount=-1
kerning first=89 second=382 amount=-4
kerning first=374 second=46 amount=-8
kerning first=217 second=44 amount=-1
kerning first=229 second=375 amount=-1
kerning first=1114 second=1091 amount=-2
kerning first=1061 second=1089 amount=-1
kerning first=122 second=97 amount=-1
kerning first=380 second=242 amount=-2
kerning first=200 second=333 amount=-1
kerning first=1075 second=1089 amount=-1
kerning first=198 second=265 amount=-1
kerning first=340 second=235 amount=-1
kerning first=354 second=256 amount=-6
kerning first=201 second=338 amount=-1
kerning first=356 second=333 amount=-6
kerning first=376 second=105 amount=-1
kerning first=373 second=45 amount=-2
kerning first=1123 second=1091 amount=-2
kerning first=902 second=45 amount=-1
kerning first=1102 second=8216 amount=-1
kerning first=8220 second=290 amount=-1
kerning first=8221 second=114 amount=-2
kerning first=75 second=229 amount=-1
kerning first=280 second=214 amount=-1
kerning first=88 second=262 amount=-2
kerning first=310 second=239 amount=1
kerning first=86 second=227 amount=-4
kerning first=1168 second=1076 amount=-8
kerning first=195 second=290 amount=-1
kerning first=66 second=197 amount=-1
kerning first=274 second=290 amount=-1
kerning first=267 second=229 amount=-1
kerning first=86 second=81 amount=-1
kerning first=264 second=210 amount=-1
kerning first=377 second=210 amount=-1
kerning first=120 second=263 amount=-2
kerning first=344 second=210 amount=-1
kerning first=68 second=88 amount=-1
kerning first=1059 second=1119 amount=-2
kerning first=341 second=8221 amount=3
kerning first=107 second=242 amount=-2
kerning first=221 second=109 amount=-3
kerning first=193 second=334 amount=-1
kerning first=70 second=225 amount=-2
kerning first=47 second=269 amount=-1
kerning first=194 second=81 amount=-1
kerning first=202 second=350 amount=-1
kerning first=102 second=230 amount=-1
kerning first=315 second=71 amount=-2
kerning first=1045 second=1118 amount=-1
kerning first=44 second=87 amount=-5
kerning first=1059 second=1114 amount=-2
kerning first=1043 second=1107 amount=-5
kerning first=1046 second=1105 amount=-1
kerning first=282 second=284 amount=-1
kerning first=282 second=253 amount=-2
kerning first=8217 second=103 amount=-5
kerning first=332 second=372 amount=-1
kerning first=196 second=372 amount=-3
kerning first=220 second=198 amount=-1
kerning first=69 second=99 amount=-1
kerning first=312 second=245 amount=-2
kerning first=1094 second=1098 amount=-1
kerning first=65 second=8216 amount=-6
kerning first=87 second=230 amount=-2
kerning first=89 second=236 amount=1
kerning first=381 second=299 amount=2
kerning first=1114 second=1090 amount=-3
kerning first=210 second=8216 amount=-1
kerning first=1098 second=1118 amount=-2
kerning first=380 second=103 amount=-1
kerning first=84 second=99 amount=-6
kerning first=114 second=353 amount=-1
kerning first=230 second=378 amount=-1
kerning first=1091 second=1083 amount=-2
kerning first=275 second=8216 amount=-1
kerning first=203 second=196 amount=-1
kerning first=374 second=378 amount=-4
kerning first=336 second=89 amount=-2
kerning first=902 second=8216 amount=-6
kerning first=1036 second=1139 amount=-1
kerning first=217 second=196 amount=-2
kerning first=289 second=289 amount=1
kerning first=379 second=283 amount=-1
kerning first=115 second=8216 amount=-1
kerning first=310 second=87 amount=-1
kerning first=352 second=87 amount=-1
kerning first=88 second=121 amount=-2
kerning first=1038 second=1083 amount=-6
kerning first=272 second=74 amount=-1
kerning first=120 second=283 amount=-2
kerning first=200 second=373 amount=-1
kerning first=119 second=378 amount=-1
kerning first=1073 second=1083 amount=-1
kerning first=315 second=373 amount=-3
kerning first=89 second=341 amount=-3
kerning first=356 second=373 amount=-3
kerning first=76 second=74 amount=1
kerning first=192 second=368 amount=-1
kerning first=8216 second=97 amount=-2
kerning first=8222 second=67 amount=-1
kerning first=82 second=335 amount=-1
kerning first=376 second=335 amount=-5
kerning first=1107 second=1076 amount=-4
kerning first=256 second=368 amount=-1
kerning first=197 second=74 amount=1
kerning first=1043 second=1076 amount=-8
kerning first=118 second=337 amount=-1
kerning first=198 second=211 amount=-1
kerning first=44 second=89 amount=-5
kerning first=354 second=289 amount=-5
kerning first=350 second=119 amount=-1
kerning first=76 second=119 amount=-3
kerning first=8221 second=356 amount=1
kerning first=380 second=97 amount=-1
kerning first=202 second=245 amount=-1
kerning first=293 second=375 amount=-1
kerning first=276 second=269 amount=-1
kerning first=86 second=103 amount=-4
kerning first=200 second=197 amount=-1
kerning first=967 second=949 amount=-1
kerning first=214 second=356 amount=-2
kerning first=356 second=197 amount=-6
kerning first=375 second=242 amount=-1
kerning first=280 second=232 amount=-1
kerning first=195 second=356 amount=-6
kerning first=276 second=253 amount=-2
kerning first=346 second=375 amount=-1
kerning first=86 second=353 amount=-3
kerning first=101 second=102 amount=-1
kerning first=254 second=102 amount=-1
kerning first=356 second=109 amount=-4
kerning first=1024 second=1083 amount=-1
kerning first=345 second=229 amount=-1
kerning first=75 second=117 amount=-1
kerning first=197 second=374 amount=-5
kerning first=88 second=373 amount=-2
kerning first=274 second=102 amount=-2
kerning first=1044 second=1054 amount=-1
kerning first=1105 second=1083 amount=-1
kerning first=274 second=346 amount=-1
kerning first=382 second=289 amount=-1
kerning first=75 second=210 amount=-3
kerning first=254 second=8216 amount=-1
kerning first=315 second=213 amount=-2
kerning first=1058 second=1141 amount=-4
kerning first=1169 second=224 amount=-2
kerning first=276 second=210 amount=-1
kerning first=65 second=355 amount=-2
kerning first=356 second=213 amount=-2
kerning first=1044 second=8216 amount=-1
kerning first=1046 second=1090 amount=-1
kerning first=115 second=355 amount=-1
kerning first=1066 second=8216 amount=-3
kerning first=332 second=46 amount=-1
kerning first=1097 second=8216 amount=-1
kerning first=941 second=966 amount=-1
kerning first=47 second=74 amount=-1
kerning first=8216 second=224 amount=-2
kerning first=916 second=932 amount=-6
kerning first=202 second=338 amount=-1
kerning first=213 second=198 amount=-1
kerning first=278 second=284 amount=-1
kerning first=270 second=89 amount=-1
kerning first=354 second=238 amount=2
kerning first=352 second=89 amount=-1
kerning first=221 second=198 amount=-6
kerning first=201 second=224 amount=-1
kerning first=344 second=336 amount=-1
kerning first=372 second=277 amount=-3
kerning first=203 second=228 amount=-1
kerning first=8218 second=218 amount=-1
kerning first=354 second=224 amount=-6
kerning first=380 second=99 amount=-2
kerning first=381 second=255 amount=-1
kerning first=282 second=336 amount=-1
kerning first=345 second=115 amount=-1
kerning first=276 second=233 amount=-1
kerning first=334 second=376 amount=-2
kerning first=86 second=328 amount=-2
kerning first=280 second=262 amount=-1
kerning first=342 second=255 amount=-1
kerning first=87 second=196 amount=-3
kerning first=278 second=121 amount=-2
kerning first=940 second=974 amount=-1
kerning first=1058 second=1080 amount=-5
kerning first=46 second=118 amount=-3
kerning first=121 second=351 amount=-1
kerning first=84 second=117 amount=-4
kerning first=949 second=940 amount=-1
kerning first=312 second=231 amount=-2
kerning first=310 second=220 amount=-1
kerning first=90 second=111 amount=-1
kerning first=376 second=252 amount=-3
kerning first=274 second=337 amount=-1
kerning first=1027 second=8216 amount=1
kerning first=66 second=86 amount=-1
kerning first=8221 second=233 amount=-5
kerning first=343 second=101 amount=-1
kerning first=203 second=267 amount=-1
kerning first=119 second=227 amount=-1
kerning first=86 second=248 amount=-3
kerning first=75 second=266 amount=-3
kerning first=353 second=253 amount=-1
kerning first=196 second=214 amount=-1
kerning first=69 second=118 amount=-2
kerning first=1038 second=1118 amount=-1
kerning first=84 second=118 amount=-3
kerning first=101 second=8216 amount=-1
kerning first=374 second=227 amount=-5
kerning first=1061 second=1139 amount=-1
kerning first=86 second=253 amount=-1
kerning first=116 second=227 amount=-1
kerning first=1168 second=1111 amount=4
kerning first=200 second=275 amount=-1
kerning first=82 second=281 amount=-1
kerning first=360 second=260 amount=-2
kerning first=224 second=253 amount=-1
kerning first=87 second=226 amount=-2
kerning first=278 second=260 amount=-1
kerning first=260 second=221 amount=-5
kerning first=376 second=281 amount=-5
kerning first=202 second=213 amount=-1
kerning first=47 second=65 amount=-2
kerning first=90 second=239 amount=2
kerning first=102 second=226 amount=-1
kerning first=1094 second=1086 amount=-1
kerning first=1059 second=1057 amount=-2
kerning first=195 second=268 amount=-1
kerning first=374 second=299 amount=3
kerning first=87 second=268 amount=-1
kerning first=253 second=230 amount=-1
kerning first=221 second=334 amount=-2
kerning first=258 second=89 amount=-5
kerning first=290 second=253 amount=-1
kerning first=231 second=230 amount=-1
kerning first=271 second=320 amount=1
kerning first=287 second=287 amount=1
kerning first=194 second=332 amount=-1
kerning first=264 second=71 amount=-1
kerning first=290 second=376 amount=-1
kerning first=923 second=8217 amount=-3
kerning first=377 second=71 amount=-1
kerning first=344 second=71 amount=-1
kerning first=342 second=350 amount=-1
kerning first=86 second=332 amount=-1
kerning first=301 second=8217 amount=1
kerning first=1024 second=1089 amount=-2
kerning first=310 second=230 amount=-1
kerning first=1168 second=1096 amount=-5
kerning first=89 second=287 amount=-5
kerning first=8222 second=211 amount=-2
kerning first=378 second=333 amount=-2
kerning first=282 second=97 amount=-1
kerning first=70 second=275 amount=-1
kerning first=372 second=363 amount=-2
kerning first=196 second=286 amount=-1
kerning first=203 second=79 amount=-1
kerning first=8221 second=243 amount=-5
kerning first=63 second=8221 amount=1
kerning first=274 second=269 amount=-1
kerning first=192 second=63 amount=-2
kerning first=1076 second=1092 amount=-1
kerning first=914 second=910 amount=-2
kerning first=381 second=375 amount=-1
kerning first=69 second=101 amount=-1
kerning first=71 second=221 amount=-1
kerning first=8221 second=46 amount=-7
kerning first=345 second=275 amount=-1
kerning first=81 second=84 amount=-2
kerning first=1058 second=1072 amount=-5
kerning first=102 second=267 amount=-2
kerning first=333 second=120 amount=-1
kerning first=198 second=113 amount=-1
kerning first=1046 second=1028 amount=-2
kerning first=354 second=367 amount=-4
kerning first=8217 second=257 amount=-5
kerning first=372 second=269 amount=-3
kerning first=203 second=199 amount=-1
kerning first=1033 second=1058 amount=-5
kerning first=1045 second=1063 amount=-1
kerning first=8222 second=932 amount=-6
kerning first=338 second=339 amount=-1
kerning first=276 second=111 amount=-1
kerning first=373 second=279 amount=-1
kerning first=89 second=339 amount=-5
kerning first=118 second=231 amount=-1
kerning first=89 second=241 amount=-3
kerning first=354 second=122 amount=-5
kerning first=198 second=111 amount=-1
kerning first=256 second=264 amount=-1
kerning first=1041 second=1098 amount=-1
kerning first=1062 second=1098 amount=-1
kerning first=200 second=243 amount=-1
kerning first=344 second=279 amount=-1
kerning first=377 second=279 amount=-1
kerning first=199 second=198 amount=1
kerning first=372 second=246 amount=-3
kerning first=375 second=113 amount=-1
kerning first=122 second=291 amount=-1
kerning first=1027 second=1114 amount=-5
kerning first=282 second=248 amount=-1
kerning first=954 second=943 amount=-1
kerning first=87 second=267 amount=-3
kerning first=372 second=343 amount=-2
kerning first=202 second=258 amount=-1
kerning first=46 second=291 amount=1
kerning first=238 second=8221 amount=3
kerning first=255 second=45 amount=-2
kerning first=116 second=339 amount=-1
kerning first=276 second=243 amount=-1
kerning first=381 second=297 amount=4
kerning first=193 second=253 amount=-1
kerning first=248 second=122 amount=-1
kerning first=8221 second=244 amount=-5
kerning first=265 second=339 amount=-1
kerning first=8217 second=333 amount=-5
kerning first=336 second=221 amount=-2
kerning first=1138 second=1044 amount=-2
kerning first=8217 second=258 amount=-9
kerning first=120 second=235 amount=-2
kerning first=86 second=100 amount=-3
kerning first=374 second=194 amount=-5
kerning first=914 second=44 amount=-1
kerning first=102 second=382 amount=-1
kerning first=379 second=235 amount=-1
kerning first=256 second=67 amount=-1
kerning first=373 second=46 amount=-5
kerning first=76 second=253 amount=-3
kerning first=208 second=197 amount=-1
kerning first=242 second=44 amount=-1
kerning first=197 second=262 amount=-1
kerning first=353 second=102 amount=-1
kerning first=1058 second=1060 amount=-3
kerning first=372 second=347 amount=-3
kerning first=203 second=244 amount=-1
kerning first=202 second=196 amount=-1
kerning first=376 second=324 amount=-3
kerning first=74 second=44 amount=-1
kerning first=194 second=288 amount=-1
kerning first=76 second=116 amount=-1
kerning first=8222 second=920 amount=-1
kerning first=255 second=115 amount=-1
kerning first=98 second=8220 amount=-1
kerning first=86 second=288 amount=-1
kerning first=220 second=74 amount=-1
kerning first=80 second=256 amount=-5
kerning first=44 second=8220 amount=-6
kerning first=1102 second=1076 amount=-2
kerning first=8217 second=336 amount=-3
kerning first=198 second=256 amount=-1
kerning first=375 second=265 amount=-1
kerning first=212 second=354 amount=-2
kerning first=200 second=259 amount=-1
kerning first=356 second=259 amount=-6
kerning first=202 second=212 amount=-1
kerning first=221 second=308 amount=-4
kerning first=202 second=257 amount=-1
kerning first=338 second=357 amount=-1
kerning first=75 second=85 amount=-1
kerning first=226 second=253 amount=-1
kerning first=277 second=120 amount=-1
kerning first=221 second=328 amount=-3
kerning first=192 second=290 amount=-1
kerning first=217 second=195 amount=-2
kerning first=193 second=357 amount=-2
kerning first=8217 second=212 amount=-3
kerning first=119 second=8221 amount=2
kerning first=1040 second=1054 amount=-2
kerning first=356 second=279 amount=-6
kerning first=214 second=195 amount=-1
kerning first=1076 second=1141 amount=-1
kerning first=193 second=354 amount=-6
kerning first=88 second=210 amount=-2
kerning first=1107 second=259 amount=-2
kerning first=285 second=229 amount=-1
kerning first=349 second=357 amount=-1
kerning first=203 second=195 amount=-1
kerning first=72 second=8220 amount=-1
kerning first=1056 second=1051 amount=-5
kerning first=350 second=375 amount=-1
kerning first=195 second=8220 amount=-6
kerning first=8217 second=71 amount=-3
kerning first=340 second=268 amount=-1
kerning first=950 second=970 amount=-2
kerning first=354 second=239 amount=3
kerning first=255 second=229 amount=-1
kerning first=904 second=916 amount=-1
kerning first=88 second=81 amount=-2
kerning first=1113 second=1141 amount=-2
kerning first=1066 second=1038 amount=-3
kerning first=334 second=377 amount=-1
kerning first=1044 second=1038 amount=-1
kerning first=116 second=230 amount=-1
kerning first=1075 second=227 amount=-2
kerning first=313 second=334 amount=-2
kerning first=276 second=283 amount=-1
kerning first=276 second=225 amount=-1
kerning first=8218 second=85 amount=-1
kerning first=90 second=268 amount=-1
kerning first=1045 second=1057 amount=-2
kerning first=287 second=230 amount=-1
kerning first=89 second=230 amount=-5
kerning first=1111 second=8220 amount=2
kerning first=258 second=211 amount=-1
kerning first=203 second=264 amount=-1
kerning first=102 second=335 amount=-2
kerning first=99 second=245 amount=-1
kerning first=201 second=290 amount=-1
kerning first=338 second=230 amount=-1
kerning first=1107 second=224 amount=-2
kerning first=354 second=290 amount=-2
kerning first=256 second=255 amount=-1
kerning first=1058 second=1093 amount=-4
kerning first=372 second=211 amount=-1
kerning first=310 second=219 amount=-1
kerning first=65 second=116 amount=-2
kerning first=373 second=245 amount=-1
kerning first=75 second=233 amount=-1
kerning first=8221 second=283 amount=-5
kerning first=84 second=369 amount=-4
kerning first=375 second=232 amount=-1
kerning first=951 second=957 amount=-2
kerning first=83 second=87 amount=-1
kerning first=227 second=255 amount=-1
kerning first=120 second=246 amount=-2
kerning first=87 second=193 amount=-3
kerning first=356 second=335 amount=-6
kerning first=198 second=119 amount=-1
kerning first=377 second=245 amount=-1
kerning first=289 second=259 amount=-1
kerning first=376 second=103 amount=-5
kerning first=344 second=245 amount=-1
kerning first=223 second=375 amount=-2
kerning first=75 second=269 amount=-1
kerning first=200 second=335 amount=-1
kerning first=47 second=265 amount=-1
kerning first=376 second=260 amount=-5
kerning first=257 second=375 amount=-1
kerning first=348 second=373 amount=-1
kerning first=366 second=197 amount=-2
kerning first=292 second=8216 amount=-1
kerning first=196 second=8216 amount=-6
kerning first=111 second=378 amount=-1
kerning first=340 second=356 amount=-1
kerning first=332 second=8216 amount=-1
kerning first=210 second=197 amount=-1
kerning first=280 second=253 amount=-2
kerning first=75 second=373 amount=-3
kerning first=226 second=8216 amount=-1
kerning first=354 second=67 amount=-1
kerning first=197 second=355 amount=-2
kerning first=88 second=117 amount=-1
kerning first=232 second=102 amount=-1
kerning first=378 second=335 amount=-2
kerning first=280 second=234 amount=-1
kerning first=277 second=122 amount=-1
kerning first=347 second=122 amount=-1
kerning first=963 second=955 amount=-1
kerning first=67 second=213 amount=-1
kerning first=114 second=246 amount=-1
kerning first=120 second=267 amount=-2
kerning first=1086 second=1078 amount=-1
kerning first=84 second=299 amount=3
kerning first=82 second=213 amount=-1
kerning first=203 second=226 amount=-1
kerning first=376 second=213 amount=-2
kerning first=194 second=8217 amount=-3
kerning first=1106 second=1095 amount=-1
kerning first=315 second=355 amount=-1
kerning first=86 second=8217 amount=2
kerning first=278 second=246 amount=-1
kerning first=121 second=99 amount=-1
kerning first=310 second=121 amount=-3
kerning first=356 second=252 amount=-4
kerning first=87 second=228 amount=-2
kerning first=258 second=284 amount=-1
kerning first=47 second=351 amount=-1
kerning first=352 second=121 amount=-1
kerning first=8217 second=231 amount=-5
kerning first=372 second=249 amount=-2
kerning first=340 second=79 amount=-1
kerning first=282 second=332 amount=-1
kerning first=90 second=79 amount=-1
kerning first=114 second=277 amount=-1
kerning first=1043 second=1116 amount=-5
kerning first=262 second=79 amount=-1
kerning first=86 second=336 amount=-1
kerning first=347 second=8220 amount=-1
kerning first=342 second=348 amount=-1
kerning first=80 second=113 amount=-1
kerning first=194 second=336 amount=-1
kerning first=341 second=234 amount=-1
kerning first=255 second=333 amount=-1
kerning first=313 second=218 amount=-2
kerning first=263 second=111 amount=-1
kerning first=69 second=227 amount=-1
kerning first=8220 second=214 amount=-1
kerning first=280 second=242 amount=-1
kerning first=1050 second=1073 amount=-1
kerning first=202 second=71 amount=-1
kerning first=68 second=195 amount=-1
kerning first=343 second=287 amount=-1
kerning first=1060 second=1071 amount=-1
kerning first=274 second=279 amount=-1
kerning first=87 second=371 amount=-2
kerning first=342 second=111 amount=-1
kerning first=8218 second=217 amount=-1
kerning first=381 second=111 amount=-1
kerning first=374 second=118 amount=-2
kerning first=1042 second=1063 amount=-1
kerning first=8221 second=225 amount=-5
kerning first=274 second=214 amount=-1
kerning first=376 second=248 amount=-5
kerning first=84 second=101 amount=-6
kerning first=82 second=333 amount=-1
kerning first=212 second=86 amount=-1
kerning first=282 second=226 amount=-1
kerning first=193 second=86 amount=-3
kerning first=332 second=197 amount=-1
kerning first=116 second=355 amount=-1
kerning first=202 second=231 amount=-1
kerning first=274 second=333 amount=-1
kerning first=271 second=318 amount=1
kerning first=276 second=266 amount=-1
kerning first=1065 second=1038 amount=-1
kerning first=66 second=118 amount=-1
kerning first=47 second=195 amount=-2
kerning first=312 second=257 amount=-1
kerning first=1045 second=1073 amount=-1
kerning first=377 second=337 amount=-1
kerning first=344 second=337 amount=-1
kerning first=8221 second=256 amount=-9
kerning first=1054 second=1051 amount=-2
kerning first=221 second=45 amount=-4
kerning first=350 second=256 amount=-1
kerning first=1076 second=1077 amount=-1
kerning first=352 second=260 amount=-1
kerning first=377 second=212 amount=-1
kerning first=349 second=118 amount=-1
kerning first=344 second=212 amount=-1
kerning first=1027 second=1077 amount=-7
kerning first=373 second=231 amount=-1
kerning first=264 second=332 amount=-1
kerning first=264 second=212 amount=-1
kerning first=1043 second=46 amount=-5
kerning first=193 second=118 amount=-1
kerning first=83 second=195 amount=-1
kerning first=374 second=101 amount=-5
kerning first=334 second=192 amount=-1
kerning first=202 second=357 amount=-1
kerning first=381 second=253 amount=-1
kerning first=86 second=365 amount=-2
kerning first=81 second=354 amount=-2
kerning first=119 second=101 amount=-1
kerning first=44 second=260 amount=1
kerning first=308 second=65 amount=-1
kerning first=346 second=65 amount=-1
kerning first=910 second=902 amount=-5
kerning first=355 second=45 amount=-2
kerning first=374 second=380 amount=-4
kerning first=118 second=45 amount=-2
kerning first=908 second=939 amount=-2
kerning first=282 second=348 amount=-1
kerning first=87 second=79 amount=-1
kerning first=221 second=285 amount=-5
kerning first=282 second=269 amount=-1
kerning first=376 second=345 amount=-3
kerning first=342 second=253 amount=-1
kerning first=8222 second=1118 amount=-3
kerning first=267 second=243 amount=-1
kerning first=46 second=287 amount=1
kerning first=68 second=379 amount=-1
kerning first=375 second=234 amount=-1
kerning first=65 second=286 amount=-1
kerning first=269 second=242 amount=-1
kerning first=118 second=285 amount=-1
kerning first=313 second=374 amount=-6
kerning first=276 second=67 amount=-1
kerning first=1058 second=1091 amount=-4
kerning first=46 second=286 amount=-1
kerning first=1075 second=229 amount=-2
kerning first=8218 second=1058 amount=-6
kerning first=373 second=337 amount=-1
kerning first=1038 second=58 amount=-2
kerning first=76 second=338 amount=-2
kerning first=263 second=97 amount=-1
kerning first=376 second=297 amount=3
kerning first=379 second=199 amount=-1
kerning first=8220 second=286 amount=-1
kerning first=107 second=335 amount=-2
kerning first=289 second=232 amount=-1
kerning first=336 second=87 amount=-1
kerning first=341 second=265 amount=-1
kerning first=114 second=347 amount=-1
kerning first=1059 second=1089 amount=-4
kerning first=258 second=87 amount=-3
kerning first=954 second=969 amount=-2
kerning first=376 second=110 amount=-3
kerning first=8221 second=67 amount=-2
kerning first=198 second=232 amount=-1
kerning first=334 second=308 amount=-1
kerning first=381 second=264 amount=-1
kerning first=80 second=232 amount=-1
kerning first=193 second=8221 amount=-3
kerning first=1044 second=1073 amount=-1
kerning first=107 second=259 amount=-1
kerning first=197 second=366 amount=-1
kerning first=8221 second=194 amount=-9
kerning first=223 second=102 amount=-1
kerning first=256 second=308 amount=1
kerning first=955 second=966 amount=-1
kerning first=280 second=375 amount=-2
kerning first=211 second=198 amount=-1
kerning first=954 second=945 amount=-2
kerning first=75 second=243 amount=-1
kerning first=272 second=374 amount=-1
kerning first=76 second=374 amount=-6
kerning first=1045 second=1092 amount=-2
kerning first=368 second=198 amount=-1
kerning first=208 second=88 amount=-1
kerning first=929 second=8221 amount=2
kerning first=370 second=258 amount=-2
kerning first=343 second=339 amount=-1
kerning first=8220 second=101 amount=-3
kerning first=8216 second=84 amount=3
kerning first=1068 second=1059 amount=-3
kerning first=212 second=258 amount=-1
kerning first=265 second=244 amount=-1
kerning first=88 second=275 amount=-1
kerning first=230 second=380 amount=-1
kerning first=939 second=44 amount=-9
kerning first=376 second=100 amount=-5
kerning first=192 second=74 amount=1
kerning first=120 second=244 amount=-2
kerning first=8221 second=269 amount=-5
kerning first=119 second=380 amount=-1
kerning first=285 second=275 amount=-1
kerning first=212 second=194 amount=-1
kerning first=374 second=291 amount=-5
kerning first=1024 second=1108 amount=-2
kerning first=370 second=194 amount=-2
kerning first=81 second=291 amount=2
kerning first=75 second=234 amount=-1
kerning first=214 second=381 amount=-1
kerning first=932 second=8220 amount=1
kerning first=379 second=211 amount=-1
kerning first=1102 second=1083 amount=-1
kerning first=253 second=226 amount=-1
kerning first=347 second=102 amount=-1
kerning first=910 second=920 amount=-2
kerning first=289 second=116 amount=-1
kerning first=278 second=83 amount=-1
kerning first=98 second=382 amount=-1
kerning first=266 second=211 amount=-1
kerning first=336 second=44 amount=-2
kerning first=324 second=116 amount=-1
kerning first=280 second=265 amount=-1
kerning first=272 second=256 amount=-1
kerning first=8220 second=349 amount=-2
kerning first=351 second=8220 amount=-1
kerning first=221 second=238 amount=2
kerning first=1025 second=1141 amount=-1
kerning first=198 second=116 amount=-1
kerning first=379 second=244 amount=-1
kerning first=225 second=8220 amount=-1
kerning first=8221 second=268 amount=-2
kerning first=68 second=376 amount=-1
kerning first=1100 second=1141 amount=-2
kerning first=348 second=376 amount=-1
kerning first=1038 second=1082 amount=-2
kerning first=1058 second=46 amount=-5
kerning first=89 second=347 amount=-4
kerning first=336 second=381 amount=-1
kerning first=356 second=288 amount=-2
kerning first=276 second=255 amount=-2
kerning first=315 second=288 amount=-2
kerning first=242 second=382 amount=-1
kerning first=198 second=262 amount=-1
kerning first=375 second=259 amount=-1
kerning first=376 second=256 amount=-5
kerning first=1065 second=8220 amount=-1
kerning first=1056 second=1139 amount=-1
kerning first=87 second=81 amount=-1
kerning first=285 second=233 amount=-1
kerning first=8216 second=195 amount=-6
kerning first=88 second=233 amount=-1
kerning first=202 second=194 amount=-1
kerning first=1168 second=8220 amount=1
kerning first=235 second=120 amount=-1
kerning first=201 second=262 amount=-1
kerning first=340 second=244 amount=-1
kerning first=90 second=244 amount=-1
kerning first=1036 second=1118 amount=-2
kerning first=197 second=360 amount=-1
kerning first=68 second=308 amount=-1
kerning first=112 second=8220 amount=-1
kerning first=258 second=288 amount=-1
kerning first=1056 second=1076 amount=-5
kerning first=121 second=46 amount=-5
kerning first=221 second=210 amount=-2
kerning first=1058 second=1038 amount=1
kerning first=1075 second=1105 amount=-1
kerning first=1061 second=1105 amount=-2
kerning first=374 second=339 amount=-5
kerning first=355 second=229 amount=-1
kerning first=344 second=354 amount=-1
kerning first=46 second=357 amount=-2
kerning first=380 second=349 amount=-1
kerning first=256 second=210 amount=-1
kerning first=354 second=195 amount=-6
kerning first=295 second=120 amount=-1
kerning first=278 second=214 amount=-1
kerning first=221 second=112 amount=-3
kerning first=1027 second=1100 amount=-5
kerning first=339 second=378 amount=-1
kerning first=198 second=338 amount=-1
kerning first=244 second=120 amount=-1
kerning first=84 second=71 amount=-2
kerning first=276 second=373 amount=-1
kerning first=1058 second=1028 amount=-2
kerning first=377 second=236 amount=1
kerning first=8221 second=275 amount=-5
kerning first=8217 second=349 amount=-4
kerning first=118 second=229 amount=-1
kerning first=79 second=44 amount=-2
kerning first=265 second=226 amount=-1
kerning first=200 second=116 amount=-1
kerning first=193 second=372 amount=-3
kerning first=120 second=226 amount=-1
kerning first=315 second=116 amount=-1
kerning first=1046 second=1104 amount=-1
kerning first=8221 second=288 amount=-3
kerning first=262 second=290 amount=-1
kerning first=354 second=328 amount=-4
kerning first=86 second=192 amount=-3
kerning first=114 second=235 amount=-1
kerning first=87 second=331 amount=-2
kerning first=121 second=265 amount=-1
kerning first=268 second=332 amount=-1
kerning first=278 second=211 amount=-1
kerning first=381 second=100 amount=-1
kerning first=90 second=290 amount=-1
kerning first=110 second=121 amount=-1
kerning first=274 second=99 amount=-1
kerning first=44 second=192 amount=1
kerning first=260 second=8221 amount=-3
kerning first=356 second=227 amount=-6
kerning first=253 second=277 amount=-1
kerning first=118 second=289 amount=-1
kerning first=351 second=122 amount=-1
kerning first=196 second=362 amount=-1
kerning first=223 second=119 amount=-1
kerning first=89 second=8221 amount=1
kerning first=218 second=74 amount=-1
kerning first=89 second=121 amount=-2
kerning first=291 second=289 amount=1
kerning first=338 second=121 amount=-2
kerning first=8216 second=334 amount=-1
kerning first=356 second=341 amount=-4
kerning first=229 second=121 amount=-1
kerning first=287 second=8221 amount=3
kerning first=8218 second=198 amount=2
kerning first=121 second=232 amount=-1
kerning first=221 second=289 amount=-5
kerning first=116 second=8221 amount=2
kerning first=338 second=233 amount=-1
kerning first=8222 second=89 amount=-8
kerning first=345 second=265 amount=-1
kerning first=120 second=225 amount=-1
kerning first=313 second=85 amount=-2
kerning first=1026 second=1063 amount=-4
kerning first=1058 second=1051 amount=-4
kerning first=8221 second=117 amount=-2
kerning first=191 second=121 amount=-2
kerning first=354 second=356 amount=1
kerning first=221 second=229 amount=-5
kerning first=226 second=375 amount=-1
kerning first=47 second=242 amount=-1
kerning first=86 second=197 amount=-3
kerning first=213 second=197 amount=-1
kerning first=282 second=335 amount=-1
kerning first=1038 second=1079 amount=-2
kerning first=196 second=375 amount=-1
kerning first=87 second=252 amount=-2
kerning first=67 second=336 amount=-1
kerning first=8216 second=356 amount=3
kerning first=1062 second=1108 amount=-1
kerning first=346 second=253 amount=-1
kerning first=257 second=8216 amount=-1
kerning first=192 second=45 amount=-1
kerning first=1097 second=1091 amount=-1
kerning first=376 second=355 amount=-2
kerning first=223 second=8216 amount=-2
kerning first=121 second=333 amount=-1
kerning first=382 second=234 amount=-2
kerning first=75 second=115 amount=-1
kerning first=194 second=213 amount=-1
kerning first=308 second=46 amount=-1
kerning first=86 second=213 amount=-1
kerning first=269 second=333 amount=-1
kerning first=1043 second=1095 amount=-4
kerning first=340 second=352 amount=-1
kerning first=1113 second=8216 amount=-4
kerning first=379 second=284 amount=-1
kerning first=345 second=100 amount=-1
kerning first=368 second=193 amount=-2
kerning first=1065 second=1059 amount=-1
kerning first=8220 second=99 amount=-3
kerning first=266 second=284 amount=-1
kerning first=211 second=193 amount=-1
kerning first=260 second=121 amount=-1
kerning first=71 second=89 amount=-1
kerning first=362 second=198 amount=-1
kerning first=82 second=248 amount=-1
kerning first=311 second=45 amount=-3
kerning first=1059 second=59 amount=-2
kerning first=70 second=198 amount=-3
kerning first=79 second=89 amount=-2
kerning first=66 second=194 amount=-1
kerning first=1138 second=1040 amount=-2
kerning first=376 second=336 amount=-2
kerning first=82 second=336 amount=-1
kerning first=274 second=65 amount=-1
kerning first=8216 second=228 amount=-2
kerning first=356 second=336 amount=-2
kerning first=315 second=336 amount=-2
kerning first=8221 second=111 amount=-5
kerning first=200 second=336 amount=-1
kerning first=255 second=285 amount=-1
kerning first=234 second=8216 amount=-1
kerning first=194 second=355 amount=-2
kerning first=341 second=8216 amount=1
kerning first=122 second=287 amount=-1
kerning first=8217 second=214 amount=-3
kerning first=8221 second=97 amount=-5
kerning first=202 second=227 amount=-1
kerning first=75 second=67 amount=-3
kerning first=382 second=228 amount=-1
kerning first=8222 second=220 amount=-1
kerning first=1038 second=1032 amount=-3
kerning first=286 second=376 amount=-1
kerning first=1100 second=8216 amount=-4
kerning first=75 second=81 amount=-3
kerning first=84 second=46 amount=-7
kerning first=193 second=84 amount=-6
kerning first=212 second=84 amount=-2
kerning first=8217 second=118 amount=-1
kerning first=199 second=71 amount=-1
kerning first=203 second=224 amount=-1
kerning first=256 second=266 amount=-1
kerning first=332 second=196 amount=-1
kerning first=372 second=199 amount=-1
kerning first=202 second=337 amount=-1
kerning first=201 second=334 amount=-1
kerning first=87 second=287 amount=-2
kerning first=282 second=197 amount=-1
kerning first=354 second=334 amount=-2
kerning first=312 second=101 amount=-2
kerning first=85 second=197 amount=-2
kerning first=202 second=214 amount=-1
kerning first=67 second=8217 amount=3
kerning first=255 second=289 amount=-1
kerning first=343 second=8221 amount=3
kerning first=192 second=218 amount=-1
kerning first=376 second=8217 amount=1
kerning first=308 second=192 amount=-1
kerning first=89 second=260 amount=-5
kerning first=338 second=260 amount=-1
kerning first=380 second=337 amount=-2
kerning first=8220 second=65 amount=-6
kerning first=227 second=253 amount=-1
kerning first=379 second=239 amount=2
kerning first=81 second=221 amount=-2
kerning first=372 second=268 amount=-1
kerning first=84 second=287 amount=-5
kerning first=1168 second=1092 amount=-7
kerning first=202 second=118 amount=-2
kerning first=258 second=268 amount=-1
kerning first=282 second=225 amount=-1
kerning first=82 second=277 amount=-1
kerning first=256 second=253 amount=-1
kerning first=276 second=192 amount=-1
kerning first=344 second=86 amount=-1
kerning first=8217 second=74 amount=-5
kerning first=1065 second=1086 amount=-1
kerning first=1168 second=1086 amount=-8
kerning first=380 second=269 amount=-2
kerning first=199 second=286 amount=-1
kerning first=1025 second=1083 amount=-1
kerning first=345 second=225 amount=-1
kerning first=376 second=97 amount=-5
kerning first=282 second=79 amount=-1
kerning first=373 second=275 amount=-1
kerning first=381 second=281 amount=-1
kerning first=1070 second=1026 amount=-2
kerning first=342 second=281 amount=-1
kerning first=1047 second=1026 amount=-1
kerning first=259 second=120 amount=-1
kerning first=1101 second=1078 amount=-1
kerning first=372 second=79 amount=-1
kerning first=69 second=357 amount=-1
kerning first=1050 second=1092 amount=-1
kerning first=258 second=79 amount=-1
kerning first=200 second=348 amount=-1
kerning first=242 second=378 amount=-1
kerning first=951 second=964 amount=-1
kerning first=342 second=83 amount=-1
kerning first=221 second=367 amount=-3
kerning first=8221 second=902 amount=-9
kerning first=102 second=279 amount=-2
kerning first=372 second=251 amount=-2
kerning first=221 second=297 amount=3
kerning first=8217 second=337 amount=-5
kerning first=1046 second=1054 amount=-2
kerning first=1059 second=1028 amount=-2
kerning first=198 second=259 amount=-1
kerning first=348 second=255 amount=-1
kerning first=201 second=267 amount=-1
kerning first=374 second=258 amount=-5
kerning first=66 second=258 amount=-1
kerning first=8217 second=194 amount=-9
kerning first=121 second=115 amount=-1
kerning first=75 second=255 amount=-3
kerning first=377 second=332 amount=-1
kerning first=80 second=90 amount=-1
kerning first=116 second=279 amount=-1
kerning first=295 second=255 amount=-1
kerning first=310 second=347 amount=-1
kerning first=201 second=102 amount=-2
kerning first=312 second=279 amount=-2
kerning first=282 second=264 amount=-1
kerning first=939 second=920 amount=-2
kerning first=84 second=380 amount=-5
kerning first=107 second=234 amount=-2
kerning first=382 second=267 amount=-2
kerning first=88 second=243 amount=-1
kerning first=86 second=297 amount=5
kerning first=943 second=947 amount=-1
kerning first=253 second=347 amount=-1
kerning first=69 second=339 amount=-1
kerning first=76 second=217 amount=-2
kerning first=102 second=122 amount=-1
kerning first=84 second=339 amount=-6
kerning first=8220 second=972 amount=-2
kerning first=75 second=275 amount=-1
kerning first=221 second=371 amount=-3
kerning first=203 second=246 amount=-1
kerning first=43 second=43 amount=1
kerning first=70 second=88 amount=-1
kerning first=354 second=267 amount=-6
kerning first=1027 second=1044 amount=-5
kerning first=376 second=371 amount=-3
kerning first=107 second=245 amount=-2
kerning first=232 second=120 amount=-1
kerning first=1038 second=1081 amount=-2
kerning first=84 second=258 amount=-6
kerning first=201 second=336 amount=-1
kerning first=1054 second=1026 amount=-2
kerning first=375 second=351 amount=-1
kerning first=69 second=258 amount=-1
kerning first=278 second=333 amount=-1
kerning first=312 second=339 amount=-2
kerning first=69 second=290 amount=-1
kerning first=1046 second=1089 amount=-2
kerning first=280 second=286 amount=-1
kerning first=69 second=102 amount=-2
kerning first=274 second=265 amount=-1
kerning first=256 second=376 amount=-5
kerning first=8217 second=291 amount=-5
kerning first=1029 second=1059 amount=-1
kerning first=372 second=226 amount=-2
kerning first=1138 second=1069 amount=-1
kerning first=1068 second=1061 amount=-1
kerning first=201 second=193 amount=-1
kerning first=1058 second=1113 amount=-7
kerning first=255 second=243 amount=-1
kerning first=280 second=259 amount=-1
kerning first=221 second=106 amount=-2
kerning first=208 second=198 amount=-1
kerning first=1090 second=1113 amount=-3
kerning first=1091 second=44 amount=-2
kerning first=380 second=291 amount=-1
kerning first=233 second=122 amount=-1
kerning first=310 second=369 amount=-1
kerning first=313 second=198 amount=2
kerning first=326 second=116 amount=-1
kerning first=1027 second=1075 amount=-5
kerning first=256 second=332 amount=-1
kerning first=253 second=44 amount=-5
kerning first=342 second=264 amount=-1
kerning first=112 second=382 amount=-1
kerning first=203 second=83 amount=-1
kerning first=349 second=122 amount=-1
kerning first=289 second=233 amount=-1
kerning first=929 second=918 amount=-1
kerning first=346 second=376 amount=-1
kerning first=376 second=116 amount=-2
kerning first=1059 second=1101 amount=-2
kerning first=356 second=256 amount=-6
kerning first=270 second=44 amount=-2
kerning first=90 second=238 amount=1
kerning first=1168 second=1040 amount=-5
kerning first=200 second=256 amount=-1
kerning first=1024 second=1026 amount=-1
kerning first=372 second=235 amount=-3
kerning first=104 second=8220 amount=-1
kerning first=378 second=226 amount=-1
kerning first=910 second=916 amount=-5
kerning first=89 second=380 amount=-4
kerning first=964 second=964 amount=2
kerning first=87 second=244 amount=-3
kerning first=380 second=111 amount=-2
kerning first=376 second=288 amount=-2
kerning first=82 second=288 amount=-1
kerning first=196 second=366 amount=-1
kerning first=44 second=910 amount=-5
kerning first=83 second=8220 amount=-1
kerning first=86 second=305 amount=-2
kerning first=343 second=347 amount=-1
kerning first=356 second=324 amount=-4
kerning first=278 second=277 amount=-1
kerning first=201 second=45 amount=-1
kerning first=200 second=213 amount=-1
kerning first=381 second=210 amount=-1
kerning first=381 second=283 amount=-1
kerning first=342 second=210 amount=-1
kerning first=8216 second=234 amount=-3
kerning first=70 second=214 amount=-1
kerning first=203 second=268 amount=-1
kerning first=263 second=225 amount=-1
kerning first=8221 second=363 amount=-2
kerning first=268 second=210 amount=-1
kerning first=1058 second=8216 amount=1
kerning first=353 second=378 amount=-1
kerning first=280 second=338 amount=-1
kerning first=311 second=229 amount=-1
kerning first=192 second=334 amount=-1
kerning first=276 second=81 amount=-1
kerning first=1168 second=1083 amount=-7
kerning first=1090 second=8216 amount=1
kerning first=114 second=230 amount=-1
kerning first=196 second=71 amount=-1
kerning first=282 second=350 amount=-1
kerning first=1038 second=45 amount=-2
kerning first=65 second=338 amount=-1
kerning first=274 second=245 amount=-1
kerning first=66 second=372 amount=-1
kerning first=8221 second=115 amount=-4
kerning first=47 second=333 amount=-1
kerning first=231 second=224 amount=-1
kerning first=1169 second=228 amount=-2
kerning first=354 second=45 amount=-6
kerning first=1056 second=1040 amount=-4
kerning first=278 second=230 amount=-1
kerning first=118 second=232 amount=-1
kerning first=258 second=219 amount=-1
kerning first=116 second=277 amount=-1
kerning first=341 second=259 amount=-1
kerning first=8220 second=265 amount=-3
kerning first=287 second=277 amount=-1
kerning first=89 second=338 amount=-2
kerning first=1169 second=45 amount=-2
kerning first=201 second=228 amount=-1
kerning first=354 second=228 amount=-6
kerning first=8217 second=363 amount=-2
kerning first=354 second=193 amount=-6
kerning first=71 second=121 amount=-1
kerning first=66 second=375 amount=-1
kerning first=332 second=74 amount=-1
kerning first=1069 second=1047 amount=-1
kerning first=103 second=232 amount=-1
kerning first=280 second=119 amount=-1
kerning first=8222 second=121 amount=-5
kerning first=224 second=355 amount=-1
kerning first=338 second=79 amount=-1
kerning first=196 second=74 amount=1
kerning first=195 second=87 amount=-3
kerning first=1076 second=1076 amount=2
kerning first=1024 second=1118 amount=-1
kerning first=311 second=243 amount=-2
kerning first=88 second=67 amount=-2
kerning first=376 second=197 amount=-5
kerning first=196 second=288 amount=-1
kerning first=1062 second=1138 amount=-1
kerning first=274 second=375 amount=-2
kerning first=345 second=269 amount=-1
kerning first=87 second=239 amount=2
kerning first=1027 second=1076 amount=-8
kerning first=8216 second=193 amount=-6
kerning first=211 second=356 amount=-2
kerning first=86 second=335 amount=-3
kerning first=346 second=8216 amount=-1
kerning first=1045 second=1033 amount=-1
kerning first=192 second=356 amount=-6
kerning first=353 second=355 amount=-1
kerning first=346 second=374 amount=-1
kerning first=378 second=8217 amount=1
kerning first=46 second=372 amount=-5
kerning first=272 second=90 amount=-1
kerning first=1059 second=1088 amount=-2
kerning first=75 second=111 amount=-1
kerning first=218 second=196 amount=-2
kerning first=89 second=299 amount=3
kerning first=84 second=103 amount=-5
kerning first=1041 second=1040 amount=-1
kerning first=1062 second=1026 amount=-1
kerning first=342 second=248 amount=-1
kerning first=356 second=8217 amount=2
kerning first=381 second=248 amount=-1
kerning first=380 second=279 amount=-2
kerning first=310 second=241 amount=-1
kerning first=84 second=8221 amount=2
kerning first=311 second=228 amount=-1
kerning first=297 second=8217 amount=4
kerning first=381 second=213 amount=-1
kerning first=268 second=213 amount=-1
kerning first=289 second=234 amount=-1
kerning first=338 second=192 amount=-1
kerning first=265 second=246 amount=-1
kerning first=203 second=284 amount=-1
kerning first=122 second=8221 amount=1
kerning first=8220 second=196 amount=-6
kerning first=89 second=277 amount=-5
kerning first=8220 second=351 amount=-2
kerning first=341 second=113 amount=-1
kerning first=198 second=234 amount=-1
kerning first=8217 second=279 amount=-5
kerning first=197 second=8216 amount=-6
kerning first=98 second=122 amount=-1
kerning first=1043 second=1085 amount=-5
kerning first=380 second=333 amount=-2
kerning first=119 second=287 amount=-1
kerning first=265 second=224 amount=-1
kerning first=280 second=113 amount=-1
kerning first=1062 second=1054 amount=-1
kerning first=66 second=84 amount=-2
kerning first=332 second=65 amount=-1
kerning first=374 second=287 amount=-5
kerning first=121 second=242 amount=-1
kerning first=65 second=336 amount=-1
kerning first=198 second=268 amount=-1
kerning first=8220 second=71 amount=-1
kerning first=260 second=220 amount=-1
kerning first=86 second=252 amount=-2
kerning first=218 second=65 amount=-2
kerning first=66 second=356 amount=-2
kerning first=1114 second=1141 amount=-2
kerning first=8217 second=101 amount=-5
kerning first=312 second=227 amount=-1
kerning first=202 second=279 amount=-1
kerning first=1034 second=1063 amount=-4
kerning first=315 second=8217 amount=-5
kerning first=344 second=214 amount=-1
kerning first=377 second=214 amount=-1
kerning first=1168 second=1075 amount=-5
kerning first=345 second=287 amount=-1
kerning first=264 second=214 amount=-1
kerning first=203 second=352 amount=-1
kerning first=8220 second=231 amount=-3
kerning first=1061 second=1057 amount=-2
kerning first=267 second=111 amount=-1
kerning first=8222 second=268 amount=-1
kerning first=381 second=266 amount=-1
kerning first=210 second=256 amount=-1
kerning first=1116 second=1077 amount=-1
kerning first=332 second=86 amount=-1
kerning first=8217 second=256 amount=-9
kerning first=274 second=231 amount=-1
kerning first=331 second=118 amount=-1
kerning first=380 second=101 amount=-2
kerning first=223 second=253 amount=-2
kerning first=1050 second=1077 amount=-1
kerning first=99 second=257 amount=-1
kerning first=278 second=199 amount=-1
kerning first=1078 second=1092 amount=-1
kerning first=259 second=255 amount=-1
kerning first=281 second=378 amount=-1
kerning first=344 second=118 amount=-1
kerning first=377 second=118 amount=-2
kerning first=84 second=268 amount=-1
kerning first=923 second=932 amount=-6
kerning first=1034 second=1058 amount=-5
kerning first=366 second=256 amount=-2
kerning first=193 second=221 amount=-5
kerning first=1038 second=1111 amount=3
kerning first=8220 second=236 amount=3
kerning first=256 second=364 amount=-1
kerning first=276 second=263 amount=-1
kerning first=8221 second=932 amount=1
kerning first=375 second=122 amount=-1
kerning first=910 second=46 amount=-8
kerning first=212 second=221 amount=-2
kerning first=344 second=277 amount=-1
kerning first=288 second=221 amount=-1
kerning first=241 second=357 amount=-1
kerning first=338 second=290 amount=-1
kerning first=282 second=100 amount=-1
kerning first=1032 second=46 amount=-1
kerning first=235 second=380 amount=-1
kerning first=196 second=86 amount=-3
kerning first=87 second=195 amount=-3
kerning first=348 second=192 amount=-1
kerning first=255 second=233 amount=-1
kerning first=1024 second=1060 amount=-2
kerning first=68 second=192 amount=-1
kerning first=8218 second=376 amount=-8
kerning first=86 second=97 amount=-4
kerning first=266 second=79 amount=-1
kerning first=99 second=339 amount=-1
kerning first=76 second=360 amount=-2
kerning first=345 second=243 amount=-1
kerning first=955 second=971 amount=-1
kerning first=1100 second=1091 amount=-2
kerning first=345 second=285 amount=-1
kerning first=120 second=100 amount=-2
kerning first=70 second=243 amount=-1
kerning first=1025 second=1091 amount=-1
kerning first=1040 second=1026 amount=-4
kerning first=82 second=348 amount=-1
kerning first=334 second=379 amount=-1
kerning first=66 second=357 amount=-1
kerning first=197 second=286 amount=-1
kerning first=379 second=79 amount=-1
kerning first=86 second=264 amount=-1
kerning first=278 second=226 amount=-1
kerning first=194 second=264 amount=-1
kerning first=313 second=8217 amount=-5
kerning first=193 second=212 amount=-1
kerning first=200 second=199 amount=-1
kerning first=1045 second=1028 amount=-2
kerning first=267 second=228 amount=-1
kerning first=373 second=257 amount=-1
kerning first=114 second=226 amount=-1
kerning first=8217 second=354 amount=1
kerning first=76 second=332 amount=-2
kerning first=260 second=357 amount=-2
kerning first=44 second=354 amount=-5
kerning first=86 second=301 amount=2
kerning first=89 second=357 amount=-2
kerning first=192 second=199 amount=-1
kerning first=287 second=357 amount=-1
kerning first=910 second=8221 amount=1
kerning first=110 second=357 amount=-1
kerning first=116 second=357 amount=-1
kerning first=271 second=311 amount=1
kerning first=101 second=380 amount=-1
kerning first=332 second=90 amount=-1
kerning first=8216 second=246 amount=-2
kerning first=381 second=275 amount=-1
kerning first=313 second=264 amount=-1
kerning first=8217 second=65 amount=-9
kerning first=274 second=234 amount=-1
kerning first=1059 second=1084 amount=-3
kerning first=66 second=260 amount=-1
kerning first=287 second=257 amount=-1
kerning first=116 second=257 amount=-1
kerning first=254 second=380 amount=-1
kerning first=345 second=263 amount=-1
kerning first=197 second=212 amount=-1
kerning first=374 second=260 amount=-5
kerning first=382 second=246 amount=-2
kerning first=338 second=257 amount=-1
kerning first=227 second=120 amount=-1
kerning first=315 second=366 amount=-2
kerning first=197 second=217 amount=-1
kerning first=1065 second=1026 amount=-1
kerning first=89 second=249 amount=-3
kerning first=86 second=243 amount=-3
kerning first=1043 second=1057 amount=-2
kerning first=354 second=246 amount=-6
kerning first=356 second=290 amount=-2
kerning first=364 second=258 amount=-2
kerning first=1059 second=1102 amount=-2
kerning first=201 second=246 amount=-1
kerning first=310 second=267 amount=-1
kerning first=80 second=248 amount=-1
kerning first=84 second=333 amount=-6
kerning first=343 second=99 amount=-1
kerning first=280 second=243 amount=-1
kerning first=338 second=242 amount=-1
kerning first=380 second=339 amount=-2
kerning first=69 second=333 amount=-1
kerning first=960 second=940 amount=-1
kerning first=376 second=113 amount=-5
kerning first=372 second=291 amount=-2
kerning first=122 second=333 amount=-2
kerning first=8222 second=258 amount=1
kerning first=196 second=84 amount=-6
kerning first=310 second=79 amount=-3
kerning first=332 second=194 amount=-1
kerning first=332 second=84 amount=-2
kerning first=955 second=974 amount=-1
kerning first=75 second=348 amount=-1
kerning first=90 second=45 amount=-2
kerning first=253 second=267 amount=-1
kerning first=8217 second=339 amount=-5
kerning first=8216 second=267 amount=-3
kerning first=8220 second=234 amount=-3
kerning first=224 second=116 amount=-1
kerning first=379 second=238 amount=1
kerning first=8221 second=908 amount=-2
kerning first=1101 second=8220 amount=-1
kerning first=1139 second=1113 amount=-1
kerning first=1025 second=1066 amount=-1
kerning first=1088 second=1093 amount=-1
kerning first=192 second=284 amount=-1
kerning first=964 second=972 amount=-1
kerning first=260 second=44 amount=1
kerning first=326 second=8220 amount=-1
kerning first=194 second=116 amount=-2
kerning first=287 second=100 amount=-1
kerning first=1069 second=46 amount=-1
kerning first=1040 second=1059 amount=-3
kerning first=344 second=268 amount=-1
kerning first=344 second=262 amount=-1
kerning first=270 second=381 amount=-1
kerning first=345 second=8220 amount=1
kerning first=85 second=256 amount=-2
kerning first=202 second=339 amount=-1
kerning first=283 second=8220 amount=-1
kerning first=282 second=256 amount=-1
kerning first=116 second=244 amount=-1
kerning first=1069 second=1038 amount=-1
kerning first=220 second=46 amount=-1
kerning first=356 second=266 amount=-1
kerning first=70 second=67 amount=-1
kerning first=341 second=46 amount=-7
kerning first=8221 second=328 amount=-2
kerning first=315 second=308 amount=1
kerning first=356 second=308 amount=-2
kerning first=203 second=290 amount=-1
kerning first=1045 second=1138 amount=-2
kerning first=8217 second=369 amount=-2
kerning first=354 second=229 amount=-6
kerning first=201 second=373 amount=-1
kerning first=121 second=245 amount=-1
kerning first=201 second=229 amount=-1
kerning first=382 second=275 amount=-2
kerning first=338 second=196 amount=-1
kerning first=311 second=283 amount=-2
kerning first=89 second=196 amount=-5
kerning first=86 second=117 amount=-2
kerning first=270 second=354 amount=-1
kerning first=343 second=353 amount=-1
kerning first=338 second=244 amount=-1
kerning first=89 second=244 amount=-5
kerning first=203 second=197 amount=-1
kerning first=8220 second=245 amount=-2
kerning first=354 second=373 amount=-3
kerning first=221 second=195 amount=-5
kerning first=375 second=100 amount=-1
kerning first=70 second=263 amount=-1
kerning first=374 second=346 amount=-1
kerning first=116 second=235 amount=-1
kerning first=8218 second=67 amount=-1
kerning first=66 second=256 amount=-1
kerning first=354 second=277 amount=-6
kerning first=342 second=275 amount=-1
kerning first=356 second=338 amount=-2
kerning first=8217 second=289 amount=-5
kerning first=119 second=224 amount=-1
kerning first=333 second=382 amount=-1
kerning first=89 second=235 amount=-5
kerning first=269 second=245 amount=-1
kerning first=338 second=235 amount=-1
kerning first=376 second=378 amount=-4
kerning first=263 second=259 amount=-1
kerning first=375 second=349 amount=-1
kerning first=196 second=8221 amount=-3
kerning first=272 second=258 amount=-1
kerning first=287 second=235 amount=-1
kerning first=1027 second=1088 amount=-5
kerning first=932 second=916 amount=-6
kerning first=286 second=373 amount=-1
kerning first=107 second=279 amount=-2
kerning first=75 second=328 amount=-1
kerning first=90 second=121 amount=-1
kerning first=109 second=355 amount=-1
kerning first=221 second=283 amount=-5
kerning first=291 second=283 amount=-1
kerning first=194 second=211 amount=-1
kerning first=66 second=89 amount=-2
kerning first=329 second=121 amount=-1
kerning first=266 second=290 amount=-1
kerning first=1059 second=1071 amount=-1
kerning first=200 second=350 amount=-1
kerning first=379 second=290 amount=-1
kerning first=355 second=283 amount=-1
kerning first=1024 second=1063 amount=-1
kerning first=382 second=277 amount=-2
kerning first=219 second=198 amount=-1
kerning first=1168 second=1085 amount=-5
kerning first=340 second=121 amount=-1
kerning first=8220 second=242 amount=-2
kerning first=1043 second=1141 amount=-5
kerning first=8216 second=277 amount=-3
kerning first=315 second=368 amount=-2
kerning first=285 second=289 amount=1
kerning first=208 second=377 amount=-1
kerning first=380 second=257 amount=-1
kerning first=289 second=103 amount=1
kerning first=290 second=119 amount=-1
kerning first=380 second=375 amount=-1
kerning first=336 second=193 amount=-1
kerning first=1169 second=1089 amount=-1
kerning first=79 second=372 amount=-1
kerning first=214 second=376 amount=-2
kerning first=211 second=87 amount=-1
kerning first=82 second=232 amount=-1
kerning first=1060 second=1038 amount=-1
kerning first=376 second=232 amount=-5
kerning first=372 second=193 amount=-3
kerning first=192 second=85 amount=-1
kerning first=349 second=102 amount=-1
kerning first=315 second=374 amount=-6
kerning first=313 second=356 amount=-5
kerning first=221 second=382 amount=-4
kerning first=75 second=353 amount=-1
kerning first=208 second=356 amount=-1
kerning first=202 second=375 amount=-2
kerning first=8222 second=372 amount=-5
kerning first=223 second=355 amount=-1
kerning first=279 second=102 amount=-1
kerning first=313 second=376 amount=-6
kerning first=291 second=228 amount=-1
kerning first=356 second=255 amount=-3
kerning first=208 second=376 amount=-1
kerning first=276 second=288 amount=-1
kerning first=118 second=228 amount=-1
kerning first=932 second=923 amount=-6
kerning first=200 second=255 amount=-2
kerning first=8222 second=1059 amount=-3
kerning first=342 second=335 amount=-1
kerning first=378 second=255 amount=-1
kerning first=283 second=380 amount=-1
kerning first=233 second=8216 amount=-1
kerning first=179 second=47 amount=-11
kerning first=965 second=955 amount=-2
kerning first=8221 second=226 amount=-5
kerning first=256 second=213 amount=-1
kerning first=374 second=363 amount=-3
kerning first=1169 second=230 amount=-2
kerning first=355 second=233 amount=-1
kerning first=274 second=242 amount=-1
kerning first=118 second=233 amount=-1
kerning first=1082 second=1077 amount=-1
kerning first=257 second=355 amount=-1
kerning first=1168 second=1091 amount=-5
kerning first=87 second=284 amount=-1
kerning first=8216 second=972 amount=-2
kerning first=107 second=99 amount=-2
kerning first=291 second=233 amount=-1
kerning first=195 second=284 amount=-1
kerning first=372 second=224 amount=-2
kerning first=8216 second=939 amount=2
kerning first=374 second=361 amount=-3
kerning first=90 second=246 amount=-1
kerning first=366 second=192 amount=-2
kerning first=210 second=192 amount=-1
kerning first=195 second=220 amount=-1
kerning first=8217 second=351 amount=-4
kerning first=88 second=211 amount=-2
kerning first=274 second=192 amount=-1
kerning first=280 second=71 amount=-1
kerning first=382 second=351 amount=-1
kerning first=255 second=111 amount=-1
kerning first=193 second=8216 amount=-6
kerning first=212 second=8216 amount=-1
kerning first=198 second=336 amount=-1
kerning first=86 second=113 amount=-3
kerning first=1168 second=1118 amount=-5
kerning first=242 second=122 amount=-1
kerning first=97 second=8216 amount=-1
kerning first=380 second=351 amount=-1
kerning first=349 second=8216 amount=-1
kerning first=344 second=84 amount=-1
kerning first=121 second=231 amount=-1
kerning first=341 second=279 amount=-1
kerning first=1046 second=1073 amount=-1
kerning first=1168 second=1103 amount=-6
kerning first=920 second=939 amount=-2
kerning first=1038 second=1119 amount=-2
kerning first=8220 second=86 amount=2
kerning first=221 second=226 amount=-5
kerning first=1082 second=1139 amount=-1
kerning first=291 second=226 amount=-1
kerning first=280 second=279 amount=-1
kerning first=1059 second=1033 amount=-5
kerning first=282 second=214 amount=-1
kerning first=179 second=8260 amount=-9
kerning first=280 second=197 amount=-1
kerning first=935 second=8217 amount=1
kerning first=374 second=214 amount=-2
kerning first=376 second=301 amount=2
kerning first=355 second=226 amount=-1
kerning first=1065 second=1118 amount=-1
kerning first=372 second=101 amount=-3
kerning first=920 second=902 amount=-1
kerning first=198 second=332 amount=-1
kerning first=119 second=337 amount=-1
kerning first=295 second=253 amount=-1
kerning first=221 second=199 amount=-2
kerning first=285 second=8217 amount=3
kerning first=356 second=331 amount=-4
kerning first=256 second=218 amount=-1
kerning first=220 second=197 amount=-2
kerning first=356 second=101 amount=-6
kerning first=8221 second=281 amount=-5
kerning first=1065 second=1104 amount=-1
kerning first=280 second=275 amount=-1
kerning first=119 second=279 amount=-1
kerning first=8216 second=902 amount=-6
kerning first=1168 second=1104 amount=-7
kerning first=69 second=260 amount=-1
kerning first=75 second=253 amount=-3
kerning first=376 second=47 amount=-4
kerning first=192 second=81 amount=-1
kerning first=8220 second=299 amount=2
kerning first=286 second=253 amount=-1
kerning first=84 second=260 amount=-6
kerning first=276 second=281 amount=-1
kerning first=217 second=260 amount=-2
kerning first=195 second=334 amount=-1
kerning first=83 second=221 amount=-1
kerning first=920 second=910 amount=-2
kerning first=1138 second=1076 amount=-2
kerning first=87 second=334 amount=-1
kerning first=312 second=230 amount=-1
kerning first=1066 second=8220 amount=-3
kerning first=198 second=97 amount=-1
kerning first=1046 second=1086 amount=-1
kerning first=374 second=337 amount=-5
kerning first=261 second=118 amount=-1
kerning first=287 second=229 amount=-1
kerning first=211 second=195 amount=-1
kerning first=378 second=115 amount=-1
kerning first=310 second=118 amount=-4
kerning first=368 second=195 amount=-2
kerning first=278 second=101 amount=-1
kerning first=195 second=221 amount=-5
kerning first=908 second=8220 amount=-1
kerning first=259 second=253 amount=-1
kerning first=8218 second=212 amount=-2
kerning first=370 second=65 amount=-2
kerning first=958 second=971 amount=-1
kerning first=109 second=253 amount=-1
kerning first=114 second=101 amount=-1
kerning first=212 second=65 amount=-1
kerning first=87 second=45 amount=-3
kerning first=45 second=120 amount=-1
kerning first=69 second=337 amount=-1
kerning first=374 second=333 amount=-5
kerning first=88 second=253 amount=-2
kerning first=75 second=364 amount=-1
kerning first=356 second=326 amount=-4
kerning first=195 second=45 amount=-1
kerning first=258 second=221 amount=-5
kerning first=84 second=337 amount=-6
kerning first=373 second=287 amount=-1
kerning first=311 second=100 amount=-2
kerning first=119 second=333 amount=-1
kerning first=258 second=8220 amount=-6
kerning first=280 second=100 amount=-1
kerning first=8222 second=357 amount=-2
kerning first=67 second=79 amount=-1
kerning first=1092 second=1113 amount=-1
kerning first=8217 second=241 amount=-2
kerning first=1043 second=1060 amount=-3
kerning first=1050 second=1098 amount=-2
kerning first=1050 second=1058 amount=-1
kerning first=278 second=79 amount=-1
kerning first=260 second=332 amount=-1
kerning first=932 second=45 amount=-6
kerning first=70 second=97 amount=-2
kerning first=88 second=348 amount=-1
kerning first=201 second=199 amount=-1
kerning first=354 second=199 amount=-1
kerning first=1059 second=1092 amount=-4
kerning first=253 second=257 amount=-1
kerning first=918 second=959 amount=-1
kerning first=310 second=257 amount=-1
kerning first=70 second=264 amount=-1
kerning first=1097 second=1139 amount=-1
kerning first=967 second=969 amount=-1
kerning first=280 second=212 amount=-1
kerning first=945 second=969 amount=-1
kerning first=313 second=67 amount=-1
kerning first=370 second=260 amount=-2
kerning first=212 second=260 amount=-1
kerning first=210 second=260 amount=-1
kerning first=932 second=58 amount=-5
kerning first=203 second=335 amount=-1
kerning first=373 second=339 amount=-1
kerning first=8218 second=264 amount=-1
kerning first=376 second=308 amount=-4
kerning first=82 second=374 amount=-1
kerning first=360 second=44 amount=-1
kerning first=114 second=291 amount=-1
kerning first=328 second=357 amount=-1
kerning first=377 second=339 amount=-1
kerning first=344 second=339 amount=-1
kerning first=202 second=102 amount=-2
kerning first=89 second=258 amount=-5
kerning first=79 second=196 amount=-1
kerning first=364 second=196 amount=-2
kerning first=8220 second=194 amount=-6
kerning first=338 second=258 amount=-1
kerning first=8221 second=238 amount=1
kerning first=352 second=193 amount=-1
kerning first=373 second=265 amount=-1
kerning first=87 second=110 amount=-2
kerning first=193 second=286 amount=-1
kerning first=270 second=193 amount=-1
kerning first=1070 second=44 amount=-1
kerning first=288 second=118 amount=-1
kerning first=218 second=194 amount=-2
kerning first=213 second=379 amount=-1
kerning first=197 second=338 amount=-1
kerning first=908 second=44 amount=-2
kerning first=343 second=44 amount=-6
kerning first=201 second=211 amount=-1
kerning first=88 second=288 amount=-2
kerning first=1076 second=1105 amount=-1
kerning first=354 second=211 amount=-2
kerning first=1058 second=1109 amount=-6
kerning first=350 second=374 amount=-1
kerning first=310 second=235 amount=-1
kerning first=340 second=83 amount=-1
kerning first=1047 second=1066 amount=-1
kerning first=364 second=44 amount=-1
kerning first=908 second=932 amount=-2
kerning first=1054 second=1059 amount=-1
kerning first=331 second=8220 amount=-1
kerning first=255 second=8220 amount=1
kerning first=90 second=118 amount=-2
kerning first=341 second=242 amount=-1
kerning first=282 second=275 amount=-1
kerning first=67 second=290 amount=-1
kerning first=1043 second=1093 amount=-4
kerning first=282 second=116 amount=-1
kerning first=313 second=8220 amount=-7
kerning first=1062 second=1035 amount=-1
kerning first=102 second=347 amount=-1
kerning first=86 second=256 amount=-3
kerning first=260 second=354 amount=-6
kerning first=47 second=245 amount=-1
kerning first=213 second=256 amount=-1
kerning first=208 second=8220 amount=-1
kerning first=343 second=244 amount=-1
kerning first=8217 second=236 amount=2
kerning first=932 second=8221 amount=2
kerning first=1075 second=1108 amount=-1
kerning first=87 second=347 amount=-3
kerning first=372 second=289 amount=-2
kerning first=8217 second=262 amount=-2
kerning first=80 second=228 amount=-2
kerning first=310 second=309 amount=1
kerning first=84 second=44 amount=-7
kerning first=1027 second=1087 amount=-5
kerning first=373 second=227 amount=-1
kerning first=328 second=8220 amount=-1
kerning first=8217 second=84 amount=1
kerning first=118 second=8220 amount=1
kerning first=261 second=8220 amount=-1
kerning first=99 second=227 amount=-1
kerning first=1088 second=1141 amount=-1
kerning first=1138 second=1033 amount=-2
kerning first=376 second=114 amount=-3
kerning first=8217 second=230 amount=-5
kerning first=86 second=210 amount=-1
kerning first=340 second=335 amount=-1
kerning first=278 second=268 amount=-1
kerning first=194 second=210 amount=-1
kerning first=82 second=214 amount=-1
kerning first=376 second=255 amount=-2
kerning first=354 second=81 amount=-2
kerning first=86 second=225 amount=-4
kerning first=82 second=255 amount=-1
kerning first=201 second=81 amount=-1
kerning first=1075 second=230 amount=-2
kerning first=340 second=334 amount=-1
kerning first=82 second=350 amount=-1
kerning first=285 second=47 amount=3
kerning first=90 second=334 amount=-1
kerning first=262 second=334 amount=-1
kerning first=75 second=267 amount=-1
kerning first=380 second=230 amount=-1
kerning first=67 second=338 amount=-1
kerning first=326 second=255 amount=-1
kerning first=376 second=338 amount=-2
kerning first=1027 second=1032 amount=-3
kerning first=75 second=281 amount=-1
kerning first=260 second=372 amount=-3
kerning first=45 second=255 amount=-1
kerning first=195 second=121 amount=-1
kerning first=193 second=89 amount=-5
kerning first=87 second=121 amount=-2
kerning first=90 second=277 amount=-1
kerning first=65 second=360 amount=-1
kerning first=66 second=8216 amount=-1
kerning first=102 second=291 amount=-2
kerning first=225 second=121 amount=-1
kerning first=192 second=211 amount=-1
kerning first=202 second=230 amount=-1
kerning first=87 second=251 amount=-2
kerning first=221 second=373 amount=-2
kerning first=1056 second=1105 amount=-1
kerning first=191 second=374 amount=-4
kerning first=372 second=290 amount=-1
kerning first=84 second=363 amount=-4
kerning first=230 second=8216 amount=-1
kerning first=258 second=290 amount=-1
kerning first=312 second=235 amount=-2
kerning first=354 second=265 amount=-6
kerning first=212 second=89 amount=-2
kerning first=288 second=89 amount=-1
kerning first=1056 second=1092 amount=-1
kerning first=193 second=219 amount=-1
kerning first=229 second=8220 amount=-1
kerning first=45 second=374 amount=-4
kerning first=341 second=245 amount=-1
kerning first=119 second=8216 amount=1
kerning first=76 second=362 amount=-2
kerning first=282 second=119 amount=-1
kerning first=263 second=335 amount=-1
kerning first=375 second=103 amount=-1
kerning first=1169 second=1083 amount=-3
kerning first=941 second=945 amount=-1
kerning first=331 second=375 amount=-1
kerning first=86 second=232 amount=-4
kerning first=280 second=245 amount=-1
kerning first=351 second=121 amount=-1
kerning first=381 second=335 amount=-1
kerning first=1139 second=8216 amount=-1
kerning first=360 second=193 amount=-2
kerning first=354 second=231 amount=-6
kerning first=47 second=197 amount=-2
kerning first=192 second=214 amount=-1
kerning first=281 second=102 amount=-1
kerning first=275 second=378 amount=-1
kerning first=278 second=193 amount=-1
kerning first=89 second=267 amount=-6
kerning first=311 second=226 amount=-1
kerning first=941 second=963 amount=-1
kerning first=338 second=267 amount=-1
kerning first=202 second=267 amount=-1
kerning first=194 second=266 amount=-1
kerning first=1097 second=59 amount=2
kerning first=310 second=268 amount=-3
kerning first=86 second=266 amount=-1
kerning first=918 second=920 amount=-1
kerning first=1058 second=1116 amount=-5
kerning first=79 second=194 amount=-1
kerning first=285 second=279 amount=-1
kerning first=954 second=949 amount=-1
kerning first=310 second=370 amount=-1
kerning first=255 second=228 amount=-1
kerning first=65 second=332 amount=-1
kerning first=916 second=8217 amount=-3
kerning first=356 second=47 amount=-4
kerning first=377 second=234 amount=-1
kerning first=112 second=122 amount=-1
kerning first=8218 second=356 amount=-7
kerning first=344 second=234 amount=-1
kerning first=974 second=955 amount=-1
kerning first=89 second=343 amount=-3
kerning first=80 second=99 amount=-2
kerning first=1050 second=1090 amount=-1
kerning first=8218 second=373 amount=-5
kerning first=116 second=267 amount=-1
kerning first=102 second=246 amount=-2
kerning first=276 second=213 amount=-1
kerning first=87 second=246 amount=-3
kerning first=262 second=284 amount=-1
kerning first=103 second=44 amount=1
kerning first=280 second=355 amount=-1
kerning first=90 second=284 amount=-1
kerning first=340 second=284 amount=-1
kerning first=47 second=231 amount=-1
kerning first=200 second=192 amount=-1
kerning first=354 second=114 amount=-4
kerning first=278 second=224 amount=-1
kerning first=356 second=192 amount=-6
kerning first=1036 second=1063 amount=-1
kerning first=373 second=351 amount=-1
kerning first=362 second=65 amount=-2
kerning first=1113 second=1118 amount=-2
kerning first=221 second=345 amount=-3
kerning first=375 second=279 amount=-1
kerning first=8217 second=287 amount=-5
kerning first=203 second=45 amount=-1
kerning first=380 second=287 amount=-1
kerning first=313 second=357 amount=-1
kerning first=197 second=71 amount=-1
kerning first=1104 second=1078 amount=-1
kerning first=122 second=335 amount=-2
kerning first=377 second=253 amount=-1
kerning first=70 second=111 amount=-1
kerning first=8216 second=81 amount=-1
kerning first=375 second=248 amount=-1
kerning first=1045 second=1139 amount=-1
kerning first=312 second=333 amount=-2
kerning first=1168 second=45 amount=-2
kerning first=8217 second=908 amount=-2
kerning first=311 second=111 amount=-2
kerning first=89 second=332 amount=-2
kerning first=374 second=211 amount=-2
kerning first=348 second=374 amount=-1
kerning first=84 second=351 amount=-5
kerning first=376 second=326 amount=-3
kerning first=951 second=967 amount=-1
kerning first=200 second=65 amount=-1
kerning first=265 second=337 amount=-1
kerning first=88 second=339 amount=-1
kerning first=8217 second=299 amount=2
kerning first=198 second=71 amount=-1
kerning first=75 second=371 amount=-1
kerning first=200 second=266 amount=-1
kerning first=310 second=224 amount=-1
kerning first=315 second=266 amount=-1
kerning first=1038 second=1044 amount=-5
kerning first=1059 second=1090 amount=-1
kerning first=89 second=214 amount=-2
kerning first=75 second=218 amount=-1
kerning first=253 second=101 amount=-1
kerning first=8222 second=79 amount=-2
kerning first=107 second=227 amount=-1
kerning first=338 second=214 amount=-1
kerning first=200 second=248 amount=-1
kerning first=1073 second=1090 amount=-1
kerning first=103 second=227 amount=-1
kerning first=229 second=118 amount=-1
kerning first=373 second=8216 amount=1
kerning first=121 second=279 amount=-1
kerning first=356 second=248 amount=-6
kerning first=341 second=231 amount=-1
kerning first=8218 second=253 amount=-5
kerning first=376 second=237 amount=-2
kerning first=260 second=118 amount=-1
kerning first=310 second=101 amount=-1
kerning first=1043 second=1051 amount=-5
kerning first=285 second=281 amount=-1
kerning first=1061 second=1111 amount=2
kerning first=255 second=353 amount=-1
kerning first=70 second=81 amount=-1
kerning first=198 second=225 amount=-1
kerning first=89 second=118 amount=-2
kerning first=256 second=8217 amount=-3
kerning first=80 second=225 amount=-2
kerning first=213 second=192 amount=-1
kerning first=197 second=86 amount=-3
kerning first=8218 second=87 amount=-5
kerning first=343 second=230 amount=-1
kerning first=198 second=197 amount=-1
kerning first=203 second=275 amount=-1
kerning first=8221 second=289 amount=-5
kerning first=69 second=196 amount=-1
kerning first=84 second=196 amount=-6
kerning first=374 second=65 amount=-5
kerning first=356 second=309 amount=2
kerning first=221 second=251 amount=-3
kerning first=278 second=118 amount=-2
kerning first=8217 second=260 amount=-9
kerning first=66 second=65 amount=-1
kerning first=953 second=957 amount=-1
kerning first=282 second=113 amount=-1
kerning first=379 second=119 amount=-1
kerning first=46 second=375 amount=-3
kerning first=1168 second=58 amount=-3
kerning first=260 second=219 amount=-1
kerning first=378 second=103 amount=-1
kerning first=237 second=8217 amount=3
kerning first=88 second=281 amount=-1
kerning first=299 second=8221 amount=2
kerning first=1094 second=1104 amount=-1
kerning first=967 second=959 amount=-1
kerning first=75 second=264 amount=-3
kerning first=1169 second=1104 amount=-1
kerning first=311 second=349 amount=-1
kerning first=1075 second=257 amount=-2
kerning first=8216 second=196 amount=-6
kerning first=192 second=357 amount=-2
kerning first=86 second=275 amount=-4
kerning first=373 second=380 amount=-1
kerning first=8222 second=354 amount=-7
kerning first=256 second=81 amount=-1
kerning first=122 second=257 amount=-1
kerning first=340 second=199 amount=-1
kerning first=355 second=263 amount=-1
kerning first=270 second=258 amount=-1
kerning first=960 second=969 amount=-1
kerning first=354 second=111 amount=-6
kerning first=118 second=263 amount=-1
kerning first=79 second=354 amount=-2
kerning first=69 second=257 amount=-1
kerning first=230 second=122 amount=-1
kerning first=84 second=257 amount=-6
kerning first=90 second=199 amount=-1
kerning first=200 second=281 amount=-1
kerning first=221 second=263 amount=-6
kerning first=339 second=120 amount=-1
kerning first=102 second=351 amount=-1
kerning first=88 second=264 amount=-2
kerning first=201 second=284 amount=-1
kerning first=87 second=367 amount=-2
kerning first=278 second=357 amount=-1
kerning first=292 second=297 amount=1
kerning first=381 second=301 amount=1
kerning first=253 second=235 amount=-1
kerning first=118 second=246 amount=-1
kerning first=374 second=343 amount=-3
kerning first=194 second=308 amount=1
kerning first=103 second=234 amount=-1
kerning first=193 second=366 amount=-1
kerning first=213 second=308 amount=-1
kerning first=116 second=259 amount=-1
kerning first=86 second=308 amount=-3
kerning first=221 second=246 amount=-5
kerning first=372 second=267 amount=-3
kerning first=1075 second=44 amount=-3
kerning first=352 second=258 amount=-1
kerning first=295 second=355 amount=-1
kerning first=1040 second=44 amount=1
kerning first=107 second=113 amount=-2
kerning first=192 second=376 amount=-5
kerning first=1168 second=1082 amount=-5
kerning first=86 second=255 amount=-1
kerning first=211 second=376 amount=-2
kerning first=344 second=356 amount=-1
kerning first=194 second=255 amount=-1
kerning first=276 second=97 amount=-1
kerning first=47 second=234 amount=-1
kerning first=1065 second=1060 amount=-1
kerning first=335 second=44 amount=-1
kerning first=342 second=243 amount=-1
kerning first=1086 second=8220 amount=-1
kerning first=381 second=243 amount=-1
kerning first=308 second=194 amount=-1
kerning first=353 second=255 amount=-1
kerning first=122 second=351 amount=-1
kerning first=44 second=258 amount=1
kerning first=201 second=233 amount=-1
kerning first=202 second=286 amount=-1
kerning first=354 second=233 amount=-6
kerning first=258 second=220 amount=-1
kerning first=219 second=308 amount=-1
kerning first=1054 second=1046 amount=-1
kerning first=356 second=361 amount=-4
kerning first=382 second=233 amount=-2
kerning first=372 second=244 amount=-3
kerning first=287 second=291 amount=1
kerning first=310 second=113 amount=-1
kerning first=1025 second=1035 amount=-1
kerning first=376 second=365 amount=-3
kerning first=47 second=916 amount=-3
kerning first=377 second=338 amount=-1
kerning first=274 second=262 amount=-1
kerning first=8221 second=112 amount=-2
kerning first=1043 second=1139 amount=-8
kerning first=315 second=360 amount=-2
kerning first=119 second=235 amount=-1
kerning first=1054 second=44 amount=-1
kerning first=262 second=211 amount=-1
kerning first=200 second=100 amount=-1
kerning first=265 second=97 amount=-1
kerning first=201 second=83 amount=-1
kerning first=340 second=211 amount=-1
kerning first=356 second=100 amount=-5
kerning first=8221 second=231 amount=-5
kerning first=90 second=211 amount=-1
kerning first=1169 second=229 amount=-2
kerning first=342 second=288 amount=-1
kerning first=380 second=118 amount=-1
kerning first=121 second=349 amount=-1
kerning first=268 second=288 amount=-1
kerning first=284 second=374 amount=-1
kerning first=1040 second=1046 amount=-1
kerning first=241 second=8216 amount=-1
kerning first=374 second=235 amount=-5
kerning first=248 second=382 amount=-1
kerning first=381 second=288 amount=-1
kerning first=1061 second=1077 amount=-2
kerning first=334 second=256 amount=-1
kerning first=374 second=44 amount=-9
kerning first=354 second=226 amount=-6
kerning first=80 second=46 amount=-9
kerning first=354 second=382 amount=-5
kerning first=8218 second=195 amount=1
kerning first=201 second=226 amount=-1
kerning first=69 second=214 amount=-1
kerning first=376 second=122 amount=-4
kerning first=202 second=265 amount=-1
kerning first=119 second=257 amount=-1
kerning first=66 second=44 amount=-1
kerning first=8217 second=361 amount=-2
kerning first=265 second=111 amount=-1
kerning first=277 second=8220 amount=-1
kerning first=374 second=257 amount=-5
kerning first=1060 second=1029 amount=-1
kerning first=211 second=8220 amount=-1
kerning first=87 second=229 amount=-2
kerning first=1139 second=1093 amount=-1
kerning first=196 second=212 amount=-1
kerning first=8218 second=338 amount=-2
kerning first=1056 second=1113 amount=-5
kerning first=342 second=332 amount=-1
kerning first=1104 second=1076 amount=-1
kerning first=380 second=265 amount=-2
kerning first=315 second=332 amount=-2
kerning first=200 second=332 amount=-1
kerning first=82 second=210 amount=-1
kerning first=86 second=259 amount=-4
kerning first=356 second=332 amount=-2
kerning first=1169 second=226 amount=-2
kerning first=311 second=263 amount=-2
kerning first=376 second=210 amount=-2
kerning first=376 second=266 amount=-2
kerning first=70 second=195 amount=-4
kerning first=202 second=346 amount=-1
kerning first=102 second=229 amount=-1
kerning first=67 second=210 amount=-1
kerning first=362 second=195 amount=-2
kerning first=8216 second=226 amount=-2
kerning first=1088 second=1113 amount=-1
kerning first=84 second=236 amount=1
kerning first=258 second=357 amount=-2
kerning first=8217 second=265 amount=-5
kerning first=86 second=338 amount=-1
kerning first=84 second=354 amount=1
kerning first=194 second=338 amount=-1
kerning first=192 second=8220 amount=-6
kerning first=203 second=193 amount=-1
kerning first=241 second=355 amount=-1
kerning first=315 second=220 amount=-2
kerning first=1168 second=1078 amount=-5
kerning first=354 second=284 amount=-2
kerning first=352 second=372 amount=-1
kerning first=345 second=283 amount=-1
kerning first=203 second=121 amount=-2
kerning first=86 second=59 amount=-4
kerning first=217 second=193 amount=-2
kerning first=84 second=226 amount=-6
kerning first=1058 second=44 amount=-6
kerning first=115 second=380 amount=-1
kerning first=102 second=277 amount=-2
kerning first=375 second=245 amount=-1
kerning first=198 second=350 amount=-1
kerning first=69 second=230 amount=-1
kerning first=278 second=290 amount=-1
kerning first=87 second=277 amount=-3
kerning first=202 second=333 amount=-1
kerning first=343 second=269 amount=-1
kerning first=1097 second=1077 amount=-1
kerning first=1027 second=1094 amount=-5
kerning first=356 second=103 amount=-5
kerning first=378 second=119 amount=-1
kerning first=345 second=353 amount=-1
kerning first=80 second=197 amount=-5
kerning first=313 second=373 amount=-3
kerning first=212 second=74 amount=-1
kerning first=377 second=299 amount=2
kerning first=86 second=269 amount=-4
kerning first=107 second=232 amount=-2
kerning first=277 second=102 amount=-1
kerning first=356 second=119 amount=-3
kerning first=315 second=119 amount=-3
kerning first=200 second=119 amount=-1
kerning first=1058 second=1095 amount=-4
kerning first=310 second=85 amount=-1
kerning first=241 second=375 amount=-1
kerning first=278 second=339 amount=-1
kerning first=345 second=228 amount=-1
kerning first=120 second=45 amount=-2
kerning first=86 second=326 amount=-2
kerning first=267 second=335 amount=-1
kerning first=374 second=375 amount=-2
kerning first=82 second=266 amount=-1
kerning first=1058 second=1032 amount=-2
kerning first=75 second=335 amount=-1
kerning first=70 second=353 amount=-1
kerning first=80 second=281 amount=-1
kerning first=1098 second=1090 amount=-3
kerning first=950 second=955 amount=1
kerning first=280 second=336 amount=-1
kerning first=195 second=355 amount=-2
kerning first=377 second=242 amount=-1
kerning first=1045 second=1090 amount=-1
kerning first=344 second=242 amount=-1
kerning first=268 second=198 amount=1
kerning first=8218 second=1090 amount=-3
kerning first=99 second=242 amount=-1
kerning first=76 second=355 amount=-1
kerning first=70 second=228 amount=-2
kerning first=287 second=44 amount=1
kerning first=75 second=213 amount=-3
kerning first=274 second=255 amount=-2
kerning first=1059 second=1096 amount=-2
kerning first=1038 second=1085 amount=-2
kerning first=114 second=267 amount=-1
kerning first=88 second=213 amount=-2
kerning first=1168 second=1032 amount=-3
kerning first=192 second=67 amount=-1
kerning first=1042 second=1038 amount=-1
kerning first=366 second=44 amount=-1
kerning first=221 second=284 amount=-2
kerning first=121 second=234 amount=-1
kerning first=381 second=8217 amount=2
kerning first=198 second=355 amount=-1
kerning first=379 second=277 amount=-1
kerning first=1084 second=1090 amount=-1
kerning first=46 second=74 amount=1
kerning first=47 second=279 amount=-1
kerning first=378 second=113 amount=-2
kerning first=278 second=267 amount=-1
kerning first=114 second=44 amount=-6
kerning first=8217 second=238 amount=1
kerning first=324 second=355 amount=-1
kerning first=289 second=355 amount=-1
kerning first=68 second=356 amount=-1
kerning first=66 second=196 amount=-1
kerning first=75 second=97 amount=-1
kerning first=264 second=8221 amount=3
kerning first=1027 second=1033 amount=-5
kerning first=89 second=79 amount=-2
kerning first=221 second=223 amount=-3
kerning first=260 second=79 amount=-1
kerning first=287 second=224 amount=-1
kerning first=374 second=196 amount=-5
kerning first=228 second=121 amount=-1
kerning first=196 second=89 amount=-5
kerning first=89 second=224 amount=-5
kerning first=356 second=113 amount=-5
kerning first=332 second=89 amount=-2
kerning first=119 second=351 amount=-1
kerning first=200 second=113 amount=-1
kerning first=341 second=99 amount=-1
kerning first=86 second=47 amount=-4
kerning first=258 second=121 amount=-1
kerning first=276 second=348 amount=-1
kerning first=75 second=252 amount=-1
kerning first=375 second=380 amount=-1
kerning first=377 second=8221 amount=2
kerning first=8217 second=99 amount=-5
kerning first=8220 second=332 amount=-1
kerning first=116 second=224 amount=-1
kerning first=310 second=249 amount=-1
kerning first=120 second=277 amount=-2
kerning first=372 second=121 amount=-2
kerning first=902 second=8221 amount=-3
kerning first=1086 second=1090 amount=-1
kerning first=374 second=351 amount=-4
kerning first=76 second=71 amount=-2
kerning first=208 second=44 amount=-2
kerning first=1043 second=1054 amount=-2
kerning first=269 second=227 amount=-1
kerning first=310 second=352 amount=-1
kerning first=373 second=8221 amount=2
kerning first=1038 second=1103 amount=-3
kerning first=1059 second=1082 amount=-2
kerning first=197 second=8221 amount=-3
kerning first=338 second=224 amount=-1
kerning first=195 second=63 amount=-2
kerning first=1038 second=1084 amount=-3
kerning first=355 second=111 amount=-1
kerning first=967 second=940 amount=-2
kerning first=118 second=111 amount=-1
kerning first=8220 second=287 amount=-3
kerning first=8220 second=84 amount=3
kerning first=65 second=266 amount=-1
kerning first=342 second=81 amount=-1
kerning first=266 second=334 amount=-1
kerning first=258 second=370 amount=-1
kerning first=107 second=248 amount=-2
kerning first=360 second=194 amount=-2
kerning first=1027 second=1071 amount=-1
kerning first=379 second=334 amount=-1
kerning first=221 second=111 amount=-5
kerning first=287 second=101 amount=-1
kerning first=312 second=242 amount=-2
kerning first=1093 second=1077 amount=-1
kerning first=116 second=101 amount=-1
kerning first=1027 second=44 amount=-6
kerning first=313 second=81 amount=-2
kerning first=84 second=230 amount=-6
kerning first=44 second=221 amount=-5
kerning first=376 second=109 amount=-3
kerning first=352 second=221 amount=-1
kerning first=372 second=257 amount=-2
kerning first=1097 second=1141 amount=-1
kerning first=270 second=221 amount=-1
kerning first=345 second=281 amount=-1
kerning first=1027 second=1084 amount=-5
kerning first=310 second=244 amount=-1
kerning first=71 second=118 amount=-1
kerning first=80 second=100 amount=-1
kerning first=313 second=253 amount=-3
kerning first=231 second=244 amount=-1
kerning first=282 second=192 amount=-1
kerning first=289 second=100 amount=-1
kerning first=89 second=101 amount=-5
kerning first=338 second=101 amount=-1
kerning first=310 second=363 amount=-1
kerning first=338 second=337 amount=-1
kerning first=46 second=221 amount=-7
kerning first=85 second=192 amount=-2
kerning first=374 second=236 amount=1
kerning first=343 second=235 amount=-1
kerning first=84 second=65 amount=-6
kerning first=345 second=232 amount=-1
kerning first=198 second=100 amount=-1
kerning first=44 second=357 amount=-2
kerning first=70 second=281 amount=-1
kerning first=116 second=337 amount=-1
kerning first=340 second=233 amount=-1
kerning first=90 second=233 amount=-1
kerning first=69 second=65 amount=-1
kerning first=1038 second=1040 amount=-5
kerning first=194 second=8220 amount=-6
kerning first=1044 second=1105 amount=-1
kerning first=310 second=357 amount=-1
kerning first=118 second=283 amount=-1
kerning first=199 second=212 amount=-1
kerning first=1097 second=1105 amount=-1
kerning first=381 second=334 amount=-1
kerning first=920 second=923 amount=-1
kerning first=8218 second=916 amount=1
kerning first=1106 second=1113 amount=-1
kerning first=343 second=257 amount=-1
kerning first=297 second=314 amount=1
kerning first=354 second=285 amount=-5
kerning first=221 second=114 amount=-3
kerning first=1046 second=1118 amount=-2
kerning first=8216 second=376 amount=2
kerning first=8216 second=923 amount=-6
kerning first=347 second=357 amount=-1
kerning first=111 second=122 amount=-1
kerning first=360 second=258 amount=-2
kerning first=282 second=357 amount=-1
kerning first=218 second=198 amount=-1
kerning first=278 second=258 amount=-1
kerning first=382 second=285 amount=-1
kerning first=195 second=199 amount=-1
kerning first=374 second=241 amount=-3
kerning first=87 second=199 amount=-1
kerning first=311 second=246 amount=-2
kerning first=8218 second=364 amount=-1
kerning first=1046 second=1092 amount=-1
kerning first=255 second=263 amount=-1
kerning first=8216 second=285 amount=-3
kerning first=276 second=267 amount=-1
kerning first=200 second=232 amount=-1
kerning first=1107 second=1113 amount=-3
kerning first=1069 second=1069 amount=-1
kerning first=356 second=232 amount=-6
kerning first=8218 second=193 amount=1
kerning first=276 second=264 amount=-1
kerning first=970 second=964 amount=-1
kerning first=312 second=265 amount=-2
kerning first=288 second=375 amount=-1
kerning first=1043 second=1113 amount=-7
kerning first=193 second=375 amount=-1
kerning first=1059 second=1078 amount=-2
kerning first=230 second=102 amount=-1
kerning first=210 second=374 amount=-2
kerning first=376 second=259 amount=-5
kerning first=1033 second=1061 amount=-1
kerning first=65 second=374 amount=-5
kerning first=274 second=339 amount=-1
kerning first=374 second=102 amount=-2
kerning first=283 second=8216 amount=-1
kerning first=67 second=332 amount=-1
kerning first=185 second=47 amount=-10
kerning first=376 second=332 amount=-2
kerning first=372 second=258 amount=-3
kerning first=82 second=332 amount=-1
kerning first=354 second=341 amount=-4
kerning first=118 second=287 amount=-1
kerning first=336 second=258 amount=-1
kerning first=264 second=286 amount=-1
kerning first=114 second=228 amount=-1
kerning first=377 second=286 amount=-1
kerning first=8221 second=251 amount=-2
kerning first=929 second=916 amount=-5
kerning first=280 second=194 amount=-1
kerning first=356 second=365 amount=-4
kerning first=278 second=244 amount=-1
kerning first=220 second=194 amount=-2
kerning first=107 second=100 amount=-2
kerning first=1059 second=1103 amount=-3
kerning first=372 second=238 amount=1
kerning first=120 second=281 amount=-2
kerning first=256 second=116 amount=-2
kerning first=375 second=46 amount=-5
kerning first=82 second=275 amount=-1
kerning first=278 second=192 amount=-1
kerning first=376 second=275 amount=-5
kerning first=8220 second=212 amount=-1
kerning first=87 second=211 amount=-1
kerning first=122 second=235 amount=-2
kerning first=87 second=114 amount=-2
kerning first=343 second=291 amount=-1
kerning first=1118 second=46 amount=-2
kerning first=114 second=244 amount=-1
kerning first=196 second=262 amount=-1
kerning first=69 second=224 amount=-1
kerning first=349 second=380 amount=-1
kerning first=313 second=364 amount=-2
kerning first=929 second=44 amount=-9
kerning first=195 second=211 amount=-1
kerning first=84 second=235 amount=-6
kerning first=1094 second=8220 amount=-1
kerning first=224 second=120 amount=-1
kerning first=1168 second=1081 amount=-5
kerning first=277 second=382 amount=-1
kerning first=75 second=121 amount=-3
kerning first=84 second=256 amount=-6
kerning first=353 second=120 amount=-1
kerning first=355 second=234 amount=-1
kerning first=1070 second=1046 amount=-1
kerning first=75 second=112 amount=-1
kerning first=1169 second=8220 amount=1
kerning first=281 second=120 amount=-1
kerning first=256 second=288 amount=-1
kerning first=370 second=44 amount=-1
kerning first=212 second=44 amount=-2
kerning first=272 second=46 amount=-2
kerning first=380 second=244 amount=-2
kerning first=227 second=116 amount=-1
kerning first=377 second=333 amount=-1
kerning first=344 second=333 amount=-1
kerning first=1090 second=46 amount=-3
kerning first=69 second=235 amount=-1
kerning first=193 second=44 amount=1
kerning first=120 second=232 amount=-2
kerning first=342 second=286 amount=-1
kerning first=373 second=333 amount=-1
kerning first=239 second=8220 amount=1
kerning first=1169 second=1086 amount=-1
kerning first=379 second=212 amount=-1
kerning first=195 second=85 amount=-1
kerning first=89 second=268 amount=-2
kerning first=8221 second=252 amount=-2
kerning first=354 second=8220 amount=1
kerning first=338 second=268 amount=-1
kerning first=350 second=197 amount=-1
kerning first=221 second=239 amount=2
kerning first=203 second=229 amount=-1
kerning first=1107 second=225 amount=-2
kerning first=1092 second=1093 amount=-1
kerning first=1059 second=1076 amount=-7
kerning first=68 second=377 amount=-1
kerning first=356 second=297 amount=5
kerning first=258 second=45 amount=-1
kerning first=276 second=275 amount=-1
kerning first=274 second=227 amount=-1
kerning first=202 second=242 amount=-1
kerning first=372 second=45 amount=-3
kerning first=1044 second=1060 amount=-1
kerning first=8220 second=227 amount=-2
kerning first=109 second=121 amount=-1
kerning first=221 second=81 amount=-2
kerning first=310 second=290 amount=-3
kerning first=315 second=210 amount=-2
kerning first=200 second=210 amount=-1
kerning first=356 second=210 amount=-2
kerning first=1076 second=1098 amount=-1
kerning first=1050 second=1108 amount=-1
kerning first=97 second=357 amount=-1
kerning first=282 second=338 amount=-1
kerning first=260 second=268 amount=-1
kerning first=200 second=225 amount=-1
kerning first=282 second=255 amount=-2
kerning first=939 second=45 amount=-5
kerning first=1027 second=1098 amount=-2
kerning first=203 second=334 amount=-1
kerning first=46 second=219 amount=-1
kerning first=80 second=245 amount=-1
kerning first=1078 second=1139 amount=-1
kerning first=1043 second=1078 amount=-5
kerning first=198 second=245 amount=-1
kerning first=379 second=338 amount=-1
kerning first=83 second=193 amount=-1
kerning first=379 second=245 amount=-1
kerning first=191 second=119 amount=-3
kerning first=201 second=67 amount=-1
kerning first=280 second=99 amount=-1
kerning first=334 second=198 amount=-1
kerning first=70 second=115 amount=-1
kerning first=99 second=337 amount=-1
kerning first=374 second=230 amount=-5
kerning first=89 second=363 amount=-3
kerning first=1050 second=1138 amount=-2
kerning first=1123 second=1118 amount=-2
kerning first=267 second=97 amount=-1
kerning first=354 second=251 amount=-4
kerning first=203 second=277 amount=-1
kerning first=1038 second=44 amount=-6
kerning first=227 second=8220 amount=-1
kerning first=958 second=945 amount=-1
kerning first=276 second=335 amount=-1
kerning first=104 second=121 amount=-1
kerning first=1086 second=1083 amount=-1
kerning first=341 second=269 amount=-1
kerning first=68 second=86 amount=-1
kerning first=246 second=378 amount=-1
kerning first=378 second=232 amount=-2
kerning first=212 second=196 amount=-1
kerning first=8222 second=288 amount=-1
kerning first=370 second=196 amount=-2
kerning first=88 second=283 amount=-1
kerning first=285 second=283 amount=-1
kerning first=1138 second=1047 amount=-1
kerning first=86 second=115 amount=-3
kerning first=376 second=119 amount=-2
kerning first=83 second=121 amount=-1
kerning first=197 second=362 amount=-1
kerning first=375 second=378 amount=-1
kerning first=349 second=375 amount=-1
kerning first=374 second=74 amount=-4
kerning first=939 second=58 amount=-5
kerning first=1043 second=8216 amount=1
kerning first=1088 second=1076 amount=-2
kerning first=343 second=289 amount=-1
kerning first=1168 second=1107 amount=-5
kerning first=8220 second=353 amount=-2
kerning first=364 second=74 amount=-1
kerning first=44 second=372 amount=-5
kerning first=118 second=103 amount=-1
kerning first=374 second=285 amount=-5
kerning first=79 second=74 amount=-1
kerning first=291 second=103 amount=1
kerning first=1168 second=1074 amount=-5
kerning first=8220 second=89 amount=2
kerning first=8218 second=197 amount=1
kerning first=65 second=85 amount=-1
kerning first=1038 second=1071 amount=-1
kerning first=69 second=193 amount=-1
kerning first=336 second=372 amount=-1
kerning first=310 second=361 amount=-1
kerning first=44 second=376 amount=-5
kerning first=258 second=372 amount=-3
kerning first=87 second=269 amount=-3
kerning first=221 second=103 amount=-5
kerning first=382 second=242 amount=-2
kerning first=253 second=229 amount=-1
kerning first=75 second=87 amount=-1
kerning first=1044 second=1108 amount=-1
kerning first=84 second=193 amount=-6
kerning first=348 second=87 amount=-1
kerning first=372 second=229 amount=-2
kerning first=69 second=232 amount=-1
kerning first=81 second=356 amount=-2
kerning first=342 second=374 amount=-1
kerning first=80 second=283 amount=-1
kerning first=119 second=242 amount=-1
kerning first=84 second=232 amount=-6
kerning first=83 second=373 amount=-1
kerning first=98 second=378 amount=-1
kerning first=89 second=255 amount=-2
kerning first=289 second=283 amount=-1
kerning first=102 second=269 amount=-2
kerning first=253 second=232 amount=-1
kerning first=46 second=67 amount=-1
kerning first=282 second=375 amount=-2
kerning first=374 second=305 amount=-3
kerning first=1061 second=1063 amount=-1
kerning first=255 second=335 amount=-1
kerning first=76 second=87 amount=-4
kerning first=203 second=210 amount=-1
kerning first=379 second=373 amount=-1
kerning first=1040 second=1063 amount=-5
kerning first=362 second=197 amount=-2
kerning first=1069 second=1066 amount=-2
kerning first=114 second=227 amount=-1
kerning first=310 second=102 amount=-1
kerning first=260 second=362 amount=-1
kerning first=264 second=213 amount=-1
kerning first=107 second=101 amount=-2
kerning first=202 second=355 amount=-1
kerning first=354 second=47 amount=-4
kerning first=377 second=213 amount=-1
kerning first=1036 second=1090 amount=-1
kerning first=376 second=46 amount=-8
kerning first=1073 second=8216 amount=-1
kerning first=8222 second=74 amount=1
kerning first=1091 second=8216 amount=1
kerning first=196 second=284 amount=-1
kerning first=102 second=8216 amount=1
kerning first=263 second=224 amount=-1
kerning first=372 second=115 amount=-3
kerning first=226 second=121 amount=-1
kerning first=75 second=228 amount=-1
kerning first=70 second=277 amount=-1
kerning first=916 second=8221 amount=-3
kerning first=8218 second=1059 amount=-3
kerning first=199 second=336 amount=-1
kerning first=270 second=198 amount=-1
kerning first=84 second=262 amount=-1
kerning first=345 second=277 amount=-1
kerning first=8221 second=285 amount=-5
kerning first=69 second=262 amount=-1
kerning first=196 second=121 amount=-1
kerning first=297 second=104 amount=1
kerning first=102 second=233 amount=-2
kerning first=107 second=97 amount=-1
kerning first=1058 second=1105 amount=-6
kerning first=87 second=233 amount=-3
kerning first=257 second=255 amount=-1
kerning first=84 second=371 amount=-4
kerning first=338 second=352 amount=-1
kerning first=376 second=279 amount=-5
kerning first=89 second=351 amount=-4
kerning first=47 second=192 amount=-2
kerning first=280 second=111 amount=-1
kerning first=82 second=279 amount=-1
kerning first=86 second=337 amount=-3
kerning first=8217 second=343 amount=-2
kerning first=1043 second=1098 amount=-2
kerning first=89 second=352 amount=-1
kerning first=8221 second=234 amount=-5
kerning first=214 second=86 amount=-1
kerning first=274 second=248 amount=-1
kerning first=103 second=101 amount=-1
kerning first=8221 second=920 amount=-2
kerning first=195 second=86 amount=-3
kerning first=102 second=227 amount=-1
kerning first=1044 second=1118 amount=-1
kerning first=376 second=361 amount=-3
kerning first=329 second=118 amount=-1
kerning first=198 second=266 amount=-1
kerning first=195 second=255 amount=-1
kerning first=270 second=8216 amount=-1
kerning first=328 second=253 amount=-1
kerning first=352 second=8216 amount=-1
kerning first=923 second=920 amount=-1
kerning first=1090 second=1105 amount=-1
kerning first=285 second=355 amount=-1
kerning first=340 second=118 amount=-1
kerning first=198 second=231 amount=-1
kerning first=87 second=227 amount=-2
kerning first=258 second=218 amount=-1
kerning first=1097 second=1118 amount=-1
kerning first=192 second=8217 amount=-3
kerning first=8220 second=230 amount=-2
kerning first=221 second=253 amount=-2
kerning first=44 second=8211 amount=-2
kerning first=203 second=81 amount=-1
kerning first=1059 second=1111 amount=3
kerning first=374 second=226 amount=-5
kerning first=332 second=260 amount=-1
kerning first=211 second=221 amount=-2
kerning first=261 second=253 amount=-1
kerning first=286 second=221 amount=-1
kerning first=1059 second=1029 amount=-1
kerning first=192 second=221 amount=-5
kerning first=1025 second=1139 amount=-1
kerning first=194 second=334 amount=-1
kerning first=86 second=309 amount=2
kerning first=276 second=268 amount=-1
kerning first=255 second=382 amount=-1
kerning first=86 second=334 amount=-1
kerning first=280 second=225 amount=-1
kerning first=939 second=902 amount=-5
kerning first=201 second=350 amount=-1
kerning first=120 second=113 amount=-2
kerning first=8221 second=119 amount=-1
kerning first=119 second=226 amount=-1
kerning first=47 second=244 amount=-1
kerning first=200 second=71 amount=-1
kerning first=89 second=337 amount=-5
kerning first=356 second=71 amount=-2
kerning first=121 second=287 amount=-1
kerning first=221 second=332 amount=-2
kerning first=274 second=230 amount=-1
kerning first=341 second=111 amount=-1
kerning first=313 second=219 amount=-2
kerning first=368 second=260 amount=-2
kerning first=8220 second=248 amount=-2
kerning first=1077 second=1078 amount=-1
kerning first=225 second=118 amount=-1
kerning first=69 second=268 amount=-1
kerning first=8221 second=267 amount=-5
kerning first=192 second=253 amount=-1
kerning first=210 second=379 amount=-1
kerning first=1058 second=1084 amount=-6
kerning first=373 second=230 amount=-1
kerning first=284 second=119 amount=-1
kerning first=939 second=59 amount=-4
kerning first=372 second=275 amount=-3
kerning first=193 second=79 amount=-1
kerning first=274 second=348 amount=-1
kerning first=278 second=286 amount=-1
kerning first=1116 second=1086 amount=-1
kerning first=46 second=354 amount=-5
kerning first=274 second=281 amount=-1
kerning first=260 second=63 amount=-2
kerning first=266 second=336 amount=-1
kerning first=1050 second=1086 amount=-1
kerning first=376 second=120 amount=-2
kerning first=1033 second=8220 amount=-3
kerning first=315 second=218 amount=-2
kerning first=374 second=223 amount=-3
kerning first=326 second=120 amount=-1
kerning first=1094 second=1077 amount=-1
kerning first=79 second=84 amount=-2
kerning first=1062 second=1028 amount=-1
kerning first=374 second=301 amount=2
kerning first=114 second=289 amount=-1
kerning first=914 second=902 amount=-1
kerning first=345 second=111 amount=-1
kerning first=112 second=120 amount=-1
kerning first=193 second=199 amount=-1
kerning first=356 second=257 amount=-6
kerning first=374 second=265 amount=-6
kerning first=353 second=382 amount=-1
kerning first=8218 second=81 amount=-2
kerning first=235 second=122 amount=-1
kerning first=114 second=333 amount=-1
kerning first=121 second=248 amount=-1
kerning first=90 second=264 amount=-1
kerning first=378 second=279 amount=-2
kerning first=70 second=290 amount=-1
kerning first=276 second=234 amount=-1
kerning first=340 second=264 amount=-1
kerning first=373 second=246 amount=-1
kerning first=1062 second=1090 amount=-1
kerning first=65 second=87 amount=-3
kerning first=376 second=45 amount=-4
kerning first=373 second=283 amount=-1
kerning first=228 second=116 amount=-1
kerning first=256 second=366 amount=-1
kerning first=192 second=118 amount=-1
kerning first=354 second=308 amount=-2
kerning first=269 second=248 amount=-1
kerning first=345 second=347 amount=-1
kerning first=99 second=246 amount=-1
kerning first=87 second=243 amount=-3
kerning first=75 second=71 amount=-3
kerning first=344 second=246 amount=-1
kerning first=245 second=122 amount=-1
kerning first=377 second=246 amount=-1
kerning first=119 second=113 amount=-1
kerning first=336 second=86 amount=-1
kerning first=102 second=243 amount=-2
kerning first=1088 second=1098 amount=-1
kerning first=213 second=88 amount=-2
kerning first=916 second=920 amount=-1
kerning first=354 second=297 amount=5
kerning first=1075 second=1092 amount=-1
kerning first=110 second=118 amount=-1
kerning first=278 second=212 amount=-1
kerning first=83 second=258 amount=-1
kerning first=311 second=339 amount=-2
kerning first=374 second=113 amount=-5
kerning first=1058 second=1118 amount=-4
kerning first=196 second=45 amount=-1
kerning first=342 second=338 amount=-1
kerning first=381 second=338 amount=-1
kerning first=274 second=100 amount=-1
kerning first=380 second=235 amount=-2
kerning first=1060 second=1044 amount=-3
kerning first=1043 second=44 amount=-6
kerning first=87 second=194 amount=-3
kerning first=356 second=46 amount=-7
kerning first=232 second=382 amount=-1
kerning first=202 second=235 amount=-1
kerning first=1069 second=1040 amount=-2
kerning first=280 second=67 amount=-1
kerning first=121 second=44 amount=-5
kerning first=1034 second=1061 amount=-1
kerning first=88 second=212 amount=-2
kerning first=221 second=83 amount=-1
kerning first=1075 second=8220 amount=1
kerning first=8216 second=297 amount=4
kerning first=312 second=244 amount=-2
kerning first=70 second=347 amount=-1
kerning first=75 second=211 amount=-3
kerning first=1069 second=1033 amount=-2
kerning first=89 second=238 amount=2
kerning first=276 second=116 amount=-1
kerning first=75 second=244 amount=-1
kerning first=210 second=196 amount=-1
kerning first=366 second=196 amount=-2
kerning first=221 second=105 amount=-1
kerning first=310 second=339 amount=-1
kerning first=1056 second=1032 amount=-3
kerning first=44 second=916 amount=1
kerning first=267 second=244 amount=-1
kerning first=8218 second=334 amount=-2
kerning first=68 second=381 amount=-1
kerning first=114 second=115 amount=-1
kerning first=221 second=288 amount=-2
kerning first=376 second=349 amount=-4
kerning first=84 second=74 amount=-2
kerning first=212 second=256 amount=-1
kerning first=84 second=8220 amount=1
kerning first=119 second=265 amount=-1
kerning first=214 second=377 amount=-1
kerning first=341 second=263 amount=-1
kerning first=198 second=283 amount=-1
kerning first=376 second=192 amount=-5
kerning first=195 second=8221 amount=-3
kerning first=345 second=335 amount=-1
kerning first=280 second=263 amount=-1
kerning first=348 second=195 amount=-1
kerning first=373 second=259 amount=-1
kerning first=310 second=343 amount=-1
kerning first=377 second=243 amount=-1
kerning first=1025 second=1054 amount=-2
kerning first=102 second=8221 amount=4
kerning first=353 second=121 amount=-1
kerning first=373 second=267 amount=-1
kerning first=8221 second=382 amount=-1
kerning first=266 second=210 amount=-1
kerning first=379 second=210 amount=-1
kerning first=289 second=357 amount=-1
kerning first=1107 second=46 amount=-3
kerning first=82 second=346 amount=-1
kerning first=355 second=243 amount=-1
kerning first=195 second=116 amount=-2
kerning first=356 second=275 amount=-6
kerning first=8217 second=235 amount=-5
kerning first=265 second=229 amount=-1
kerning first=253 second=103 amount=-1
kerning first=381 second=239 amount=2
kerning first=195 second=210 amount=-1
kerning first=266 second=81 amount=-1
kerning first=954 second=970 amount=-1
kerning first=313 second=121 amount=-3
kerning first=114 second=229 amount=-1
kerning first=379 second=81 amount=-1
kerning first=1068 second=8216 amount=-3
kerning first=121 second=230 amount=-1
kerning first=1105 second=8216 amount=-1
kerning first=293 second=121 amount=-1
kerning first=82 second=334 amount=-1
kerning first=340 second=350 amount=-1
kerning first=269 second=230 amount=-1
kerning first=354 second=378 amount=-5
kerning first=955 second=973 amount=-1
kerning first=285 second=97 amount=-1
kerning first=87 second=65 amount=-3
kerning first=276 second=338 amount=-1
kerning first=70 second=211 amount=-1
kerning first=1040 second=8221 amount=-3
kerning first=268 second=290 amount=-1
kerning first=376 second=309 amount=2
kerning first=381 second=290 amount=-1
kerning first=360 second=46 amount=-1
kerning first=221 second=262 amount=-2
kerning first=8222 second=1141 amount=-3
kerning first=342 second=290 amount=-1
kerning first=280 second=255 amount=-2
kerning first=1027 second=1093 amount=-4
kerning first=280 second=231 amount=-1
kerning first=272 second=376 amount=-1
kerning first=378 second=245 amount=-2
kerning first=350 second=376 amount=-1
kerning first=373 second=289 amount=-1
kerning first=255 second=103 amount=-1
kerning first=265 second=333 amount=-1
kerning first=374 second=193 amount=-5
kerning first=89 second=74 amount=-4
kerning first=84 second=289 amount=-5
kerning first=348 second=119 amount=-1
kerning first=282 second=99 amount=-1
kerning first=75 second=119 amount=-3
kerning first=203 second=233 amount=-1
kerning first=374 second=232 amount=-5
kerning first=312 second=259 amount=-1
kerning first=356 second=245 amount=-6
kerning first=260 second=74 amount=1
kerning first=200 second=245 amount=-1
kerning first=203 second=269 amount=-1
kerning first=66 second=193 amount=-1
kerning first=83 second=372 amount=-1
kerning first=366 second=308 amount=-1
kerning first=46 second=289 amount=1
kerning first=256 second=374 amount=-5
kerning first=1070 second=1066 amount=-2
kerning first=84 second=356 amount=1
kerning first=248 second=378 amount=-1
kerning first=107 second=117 amount=-1
kerning first=221 second=197 amount=-5
kerning first=331 second=355 amount=-1
kerning first=203 second=373 amount=-1
kerning first=334 second=374 amount=-2
kerning first=315 second=85 amount=-2
kerning first=121 second=353 amount=-1
kerning first=1101 second=1083 amount=-1
kerning first=1079 second=1083 amount=-1
kerning first=282 second=346 amount=-1
kerning first=89 second=102 amount=-2
kerning first=122 second=289 amount=-1
kerning first=338 second=102 amount=-2
kerning first=114 second=8216 amount=1
kerning first=8221 second=341 amount=-2
kerning first=87 second=210 amount=-1
kerning first=278 second=229 amount=-1
kerning first=86 second=361 amount=-2
kerning first=1024 second=1090 amount=-1
kerning first=194 second=368 amount=-1
kerning first=291 second=8217 amount=3
kerning first=8217 second=109 amount=-2
kerning first=8216 second=239 amount=2
kerning first=196 second=213 amount=-1
kerning first=203 second=235 amount=-1
kerning first=1073 second=1078 amount=-1
kerning first=89 second=122 amount=-4
kerning first=1038 second=1078 amount=-2
kerning first=373 second=349 amount=-1
kerning first=70 second=233 amount=-1
kerning first=79 second=90 amount=-1
kerning first=377 second=284 amount=-1
kerning first=312 second=277 amount=-2
kerning first=344 second=284 amount=-1
kerning first=196 second=217 amount=-1
kerning first=221 second=8217 amount=1
kerning first=276 second=228 amount=-1
kerning first=274 second=121 amount=-2
kerning first=66 second=102 amount=-1
kerning first=196 second=220 amount=-1
kerning first=264 second=284 amount=-1
kerning first=84 second=79 amount=-2
kerning first=255 second=277 amount=-1
kerning first=118 second=8217 amount=2
kerning first=192 second=332 amount=-1
kerning first=69 second=79 amount=-1
kerning first=8216 second=332 amount=-1
kerning first=1027 second=1104 amount=-7
kerning first=274 second=336 amount=-1
kerning first=346 second=89 amount=-1
kerning first=227 second=118 amount=-1
kerning first=1076 second=1104 amount=-1
kerning first=372 second=250 amount=-2
kerning first=8216 second=221 amount=2
kerning first=8220 second=260 amount=-6
kerning first=84 second=252 amount=-4
kerning first=69 second=242 amount=-1
kerning first=47 second=287 amount=-1
kerning first=8220 second=336 amount=-1
kerning first=86 second=279 amount=-4
kerning first=276 second=242 amount=-1
kerning first=84 second=242 amount=-6
kerning first=376 second=337 amount=-5
kerning first=202 second=65 amount=-1
kerning first=65 second=71 amount=-1
kerning first=208 second=89 amount=-1
kerning first=374 second=371 amount=-3
kerning first=197 second=84 amount=-6
kerning first=87 second=118 amount=-1
kerning first=1065 second=1095 amount=-1
kerning first=122 second=242 amount=-2
kerning first=1168 second=1095 amount=-4
kerning first=310 second=214 amount=-3
kerning first=76 second=266 amount=-1
kerning first=46 second=79 amount=-1
kerning first=280 second=101 amount=-1
kerning first=119 second=8217 amount=2
kerning first=193 second=370 amount=-1
kerning first=1169 second=1103 amount=-1
kerning first=208 second=381 amount=-1
kerning first=8222 second=1063 amount=-8
kerning first=347 second=253 amount=-1
kerning first=8218 second=362 amount=-1
kerning first=195 second=118 amount=-1
kerning first=1047 second=1063 amount=-1
kerning first=375 second=225 amount=-1
kerning first=8220 second=281 amount=-3
kerning first=115 second=357 amount=-1
kerning first=364 second=194 amount=-2
kerning first=289 second=257 amount=-1
kerning first=86 second=349 amount=-3
kerning first=310 second=218 amount=-1
kerning first=356 second=337 amount=-6
kerning first=310 second=333 amount=-1
kerning first=1169 second=97 amount=-2
kerning first=86 second=45 amount=-4
kerning first=374 second=256 amount=-5
kerning first=198 second=257 amount=-1
kerning first=90 second=119 amount=-1
kerning first=80 second=257 amount=-2
kerning first=274 second=260 amount=-1
kerning first=84 second=326 amount=-4
kerning first=194 second=45 amount=-1
kerning first=311 second=230 amount=-1
kerning first=356 second=212 amount=-2
kerning first=315 second=212 amount=-2
kerning first=378 second=231 amount=-2
kerning first=253 second=333 amount=-1
kerning first=8217 second=199 amount=-2
kerning first=1056 second=1083 amount=-5
kerning first=382 second=253 amount=-1
kerning first=203 second=118 amount=-2
kerning first=955 second=965 amount=-1
kerning first=220 second=192 amount=-2
kerning first=280 second=192 amount=-1
kerning first=121 second=281 amount=-1
kerning first=103 second=287 amount=1
kerning first=354 second=253 amount=-3
kerning first=364 second=260 amount=-2
kerning first=221 second=365 amount=-3
kerning first=214 second=354 amount=-2
kerning first=200 second=231 amount=-1
kerning first=375 second=101 amount=-1
kerning first=328 second=118 amount=-1
kerning first=195 second=354 amount=-6
kerning first=282 second=65 amount=-1
kerning first=85 second=65 amount=-2
kerning first=381 second=236 amount=1
kerning first=310 second=286 amount=-3
kerning first=86 second=285 amount=-4
kerning first=328 second=121 amount=-1
kerning first=47 second=101 amount=-1
kerning first=1045 second=1086 amount=-1
kerning first=221 second=58 amount=-5
kerning first=374 second=79 amount=-2
kerning first=201 second=253 amount=-2
kerning first=954 second=952 amount=-1
kerning first=80 second=379 amount=-1
kerning first=1059 second=1169 amount=-2
kerning first=1070 second=1040 amount=-2
kerning first=943 second=957 amount=-1
kerning first=8216 second=339 amount=-2
kerning first=340 second=221 amount=-1
kerning first=47 second=959 amount=-1
kerning first=76 second=67 amount=-1
kerning first=8218 second=221 amount=-8
kerning first=382 second=97 amount=-1
kerning first=1027 second=1091 amount=-5
kerning first=1076 second=1091 amount=-1
kerning first=378 second=337 amount=-2
kerning first=1036 second=1058 amount=-1
kerning first=291 second=113 amount=-1
kerning first=103 second=8221 amount=3
kerning first=211 second=88 amount=-2
kerning first=354 second=97 amount=-6
kerning first=200 second=196 amount=-1
kerning first=344 second=121 amount=-1
kerning first=356 second=196 amount=-6
kerning first=197 second=79 amount=-1
kerning first=202 second=199 amount=-1
kerning first=88 second=119 amount=-2
kerning first=374 second=266 amount=-2
kerning first=312 second=232 amount=-2
kerning first=122 second=265 amount=-2
kerning first=950 second=969 amount=-2
kerning first=202 second=259 amount=-1
kerning first=201 second=339 amount=-1
kerning first=255 second=347 amount=-1
kerning first=354 second=339 amount=-6
kerning first=244 second=380 amount=-1
kerning first=354 second=264 amount=-1
kerning first=88 second=335 amount=-1
kerning first=227 second=375 amount=-1
kerning first=47 second=259 amount=-1
kerning first=201 second=264 amount=-1
kerning first=235 second=102 amount=-1
kerning first=1138 second=1113 amount=-1
kerning first=380 second=259 amount=-1
kerning first=382 second=339 amount=-2
kerning first=256 second=375 amount=-1
kerning first=203 second=243 amount=-1
kerning first=279 second=8216 amount=-1
kerning first=376 second=334 amount=-2
kerning first=1118 second=1083 amount=-2
kerning first=89 second=198 amount=-6
kerning first=8220 second=916 amount=-6
kerning first=274 second=212 amount=-1
kerning first=217 second=258 amount=-2
kerning first=203 second=258 amount=-1
kerning first=379 second=275 amount=-1
kerning first=246 second=380 amount=-1
kerning first=366 second=46 amount=-1
kerning first=256 second=338 amount=-1
kerning first=8221 second=198 amount=-11
kerning first=356 second=120 amount=-3
kerning first=285 second=277 amount=-1
kerning first=228 second=255 amount=-1
kerning first=8216 second=354 amount=3
kerning first=373 second=44 amount=-6
kerning first=253 second=224 amount=-1
kerning first=192 second=364 amount=-1
kerning first=203 second=194 amount=-1
kerning first=120 second=275 amount=-2
kerning first=87 second=291 amount=-2
kerning first=217 second=194 amount=-2
kerning first=337 second=120 amount=-1
kerning first=1139 second=8220 amount=-1
kerning first=47 second=99 amount=-1
kerning first=1138 second=1061 amount=-1
kerning first=1060 second=1061 amount=-2
kerning first=338 second=262 amount=-1
kerning first=211 second=84 amount=-2
kerning first=295 second=116 amount=-1
kerning first=119 second=8220 amount=1
kerning first=260 second=262 amount=-1
kerning first=69 second=265 amount=-1
kerning first=84 second=265 amount=-6
kerning first=310 second=238 amount=1
kerning first=122 second=337 amount=-2
kerning first=241 second=8220 amount=-1
kerning first=1061 second=1141 amount=-2
kerning first=8221 second=266 amount=-2
kerning first=66 second=8220 amount=-1
kerning first=75 second=116 amount=-1
kerning first=88 second=244 amount=-1
kerning first=192 second=288 amount=-1
kerning first=70 second=288 amount=-1
kerning first=910 second=44 amount=-9
kerning first=44 second=939 amount=-5
kerning first=338 second=118 amount=-2
kerning first=1027 second=46 amount=-5
kerning first=76 second=255 amount=-3
kerning first=350 second=255 amount=-1
kerning first=1059 second=1080 amount=-2
kerning first=193 second=262 amount=-1
kerning first=119 second=259 amount=-1
kerning first=374 second=238 amount=2
kerning first=935 second=8221 amount=1
kerning first=332 second=256 amount=-1
kerning first=1074 second=1083 amount=-1
kerning first=379 second=233 amount=-1
kerning first=217 second=308 amount=-1
kerning first=196 second=44 amount=1
kerning first=102 second=339 amount=-2
kerning first=1078 second=1105 amount=-1
kerning first=332 second=44 amount=-2
kerning first=85 second=46 amount=-1
kerning first=310 second=211 amount=-3
kerning first=910 second=923 amount=-5
kerning first=279 second=8220 amount=-1
kerning first=1029 second=1118 amount=-1
kerning first=120 second=233 amount=-2
kerning first=349 second=8220 amount=-1
kerning first=1088 second=1118 amount=-1
kerning first=311 second=347 amount=-1
kerning first=1058 second=1139 amount=-7
kerning first=197 second=255 amount=-1
kerning first=8218 second=116 amount=-2
kerning first=97 second=8220 amount=-1
kerning first=8217 second=245 amount=-5
kerning first=1138 second=1035 amount=-2
kerning first=193 second=8220 amount=-6
kerning first=1025 second=1105 amount=-2
kerning first=87 second=339 amount=-3
kerning first=90 second=210 amount=-1
kerning first=107 second=349 amount=-1
kerning first=336 second=354 amount=-2
kerning first=340 second=210 amount=-1
kerning first=258 second=354 amount=-6
kerning first=195 second=67 amount=-1
kerning first=1090 second=1139 amount=-1
kerning first=86 second=112 amount=-2
kerning first=376 second=257 amount=-5
kerning first=342 second=346 amount=-1
kerning first=262 second=210 amount=-1
kerning first=202 second=226 amount=-1
kerning first=1041 second=1066 amount=-2
kerning first=198 second=263 amount=-1
kerning first=347 second=378 amount=-1
kerning first=973 second=949 amount=-1
kerning first=280 second=283 amount=-1
kerning first=75 second=338 amount=-3
kerning first=324 second=120 amount=-1
kerning first=80 second=263 amount=-2
kerning first=939 second=916 amount=-5
kerning first=341 second=103 amount=-1
kerning first=121 second=382 amount=-1
kerning first=277 second=378 amount=-1
kerning first=356 second=236 amount=1
kerning first=8220 second=235 amount=-3
kerning first=376 second=367 amount=-3
kerning first=1062 second=1066 amount=-1
kerning first=1027 second=1138 amount=-2
kerning first=338 second=265 amount=-1
kerning first=89 second=265 amount=-6
kerning first=1043 second=1040 amount=-5
kerning first=8218 second=908 amount=-1
kerning first=1107 second=1104 amount=-1
kerning first=380 second=226 amount=-1
kerning first=376 second=245 amount=-5
kerning first=103 second=100 amount=-1
kerning first=1062 second=1104 amount=-1
kerning first=82 second=245 amount=-1
kerning first=88 second=83 amount=-1
kerning first=116 second=265 amount=-1
kerning first=1093 second=1104 amount=-1
kerning first=201 second=332 amount=-1
kerning first=354 second=332 amount=-2
kerning first=313 second=211 amount=-2
kerning first=195 second=364 amount=-1
kerning first=86 second=99 amount=-4
kerning first=8217 second=226 amount=-5
kerning first=8220 second=213 amount=-1
kerning first=118 second=277 amount=-1
kerning first=192 second=8221 amount=-3
kerning first=355 second=277 amount=-1
kerning first=45 second=375 amount=-1
kerning first=354 second=103 amount=-5
kerning first=253 second=289 amount=-1
kerning first=191 second=375 amount=-2
kerning first=291 second=277 amount=-1
kerning first=203 second=242 amount=-1
kerning first=47 second=353 amount=-1
kerning first=346 second=121 amount=-1
kerning first=268 second=338 amount=-1
kerning first=87 second=59 amount=-5
kerning first=80 second=267 amount=-2
kerning first=274 second=224 amount=-1
kerning first=270 second=74 amount=-1
kerning first=381 second=119 amount=-1
kerning first=341 second=283 amount=-1
kerning first=342 second=119 amount=-1
kerning first=356 second=117 amount=-4
kerning first=287 second=232 amount=-1
kerning first=326 second=375 amount=-1
kerning first=338 second=232 amount=-1
kerning first=226 second=355 amount=-1
kerning first=1027 second=1051 amount=-5
kerning first=88 second=268 amount=-2
kerning first=334 second=87 amount=-1
kerning first=932 second=59 amount=-4
kerning first=1097 second=1076 amount=2
kerning first=75 second=239 amount=1
kerning first=79 second=356 amount=-2
kerning first=1027 second=1169 amount=-5
kerning first=1025 second=1104 amount=-2
kerning first=310 second=229 amount=-1
kerning first=1038 second=1099 amount=-2
kerning first=375 second=231 amount=-1
kerning first=376 second=375 amount=-2
kerning first=1077 second=1076 amount=-1
kerning first=213 second=374 amount=-2
kerning first=372 second=114 amount=-2
kerning first=258 second=63 amount=-2
kerning first=82 second=375 amount=-1
kerning first=120 second=269 amount=-2
kerning first=89 second=333 amount=-5
kerning first=338 second=333 amount=-1
kerning first=102 second=337 amount=-2
kerning first=196 second=336 amount=-1
kerning first=8222 second=356 amount=-7
kerning first=235 second=8216 amount=-1
kerning first=374 second=45 amount=-4
kerning first=194 second=374 amount=-5
kerning first=116 second=333 amount=-1
kerning first=274 second=213 amount=-1
kerning first=343 second=8216 amount=1
kerning first=8221 second=224 amount=-5
kerning first=1038 second=1109 amount=-2
kerning first=8216 second=103 amount=-3
kerning first=908 second=8216 amount=-1
kerning first=196 second=355 amount=-2
kerning first=289 second=97 amount=-1
kerning first=916 second=939 amount=-5
kerning first=84 second=100 amount=-5
kerning first=87 second=283 amount=-3
kerning first=289 second=229 amount=-1
kerning first=1027 second=45 amount=-2
kerning first=69 second=352 amount=-1
kerning first=84 second=233 amount=-6
kerning first=89 second=193 amount=-5
kerning first=44 second=74 amount=1
kerning first=89 second=106 amount=-2
kerning first=74 second=258 amount=-1
kerning first=202 second=284 amount=-1
kerning first=374 second=326 amount=-3
kerning first=372 second=198 amount=-4
kerning first=197 second=89 amount=-5
kerning first=336 second=198 amount=-1
kerning first=382 second=103 amount=-1
kerning first=8217 second=285 amount=-5
kerning first=276 second=224 amount=-1
kerning first=375 second=291 amount=-1
kerning first=221 second=277 amount=-5
kerning first=1123 second=8220 amount=-4
kerning first=923 second=939 amount=-5
kerning first=213 second=65 amount=-1
kerning first=377 second=336 amount=-1
kerning first=84 second=8216 amount=1
kerning first=264 second=336 amount=-1
kerning first=1043 second=1138 amount=-2
kerning first=267 second=224 amount=-1
kerning first=274 second=355 amount=-1
kerning first=47 second=260 amount=-2
kerning first=1038 second=1072 amount=-2
kerning first=1040 second=1060 amount=-3
kerning first=238 second=8216 amount=1
kerning first=372 second=232 amount=-3
kerning first=341 second=287 amount=-1
kerning first=197 second=220 amount=-1
kerning first=1061 second=1060 amount=-3
kerning first=199 second=213 amount=-1
kerning first=374 second=212 amount=-2
kerning first=107 second=111 amount=-2
kerning first=1036 second=1092 amount=-1
kerning first=256 second=284 amount=-1
kerning first=263 second=228 amount=-1
kerning first=116 second=275 amount=-1
kerning first=198 second=67 amount=-1
kerning first=1040 second=8216 amount=-7
kerning first=374 second=352 amount=-1
kerning first=68 second=260 amount=-1
kerning first=80 second=279 amount=-1
kerning first=1075 second=8216 amount=1
kerning first=280 second=266 amount=-1
kerning first=8220 second=334 amount=-1
kerning first=46 second=8216 amount=-7
kerning first=379 second=214 amount=-1
kerning first=1168 second=1073 amount=-2
kerning first=282 second=71 amount=-1
kerning first=8217 second=284 amount=-3
kerning first=343 second=118 amount=1
kerning first=377 second=199 amount=-1
kerning first=1038 second=1086 amount=-4
kerning first=376 second=196 amount=-5
kerning first=344 second=199 amount=-1
kerning first=354 second=8221 amount=2
kerning first=268 second=334 amount=-1
kerning first=376 second=231 amount=-6
kerning first=211 second=197 amount=-1
kerning first=1169 second=1077 amount=-1
kerning first=44 second=193 amount=1
kerning first=255 second=8217 amount=2
kerning first=266 second=214 amount=-1
kerning first=368 second=197 amount=-2
kerning first=379 second=118 amount=-2
kerning first=340 second=81 amount=-1
kerning first=107 second=337 amount=-2
kerning first=239 second=8221 amount=2
kerning first=90 second=81 amount=-1
kerning first=265 second=227 amount=-1
kerning first=262 second=81 amount=-1
kerning first=260 second=218 amount=-1
kerning first=1056 second=1033 amount=-5
kerning first=121 second=225 amount=-1
kerning first=213 second=377 amount=-1
kerning first=310 second=250 amount=-1
kerning first=932 second=902 amount=-6
kerning first=346 second=260 amount=-1
kerning first=104 second=118 amount=-1
kerning first=269 second=225 amount=-1
kerning first=8217 second=301 amount=2
kerning first=70 second=268 amount=-1
kerning first=214 second=221 amount=-2
kerning first=268 second=214 amount=-1
kerning first=1058 second=1054 amount=-2
kerning first=329 second=253 amount=-1
kerning first=198 second=101 amount=-1
kerning first=1088 second=1083 amount=-1
kerning first=382 second=8221 amount=1
kerning first=103 second=281 amount=-1
kerning first=83 second=118 amount=-1
kerning first=258 second=86 amount=-3
kerning first=350 second=192 amount=-1
kerning first=310 second=372 amount=-1
kerning first=289 second=101 amount=-1
kerning first=99 second=230 amount=-1
kerning first=272 second=192 amount=-1
kerning first=340 second=253 amount=-1
kerning first=929 second=959 amount=-1
kerning first=90 second=253 amount=-1
kerning first=221 second=350 amount=-1
kerning first=80 second=101 amount=-1
kerning first=372 second=266 amount=-1
kerning first=120 second=243 amount=-2
kerning first=1059 second=1086 amount=-4
kerning first=310 second=110 amount=-1
kerning first=260 second=286 amount=-1
kerning first=118 second=230 amount=-1
kerning first=374 second=59 amount=-5
kerning first=338 second=355 amount=-1
kerning first=1042 second=1026 amount=-1
kerning first=76 second=375 amount=-3
kerning first=344 second=79 amount=-1
kerning first=376 second=236 amount=1
kerning first=377 second=79 amount=-1
kerning first=280 second=357 amount=-1
kerning first=264 second=79 amount=-1
kerning first=1116 second=1139 amount=-1
kerning first=380 second=281 amount=-2
kerning first=98 second=8216 amount=-1
kerning first=89 second=286 amount=-2
kerning first=115 second=120 amount=-1
kerning first=344 second=348 amount=-1
kerning first=1068 second=1058 amount=-5
kerning first=76 second=212 amount=-2
kerning first=46 second=116 amount=-2
kerning first=86 second=367 amount=-2
kerning first=255 second=97 amount=-1
kerning first=84 second=225 amount=-6
kerning first=45 second=939 amount=-4
kerning first=380 second=119 amount=-1
kerning first=343 second=265 amount=-1
kerning first=76 second=373 amount=-3
kerning first=214 second=258 amount=-1
kerning first=87 second=258 amount=-3
kerning first=47 second=972 amount=-1
kerning first=198 second=255 amount=-2
kerning first=284 second=376 amount=-1
kerning first=341 second=248 amount=-1
kerning first=1038 second=1116 amount=-2
kerning first=210 second=376 amount=-2
kerning first=8218 second=920 amount=-1
kerning first=212 second=90 amount=-1
kerning first=84 second=249 amount=-4
kerning first=109 second=116 amount=-1
kerning first=372 second=233 amount=-3
kerning first=285 second=116 amount=-1
kerning first=195 second=308 amount=1
kerning first=280 second=248 amount=-1
kerning first=221 second=347 amount=-4
kerning first=191 second=89 amount=-4
kerning first=289 second=279 amount=-1
kerning first=1038 second=1169 amount=-2
kerning first=337 second=122 amount=-1
kerning first=312 second=246 amount=-2
kerning first=214 second=308 amount=-1
kerning first=87 second=308 amount=-3
kerning first=285 second=234 amount=-1
kerning first=119 second=122 amount=-1
kerning first=379 second=243 amount=-1
kerning first=1118 second=1105 amount=-1
kerning first=923 second=908 amount=-1
kerning first=45 second=118 amount=-1
kerning first=90 second=339 amount=-1
kerning first=256 second=360 amount=-1
kerning first=340 second=339 amount=-1
kerning first=310 second=371 amount=-1
kerning first=88 second=234 amount=-1
kerning first=214 second=193 amount=-1
kerning first=379 second=262 amount=-1
kerning first=1076 second=1093 amount=1
kerning first=234 second=380 amount=-1
kerning first=343 second=113 amount=-1
kerning first=278 second=102 amount=-2
kerning first=214 second=260 amount=-1
kerning first=80 second=88 amount=-1
kerning first=114 second=243 amount=-1
kerning first=75 second=346 amount=-1
kerning first=1027 second=1086 amount=-8
kerning first=280 second=235 amount=-1
kerning first=1062 second=1089 amount=-1
kerning first=69 second=286 amount=-1
kerning first=8216 second=347 amount=-2
kerning first=356 second=367 amount=-4
kerning first=1107 second=1089 amount=-1
kerning first=278 second=243 amount=-1
kerning first=1093 second=1089 amount=-1
kerning first=310 second=265 amount=-1
kerning first=86 second=46 amount=-7
kerning first=213 second=46 amount=-1
kerning first=1033 second=1059 amount=-3
kerning first=336 second=194 amount=-1
kerning first=354 second=305 amount=-4
kerning first=1060 second=1069 amount=-1
kerning first=372 second=194 amount=-3
kerning first=99 second=226 amount=-1
kerning first=1027 second=1113 amount=-7
kerning first=69 second=259 amount=-1
kerning first=44 second=355 amount=-2
kerning first=372 second=44 amount=-8
kerning first=1097 second=44 amount=3
kerning first=84 second=259 amount=-6
kerning first=80 second=349 amount=-1
kerning first=97 second=118 amount=-1
kerning first=360 second=198 amount=-1
kerning first=260 second=8216 amount=-6
kerning first=262 second=332 amount=-1
kerning first=200 second=267 amount=-1
kerning first=90 second=332 amount=-1
kerning first=244 second=382 amount=-1
kerning first=340 second=332 amount=-1
kerning first=354 second=347 amount=-5
kerning first=374 second=262 amount=-2
kerning first=939 second=8221 amount=1
kerning first=1043 second=8220 amount=1
kerning first=84 second=238 amount=2
kerning first=377 second=235 amount=-1
kerning first=201 second=194 amount=-1
kerning first=218 second=44 amount=-1
kerning first=344 second=235 amount=-1
kerning first=121 second=229 amount=-1
kerning first=1043 second=1084 amount=-5
kerning first=276 second=244 amount=-1
kerning first=340 second=275 amount=-1
kerning first=8221 second=290 amount=-3
kerning first=121 second=380 amount=-1
kerning first=380 second=245 amount=-2
kerning first=334 second=195 amount=-1
kerning first=243 second=380 amount=-1
kerning first=223 second=255 amount=-2
kerning first=1082 second=1104 amount=-1
kerning first=1078 second=1077 amount=-1
kerning first=8220 second=910 amount=2
kerning first=115 second=375 amount=-1
kerning first=221 second=305 amount=-3
kerning first=118 second=333 amount=-1
kerning first=345 second=227 amount=-1
kerning first=382 second=347 amount=-1
kerning first=203 second=339 amount=-1
kerning first=299 second=8220 amount=1
kerning first=87 second=115 amount=-3
kerning first=110 second=8216 amount=-1
kerning first=329 second=255 amount=-1
kerning first=1168 second=59 amount=-1
kerning first=338 second=229 amount=-1
kerning first=1065 second=59 amount=2
kerning first=87 second=365 amount=-2
kerning first=75 second=268 amount=-3
kerning first=278 second=232 amount=-1
kerning first=340 second=373 amount=-1
kerning first=90 second=373 amount=-1
kerning first=103 second=225 amount=-1
kerning first=201 second=210 amount=-1
kerning first=1138 second=1066 amount=-2
kerning first=102 second=115 amount=-1
kerning first=195 second=81 amount=-1
kerning first=118 second=378 amount=-1
kerning first=103 second=283 amount=-1
kerning first=282 second=334 amount=-1
kerning first=200 second=279 amount=-1
kerning first=86 second=245 amount=-3
kerning first=67 second=71 amount=-1
kerning first=376 second=71 amount=-2
kerning first=82 second=71 amount=-1
kerning first=343 second=333 amount=-1
kerning first=214 second=372 amount=-1
kerning first=297 second=320 amount=1
kerning first=263 second=248 amount=-1
kerning first=958 second=974 amount=-1
kerning first=381 second=45 amount=-2
kerning first=89 second=242 amount=-5
kerning first=375 second=111 amount=-1
kerning first=1043 second=1118 amount=-5
kerning first=257 second=121 amount=-1
kerning first=341 second=353 amount=-1
kerning first=311 second=277 amount=-2
kerning first=121 second=101 amount=-1
kerning first=122 second=259 amount=-1
kerning first=223 second=121 amount=-2
kerning first=221 second=353 amount=-4
kerning first=287 second=289 amount=1
kerning first=86 second=339 amount=-3
kerning first=343 second=232 amount=-1
kerning first=197 second=121 amount=-1
kerning first=364 second=193 amount=-2
kerning first=65 second=368 amount=-1
kerning first=8222 second=196 amount=1
kerning first=79 second=193 amount=-1
kerning first=89 second=289 amount=-5
kerning first=360 second=74 amount=-1
kerning first=1075 second=259 amount=-2
kerning first=8221 second=239 amount=2
kerning first=932 second=47 amount=-4
kerning first=353 second=375 amount=-1
kerning first=8216 second=210 amount=-1
kerning first=929 second=935 amount=-1
kerning first=86 second=268 amount=-1
kerning first=355 second=335 amount=-1
kerning first=224 second=375 amount=-1
kerning first=376 second=117 amount=-3
kerning first=118 second=335 amount=-1
kerning first=65 second=67 amount=-1
kerning first=1090 second=1076 amount=-3
kerning first=86 second=375 amount=-1
kerning first=233 second=102 amount=-1
kerning first=1058 second=1076 amount=-7
kerning first=290 second=375 amount=-1
kerning first=1035 second=1063 amount=-4
kerning first=194 second=375 amount=-1
kerning first=221 second=335 amount=-5
kerning first=260 second=356 amount=-6
kerning first=376 second=99 amount=-6
kerning first=354 second=58 amount=-5
kerning first=8220 second=337 amount=-2
kerning first=47 second=283 amount=-1
kerning first=45 second=1058 amount=-3
kerning first=345 second=8217 amount=3
kerning first=89 second=371 amount=-3
kerning first=8217 second=246 amount=-5
kerning first=66 second=90 amount=-1
kerning first=270 second=356 amount=-1
kerning first=239 second=8216 amount=1
kerning first=197 second=266 amount=-1
kerning first=262 second=8221 amount=3
kerning first=282 second=228 amount=-1
kerning first=80 second=111 amount=-1
kerning first=964 second=963 amount=-1
kerning first=378 second=99 amount=-2
kerning first=122 second=228 amount=-1
kerning first=1036 second=1095 amount=-1
kerning first=380 second=246 amount=-2
kerning first=374 second=249 amount=-3
kerning first=90 second=8221 amount=2
kerning first=1043 second=1033 amount=-5
kerning first=280 second=337 amount=-1
kerning first=106 second=8221 amount=1
kerning first=197 second=213 amount=-1
kerning first=935 second=920 amount=-2
kerning first=193 second=217 amount=-1
kerning first=382 second=373 amount=-1
kerning first=955 second=948 amount=-1
kerning first=122 second=113 amount=-2
kerning first=971 second=955 amount=-2
kerning first=202 second=246 amount=-1
kerning first=356 second=99 amount=-6
kerning first=70 second=212 amount=-1
kerning first=193 second=284 amount=-1
kerning first=200 second=99 amount=-1
kerning first=293 second=355 amount=-1
kerning first=356 second=285 amount=-5
kerning first=79 second=8216 amount=-1
kerning first=121 second=103 amount=-1
kerning first=8222 second=218 amount=-1
kerning first=1062 second=1057 amount=-1
kerning first=375 second=287 amount=-1
kerning first=285 second=224 amount=-1
kerning first=84 second=113 amount=-5
kerning first=381 second=309 amount=1
kerning first=69 second=113 amount=-1
kerning first=253 second=351 amount=-1
kerning first=958 second=973 amount=-1
kerning first=116 second=242 amount=-1
kerning first=87 second=332 amount=-1
kerning first=376 second=65 amount=-5
kerning first=272 second=84 amount=-1
kerning first=202 second=336 amount=-1
kerning first=199 second=338 amount=-1
kerning first=276 second=65 amount=-1
kerning first=1069 second=1076 amount=-2
kerning first=120 second=227 amount=-1
kerning first=374 second=109 amount=-3
kerning first=8216 second=920 amount=-1
kerning first=1106 second=1141 amount=-2
kerning first=311 second=117 amount=-1
kerning first=310 second=351 amount=-1
kerning first=47 second=248 amount=-1
kerning first=194 second=71 amount=-1
kerning first=46 second=373 amount=-2
kerning first=377 second=275 amount=-1
kerning first=89 second=284 amount=-2
kerning first=89 second=250 amount=-3
kerning first=1038 second=1063 amount=-1
kerning first=76 second=84 amount=-5
kerning first=372 second=214 amount=-1
kerning first=47 second=225 amount=-1
kerning first=258 second=214 amount=-1
kerning first=203 second=227 amount=-1
kerning first=347 second=382 amount=-1
kerning first=280 second=334 amount=-1
kerning first=86 second=71 amount=-1
kerning first=200 second=257 amount=-1
kerning first=377 second=301 amount=1
kerning first=196 second=199 amount=-1
kerning first=202 second=256 amount=-1
kerning first=65 second=255 amount=-1
kerning first=8221 second=195 amount=-9
kerning first=8222 second=199 amount=-1
kerning first=315 second=376 amount=-6
kerning first=378 second=349 amount=-1
kerning first=258 second=118 amount=-1
kerning first=278 second=233 amount=-1
kerning first=8222 second=290 amount=-1
kerning first=256 second=87 amount=-3
kerning first=1054 second=1040 amount=-2
kerning first=372 second=118 amount=-1
kerning first=332 second=377 amount=-1
kerning first=86 second=231 amount=-4
kerning first=284 second=255 amount=-1
kerning first=376 second=331 amount=-3
kerning first=228 second=118 amount=-1
kerning first=76 second=357 amount=-1
kerning first=354 second=365 amount=-4
kerning first=225 second=253 amount=-1
kerning first=115 second=255 amount=-1
kerning first=121 second=100 amount=-1
kerning first=97 second=355 amount=-1
kerning first=256 second=362 amount=-1
kerning first=351 second=253 amount=-1
kerning first=46 second=262 amount=-1
kerning first=198 second=192 amount=-1
kerning first=1138 second=46 amount=-1
kerning first=373 second=235 amount=-1
kerning first=276 second=195 amount=-1
kerning first=80 second=242 amount=-1
kerning first=87 second=275 amount=-3
kerning first=341 second=101 amount=-1
kerning first=219 second=195 amount=-2
kerning first=195 second=253 amount=-1
kerning first=80 second=337 amount=-1
kerning first=356 second=349 amount=-5
kerning first=341 second=281 amount=-1
kerning first=342 second=284 amount=-1
kerning first=1056 second=1084 amount=-1
kerning first=377 second=262 amount=-1
kerning first=80 second=192 amount=-5
kerning first=198 second=337 amount=-1
kerning first=87 second=253 amount=-2
kerning first=102 second=275 amount=-2
kerning first=210 second=88 amount=-2
kerning first=221 second=97 amount=-5
kerning first=202 second=79 amount=-1
kerning first=115 second=116 amount=-1
kerning first=86 second=236 amount=2
kerning first=381 second=238 amount=1
kerning first=378 second=285 amount=-1
kerning first=89 second=110 amount=-3
kerning first=1059 second=1087 amount=-2
kerning first=8217 second=281 amount=-5
kerning first=1050 second=1091 amount=-2
kerning first=280 second=281 amount=-1
kerning first=372 second=243 amount=-3
kerning first=1094 second=1092 amount=-1
kerning first=8221 second=380 amount=-1
kerning first=8222 second=370 amount=-1
kerning first=221 second=264 amount=-2
kerning first=8220 second=221 amount=2
kerning first=1038 second=46 amount=-7
kerning first=198 second=212 amount=-1
kerning first=1107 second=257 amount=-2
kerning first=87 second=349 amount=-3
kerning first=109 second=375 amount=-1
kerning first=355 second=97 amount=-1
kerning first=118 second=97 amount=-1
kerning first=291 second=97 amount=-1
kerning first=378 second=257 amount=-1
kerning first=950 second=957 amount=-3
kerning first=120 second=97 amount=-1
kerning first=122 second=230 amount=-1
kerning first=276 second=332 amount=-1
kerning first=114 second=8221 amount=3
kerning first=373 second=234 amount=-1
kerning first=70 second=297 amount=3
kerning first=8222 second=217 amount=-1
kerning first=260 second=199 amount=-1
kerning first=76 second=262 amount=-1
kerning first=1042 second=1058 amount=-1
kerning first=1045 second=1091 amount=-1
kerning first=354 second=59 amount=-4
kerning first=46 second=89 amount=-7
kerning first=293 second=357 amount=-1
kerning first=89 second=199 amount=-2
kerning first=122 second=227 amount=-1
kerning first=86 second=380 amount=-3
kerning first=338 second=199 amount=-1
kerning first=201 second=275 amount=-1
kerning first=281 second=380 amount=-1
kerning first=1058 second=1097 amount=-5
kerning first=354 second=275 amount=-6
kerning first=119 second=243 amount=-1
kerning first=8217 second=44 amount=-7
kerning first=272 second=260 amount=-1
kerning first=193 second=63 amount=-2
kerning first=943 second=964 amount=-1
kerning first=121 second=257 amount=-1
kerning first=70 second=229 amount=-2
kerning first=353 second=380 amount=-1
kerning first=1094 second=59 amount=2
kerning first=268 second=212 amount=-1
kerning first=340 second=283 amount=-1
kerning first=8221 second=332 amount=-3
kerning first=8218 second=336 amount=-2
kerning first=378 second=263 amount=-2
kerning first=350 second=260 amount=-1
kerning first=234 second=120 amount=-1
kerning first=343 second=246 amount=-1
kerning first=200 second=337 amount=-1
kerning first=342 second=212 amount=-1
kerning first=345 second=291 amount=-1
kerning first=221 second=243 amount=-5
kerning first=1043 second=1072 amount=-5
kerning first=1168 second=1102 amount=-5
kerning first=221 second=267 amount=-6
kerning first=950 second=945 amount=-2
kerning first=373 second=122 amount=-1
kerning first=340 second=333 amount=-1
kerning first=354 second=258 amount=-6
kerning first=65 second=86 amount=-3
kerning first=940 second=948 amount=-1
kerning first=86 second=234 amount=-4
kerning first=201 second=258 amount=-1
kerning first=196 second=370 amount=-1
kerning first=120 second=339 amount=-2
kerning first=8220 second=79 amount=-1
kerning first=122 second=283 amount=-2
kerning first=373 second=226 amount=-1
kerning first=935 second=908 amount=-2
kerning first=87 second=286 amount=-1
kerning first=342 second=375 amount=-1
kerning first=8216 second=258 amount=-6
kerning first=278 second=194 amount=-1
kerning first=372 second=371 amount=-2
kerning first=8218 second=308 amount=1
kerning first=195 second=286 amount=-1
kerning first=198 second=348 amount=-1
kerning first=376 second=346 amount=-1
kerning first=274 second=79 amount=-1
kerning first=1090 second=44 amount=-3
kerning first=193 second=220 amount=-1
kerning first=118 second=267 amount=-1
kerning first=118 second=243 amount=-1
kerning first=84 second=110 amount=-4
kerning first=355 second=267 amount=-1
kerning first=256 second=45 amount=-1
kerning first=86 second=212 amount=-1
kerning first=261 second=116 amount=-1
kerning first=258 second=262 amount=-1
kerning first=291 second=116 amount=-1
kerning first=243 second=44 amount=-1
kerning first=1118 second=1113 amount=-2
kerning first=355 second=116 amount=-1
kerning first=282 second=211 amount=-1
kerning first=276 second=83 amount=-1
kerning first=376 second=382 amount=-4
kerning first=232 second=378 amount=-1
kerning first=231 second=257 amount=-1
kerning first=8217 second=326 amount=-2
kerning first=308 second=44 amount=-1
kerning first=65 second=308 amount=1
kerning first=922 second=972 amount=-2
kerning first=221 second=116 amount=-2
kerning first=372 second=262 amount=-1
kerning first=203 second=288 amount=-1
kerning first=346 second=256 amount=-1
kerning first=311 second=244 amount=-2
kerning first=81 second=86 amount=-1
kerning first=228 second=8220 amount=-1
kerning first=334 second=46 amount=-1
kerning first=336 second=8220 amount=-1
kerning first=8216 second=111 amount=-2
kerning first=904 second=908 amount=-1
kerning first=362 second=308 amount=-1
kerning first=195 second=375 amount=-1
kerning first=200 second=67 amount=-1
kerning first=352 second=65 amount=-1
kerning first=315 second=67 amount=-1
kerning first=356 second=67 amount=-1
kerning first=90 second=333 amount=-1
kerning first=70 second=308 amount=-4
kerning first=75 second=290 amount=-3
kerning first=356 second=263 amount=-6
kerning first=1118 second=1108 amount=-1
kerning first=1044 second=1066 amount=-1
kerning first=65 second=89 amount=-5
kerning first=255 second=378 amount=-1
kerning first=282 second=283 amount=-1
kerning first=255 second=269 amount=-1
kerning first=86 second=198 amount=-5
kerning first=308 second=196 amount=-1
kerning first=8216 second=275 amount=-3
kerning first=8221 second=349 amount=-4
kerning first=346 second=196 amount=-1
kerning first=70 second=232 amount=-1
kerning first=278 second=265 amount=-1
kerning first=8216 second=283 amount=-3
kerning first=343 second=229 amount=-1
kerning first=75 second=83 amount=-1
kerning first=1168 second=1108 amount=-7
kerning first=1104 second=1083 amount=-1
kerning first=71 second=373 amount=-1
kerning first=86 second=195 amount=-3
kerning first=200 second=263 amount=-1
kerning first=213 second=195 amount=-1
kerning first=192 second=210 amount=-1
kerning first=119 second=100 amount=-1
kerning first=282 second=245 amount=-1
kerning first=1057 second=1111 amount=2
kerning first=201 second=97 amount=-1
kerning first=70 second=338 amount=-1
kerning first=114 second=265 amount=-1
kerning first=121 second=235 amount=-1
kerning first=1027 second=1089 amount=-8
kerning first=8217 second=288 amount=-3
kerning first=46 second=308 amount=1
kerning first=374 second=100 amount=-5
kerning first=1076 second=1089 amount=-1
kerning first=79 second=381 amount=-1
kerning first=340 second=372 amount=-1
kerning first=8221 second=100 amount=-5
kerning first=270 second=377 amount=-1
kerning first=954 second=941 amount=-1
kerning first=81 second=89 amount=-2
kerning first=103 second=259 amount=-1
kerning first=356 second=112 amount=-4
kerning first=76 second=89 amount=-6
kerning first=272 second=89 amount=-1
kerning first=338 second=373 amount=-1
kerning first=231 second=242 amount=-1
kerning first=86 second=341 amount=-2
kerning first=89 second=373 amount=-2
kerning first=350 second=89 amount=-1
kerning first=121 second=259 amount=-1
kerning first=253 second=242 amount=-1
kerning first=8222 second=1038 amount=-3
kerning first=80 second=230 amount=-2
kerning first=1168 second=1071 amount=-1
kerning first=263 second=245 amount=-1
kerning first=89 second=375 amount=-2
kerning first=381 second=277 amount=-1
kerning first=70 second=350 amount=-1
kerning first=342 second=277 amount=-1
kerning first=261 second=121 amount=-1
kerning first=315 second=44 amount=1
kerning first=195 second=219 amount=-1
kerning first=198 second=230 amount=-1
kerning first=214 second=198 amount=-1
kerning first=69 second=121 amount=-2
kerning first=87 second=198 amount=-4
kerning first=336 second=74 amount=-1
kerning first=118 second=113 amount=-1
kerning first=193 second=362 amount=-1
kerning first=266 second=8217 amount=3
kerning first=372 second=74 amount=-3
kerning first=119 second=275 amount=-1
kerning first=379 second=8217 amount=2
kerning first=46 second=121 amount=-3
kerning first=258 second=74 amount=1
kerning first=221 second=119 amount=-2
kerning first=338 second=259 amount=-1
kerning first=90 second=335 amount=-1
kerning first=120 second=337 amount=-2
kerning first=76 second=86 amount=-5
kerning first=271 second=107 amount=1
kerning first=381 second=245 amount=-1
kerning first=342 second=245 amount=-1
kerning first=283 second=378 amount=-1
kerning first=86 second=283 amount=-4
kerning first=355 second=228 amount=-1
kerning first=276 second=334 amount=-1
kerning first=112 second=102 amount=-1
kerning first=1042 second=1059 amount=-1
kerning first=1040 second=1028 amount=-2
kerning first=86 second=382 amount=-3
kerning first=80 second=353 amount=-1
kerning first=86 second=228 amount=-4
kerning first=284 second=375 amount=-1
kerning first=65 second=375 amount=-1
kerning first=1040 second=8220 amount=-7
kerning first=377 second=255 amount=-1
kerning first=211 second=381 amount=-1
kerning first=344 second=255 amount=-1
kerning first=970 second=967 amount=-1
kerning first=203 second=102 amount=-2
kerning first=82 second=119 amount=-1
kerning first=355 second=244 amount=-1
kerning first=8221 second=923 amount=-9
kerning first=280 second=213 amount=-1
kerning first=310 second=233 amount=-1
kerning first=1094 second=1090 amount=-1
kerning first=8221 second=213 amount=-3
kerning first=253 second=233 amount=-1
kerning first=374 second=284 amount=-2
kerning first=310 second=242 amount=-1
kerning first=89 second=369 amount=-3
kerning first=46 second=8221 amount=-5
kerning first=8217 second=122 amount=-1
kerning first=84 second=246 amount=-6
kerning first=191 second=376 amount=-4
kerning first=289 second=287 amount=1
kerning first=75 second=360 amount=-1
kerning first=958 second=965 amount=-1
kerning first=45 second=376 amount=-4
kerning first=70 second=224 amount=-2
kerning first=256 second=71 amount=-1
kerning first=345 second=224 amount=-1
kerning first=1106 second=1118 amount=-2
kerning first=356 second=65 amount=-6
kerning first=274 second=113 amount=-1
kerning first=313 second=288 amount=-2
kerning first=84 second=59 amount=-4
kerning first=82 second=111 amount=-1
kerning first=356 second=84 amount=1
kerning first=112 second=8216 amount=-1
kerning first=310 second=226 amount=-1
kerning first=8217 second=192 amount=-9
kerning first=904 second=902 amount=-1
kerning first=1036 second=1073 amount=-1
kerning first=382 second=248 amount=-2
kerning first=217 second=192 amount=-2
kerning first=44 second=86 amount=-5
kerning first=1065 second=8216 amount=-1
kerning first=1168 second=8216 amount=1
kerning first=88 second=350 amount=-1
kerning first=1069 second=1044 amount=-2
kerning first=231 second=226 amount=-1
kerning first=202 second=352 amount=-1
kerning first=312 second=248 amount=-2
kerning first=270 second=86 amount=-1
kerning first=8218 second=210 amount=-2
kerning first=1045 second=1077 amount=-2
kerning first=201 second=277 amount=-1
kerning first=86 second=299 amount=4
kerning first=908 second=902 amount=-1
kerning first=87 second=214 amount=-1
kerning first=914 second=8220 amount=-1
kerning first=377 second=101 amount=-1
kerning first=1101 second=1113 amount=-1
kerning first=375 second=337 amount=-1
kerning first=1050 second=1028 amount=-2
kerning first=376 second=235 amount=-5
kerning first=75 second=332 amount=-3
kerning first=8222 second=902 amount=1
kerning first=310 second=199 amount=-3
kerning first=373 second=101 amount=-1
kerning first=334 second=197 amount=-1
kerning first=1059 second=1104 amount=-4
kerning first=313 second=118 amount=-3
kerning first=120 second=8217 amount=2
kerning first=201 second=101 amount=-1
kerning first=8221 second=84 amount=1
kerning first=274 second=194 amount=-1
kerning first=195 second=214 amount=-1
kerning first=280 second=260 amount=-1
kerning first=203 second=253 amount=-2
kerning first=291 second=291 amount=1
kerning first=338 second=81 amount=-1
kerning first=8220 second=959 amount=-2
kerning first=8222 second=910 amount=-7
kerning first=260 second=81 amount=-1
kerning first=376 second=239 amount=2
kerning first=220 second=260 amount=-2
kerning first=89 second=81 amount=-2
kerning first=375 second=281 amount=-1
kerning first=221 second=268 amount=-2
kerning first=1027 second=1057 amount=-2
kerning first=355 second=242 amount=-1
kerning first=1062 second=1086 amount=-1
kerning first=344 second=101 amount=-1
kerning first=1093 second=1086 amount=-1
kerning first=1107 second=1086 amount=-1
kerning first=202 second=192 amount=-1
kerning first=285 second=285 amount=1
kerning first=8217 second=119 amount=-1
kerning first=8220 second=256 amount=-6
kerning first=282 second=195 amount=-1
kerning first=89 second=114 amount=-3
kerning first=356 second=287 amount=-5
kerning first=376 second=112 amount=-3
kerning first=221 second=118 amount=-2
kerning first=85 second=195 amount=-2
kerning first=341 second=337 amount=-1
kerning first=107 second=263 amount=-2
kerning first=1102 second=1113 amount=-1
kerning first=260 second=86 amount=-3
kerning first=245 second=120 amount=-1
kerning first=8221 second=199 amount=-2
kerning first=260 second=87 amount=-3
kerning first=122 second=100 amount=-2
kerning first=332 second=192 amount=-1
kerning first=313 second=354 amount=-5
kerning first=201 second=244 amount=-1
kerning first=104 second=253 amount=-1
kerning first=1042 second=1098 amount=-1
kerning first=354 second=244 amount=-6
kerning first=208 second=354 amount=-1
kerning first=198 second=281 amount=-1
kerning first=198 second=65 amount=-1
kerning first=258 second=199 amount=-1
kerning first=223 second=357 amount=-1
kerning first=80 second=65 amount=-5
kerning first=75 second=349 amount=-1
kerning first=83 second=253 amount=-1
kerning first=379 second=253 amount=-1
kerning first=65 second=334 amount=-1
kerning first=276 second=45 amount=-1
kerning first=340 second=89 amount=-1
kerning first=337 second=382 amount=-1
kerning first=289 second=281 amount=-1
kerning first=378 second=287 amount=-1
kerning first=69 second=100 amount=-1
kerning first=197 second=336 amount=-1
kerning first=46 second=217 amount=-1
kerning first=1025 second=1098 amount=-1
kerning first=1059 second=1091 amount=-1
kerning first=197 second=357 amount=-2
kerning first=1058 second=8220 amount=1
kerning first=372 second=97 amount=-2
kerning first=923 second=45 amount=-1
kerning first=932 second=8217 amount=2
kerning first=196 second=79 amount=-1
kerning first=1118 second=1077 amount=-1
kerning first=8220 second=257 amount=-2
kerning first=257 second=357 amount=-1
kerning first=8218 second=255 amount=-5
kerning first=910 second=45 amount=-5
kerning first=344 second=372 amount=-1
kerning first=311 second=234 amount=-2
kerning first=340 second=86 amount=-1
kerning first=1044 second=1138 amount=-1
kerning first=1065 second=1092 amount=-1
kerning first=376 second=234 amount=-5
kerning first=8221 second=229 amount=-5
kerning first=372 second=264 amount=-1
kerning first=82 second=234 amount=-1
kerning first=8220 second=113 amount=-3
kerning first=274 second=257 amount=-1
kerning first=198 second=260 amount=-1
kerning first=258 second=264 amount=-1
kerning first=256 second=212 amount=-1
kerning first=336 second=90 amount=-1
kerning first=82 second=67 amount=-1
kerning first=80 second=260 amount=-5
kerning first=376 second=67 amount=-2
kerning first=356 second=375 amount=-3
kerning first=8221 second=347 amount=-4
kerning first=315 second=375 amount=-3
kerning first=278 second=119 amount=-1
kerning first=255 second=291 amount=-1
kerning first=200 second=375 amount=-2
kerning first=70 second=269 amount=-1
kerning first=208 second=374 amount=-1
kerning first=74 second=198 amount=-1
kerning first=372 second=339 amount=-3
kerning first=80 second=231 amount=-2
kerning first=368 second=258 amount=-2
kerning first=282 second=290 amount=-1
kerning first=44 second=194 amount=1
kerning first=382 second=335 amount=-2
kerning first=211 second=258 amount=-1
kerning first=107 second=257 amount=-1
kerning first=1040 second=1032 amount=1
kerning first=218 second=193 amount=-2
kerning first=274 second=193 amount=-1
kerning first=203 second=286 amount=-1
kerning first=374 second=110 amount=-3
kerning first=1075 second=1103 amount=-1
kerning first=270 second=194 amount=-1
kerning first=269 second=259 amount=-1
kerning first=352 second=194 amount=-1
kerning first=86 second=378 amount=-3
kerning first=260 second=116 amount=-2
kerning first=1025 second=1113 amount=-1
kerning first=1105 second=8220 amount=-1
kerning first=1069 second=1113 amount=-1
kerning first=70 second=100 amount=-1
kerning first=268 second=211 amount=-1
kerning first=379 second=288 amount=-1
kerning first=342 second=211 amount=-1
kerning first=1102 second=1093 amount=-1
kerning first=274 second=235 amount=-1
kerning first=381 second=211 amount=-1
kerning first=1068 second=8220 amount=-3
kerning first=356 second=382 amount=-5
kerning first=75 second=324 amount=-1
kerning first=193 second=308 amount=1
kerning first=376 second=380 amount=-4
kerning first=310 second=277 amount=-1
kerning first=197 second=44 amount=1
kerning first=266 second=288 amount=-1
kerning first=192 second=116 amount=-2
kerning first=253 second=8221 amount=2
kerning first=288 second=121 amount=-1
kerning first=910 second=972 amount=-5
kerning first=8217 second=916 amount=-9
kerning first=44 second=8221 amount=-5
kerning first=218 second=256 amount=-2
kerning first=1036 second=1141 amount=-2
kerning first=955 second=940 amount=-1
kerning first=1065 second=1073 amount=-1
kerning first=274 second=256 amount=-1
kerning first=1025 second=1108 amount=-2
kerning first=257 second=120 amount=-1
kerning first=382 second=244 amount=-2
kerning first=208 second=308 amount=-1
kerning first=282 second=259 amount=-1
kerning first=313 second=308 amount=1
kerning first=1058 second=1087 amount=-5
kerning first=69 second=229 amount=-1
kerning first=220 second=44 amount=-1
kerning first=118 second=269 amount=-1
kerning first=75 second=283 amount=-1
kerning first=203 second=214 amount=-1
kerning first=340 second=111 amount=-1
kerning first=256 second=334 amount=-1
kerning first=376 second=353 amount=-4
kerning first=253 second=8220 amount=1
kerning first=46 second=220 amount=-1
kerning first=196 second=255 amount=-1
kerning first=198 second=346 amount=-1
kerning first=1027 second=1096 amount=-5
kerning first=382 second=115 amount=-1
kerning first=194 second=376 amount=-5
kerning first=352 second=8220 amount=-1
kerning first=8216 second=349 amount=-2
kerning first=84 second=229 amount=-6
kerning first=213 second=376 amount=-2
kerning first=1025 second=1051 amount=-1
kerning first=221 second=65 amount=-5
kerning first=87 second=231 amount=-3
kerning first=253 second=265 amount=-1
kerning first=356 second=239 amount=3
kerning first=290 second=255 amount=-1
kerning first=270 second=8220 amount=-1
kerning first=274 second=225 amount=-1
kerning first=8216 second=115 amount=-2
kerning first=278 second=242 amount=-1
kerning first=116 second=233 amount=-1
kerning first=313 second=268 amount=-1
kerning first=313 second=338 amount=-2
kerning first=217 second=198 amount=-1
kerning first=376 second=242 amount=-5
kerning first=66 second=121 amount=-1
kerning first=1168 second=1079 amount=-5
kerning first=192 second=372 amount=-3
kerning first=374 second=121 amount=-2
kerning first=8221 second=103 amount=-5
kerning first=72 second=8216 amount=-1
kerning first=310 second=373 amount=-3
kerning first=195 second=8216 amount=-6
kerning first=352 second=373 amount=-1
kerning first=8220 second=232 amount=-3
kerning first=214 second=8216 amount=-1
kerning first=225 second=8216 amount=-1
kerning first=1093 second=1105 amount=-1
kerning first=1107 second=1105 amount=-1
kerning first=1036 second=1138 amount=-2
kerning first=920 second=46 amount=-1
kerning first=351 second=8216 amount=-1
kerning first=1062 second=1105 amount=-1
kerning first=342 second=87 amount=-1
kerning first=954 second=953 amount=-1
kerning first=336 second=377 amount=-1
kerning first=372 second=345 amount=-2
kerning first=286 second=119 amount=-1
kerning first=932 second=8216 amount=1
kerning first=196 second=368 amount=-1
kerning first=46 second=355 amount=-2
kerning first=201 second=335 amount=-1
kerning first=221 second=269 amount=-6
kerning first=1058 second=1074 amount=-5
kerning first=241 second=121 amount=-1
kerning first=282 second=266 amount=-1
kerning first=354 second=335 amount=-6
kerning first=916 second=8220 amount=-6
kerning first=332 second=193 amount=-1
kerning first=354 second=115 amount=-5
kerning first=356 second=369 amount=-4
kerning first=376 second=283 amount=-5
kerning first=211 second=372 amount=-1
kerning first=274 second=232 amount=-1
kerning first=89 second=226 amount=-5
kerning first=374 second=248 amount=-5
kerning first=376 second=228 amount=-5
kerning first=287 second=226 amount=-1
kerning first=103 second=226 amount=-1
kerning first=274 second=266 amount=-1
kerning first=8221 second=279 amount=-5
kerning first=336 second=356 amount=-2
kerning first=116 second=226 amount=-1
kerning first=107 second=353 amount=-1
kerning first=258 second=356 amount=-6
kerning first=46 second=284 amount=-1
kerning first=70 second=47 amount=-2
kerning first=375 second=283 amount=-1
kerning first=356 second=241 amount=-4
kerning first=75 second=99 amount=-1
kerning first=356 second=234 amount=-6
kerning first=200 second=234 amount=-1
kerning first=916 second=908 amount=-1
kerning first=954 second=948 amount=-2
kerning first=119 second=246 amount=-1
kerning first=954 second=959 amount=-2
kerning first=46 second=910 amount=-6
kerning first=255 second=224 amount=-1
kerning first=279 second=122 amount=-1
kerning first=311 second=267 amount=-2
kerning first=1061 second=1095 amount=-1
kerning first=76 second=213 amount=-2
kerning first=8217 second=959 amount=-6
kerning first=374 second=246 amount=-5
kerning first=69 second=284 amount=-1
kerning first=69 second=355 amount=-1
kerning first=374 second=199 amount=-2
kerning first=84 second=284 amount=-2
kerning first=334 second=193 amount=-1
kerning first=121 second=113 amount=-1
kerning first=344 second=352 amount=-1
kerning first=8216 second=86 amount=2
kerning first=342 second=71 amount=-1
kerning first=381 second=71 amount=-1
kerning first=366 second=65 amount=-2
kerning first=378 second=111 amount=-2
kerning first=8220 second=301 amount=3
kerning first=1045 second=1083 amount=-1
kerning first=47 second=263 amount=-1
kerning first=374 second=336 amount=-2
kerning first=86 second=345 amount=-2
kerning first=210 second=65 amount=-1
kerning first=1024 second=1073 amount=-1
kerning first=84 second=250 amount=-4
kerning first=195 second=218 amount=-1
kerning first=1061 second=1108 amount=-2
kerning first=203 second=333 amount=-1
kerning first=372 second=227 amount=-2
kerning first=960 second=966 amount=-1
kerning first=75 second=45 amount=-1
kerning first=122 second=248 amount=-2
kerning first=372 second=351 amount=-3
kerning first=119 second=248 amount=-1
kerning first=276 second=279 amount=-1
kerning first=268 second=71 amount=-1
kerning first=356 second=111 amount=-6
kerning first=376 second=299 amount=3
kerning first=65 second=84 amount=-6
kerning first=200 second=111 amount=-1
kerning first=8222 second=81 amount=-2
kerning first=210 second=84 amount=-2
kerning first=1059 second=45 amount=-2
kerning first=8220 second=192 amount=-6
kerning first=99 second=333 amount=-1
kerning first=8222 second=86 amount=-7
kerning first=1038 second=1051 amount=-5
kerning first=916 second=45 amount=-1
kerning first=289 second=277 amount=-1
kerning first=955 second=967 amount=-2
kerning first=1074 second=1103 amount=-1
kerning first=282 second=279 amount=-1
kerning first=377 second=266 amount=-1
kerning first=1038 second=1073 amount=-2
kerning first=1038 second=1114 amount=-2
kerning first=258 second=8216 amount=-6
kerning first=1060 second=1076 amount=-3
kerning first=344 second=266 amount=-1
kerning first=8220 second=228 amount=-2
kerning first=336 second=8216 amount=-1
kerning first=1168 second=1090 amount=-3
kerning first=99 second=248 amount=-1
kerning first=373 second=248 amount=-1
kerning first=1090 second=8220 amount=1
kerning first=282 second=111 amount=-1
kerning first=228 second=8216 amount=-1
kerning first=1058 second=1138 amount=-2
kerning first=82 second=290 amount=-1
kerning first=1036 second=1060 amount=-3
kerning first=8222 second=1095 amount=-3
kerning first=8216 second=910 amount=2
kerning first=372 second=81 amount=-1
kerning first=374 second=250 amount=-3
kerning first=344 second=248 amount=-1
kerning first=347 second=118 amount=-1
kerning first=377 second=248 amount=-1
kerning first=312 second=225 amount=-1
kerning first=274 second=101 amount=-1
kerning first=194 second=67 amount=-1
kerning first=354 second=268 amount=-1
kerning first=44 second=902 amount=1
kerning first=1054 second=1032 amount=-1
kerning first=75 second=331 amount=-1
kerning first=286 second=118 amount=-1
kerning first=258 second=81 amount=-1
kerning first=221 second=110 amount=-3
kerning first=221 second=228 amount=-5
kerning first=376 second=287 amount=-5
kerning first=107 second=281 amount=-2
kerning first=201 second=268 amount=-1
kerning first=262 second=8217 amount=3
kerning first=79 second=86 amount=-1
kerning first=47 second=337 amount=-1
kerning first=276 second=350 amount=-1
kerning first=372 second=253 amount=-2
kerning first=103 second=230 amount=-1
kerning first=46 second=199 amount=-1
kerning first=280 second=196 amount=-1
kerning first=228 second=253 amount=-1
kerning first=1027 second=1028 amount=-2
kerning first=218 second=192 amount=-2
kerning first=220 second=196 amount=-2
kerning first=350 second=65 amount=-1
kerning first=1050 second=1057 amount=-2
kerning first=379 second=8221 amount=2
kerning first=86 second=251 amount=-2
kerning first=272 second=65 amount=-1
kerning first=266 second=8221 amount=3
kerning first=258 second=253 amount=-1
kerning first=1059 second=58 amount=-2
kerning first=120 second=8221 amount=2
kerning first=90 second=8217 amount=2
kerning first=192 second=219 amount=-1
kerning first=313 second=221 amount=-6
kerning first=221 second=336 amount=-2
kerning first=70 second=309 amount=1
kerning first=208 second=221 amount=-1
kerning first=84 second=121 amount=-3
kerning first=258 second=44 amount=1
kerning first=194 second=212 amount=-1
kerning first=1024 second=1092 amount=-2
kerning first=226 second=357 amount=-1
kerning first=356 second=380 amount=-5
kerning first=75 second=297 amount=3
kerning first=341 second=257 amount=-1
kerning first=221 second=275 amount=-5
kerning first=337 second=380 amount=-1
kerning first=8216 second=243 amount=-2
kerning first=1027 second=1111 amount=4
kerning first=102 second=97 amount=-1
kerning first=354 second=354 amount=1
kerning first=69 second=199 amount=-1
kerning first=280 second=257 amount=-1
kerning first=291 second=275 amount=-1
kerning first=274 second=229 amount=-1
kerning first=355 second=275 amount=-1
kerning first=118 second=275 amount=-1
kerning first=1082 second=1092 amount=-1
kerning first=1059 second=1100 amount=-2
kerning first=84 second=199 amount=-1
kerning first=86 second=263 amount=-4
kerning first=243 second=120 amount=-1
kerning first=293 second=120 amount=-1
kerning first=196 second=357 amount=-2
kerning first=256 second=79 amount=-1
kerning first=118 second=291 amount=-1
kerning first=8222 second=214 amount=-2
kerning first=381 second=234 amount=-1
kerning first=8217 second=113 amount=-5
kerning first=342 second=234 amount=-1
kerning first=253 second=246 amount=-1
kerning first=345 second=267 amount=-1
kerning first=114 second=339 amount=-1
kerning first=101 second=122 amount=-1
kerning first=1105 second=1078 amount=-1
kerning first=201 second=243 amount=-1
kerning first=231 second=246 amount=-1
kerning first=1059 second=1044 amount=-5
kerning first=1024 second=1141 amount=-1
kerning first=310 second=217 amount=-1
kerning first=310 second=246 amount=-1
kerning first=918 second=908 amount=-1
kerning first=221 second=258 amount=-5
kerning first=70 second=267 amount=-1
kerning first=380 second=113 amount=-2
kerning first=958 second=943 amount=-1
kerning first=959 second=44 amount=-1
kerning first=382 second=243 amount=-2
kerning first=87 second=97 amount=-2
kerning first=1069 second=44 amount=-1
kerning first=80 second=226 amount=-2
kerning first=311 second=335 amount=-2
kerning first=282 second=288 amount=-1
kerning first=89 second=346 amount=-1
kerning first=89 second=223 amount=-3
kerning first=354 second=243 amount=-6
kerning first=379 second=286 amount=-1
kerning first=89 second=262 amount=-2
kerning first=221 second=291 amount=-5
kerning first=1091 second=8220 amount=1
kerning first=194 second=219 amount=-1
kerning first=338 second=194 amount=-1
kerning first=332 second=379 amount=-1
kerning first=1046 second=1026 amount=-1
kerning first=202 second=113 amount=-1
kerning first=89 second=194 amount=-5
kerning first=266 second=286 amount=-1
kerning first=280 second=348 amount=-1
kerning first=377 second=100 amount=-1
kerning first=1050 second=1035 amount=-1
kerning first=256 second=211 amount=-1
kerning first=8220 second=259 amount=-2
kerning first=1040 second=1118 amount=-2
kerning first=1061 second=1118 amount=-2
kerning first=343 second=233 amount=-1
kerning first=8220 second=239 amount=2
kerning first=1118 second=44 amount=-2
kerning first=345 second=244 amount=-1
kerning first=310 second=364 amount=-1
kerning first=1059 second=46 amount=-7
kerning first=375 second=235 amount=-1
kerning first=354 second=288 amount=-2
kerning first=201 second=288 amount=-1
kerning first=8216 second=308 amount=-3
kerning first=8221 second=297 amount=2
kerning first=246 second=44 amount=-1
kerning first=111 second=382 amount=-1
kerning first=375 second=44 amount=-5
kerning first=191 second=356 amount=-4
kerning first=310 second=262 amount=-3
kerning first=1106 second=1084 amount=-1
kerning first=220 second=256 amount=-2
kerning first=8221 second=305 amount=-2
kerning first=280 second=256 amount=-1
kerning first=197 second=375 amount=-1
kerning first=75 second=347 amount=-1
kerning first=70 second=244 amount=-1
kerning first=68 second=46 amount=-2
kerning first=311 second=269 amount=-2
kerning first=8218 second=360 amount=-1
kerning first=110 second=8220 amount=-1
kerning first=82 second=212 amount=-1
kerning first=272 second=44 amount=-2
kerning first=954 second=972 amount=-2
kerning first=375 second=257 amount=-1
kerning first=67 second=212 amount=-1
kerning first=343 second=226 amount=-1
kerning first=1050 second=1104 amount=-1
kerning first=256 second=85 amount=-1
kerning first=1116 second=1104 amount=-1
kerning first=1033 second=1038 amount=-3
kerning first=374 second=229 amount=-5
kerning first=1118 second=1076 amount=-3
kerning first=1029 second=1141 amount=-1
kerning first=120 second=265 amount=-2
kerning first=70 second=332 amount=-1
kerning first=274 second=259 amount=-1
kerning first=313 second=210 amount=-2
kerning first=119 second=229 amount=-1
kerning first=345 second=226 amount=-1
kerning first=200 second=195 amount=-1
kerning first=356 second=195 amount=-6
kerning first=356 second=328 amount=-4
kerning first=1082 second=1105 amount=-1
kerning first=86 second=193 amount=-3
kerning first=373 second=100 amount=-1
kerning first=221 second=338 amount=-2
kerning first=1027 second=1101 amount=-5
kerning first=282 second=263 amount=-1
kerning first=340 second=354 amount=-1
kerning first=378 second=283 amount=-2
kerning first=212 second=193 amount=-1
kerning first=87 second=289 amount=-2
kerning first=202 second=225 amount=-1
kerning first=276 second=277 amount=-1
kerning first=344 second=213 amount=-1
kerning first=276 second=245 amount=-1
kerning first=221 second=59 amount=-5
kerning first=370 second=193 amount=-2
kerning first=8218 second=332 amount=-2
kerning first=341 second=230 amount=-1
kerning first=1107 second=1108 amount=-1
kerning first=341 second=285 amount=-1
kerning first=308 second=193 amount=-1
kerning first=210 second=89 amount=-2
kerning first=8218 second=119 amount=-5
kerning first=1093 second=1108 amount=-1
kerning first=354 second=198 amount=-6
kerning first=345 second=103 amount=-1
kerning first=276 second=99 amount=-1
kerning first=75 second=350 amount=-1
kerning first=280 second=230 amount=-1
kerning first=284 second=89 amount=-1
kerning first=354 second=363 amount=-4
kerning first=200 second=338 amount=-1
kerning first=378 second=353 amount=-1
kerning first=376 second=369 amount=-3
kerning first=379 second=333 amount=-1
kerning first=102 second=8217 amount=4
kerning first=1058 second=1094 amount=-5
kerning first=8217 second=232 amount=-5
kerning first=313 second=290 amount=-2
kerning first=200 second=283 amount=-1
kerning first=80 second=234 amount=-1
kerning first=348 second=197 amount=-1
kerning first=1038 second=1029 amount=-1
kerning first=1024 second=1076 amount=-1
kerning first=356 second=299 amount=3
kerning first=380 second=232 amount=-2
kerning first=1040 second=63 amount=-2
kerning first=356 second=283 amount=-6
kerning first=195 second=8217 amount=-3
kerning first=97 second=121 amount=-1
kerning first=8221 second=277 amount=-5
kerning first=217 second=74 amount=-1
kerning first=244 second=378 amount=-1
kerning first=349 second=121 amount=-1
kerning first=46 second=362 amount=-1
kerning first=935 second=45 amount=-4
kerning first=8221 second=99 amount=-5
kerning first=202 second=232 amount=-1
kerning first=82 second=87 amount=-1
kerning first=120 second=333 amount=-2
kerning first=378 second=228 amount=-1
kerning first=8222 second=284 amount=-1
kerning first=196 second=266 amount=-1
kerning first=1032 second=44 amount=-1
kerning first=68 second=374 amount=-1
kerning first=356 second=353 amount=-5
kerning first=88 second=45 amount=-3
kerning first=308 second=260 amount=-1
kerning first=98 second=102 amount=-1
kerning first=1123 second=1090 amount=-3
kerning first=1044 second=1098 amount=-1
kerning first=355 second=224 amount=-1
kerning first=69 second=336 amount=-1
kerning first=8217 second=213 amount=-3
kerning first=372 second=242 amount=-3
kerning first=374 second=355 amount=-2
kerning first=84 second=84 amount=1
kerning first=198 second=213 amount=-1
kerning first=82 second=376 amount=-1
kerning first=1034 second=1059 amount=-3
kerning first=200 second=228 amount=-1
kerning first=66 second=355 amount=-1
kerning first=379 second=264 amount=-1
kerning first=356 second=228 amount=-6
kerning first=84 second=343 amount=-4
kerning first=1097 second=1098 amount=-1
kerning first=88 second=246 amount=-1
kerning first=338 second=193 amount=-1
kerning first=107 second=228 amount=-1
kerning first=116 second=246 amount=-1
kerning first=8222 second=362 amount=-1
kerning first=282 second=67 amount=-1
kerning first=202 second=248 amount=-1
kerning first=260 second=217 amount=-1
kerning first=354 second=8217 amount=2
kerning first=193 second=355 amount=-2
kerning first=282 second=234 amount=-1
kerning first=338 second=246 amount=-1
kerning first=88 second=277 amount=-1
kerning first=89 second=246 amount=-5
kerning first=310 second=284 amount=-3
kerning first=373 second=113 amount=-1
kerning first=338 second=263 amount=-1
kerning first=349 second=355 amount=-1
kerning first=382 second=283 amount=-2
kerning first=272 second=196 amount=-1
kerning first=82 second=89 amount=-1
kerning first=350 second=196 amount=-1
kerning first=199 second=79 amount=-1
kerning first=311 second=224 amount=-1
kerning first=203 second=97 amount=-1
kerning first=331 second=121 amount=-1
kerning first=377 second=113 amount=-1
kerning first=221 second=47 amount=-4
kerning first=197 second=368 amount=-1
kerning first=8216 second=214 amount=-1
kerning first=65 second=213 amount=-1
kerning first=291 second=47 amount=3
kerning first=239 second=8217 amount=2
kerning first=1058 second=1033 amount=-4
kerning first=83 second=194 amount=-1
kerning first=377 second=121 amount=-1
kerning first=255 second=267 amount=-1
kerning first=342 second=279 amount=-1
kerning first=89 second=227 amount=-5
kerning first=381 second=279 amount=-1
kerning first=381 second=232 amount=-1
kerning first=87 second=351 amount=-3
kerning first=276 second=71 amount=-1
kerning first=338 second=227 amount=-1
kerning first=1060 second=1051 amount=-3
kerning first=274 second=352 amount=-1
kerning first=46 second=86 amount=-5
kerning first=356 second=345 amount=-4
kerning first=234 second=378 amount=-1
kerning first=8222 second=84 amount=-7
kerning first=194 second=84 amount=-6
kerning first=8217 second=225 amount=-5
kerning first=75 second=367 amount=-1
kerning first=311 second=115 amount=-1
kerning first=213 second=84 amount=-2
kerning first=354 second=214 amount=-2
kerning first=8260 second=179 amount=-6
kerning first=378 second=97 amount=-1
kerning first=315 second=219 amount=-2
kerning first=263 second=243 amount=-1
kerning first=276 second=231 amount=-1
kerning first=102 second=228 amount=-1
kerning first=1056 second=1058 amount=-1
kerning first=88 second=334 amount=-2
kerning first=201 second=260 amount=-1
kerning first=201 second=214 amount=-1
kerning first=380 second=225 amount=-1
kerning first=86 second=111 amount=-3
kerning first=200 second=230 amount=-1
kerning first=278 second=81 amount=-1
kerning first=354 second=118 amount=-3
kerning first=1054 second=1063 amount=-1
kerning first=287 second=227 amount=-1
kerning first=1046 second=1077 amount=-1
kerning first=119 second=339 amount=-1
kerning first=8222 second=336 amount=-2
kerning first=356 second=260 amount=-6
kerning first=312 second=100 amount=-2
kerning first=200 second=260 amount=-1
kerning first=47 second=235 amount=-1
kerning first=1076 second=8220 amount=-1
kerning first=376 second=263 amount=-6
kerning first=258 second=364 amount=-1
kerning first=111 second=120 amount=-1
kerning first=201 second=118 amount=-2
kerning first=221 second=244 amount=-5
kerning first=192 second=354 amount=-6
kerning first=343 second=245 amount=-1
kerning first=370 second=74 amount=-1
kerning first=376 second=195 amount=-5
kerning first=278 second=253 amount=-2
kerning first=346 second=192 amount=-1
kerning first=1076 second=1086 amount=-1
kerning first=1043 second=1108 amount=-7
kerning first=378 second=281 amount=-2
kerning first=260 second=284 amount=-1
kerning first=8217 second=97 amount=-5
kerning first=381 second=113 amount=-1
kerning first=103 second=235 amount=-1
kerning first=213 second=196 amount=-1
kerning first=195 second=288 amount=-1
kerning first=220 second=65 amount=-2
kerning first=1043 second=1082 amount=-5
kerning first=342 second=376 amount=-1
kerning first=69 second=233 amount=-1
kerning first=196 second=219 amount=-1
kerning first=121 second=337 amount=-1
kerning first=293 second=255 amount=-1
kerning first=8217 second=266 amount=-2
kerning first=8218 second=1091 amount=-3
kerning first=86 second=260 amount=-3
kerning first=356 second=281 amount=-6
kerning first=280 second=65 amount=-1
kerning first=346 second=255 amount=-1
kerning first=1046 second=1091 amount=-2
kerning first=88 second=290 amount=-2
kerning first=276 second=258 amount=-1
kerning first=274 second=357 amount=-1
kerning first=90 second=281 amount=-1
kerning first=282 second=212 amount=-1
kerning first=1169 second=225 amount=-2
kerning first=47 second=257 amount=-1
kerning first=329 second=375 amount=-1
kerning first=269 second=257 amount=-1
kerning first=114 second=231 amount=-1
kerning first=74 second=256 amount=-1
kerning first=122 second=233 amount=-2
kerning first=1076 second=59 amount=2
kerning first=8218 second=284 amount=-1
kerning first=313 second=360 amount=-2
kerning first=8221 second=331 amount=-2
kerning first=8217 second=229 amount=-5
kerning first=270 second=90 amount=-1
kerning first=8222 second=194 amount=1
kerning first=208 second=258 amount=-1
kerning first=338 second=277 amount=-1
kerning first=211 second=354 amount=-2
kerning first=75 second=341 amount=-1
kerning first=1027 second=1080 amount=-5
kerning first=289 second=8217 amount=3
kerning first=342 second=283 amount=-1
kerning first=8217 second=110 amount=-2
kerning first=381 second=333 amount=-1
kerning first=1065 second=1058 amount=-1
kerning first=922 second=959 amount=-2
kerning first=195 second=79 amount=-1
kerning first=344 second=232 amount=-1
kerning first=88 second=100 amount=-2
kerning first=211 second=308 amount=-1
kerning first=377 second=232 amount=-1
kerning first=368 second=308 amount=-1
kerning first=87 second=264 amount=-1
kerning first=195 second=264 amount=-1
kerning first=231 second=339 amount=-1
kerning first=76 second=366 amount=-2
kerning first=351 second=102 amount=-1
kerning first=192 second=308 amount=1
kerning first=1026 second=1059 amount=-4
kerning first=267 second=225 amount=-1
kerning first=198 second=375 amount=-2
kerning first=335 second=380 amount=-1
kerning first=1045 second=1104 amount=-2
kerning first=102 second=102 amount=-1
kerning first=122 second=226 amount=-1
kerning first=1078 second=1108 amount=-1
kerning first=342 second=262 amount=-1
kerning first=313 second=332 amount=-2
kerning first=1075 second=226 amount=-2
kerning first=362 second=258 amount=-2
kerning first=1060 second=1026 amount=-3
kerning first=258 second=286 amount=-1
kerning first=253 second=339 amount=-1
kerning first=370 second=256 amount=-2
kerning first=372 second=286 amount=-1
kerning first=340 second=243 amount=-1
kerning first=90 second=243 amount=-1
kerning first=8216 second=291 amount=-3
kerning first=8218 second=258 amount=1
kerning first=69 second=194 amount=-1
kerning first=354 second=291 amount=-5
kerning first=120 second=230 amount=-1
kerning first=84 second=194 amount=-6
kerning first=377 second=238 amount=1
kerning first=8218 second=1044 amount=1
kerning first=275 second=380 amount=-1
kerning first=380 second=100 amount=-2
kerning first=253 second=245 amount=-1
kerning first=1050 second=1139 amount=-1
kerning first=382 second=291 amount=-1
kerning first=276 second=211 amount=-1
kerning first=341 second=235 amount=-1
kerning first=343 second=285 amount=-1
kerning first=374 second=114 amount=-3
kerning first=202 second=100 amount=-1
kerning first=278 second=262 amount=-1
kerning first=1045 second=1035 amount=-1
kerning first=282 second=83 amount=-1
kerning first=255 second=244 amount=-1
kerning first=1068 second=1038 amount=-3
kerning first=101 second=120 amount=-1
kerning first=1079 second=1098 amount=-1
kerning first=255 second=275 amount=-1
kerning first=274 second=97 amount=-1
kerning first=973 second=941 amount=-1
kerning first=254 second=120 amount=-1
kerning first=90 second=288 amount=-1
kerning first=1107 second=1077 amount=-1
kerning first=340 second=288 amount=-1
kerning first=289 second=44 amount=1
kerning first=262 second=288 amount=-1
kerning first=943 second=967 amount=-1
kerning first=8217 second=100 amount=-5
kerning first=1050 second=1089 amount=-2
kerning first=343 second=8220 amount=1
kerning first=329 second=116 amount=-1
kerning first=372 second=333 amount=-3
kerning first=1116 second=1089 amount=-1
kerning first=235 second=8220 amount=-1
kerning first=231 second=227 amount=-1
kerning first=354 second=269 amount=-6
kerning first=902 second=8220 amount=-6
kerning first=338 second=266 amount=-1
kerning first=201 second=269 amount=-1
kerning first=345 second=45 amount=-2
kerning first=47 second=178 amount=-9
kerning first=262 second=214 amount=-1
kerning first=219 second=197 amount=-2
kerning first=86 second=239 amount=3
kerning first=79 second=8220 amount=-1
kerning first=382 second=269 amount=-2
kerning first=259 second=116 amount=-1
kerning first=340 second=214 amount=-1
kerning first=8217 second=121 amount=-2
kerning first=276 second=197 amount=-1
kerning first=90 second=214 amount=-1
kerning first=310 second=227 amount=-1
kerning first=1094 second=1141 amount=-1
kerning first=373 second=225 amount=-1
kerning first=379 second=242 amount=-1
kerning first=221 second=290 amount=-2
kerning first=1038 second=1060 amount=-2
kerning first=1059 second=1083 amount=-6
kerning first=1027 second=187 amount=-2
kerning first=376 second=328 amount=-3
kerning first=265 second=242 amount=-1
kerning first=70 second=210 amount=-1
kerning first=200 second=346 amount=-1
kerning first=8221 second=71 amount=-3
kerning first=929 second=972 amount=-1
kerning first=253 second=227 amount=-1
kerning first=99 second=225 amount=-1
kerning first=120 second=242 amount=-2
kerning first=192 second=268 amount=-1
kerning first=192 second=338 amount=-1
kerning first=375 second=230 amount=-1
kerning first=191 second=84 amount=-4
kerning first=310 second=81 amount=-3
kerning first=941 second=952 amount=-1
kerning first=1058 second=1098 amount=-5
kerning first=75 second=334 amount=-3
kerning first=8216 second=269 amount=-3
kerning first=8221 second=378 amount=-1
kerning first=75 second=245 amount=-1
kerning first=202 second=193 amount=-1
kerning first=338 second=284 amount=-1
kerning first=967 second=974 amount=-1
kerning first=313 second=372 amount=-4
kerning first=8221 second=197 amount=-9
kerning first=120 second=234 amount=-2
kerning first=1024 second=1051 amount=-1
kerning first=342 second=67 amount=-1
kerning first=315 second=89 amount=-6
kerning first=381 second=67 amount=-1
kerning first=285 second=103 amount=1
kerning first=262 second=198 amount=1
kerning first=1025 second=1138 amount=-2
kerning first=75 second=277 amount=-1
kerning first=1065 second=1090 amount=-1
kerning first=972 second=955 amount=-1
kerning first=83 second=8216 amount=-1
kerning first=1107 second=1139 amount=-1
kerning first=958 second=953 amount=-1
kerning first=87 second=335 amount=-3
kerning first=107 second=244 amount=-2
kerning first=198 second=196 amount=-1
kerning first=104 second=8216 amount=-1
kerning first=281 second=382 amount=-1
kerning first=380 second=121 amount=-1
kerning first=80 second=196 amount=-5
kerning first=1062 second=1139 amount=-1
kerning first=86 second=369 amount=-2
kerning first=213 second=87 amount=-1
kerning first=1043 second=1032 amount=-3
kerning first=202 second=121 amount=-2
kerning first=1060 second=1047 amount=-1
kerning first=194 second=87 amount=-3
kerning first=373 second=232 amount=-1
kerning first=313 second=119 amount=-3
kerning first=1043 second=1104 amount=-7
kerning first=195 second=74 amount=1
kerning first=107 second=283 amount=-2
kerning first=324 second=375 amount=-1
kerning first=214 second=74 amount=-1
kerning first=221 second=363 amount=-3
kerning first=354 second=74 amount=-2
kerning first=103 second=289 amount=1
kerning first=1097 second=1104 amount=-1
kerning first=8216 second=374 amount=2
kerning first=8220 second=198 amount=-7
kerning first=201 second=99 amount=-1
kerning first=380 second=373 amount=-1
kerning first=45 second=121 amount=-1
kerning first=326 second=121 amount=-1
kerning first=280 second=193 amount=-1
kerning first=8217 second=373 amount=-1
kerning first=354 second=99 amount=-6
kerning first=87 second=242 amount=-3
kerning first=90 second=232 amount=-1
kerning first=88 second=245 amount=-1
kerning first=374 second=269 amount=-6
kerning first=223 second=120 amount=-1
kerning first=347 second=375 amount=-1
kerning first=1082 second=1108 amount=-1
kerning first=287 second=285 amount=1
kerning first=102 second=242 amount=-2
kerning first=220 second=193 amount=-2
kerning first=312 second=117 amount=-1
kerning first=76 second=356 amount=-5
kerning first=202 second=373 amount=-1
kerning first=336 second=197 amount=-1
kerning first=47 second=289 amount=-1
kerning first=340 second=232 amount=-1
kerning first=119 second=269 amount=-1
kerning first=8218 second=372 amount=-5
kerning first=310 second=353 amount=-1
kerning first=372 second=109 amount=-2
kerning first=335 second=378 amount=-1
kerning first=286 second=375 amount=-1
kerning first=114 second=335 amount=-1
kerning first=192 second=375 amount=-1
kerning first=1138 second=1083 amount=-1
kerning first=221 second=346 amount=-1
kerning first=920 second=8220 amount=-1
kerning first=70 second=301 amount=1
kerning first=312 second=283 amount=-2
kerning first=79 second=258 amount=-1
kerning first=253 second=353 amount=-1
kerning first=99 second=229 amount=-1
kerning first=46 second=368 amount=-1
kerning first=193 second=210 amount=-1
kerning first=372 second=197 amount=-3
kerning first=1025 second=1063 amount=-1
kerning first=258 second=213 amount=-1
kerning first=328 second=8216 amount=-1
kerning first=118 second=8216 amount=1
kerning first=208 second=46 amount=-2
kerning first=8220 second=46 amount=-7
kerning first=253 second=122 amount=-1
kerning first=957 second=955 amount=-1
kerning first=89 second=97 amount=-5
kerning first=8222 second=376 amount=-8
kerning first=376 second=284 amount=-2
kerning first=1034 second=8216 amount=-3
kerning first=82 second=284 amount=-1
kerning first=104 second=355 amount=-1
kerning first=90 second=297 amount=4
kerning first=221 second=378 amount=-4
kerning first=47 second=224 amount=-1
kerning first=1086 second=8216 amount=-1
kerning first=8218 second=268 amount=-1
kerning first=67 second=284 amount=-1
kerning first=343 second=243 amount=-1
kerning first=194 second=89 amount=-5
kerning first=344 second=286 amount=-1
kerning first=82 second=121 amount=-1
kerning first=1044 second=1063 amount=-1
kerning first=213 second=89 amount=-2
kerning first=198 second=228 amount=-1
kerning first=290 second=89 amount=-1
kerning first=376 second=121 amount=-2
kerning first=8216 second=333 amount=-2
kerning first=223 second=378 amount=-1
kerning first=356 second=277 amount=-6
kerning first=89 second=336 amount=-2
kerning first=75 second=370 amount=-1
kerning first=200 second=277 amount=-1
kerning first=338 second=336 amount=-1
kerning first=373 second=115 amount=-1
kerning first=1093 second=1139 amount=-1
kerning first=255 second=234 amount=-1
kerning first=260 second=336 amount=-1
kerning first=1138 second=1032 amount=-1
kerning first=932 second=932 amount=1
kerning first=90 second=262 amount=-1
kerning first=71 second=255 amount=-1
kerning first=103 second=224 amount=-1
kerning first=340 second=262 amount=-1
kerning first=289 second=228 amount=-1
kerning first=1058 second=1085 amount=-5
kerning first=360 second=195 amount=-2
kerning first=258 second=360 amount=-1
kerning first=255 second=279 amount=-1
kerning first=84 second=47 amount=-4
kerning first=84 second=111 amount=-6
kerning first=8221 second=299 amount=2
kerning first=8221 second=308 amount=-5
kerning first=256 second=84 amount=-6
kerning first=932 second=920 amount=-2
kerning first=334 second=84 amount=-2
kerning first=311 second=351 amount=-1
kerning first=374 second=233 amount=-5
kerning first=1027 second=1105 amount=-7
kerning first=8218 second=288 amount=-1
kerning first=343 second=351 amount=-1
kerning first=231 second=248 amount=-1
kerning first=8222 second=192 amount=1
kerning first=69 second=111 amount=-1
kerning first=221 second=337 amount=-5
kerning first=253 second=248 amount=-1
kerning first=119 second=233 amount=-1
kerning first=310 second=248 amount=-1
kerning first=214 second=90 amount=-1
kerning first=8220 second=244 amount=-2
kerning first=121 second=285 amount=-1
kerning first=258 second=217 amount=-1
kerning first=229 second=355 amount=-1
kerning first=198 second=267 amount=-1
kerning first=1024 second=1077 amount=-2
kerning first=8222 second=255 amount=-5
kerning first=287 second=233 amount=-1
kerning first=276 second=227 amount=-1
kerning first=76 second=370 amount=-2
kerning first=258 second=8221 amount=-3
kerning first=256 second=118 amount=-1
kerning first=1075 second=225 amount=-2
kerning first=75 second=231 amount=-1
kerning first=261 second=8216 amount=-1
kerning first=313 second=214 amount=-2
kerning first=260 second=8217 amount=-3
kerning first=364 second=197 amount=-2
kerning first=352 second=253 amount=-1
kerning first=8217 second=286 amount=-3
kerning first=122 second=225 amount=-1
kerning first=89 second=8217 amount=1
kerning first=8221 second=227 amount=-5
kerning first=278 second=281 amount=-1
kerning first=1059 second=1073 amount=-2
kerning first=8216 second=65 amount=-6
kerning first=329 second=120 amount=-1
kerning first=87 second=74 amount=-3
kerning first=381 second=101 amount=-1
kerning first=221 second=309 amount=2
kerning first=342 second=101 amount=-1
kerning first=274 second=334 amount=-1
kerning first=193 second=81 amount=-1
kerning first=335 second=382 amount=-1
kerning first=69 second=225 amount=-1
kerning first=377 second=288 amount=-1
kerning first=114 second=281 amount=-1
kerning first=310 second=253 amount=-3
kerning first=364 second=192 amount=-2
kerning first=79 second=192 amount=-1
kerning first=1038 second=1033 amount=-5
kerning first=86 second=230 amount=-4
kerning first=70 second=71 amount=-1
kerning first=355 second=337 amount=-1
kerning first=8217 second=345 amount=-2
kerning first=121 second=275 amount=-1
kerning first=310 second=332 amount=-3
kerning first=122 second=111 amount=-2
kerning first=354 second=65 amount=-6
kerning first=272 second=197 amount=-1
kerning first=116 second=8217 amount=2
kerning first=310 second=234 amount=-1
kerning first=201 second=65 amount=-1
kerning first=310 second=281 amount=-1
kerning first=375 second=226 amount=-1
kerning first=260 second=253 amount=-1
kerning first=84 second=335 amount=-6
kerning first=274 second=286 amount=-1
kerning first=338 second=253 amount=-2
kerning first=344 second=275 amount=-1
kerning first=89 second=253 amount=-2
kerning first=65 second=370 amount=-1
kerning first=310 second=348 amount=-1
kerning first=102 second=283 amount=-2
kerning first=1025 second=1086 amount=-1
kerning first=342 second=221 amount=-1
kerning first=253 second=281 amount=-1
kerning first=373 second=233 amount=-1
kerning first=375 second=267 amount=-1
kerning first=226 second=120 amount=-1
kerning first=338 second=243 amount=-1
kerning first=256 second=214 amount=-1
kerning first=8218 second=211 amount=-2
kerning first=86 second=58 amount=-3
kerning first=116 second=97 amount=-1
kerning first=354 second=331 amount=-4
kerning first=374 second=8221 amount=1
kerning first=87 second=301 amount=2
kerning first=287 second=97 amount=-1
kerning first=86 second=250 amount=-2
kerning first=356 second=229 amount=-6
kerning first=255 second=337 amount=-1
kerning first=198 second=199 amount=-1
kerning first=953 second=964 amount=-1
kerning first=8217 second=902 amount=-9
kerning first=87 second=265 amount=-3
kerning first=338 second=97 amount=-1
kerning first=378 second=347 amount=-1
kerning first=282 second=339 amount=-1
kerning first=116 second=248 amount=-1
kerning first=372 second=100 amount=-3
kerning first=950 second=974 amount=-2
kerning first=374 second=111 amount=-5
kerning first=84 second=264 amount=-1
kerning first=1058 second=1102 amount=-5
kerning first=1058 second=1119 amount=-5
kerning first=378 second=246 amount=-2
kerning first=382 second=99 amount=-2
kerning first=69 second=264 amount=-1
kerning first=89 second=248 amount=-5
kerning first=1036 second=1098 amount=-2
kerning first=372 second=234 amount=-3
kerning first=79 second=308 amount=-1
kerning first=1058 second=1040 amount=-4
kerning first=86 second=196 amount=-3
kerning first=364 second=308 amount=-1
kerning first=46 second=370 amount=-1
kerning first=1058 second=1078 amount=-4
kerning first=354 second=326 amount=-4
kerning first=374 second=243 amount=-5
kerning first=356 second=246 amount=-6
kerning first=200 second=246 amount=-1
kerning first=338 second=248 amount=-1
kerning first=194 second=220 amount=-1
kerning first=356 second=343 amount=-4
kerning first=265 second=245 amount=-1
kerning first=8222 second=308 amount=1
kerning first=87 second=234 amount=-3
kerning first=102 second=113 amount=-2
kerning first=8216 second=908 amount=-1
kerning first=8216 second=238 amount=3
kerning first=102 second=234 amount=-2
kerning first=87 second=113 amount=-3
kerning first=198 second=335 amount=-1
kerning first=223 second=122 amount=-1
kerning first=354 second=338 amount=-2
kerning first=107 second=235 amount=-2
kerning first=310 second=100 amount=-1
kerning first=276 second=194 amount=-1
kerning first=362 second=46 amount=-1
kerning first=219 second=194 amount=-2
kerning first=70 second=46 amount=-7
kerning first=376 second=58 amount=-5
kerning first=1046 second=1035 amount=-1
kerning first=382 second=8217 amount=1
kerning first=69 second=67 amount=-1
kerning first=375 second=382 amount=-1
kerning first=345 second=46 amount=-7
kerning first=84 second=67 amount=-1
kerning first=1100 second=8220 amount=-4
kerning first=246 second=382 amount=-1
kerning first=1060 second=1059 amount=-1
kerning first=1079 second=1076 amount=-1
kerning first=351 second=116 amount=-1
kerning first=1046 second=1057 amount=-2
kerning first=356 second=347 amount=-5
kerning first=198 second=244 amount=-1
kerning first=65 second=44 amount=1
kerning first=310 second=288 amount=-3
kerning first=80 second=244 amount=-1
kerning first=341 second=8220 amount=1
kerning first=210 second=44 amount=-2
kerning first=272 second=8216 amount=-1
kerning first=80 second=381 amount=-1
kerning first=234 second=8220 amount=-1
kerning first=8216 second=338 amount=-1
kerning first=225 second=116 amount=-1
kerning first=354 second=262 amount=-1
kerning first=84 second=269 amount=-6
kerning first=8217 second=210 amount=-3
kerning first=69 second=269 amount=-1
kerning first=217 second=256 amount=-2
kerning first=203 second=256 amount=-1
kerning first=1043 second=1089 amount=-8
kerning first=285 second=257 amount=-1
kerning first=374 second=117 amount=-3
kerning first=66 second=377 amount=-1
kerning first=313 second=286 amount=-2
kerning first=1060 second=1063 amount=-1
kerning first=255 second=349 amount=-1
kerning first=122 second=263 amount=-2
kerning first=1168 second=1088 amount=-5
kerning first=8221 second=345 amount=-2
kerning first=193 second=85 amount=-1
kerning first=45 second=89 amount=-4
kerning first=84 second=378 amount=-5
kerning first=69 second=263 amount=-1
kerning first=8222 second=368 amount=-1
kerning first=80 second=195 amount=-5
kerning first=253 second=100 amount=-1
kerning first=199 second=290 amount=-1
kerning first=84 second=263 amount=-6
kerning first=278 second=352 amount=-1
kerning first=75 second=357 amount=-1
kerning first=1094 second=1093 amount=1
kerning first=122 second=269 amount=-2
kerning first=1042 second=1040 amount=-1
kerning first=202 second=229 amount=-1
kerning first=1086 second=1113 amount=-1
kerning first=282 second=213 amount=-1
kerning first=68 second=354 amount=-1
kerning first=202 second=210 amount=-1
kerning first=380 second=229 amount=-1
kerning first=89 second=365 amount=-3
kerning first=923 second=8221 amount=-3
kerning first=198 second=195 amount=-1
kerning first=272 second=8220 amount=-1
kerning first=233 second=8220 amount=-1
kerning first=202 second=81 amount=-1
kerning first=102 second=111 amount=-2
kerning first=350 second=8220 amount=-1
kerning first=75 second=299 amount=1
kerning first=76 second=8220 amount=-7
kerning first=8217 second=117 amount=-2
kerning first=374 second=225 amount=-5
kerning first=288 second=376 amount=-1
kerning first=1034 second=1038 amount=-3
kerning first=87 second=338 amount=-1
kerning first=380 second=8221 amount=1
kerning first=235 second=378 amount=-1
kerning first=374 second=283 amount=-5
kerning first=195 second=338 amount=-1
kerning first=76 second=336 amount=-2
kerning first=87 second=341 amount=-2
kerning first=200 second=211 amount=-1
kerning first=315 second=211 amount=-2
kerning first=47 second=198 amount=-2
kerning first=197 second=290 amount=-1
kerning first=69 second=350 amount=-1
kerning first=380 second=115 amount=-1
kerning first=282 second=230 amount=-1
kerning first=212 second=87 amount=-1
kerning first=200 second=83 amount=-1
kerning first=69 second=246 amount=-1
kerning first=8217 second=932 amount=1
kerning first=109 second=8220 amount=-1
kerning first=69 second=255 amount=-2
kerning first=356 second=211 amount=-2
kerning first=81 second=376 amount=-2
kerning first=107 second=225 amount=-1
kerning first=82 second=84 amount=-1
kerning first=203 second=259 amount=-1
kerning first=114 second=103 amount=-1
kerning first=8220 second=97 amount=-2
kerning first=68 second=198 amount=-1
kerning first=955 second=947 amount=-2
kerning first=350 second=193 amount=-1
kerning first=210 second=87 amount=-1
kerning first=102 second=232 amount=-2
kerning first=368 second=74 amount=-1
kerning first=372 second=335 amount=-3
kerning first=382 second=375 amount=-1
kerning first=8220 second=923 amount=-6
kerning first=203 second=119 amount=-1
kerning first=312 second=233 amount=-2
kerning first=224 second=121 amount=-1
kerning first=211 second=74 amount=-1
kerning first=70 second=245 amount=-1
kerning first=272 second=193 amount=-1
kerning first=311 second=99 amount=-2
kerning first=122 second=255 amount=-1
kerning first=1027 second=1079 amount=-5
kerning first=1065 second=1035 amount=-1
kerning first=354 second=375 amount=-3
kerning first=83 second=197 amount=-1
kerning first=89 second=353 amount=-4
kerning first=208 second=8216 amount=-1
kerning first=312 second=269 amount=-2
kerning first=46 second=8220 amount=-7
kerning first=201 second=375 amount=-2
kerning first=313 second=8216 amount=-7
kerning first=192 second=360 amount=-1
kerning first=288 second=373 amount=-1
kerning first=74 second=197 amount=-1
kerning first=8221 second=241 amount=-2
kerning first=340 second=374 amount=-1
kerning first=1107 second=1083 amount=-3
kerning first=116 second=113 amount=-1
kerning first=102 second=289 amount=-2
kerning first=8218 second=1051 amount=1
kerning first=374 second=210 amount=-2
kerning first=255 second=8216 amount=1
kerning first=221 second=361 amount=-3
kerning first=258 second=355 amount=-2
kerning first=340 second=234 amount=-1
kerning first=102 second=244 amount=-2
kerning first=243 second=122 amount=-1
kerning first=198 second=226 amount=-1
kerning first=278 second=213 amount=-1
kerning first=1059 second=1060 amount=-2
kerning first=105 second=8217 amount=1
kerning first=90 second=234 amount=-1
kerning first=192 second=264 amount=-1
kerning first=228 second=355 amount=-1
kerning first=107 second=267 amount=-2
kerning first=374 second=297 amount=3
kerning first=356 second=284 amount=-2
kerning first=8221 second=972 amount=-6
kerning first=1065 second=1057 amount=-1
kerning first=75 second=226 amount=-1
kerning first=949 second=963 amount=-1
kerning first=376 second=246 amount=-5
kerning first=81 second=74 amount=1
kerning first=376 second=277 amount=-5
kerning first=280 second=224 amount=-1
kerning first=82 second=246 amount=-1
kerning first=200 second=284 amount=-1
kerning first=8216 second=351 amount=-2
kerning first=107 second=231 amount=-2
kerning first=290 second=121 amount=-1
kerning first=372 second=252 amount=-2
kerning first=289 second=226 amount=-1
kerning first=194 second=121 amount=-1
kerning first=44 second=8217 amount=-5
kerning first=86 second=121 amount=-1
kerning first=84 second=97 amount=-6
kerning first=356 second=249 amount=-4
kerning first=224 second=8220 amount=-1
kerning first=69 second=97 amount=-1
kerning first=114 second=285 amount=-1
kerning first=253 second=8217 amount=2
kerning first=338 second=332 amount=-1
kerning first=280 second=79 amount=-1
kerning first=81 second=47 amount=5
kerning first=267 second=242 amount=-1
kerning first=341 second=224 amount=-1
kerning first=203 second=113 amount=-1
kerning first=8221 second=257 amount=-5
kerning first=121 second=122 amount=-1
kerning first=310 second=336 amount=-3
kerning first=375 second=228 amount=-1
kerning first=275 second=120 amount=-1
kerning first=291 second=279 amount=-1
kerning first=8217 second=115 amount=-4
kerning first=342 second=84 amount=-1
kerning first=196 second=218 amount=-1
kerning first=1058 second=1104 amount=-6
kerning first=340 second=242 amount=-1
kerning first=8221 second=118 amount=-1
kerning first=343 second=111 amount=-1
kerning first=221 second=279 amount=-5
kerning first=90 second=242 amount=-1
kerning first=1061 second=1073 amount=-1
kerning first=354 second=351 amount=-5
kerning first=114 second=248 amount=-1
kerning first=1091 second=1077 amount=-1
kerning first=82 second=338 amount=-1
kerning first=221 second=214 amount=-2
kerning first=1059 second=1095 amount=-1
kerning first=1038 second=1077 amount=-4
kerning first=103 second=233 amount=-1
kerning first=356 second=44 amount=-7
kerning first=229 second=253 amount=-1
kerning first=245 second=44 amount=-1
kerning first=261 second=355 amount=-1
kerning first=110 second=253 amount=-1
kerning first=119 second=225 amount=-1
kerning first=355 second=279 amount=-1
kerning first=276 second=118 amount=-2
kerning first=118 second=279 amount=-1
kerning first=76 second=219 amount=-2
kerning first=267 second=257 amount=-1
kerning first=193 second=336 amount=-1
kerning first=274 second=45 amount=-1
kerning first=221 second=349 amount=-4
kerning first=70 second=231 amount=-1
kerning first=87 second=256 amount=-3
kerning first=70 second=337 amount=-1
kerning first=214 second=256 amount=-1
kerning first=8220 second=238 amount=3
kerning first=343 second=263 amount=-1
kerning first=295 second=118 amount=-1
kerning first=8216 second=236 amount=3
kerning first=75 second=257 amount=-1
kerning first=345 second=337 amount=-1
kerning first=8222 second=253 amount=-5
kerning first=311 second=233 amount=-2
kerning first=1062 second=8220 amount=-1
kerning first=376 second=350 amount=-1
kerning first=8217 second=114 amount=-2
kerning first=8221 second=101 amount=-5
kerning first=345 second=231 amount=-1
kerning first=210 second=195 amount=-1
kerning first=348 second=118 amount=-1
kerning first=47 second=281 amount=-1
kerning first=366 second=195 amount=-2
kerning first=276 second=101 amount=-1
kerning first=69 second=192 amount=-1
kerning first=88 second=357 amount=-1
kerning first=374 second=345 amount=-3
kerning first=312 second=45 amount=-3
kerning first=267 second=245 amount=-1
kerning first=310 second=365 amount=-1
kerning first=114 second=100 amount=-1
kerning first=922 second=45 amount=-1
kerning first=354 second=236 amount=1
kerning first=259 second=357 amount=-1
kerning first=375 second=269 amount=-1
kerning first=46 second=102 amount=-1
kerning first=211 second=65 amount=-1
kerning first=202 second=275 amount=-1
kerning first=285 second=357 amount=-1
kerning first=368 second=65 amount=-2
kerning first=109 second=357 amount=-1
kerning first=338 second=348 amount=-1
kerning first=1168 second=1138 amount=-2
kerning first=89 second=348 amount=-1
kerning first=221 second=286 amount=-2
kerning first=1065 second=1138 amount=-1
kerning first=340 second=336 amount=-1
kerning first=71 second=253 amount=-1
kerning first=76 second=79 amount=-2
kerning first=1038 second=1092 amount=-4
kerning first=212 second=379 amount=-1
kerning first=287 second=281 amount=-1
kerning first=955 second=943 amount=-1
kerning first=89 second=281 amount=-5
kerning first=334 second=221 amount=-2
kerning first=1024 second=1098 amount=-1
kerning first=256 second=221 amount=-5
kerning first=1059 second=1139 amount=-4
kerning first=1043 second=1028 amount=-2
kerning first=8220 second=277 amount=-3
kerning first=374 second=67 amount=-2
kerning first=343 second=97 amount=-1
kerning first=196 second=63 amount=-2
kerning first=8220 second=285 amount=-3
kerning first=269 second=337 amount=-1
kerning first=70 second=196 amount=-4
kerning first=362 second=196 amount=-2
kerning first=65 second=199 amount=-1
kerning first=315 second=87 amount=-4
kerning first=83 second=119 amount=-1
kerning first=120 second=335 amount=-2
kerning first=381 second=339 amount=-1
kerning first=8221 second=291 amount=-5
kerning first=342 second=339 amount=-1
kerning first=84 second=308 amount=-2
kerning first=1113 second=1091 amount=-2
kerning first=379 second=335 amount=-1
kerning first=376 second=347 amount=-4
kerning first=373 second=285 amount=-1
kerning first=90 second=375 amount=-1
kerning first=340 second=375 amount=-1
kerning first=120 second=259 amount=-1
kerning first=194 second=253 amount=-1
kerning first=308 second=198 amount=-1
kerning first=967 second=966 amount=-1
kerning first=1074 second=1113 amount=-1
kerning first=8218 second=196 amount=1
kerning first=8222 second=264 amount=-1
kerning first=195 second=374 amount=-5
kerning first=1045 second=1054 amount=-2
kerning first=263 second=339 amount=-1
kerning first=89 second=297 amount=3
kerning first=332 second=88 amount=-2
kerning first=348 second=258 amount=-1
kerning first=81 second=93 amount=1
kerning first=68 second=258 amount=-1
kerning first=1138 second=1059 amount=-1
kerning first=278 second=100 amount=-1
kerning first=1062 second=44 amount=2
kerning first=232 second=380 amount=-1
kerning first=262 second=338 amount=-1
kerning first=1107 second=44 amount=-3
kerning first=197 second=63 amount=-2
kerning first=255 second=287 amount=-1
kerning first=373 second=229 amount=-1
kerning first=1042 second=1035 amount=-1
kerning first=89 second=105 amount=-1
kerning first=260 second=364 amount=-1
kerning first=376 second=214 amount=-2
kerning first=1045 second=1089 amount=-2
kerning first=68 second=194 amount=-1
kerning first=380 second=275 amount=-2
kerning first=348 second=194 amount=-1
kerning first=340 second=338 amount=-1
kerning first=289 second=235 amount=-1
kerning first=90 second=338 amount=-1
kerning first=331 second=120 amount=-1
kerning first=910 second=59 amount=-4
kerning first=337 second=44 amount=-1
kerning first=1118 second=8220 amount=1
kerning first=272 second=381 amount=-1
kerning first=89 second=288 amount=-2
kerning first=375 second=8220 amount=1
kerning first=234 second=382 amount=-1
kerning first=372 second=305 amount=-2
kerning first=192 second=262 amount=-1
kerning first=198 second=235 amount=-1
kerning first=376 second=83 amount=-1
kerning first=372 second=210 amount=-1
kerning first=65 second=211 amount=-1
kerning first=82 second=83 amount=-1
kerning first=80 second=235 amount=-1
kerning first=310 second=324 amount=-1
kerning first=377 second=375 amount=-1
kerning first=270 second=195 amount=-1
kerning first=68 second=8216 amount=-1
kerning first=338 second=288 amount=-1
kerning first=203 second=116 amount=-1
kerning first=260 second=288 amount=-1
kerning first=193 second=376 amount=-5
kerning first=965 second=941 amount=-1
kerning first=82 second=235 amount=-1
kerning first=212 second=376 amount=-2
kerning first=221 second=113 amount=-5
kerning first=275 second=382 amount=-1
kerning first=202 second=233 amount=-1
kerning first=102 second=259 amount=-1
kerning first=374 second=255 amount=-2
kerning first=1168 second=1080 amount=-5
kerning first=203 second=262 amount=-1
kerning first=66 second=255 amount=-1
kerning first=372 second=288 amount=-1
kerning first=115 second=382 amount=-1
kerning first=360 second=256 amount=-2
kerning first=967 second=972 amount=-1
kerning first=278 second=256 amount=-1
kerning first=8221 second=258 amount=-9
kerning first=1114 second=8220 amount=-4
kerning first=241 second=255 amount=-1
kerning first=376 second=44 amount=-9
kerning first=8216 second=74 amount=-3
kerning first=212 second=308 amount=-1
kerning first=280 second=244 amount=-1
kerning first=370 second=308 amount=-1
kerning first=1116 second=1105 amount=-1
kerning first=193 second=74 amount=1
kerning first=1062 second=1118 amount=-1
kerning first=381 second=332 amount=-1
kerning first=380 second=233 amount=-2
kerning first=324 second=8220 amount=-1
kerning first=1027 second=1139 amount=-8
kerning first=333 second=44 amount=-1
kerning first=8217 second=233 amount=-5
kerning first=341 second=244 amount=-1
kerning first=1026 second=1038 amount=-4
kerning first=1076 second=1139 amount=-1
kerning first=276 second=339 amount=-1
kerning first=914 second=935 amount=-2
kerning first=1050 second=1105 amount=-1
kerning first=8217 second=269 amount=-5
kerning first=312 second=263 amount=-2
kerning first=89 second=378 amount=-4
kerning first=79 second=377 amount=-1
kerning first=1027 second=1092 amount=-7
kerning first=185 second=8260 amount=-7
kerning first=201 second=346 amount=-1
kerning first=97 second=120 amount=-1
kerning first=279 second=120 amount=-1
kerning first=69 second=210 amount=-1
kerning first=1046 second=1066 amount=-1
kerning first=84 second=283 amount=-6
kerning first=69 second=283 amount=-1
kerning first=203 second=338 amount=-1
kerning first=1043 second=1074 amount=-5
kerning first=75 second=336 amount=-3
kerning first=198 second=258 amount=-1
kerning first=348 second=372 amount=-1
kerning first=75 second=372 amount=-1
kerning first=258 second=116 amount=-2
kerning first=227 second=357 amount=-1
kerning first=86 second=287 amount=-4
kerning first=8220 second=229 amount=-2
kerning first=1056 second=1104 amount=-1
kerning first=84 second=365 amount=-4
kerning first=107 second=226 amount=-1
kerning first=1097 second=1108 amount=-1
kerning first=376 second=211 amount=-2
kerning first=280 second=290 amount=-1
kerning first=311 second=265 amount=-2
kerning first=8221 second=212 amount=-3
kerning first=255 second=245 amount=-1
kerning first=122 second=277 amount=-2
kerning first=1086 second=1093 amount=-1
kerning first=67 second=211 amount=-1
kerning first=82 second=211 amount=-1
kerning first=221 second=99 amount=-6
kerning first=354 second=242 amount=-6
kerning first=313 second=362 amount=-2
kerning first=382 second=119 amount=-1
kerning first=340 second=277 amount=-1
kerning first=46 second=374 amount=-7
kerning first=80 second=115 amount=-1
kerning first=221 second=74 amount=-4
kerning first=355 second=99 amount=-1
kerning first=81 second=59 amount=2
kerning first=372 second=341 amount=-2
kerning first=311 second=232 amount=-2
kerning first=280 second=228 amount=-1
kerning first=301 second=8221 amount=1
kerning first=65 second=219 amount=-1
kerning first=344 second=338 amount=-1
kerning first=118 second=99 amount=-1
kerning first=312 second=275 amount=-2
kerning first=116 second=335 amount=-1
kerning first=1027 second=1085 amount=-5
kerning first=354 second=119 amount=-3
kerning first=196 second=85 amount=-1
kerning first=201 second=119 amount=-1
kerning first=374 second=373 amount=-2
kerning first=8216 second=242 amount=-2
kerning first=202 second=269 amount=-1
kerning first=89 second=335 amount=-5
kerning first=45 second=910 amount=-4
kerning first=270 second=197 amount=-1
kerning first=197 second=356 amount=-6
kerning first=1073 second=1076 amount=-2
kerning first=352 second=197 amount=-1
kerning first=8220 second=289 amount=-3
kerning first=87 second=238 amount=1
kerning first=1038 second=1076 amount=-7
kerning first=283 second=102 amount=-1
kerning first=315 second=183 amount=-2
kerning first=313 second=375 amount=-3
kerning first=338 second=335 amount=-1
kerning first=354 second=8216 amount=1
kerning first=278 second=336 amount=-1
kerning first=1044 second=1090 amount=-1
kerning first=264 second=198 amount=1
kerning first=345 second=233 amount=-1
kerning first=310 second=213 amount=-3
kerning first=311 second=333 amount=-2
kerning first=1082 second=1089 amount=-1
kerning first=920 second=8216 amount=-1
kerning first=902 second=63 amount=-2
kerning first=211 second=46 amount=-1
kerning first=114 second=8220 amount=1
kerning first=1058 second=1088 amount=-5
kerning first=116 second=229 amount=-1
kerning first=1169 second=8216 amount=1
kerning first=280 second=352 amount=-1
kerning first=1058 second=45 amount=-3
kerning first=312 second=115 amount=-1
kerning first=346 second=193 amount=-1
kerning first=1094 second=8216 amount=-1
kerning first=8220 second=333 amount=-2
kerning first=44 second=197 amount=1
kerning first=1107 second=1084 amount=-1
kerning first=341 second=228 amount=-1
kerning first=87 second=326 amount=-2
kerning first=282 second=121 amount=-2
kerning first=65 second=284 amount=-1
kerning first=214 second=87 amount=-1
kerning first=342 second=89 amount=-1
kerning first=121 second=45 amount=-2
kerning first=86 second=277 amount=-4
kerning first=343 second=103 amount=-1
kerning first=372 second=259 amount=-2
kerning first=1097 second=1090 amount=-1
kerning first=283 second=120 amount=-1
kerning first=289 second=224 amount=-1
kerning first=47 second=228 amount=-1
kerning first=297 second=318 amount=1
kerning first=372 second=336 amount=-1
kerning first=253 second=287 amount=-1
kerning first=8220 second=288 amount=-1
kerning first=258 second=336 amount=-1
kerning first=46 second=266 amount=-1
kerning first=90 second=309 amount=1
kerning first=202 second=262 amount=-1
kerning first=354 second=113 amount=-5
kerning first=310 second=355 amount=-1
kerning first=255 second=351 amount=-1
kerning first=201 second=113 amount=-1
kerning first=8218 second=71 amount=-1
kerning first=329 second=8216 amount=-1
kerning first=234 second=122 amount=-1
kerning first=87 second=192 amount=-3
kerning first=1046 second=1098 amount=-2
kerning first=8217 second=111 amount=-5
kerning first=1090 second=45 amount=-1
kerning first=338 second=213 amount=-1
kerning first=265 second=335 amount=-1
kerning first=260 second=213 amount=-1
kerning first=103 second=228 amount=-1
kerning first=8218 second=86 amount=-7
kerning first=289 second=275 amount=-1
kerning first=193 second=67 amount=-1
kerning first=46 second=81 amount=-1
kerning first=374 second=237 amount=-2
kerning first=942 second=967 amount=-1
kerning first=75 second=279 amount=-1
kerning first=121 second=8216 amount=1
kerning first=84 second=266 amount=-1
kerning first=68 second=84 amount=-1
kerning first=202 second=111 amount=-1
kerning first=68 second=197 amount=-1
kerning first=87 second=262 amount=-1
kerning first=203 second=248 amount=-1
kerning first=198 second=224 amount=-1
kerning first=192 second=71 amount=-1
kerning first=208 second=196 amount=-1
kerning first=69 second=266 amount=-1
kerning first=88 second=337 amount=-1
kerning first=46 second=253 amount=-3
kerning first=268 second=8221 amount=3
kerning first=356 second=199 amount=-1
kerning first=315 second=199 amount=-1
kerning first=197 second=334 amount=-1
kerning first=114 second=8217 amount=3
kerning first=381 second=8221 amount=2
kerning first=8220 second=193 amount=-6
kerning first=88 second=214 amount=-2
kerning first=89 second=197 amount=-5
kerning first=88 second=118 amount=-2
kerning first=287 second=225 amount=-1
kerning first=1107 second=230 amount=-2
kerning first=84 second=81 amount=-2
kerning first=1027 second=58 amount=-3
kerning first=8217 second=198 amount=-11
kerning first=116 second=225 amount=-1
kerning first=1107 second=228 amount=-2
kerning first=69 second=81 amount=-1
kerning first=379 second=301 amount=1
kerning first=259 second=118 amount=-1
kerning first=1027 second=1054 amount=-2
kerning first=221 second=333 amount=-5
kerning first=85 second=260 amount=-2
kerning first=282 second=260 amount=-1
kerning first=315 second=268 amount=-1
kerning first=8221 second=210 amount=-3
kerning first=200 second=268 amount=-1
kerning first=338 second=225 amount=-1
kerning first=75 second=101 amount=-1
kerning first=89 second=225 amount=-5
kerning first=1062 second=1091 amount=-1
kerning first=356 second=268 amount=-1
kerning first=46 second=336 amount=-1
kerning first=122 second=253 amount=-1
kerning first=65 second=8217 amount=-3
kerning first=1060 second=1033 amount=-3
kerning first=378 second=230 amount=-1
kerning first=112 second=380 amount=-1
kerning first=374 second=192 amount=-5
kerning first=84 second=253 amount=-3
kerning first=356 second=230 amount=-6
kerning first=310 second=350 amount=-1
kerning first=1038 second=1100 amount=-2
kerning first=195 second=370 amount=-1
kerning first=382 second=226 amount=-1
kerning first=192 second=286 amount=-1
kerning first=1058 second=1099 amount=-5
kerning first=214 second=65 amount=-1
kerning first=75 second=238 amount=1
kerning first=280 second=195 amount=-1
kerning first=278 second=97 amount=-1
kerning first=312 second=243 amount=-2
kerning first=1094 second=1091 amount=-1
kerning first=47 second=267 amount=-1
kerning first=66 second=379 amount=-1
kerning first=356 second=79 amount=-2
kerning first=1042 second=1046 amount=-1
kerning first=256 second=357 amount=-2
kerning first=1040 second=1092 amount=-1
kerning first=200 second=79 amount=-1
kerning first=105 second=8221 amount=1
kerning first=315 second=79 amount=-2
kerning first=276 second=212 amount=-1
kerning first=255 second=257 amount=-1
kerning first=963 second=964 amount=1
kerning first=201 second=265 amount=-1
kerning first=221 second=331 amount=-3
kerning first=84 second=227 amount=-6
kerning first=326 second=253 amount=-1
kerning first=8218 second=354 amount=-7
kerning first=1123 second=1141 amount=-2
kerning first=44 second=8216 amount=-6
kerning first=219 second=258 amount=-2
kerning first=1138 second=1046 amount=-1
kerning first=193 second=255 amount=-1
kerning first=288 second=255 amount=-1
kerning first=343 second=100 amount=-1
kerning first=379 second=268 amount=-1
kerning first=344 second=233 amount=-1
kerning first=104 second=116 amount=-1
kerning first=214 second=197 amount=-1
kerning first=377 second=233 amount=-1
kerning first=349 second=255 amount=-1
kerning first=84 second=248 amount=-6
kerning first=376 second=241 amount=-3
kerning first=338 second=264 amount=-1
kerning first=97 second=255 amount=-1
kerning first=89 second=264 amount=-2
kerning first=69 second=248 amount=-1
kerning first=260 second=264 amount=-1
kerning first=86 second=347 amount=-3
kerning first=86 second=343 amount=-2
kerning first=46 second=932 amount=-5
kerning first=312 second=337 amount=-2
kerning first=192 second=366 amount=-1
kerning first=1045 second=1105 amount=-2
kerning first=81 second=308 amount=1
kerning first=246 second=122 amount=-1
kerning first=374 second=308 amount=-4
kerning first=202 second=243 amount=-1
kerning first=88 second=338 amount=-2
kerning first=1098 second=1091 amount=-2
kerning first=195 second=217 amount=-1
kerning first=354 second=361 amount=-4
kerning first=958 second=940 amount=-1
kerning first=380 second=234 amount=-2
kerning first=932 second=908 amount=-2
kerning first=80 second=246 amount=-1
kerning first=8217 second=923 amount=-9
kerning first=379 second=234 amount=-1
kerning first=382 second=113 amount=-2
kerning first=198 second=246 amount=-1
kerning first=1138 second=44 amount=-1
kerning first=8222 second=355 amount=-2
kerning first=313 second=102 amount=-1
kerning first=230 second=120 amount=-1
kerning first=341 second=100 amount=-1
kerning first=334 second=258 amount=-1
kerning first=315 second=338 amount=-2
kerning first=118 second=224 amount=-1
kerning first=114 second=233 amount=-1
kerning first=1041 second=1046 amount=-1
kerning first=241 second=120 amount=-1
kerning first=1045 second=1026 amount=-1
kerning first=8218 second=194 amount=1
kerning first=289 second=225 amount=-1
kerning first=1025 second=1089 amount=-2
kerning first=102 second=46 amount=-5
kerning first=1056 second=1089 amount=-1
kerning first=262 second=286 amount=-1
kerning first=200 second=226 amount=-1
kerning first=8217 second=972 amount=-6
kerning first=221 second=46 amount=-8
kerning first=221 second=265 amount=-6
kerning first=362 second=194 amount=-2
kerning first=79 second=379 amount=-1
kerning first=382 second=333 amount=-2
kerning first=44 second=923 amount=1
kerning first=201 second=352 amount=-1
kerning first=356 second=226 amount=-6
kerning first=1070 second=1044 amount=-2
kerning first=340 second=286 amount=-1
kerning first=1065 second=1098 amount=-1
kerning first=90 second=286 amount=-1
kerning first=8222 second=1044 amount=1
kerning first=355 second=265 amount=-1
kerning first=118 second=265 amount=-1
kerning first=1090 second=1104 amount=-1
kerning first=195 second=262 amount=-1
kerning first=332 second=198 amount=-1
kerning first=198 second=83 amount=-1
kerning first=69 second=332 amount=-1
kerning first=378 second=259 amount=-1
kerning first=84 second=332 amount=-2
kerning first=86 second=211 amount=-1
kerning first=278 second=288 amount=-1
kerning first=1102 second=8220 amount=-1
kerning first=1107 second=226 amount=-2
kerning first=372 second=256 amount=-3
kerning first=336 second=256 amount=-1
kerning first=200 second=235 amount=-1
kerning first=194 second=44 amount=1
kerning first=70 second=194 amount=-4
kerning first=86 second=44 amount=-6
kerning first=356 second=235 amount=-6
kerning first=213 second=44 amount=-2
kerning first=315 second=84 amount=-5
kerning first=1027 second=1108 amount=-7
kerning first=75 second=339 amount=-1
kerning first=115 second=8220 amount=-1
kerning first=1076 second=1108 amount=-1
kerning first=69 second=275 amount=-1
kerning first=1061 second=1054 amount=-2
kerning first=118 second=46 amount=-6
kerning first=332 second=354 amount=-2
kerning first=275 second=8220 amount=-1
kerning first=46 second=332 amount=-1
kerning first=84 second=275 amount=-6
kerning first=267 second=339 amount=-1
kerning first=210 second=8220 amount=-1
kerning first=66 second=120 amount=-1
kerning first=220 second=195 amount=-2
kerning first=313 second=366 amount=-2
kerning first=210 second=381 amount=-1
kerning first=339 second=380 amount=-1
kerning first=65 second=8220 amount=-6
kerning first=81 second=120 amount=1
kerning first=374 second=120 amount=-2
kerning first=310 second=305 amount=-1
kerning first=375 second=244 amount=-1
kerning first=277 second=8216 amount=-1
kerning first=269 second=229 amount=-1
kerning first=197 second=45 amount=-1
kerning first=201 second=333 amount=-1
kerning first=340 second=346 amount=-1
kerning first=374 second=115 amount=-4
kerning first=347 second=8216 amount=-1
kerning first=343 second=225 amount=-1
kerning first=84 second=373 amount=-3
kerning first=343 second=283 amount=-1
kerning first=374 second=81 amount=-2
kerning first=354 second=333 amount=-6
kerning first=199 second=334 amount=-1
kerning first=253 second=378 amount=-1
kerning first=69 second=373 amount=-1
kerning first=70 second=279 amount=-1
kerning first=313 second=71 amount=-2
kerning first=89 second=350 amount=-1
kerning first=221 second=245 amount=-5
kerning first=1038 second=1101 amount=-2
kerning first=338 second=350 amount=-1
kerning first=266 second=338 amount=-1
kerning first=918 second=45 amount=-2
kerning first=118 second=245 amount=-1
kerning first=376 second=230 amount=-5
kerning first=355 second=245 amount=-1
kerning first=196 second=360 amount=-1
kerning first=1058 second=1092 amount=-6
kerning first=122 second=353 amount=-1
kerning first=1168 second=187 amount=-2
kerning first=121 second=289 amount=-1
kerning first=89 second=103 amount=-5
kerning first=374 second=47 amount=-4
kerning first=287 second=103 amount=1
kerning first=8217 second=259 amount=-5
kerning first=75 second=369 amount=-1
kerning first=334 second=89 amount=-2
kerning first=255 second=99 amount=-1
kerning first=350 second=87 amount=-1
kerning first=340 second=119 amount=-1
kerning first=349 second=120 amount=-1
kerning first=313 second=74 amount=1
kerning first=381 second=121 amount=-1
kerning first=354 second=232 amount=-6
kerning first=1069 second=1035 amount=-2
kerning first=328 second=375 amount=-1
kerning first=208 second=74 amount=-1
kerning first=89 second=213 amount=-2
kerning first=8222 second=210 amount=-2
kerning first=122 second=373 amount=-1
kerning first=44 second=196 amount=1
kerning first=261 second=375 amount=-1
kerning first=940 second=963 amount=-1
kerning first=201 second=232 amount=-1
kerning first=202 second=67 amount=-1
kerning first=118 second=235 amount=-1
kerning first=373 second=269 amount=-1
kerning first=192 second=374 amount=-5
kerning first=192 second=8216 amount=-6
kerning first=221 second=375 amount=-2
kerning first=286 second=374 amount=-1
kerning first=84 second=353 amount=-5
kerning first=211 second=8216 amount=-1
kerning first=278 second=197 amount=-1
kerning first=47 second=193 amount=-2
kerning first=360 second=197 amount=-2
kerning first=211 second=374 amount=-2
kerning first=197 second=370 amount=-1
kerning first=343 second=248 amount=-1
kerning first=345 second=99 amount=-1
kerning first=1027 second=59 amount=-1
kerning first=256 second=8221 amount=-3
kerning first=221 second=196 amount=-5
kerning first=939 second=8217 amount=1
kerning first=374 second=353 amount=-4
kerning first=8222 second=213 amount=-2
kerning first=1046 second=1095 amount=-1
kerning first=86 second=241 amount=-2
kerning first=119 second=353 amount=-1
kerning first=269 second=228 amount=-1
kerning first=8217 second=67 amount=-2
kerning first=107 second=246 amount=-2
kerning first=75 second=363 amount=-1
kerning first=89 second=355 amount=-2
kerning first=191 second=106 amount=1
kerning first=341 second=267 amount=-1
kerning first=929 second=47 amount=-4
kerning first=8221 second=959 amount=-6
kerning first=260 second=355 amount=-2
kerning first=959 second=955 amount=-1
kerning first=203 second=234 amount=-1
kerning first=198 second=284 amount=-1
kerning first=110 second=355 amount=-1
kerning first=8222 second=373 amount=-5
kerning first=70 second=99 amount=-1
kerning first=282 second=277 amount=-1
kerning first=280 second=267 amount=-1
kerning first=376 second=343 amount=-3
kerning first=1058 second=1090 amount=-3
kerning first=8218 second=214 amount=-2
kerning first=1043 second=59 amount=-1
kerning first=90 second=113 amount=-1
kerning first=208 second=65 amount=-1
kerning first=379 second=336 amount=-1
kerning first=311 second=242 amount=-2
kerning first=310 second=252 amount=-1
kerning first=370 second=192 amount=-2
kerning first=65 second=268 amount=-1
kerning first=87 second=109 amount=-2
kerning first=8222 second=266 amount=-1
kerning first=122 second=8217 amount=1
kerning first=354 second=309 amount=2
kerning first=107 second=224 amount=-1
kerning first=221 second=351 amount=-4
kerning first=121 second=228 amount=-1
kerning first=8260 second=178 amount=-8
kerning first=1105 second=1076 amount=-1
kerning first=88 second=279 amount=-1
kerning first=355 second=231 amount=-1
kerning first=341 second=225 amount=-1
kerning first=258 second=8217 amount=-3
kerning first=267 second=227 amount=-1
kerning first=90 second=101 amount=-1
kerning first=1066 second=1063 amount=-4
kerning first=1043 second=1073 amount=-2
kerning first=198 second=352 amount=-1
kerning first=75 second=227 amount=-1
kerning first=1168 second=1141 amount=-5
kerning first=8217 second=264 amount=-2
kerning first=8221 second=287 amount=-5
kerning first=221 second=71 amount=-2
kerning first=70 second=257 amount=-2
kerning first=83 second=256 amount=-1
kerning first=119 second=263 amount=-1
kerning first=278 second=264 amount=-1
kerning first=313 second=86 amount=-5
kerning first=109 second=120 amount=-1
kerning first=376 second=199 amount=-2
kerning first=345 second=349 amount=-1
kerning first=8216 second=286 amount=-1
kerning first=285 second=101 amount=-1
kerning first=267 second=337 amount=-1
kerning first=197 second=218 amount=-1
kerning first=344 second=376 amount=-1
kerning first=46 second=364 amount=-1
kerning first=1066 second=1058 amount=-5
kerning first=1044 second=1058 amount=-1
kerning first=202 second=255 amount=-2
kerning first=955 second=953 amount=-1
kerning first=372 second=301 amount=2
kerning first=103 second=357 amount=-1
kerning first=68 second=221 amount=-1
kerning first=1043 second=1075 amount=-5
kerning first=70 second=83 amount=-1
kerning first=116 second=100 amount=-1
kerning first=84 second=281 amount=-6
kerning first=8217 second=234 amount=-5
kerning first=348 second=221 amount=-1
kerning first=338 second=286 amount=-1
kerning first=241 second=253 amount=-1
kerning first=1059 second=1105 amount=-4
kerning first=88 second=101 amount=-1
kerning first=378 second=235 amount=-2
kerning first=1060 second=46 amount=-5
kerning first=89 second=100 amount=-5
kerning first=374 second=275 amount=-5
kerning first=338 second=100 amount=-1
kerning first=272 second=195 amount=-1
kerning first=70 second=349 amount=-1
kerning first=208 second=86 amount=-1
kerning first=66 second=253 amount=-1
kerning first=75 second=337 amount=-1
kerning first=350 second=195 amount=-1
kerning first=212 second=192 amount=-1
kerning first=111 second=380 amount=-1
kerning first=195 second=360 amount=-1
kerning first=122 second=281 amount=-2
kerning first=374 second=253 amount=-2
kerning first=65 second=79 amount=-1
kerning first=310 second=97 amount=-1
kerning first=373 second=243 amount=-1
kerning first=1050 second=1026 amount=-1
kerning first=221 second=236 amount=1
kerning first=278 second=348 amount=-1
kerning first=354 second=286 amount=-2
kerning first=344 second=243 amount=-1
kerning first=69 second=281 amount=-1
kerning first=99 second=335 amount=-1
kerning first=1024 second=1033 amount=-1
kerning first=276 second=357 amount=-1
kerning first=201 second=286 amount=-1
kerning first=99 second=243 amount=-1
kerning first=310 second=264 amount=-3
kerning first=1169 second=1139 amount=-1
kerning first=8217 second=193 amount=-9
kerning first=223 second=117 amount=-1
kerning first=75 second=212 amount=-3
kerning first=348 second=253 amount=-1
kerning first=1043 second=1105 amount=-7
kerning first=253 second=97 amount=-1
kerning first=345 second=257 amount=-1
kerning first=379 second=339 amount=-1
kerning first=256 second=89 amount=-5
kerning first=255 second=8221 amount=2
kerning first=8222 second=1058 amount=-6
kerning first=195 second=332 amount=-1
kerning first=65 second=356 amount=-6
kerning first=237 second=8221 amount=3
kerning first=1047 second=1058 amount=-1
kerning first=918 second=8221 amount=2
kerning first=47 second=246 amount=-1
kerning first=372 second=297 amount=4
kerning first=202 second=97 amount=-1
kerning first=208 second=90 amount=-1
kerning first=356 second=122 amount=-5
kerning first=8221 second=337 amount=-5
kerning first=74 second=195 amount=-1
kerning first=118 second=380 amount=-1
kerning first=276 second=260 amount=-1
kerning first=1027 second=1097 amount=-5
kerning first=196 second=264 amount=-1
kerning first=201 second=212 amount=-1
kerning first=1043 second=1092 amount=-7
kerning first=343 second=275 amount=-1
kerning first=219 second=260 amount=-2
kerning first=282 second=257 amount=-1
kerning first=84 second=120 amount=-3
kerning first=345 second=242 amount=-1
kerning first=118 second=234 amount=-1
kerning first=310 second=243 amount=-1
kerning first=8220 second=103 amount=-3
kerning first=44 second=116 amount=-2
kerning first=291 second=234 amount=-1
kerning first=922 second=920 amount=-3
kerning first=8218 second=374 amount=-8
kerning first=86 second=267 amount=-4
kerning first=231 second=243 amount=-1
kerning first=114 second=113 amount=-1
kerning first=221 second=234 amount=-5
kerning first=271 second=254 amount=1
kerning first=272 second=88 amount=-1
kerning first=356 second=291 amount=-5
kerning first=311 second=279 amount=-2
kerning first=341 second=45 amount=-2
kerning first=941 second=940 amount=-1
kerning first=86 second=79 amount=-1
kerning first=278 second=113 amount=-1
kerning first=208 second=194 amount=-1
kerning first=46 second=214 amount=-1
kerning first=1076 second=44 amount=3
kerning first=84 second=291 amount=-5
kerning first=208 second=84 amount=-1
kerning first=276 second=286 amount=-1
kerning first=313 second=84 amount=-5
kerning first=315 second=217 amount=-2
kerning first=374 second=252 amount=-3
kerning first=963 second=961 amount=-1
kerning first=253 second=243 amount=-1
kerning first=47 second=232 amount=-1
kerning first=280 second=45 amount=-1
kerning first=75 second=351 amount=-1
kerning first=380 second=243 amount=-2
kerning first=1045 second=1113 amount=-1
kerning first=1065 second=1077 amount=-1
kerning first=1104 second=1113 amount=-1
kerning first=1101 second=1093 amount=-1
kerning first=8218 second=366 amount=-1
kerning first=87 second=324 amount=-2
kerning first=193 second=288 amount=-1
kerning first=310 second=116 amount=-1
kerning first=347 second=355 amount=-1
kerning first=85 second=44 amount=-1
kerning first=904 second=972 amount=-1
kerning first=374 second=105 amount=-1
kerning first=373 second=8220 amount=1
kerning first=213 second=381 amount=-1
kerning first=1025 second=1060 amount=-2
kerning first=70 second=262 amount=-1
kerning first=341 second=347 amount=-1
kerning first=89 second=256 amount=-5
kerning first=338 second=256 amount=-1
kerning first=8220 second=195 amount=-6
kerning first=198 second=373 amount=-1
kerning first=381 second=212 amount=-1
kerning first=374 second=332 amount=-2
kerning first=352 second=118 amount=-1
kerning first=1097 second=1093 amount=1
kerning first=372 second=308 amount=-3
kerning first=8218 second=262 amount=-1
kerning first=1058 second=1082 amount=-5
kerning first=103 second=279 amount=-1
kerning first=1058 second=1169 amount=-5
kerning first=344 second=67 amount=-1
kerning first=377 second=67 amount=-1
kerning first=258 second=308 amount=1
kerning first=297 second=108 amount=1
kerning first=336 second=308 amount=-1
kerning first=1024 second=1138 amount=-2
kerning first=201 second=116 amount=-1
kerning first=374 second=328 amount=-3
kerning first=8216 second=212 amount=-1
kerning first=290 second=374 amount=-1
kerning first=335 second=120 amount=-1
kerning first=198 second=290 amount=-1
kerning first=114 second=269 amount=-1
kerning first=282 second=196 amount=-1
kerning first=233 second=378 amount=-1
kerning first=107 second=229 amount=-1
kerning first=311 second=245 amount=-2
kerning first=85 second=196 amount=-2
kerning first=89 second=210 amount=-2
kerning first=213 second=354 amount=-2
kerning first=282 second=244 amount=-1
kerning first=218 second=260 amount=-2
kerning first=194 second=354 amount=-6
kerning first=276 second=113 amount=-1
kerning first=103 second=229 amount=-1
kerning first=353 second=8216 amount=-1
kerning first=274 second=195 amount=-1
kerning first=276 second=346 amount=-1
kerning first=121 second=283 amount=-1
kerning first=260 second=210 amount=-1
kerning first=218 second=195 amount=-2
kerning first=258 second=338 amount=-1
kerning first=8220 second=339 amount=-2
kerning first=297 second=254 amount=1
kerning first=372 second=338 amount=-1
kerning first=87 second=100 amount=-3
kerning first=255 second=265 amount=-1
kerning first=1058 second=1089 amount=-7
kerning first=75 second=236 amount=1
kerning first=102 second=349 amount=-1
kerning first=1090 second=1089 amount=-1
kerning first=47 second=229 amount=-1
kerning first=313 second=8221 amount=-5
kerning first=282 second=355 amount=-1
kerning first=376 second=226 amount=-5
kerning first=343 second=259 amount=-1
kerning first=1059 second=1108 amount=-4
kerning first=103 second=277 amount=-1
kerning first=382 second=245 amount=-2
kerning first=221 second=341 amount=-3
kerning first=346 second=373 amount=-1
kerning first=8218 second=1141 amount=-3
kerning first=267 second=230 amount=-1
kerning first=118 second=242 amount=-1
kerning first=75 second=230 amount=-1
kerning first=84 second=109 amount=-4
kerning first=65 second=290 amount=-1
kerning first=40 second=106 amount=2
kerning first=1069 second=1083 amount=-1
kerning first=258 second=368 amount=-1
kerning first=1138 second=1063 amount=-1
kerning first=81 second=198 amount=3
kerning first=374 second=198 amount=-6
kerning first=278 second=269 amount=-1
kerning first=380 second=289 amount=-1
kerning first=8222 second=1076 amount=1
kerning first=70 second=74 amount=-4
kerning first=47 second=277 amount=-1
kerning first=278 second=335 amount=-1
kerning first=1168 second=1087 amount=-5
kerning first=362 second=74 amount=-1
kerning first=69 second=335 amount=-1
kerning first=350 second=253 amount=-1
kerning first=1070 second=1076 amount=-2
kerning first=114 second=232 amount=-1
kerning first=310 second=117 amount=-1
kerning first=352 second=119 amount=-1
kerning first=310 second=119 amount=-3
kerning first=356 second=193 amount=-6
kerning first=1038 second=1088 amount=-2
kerning first=200 second=193 amount=-1
kerning first=342 second=372 amount=-1
kerning first=354 second=245 amount=-6
kerning first=346 second=87 amount=-1
kerning first=967 second=945 amount=-2
kerning first=201 second=245 amount=-1
kerning first=259 second=375 amount=-1
kerning first=355 second=8217 amount=2
kerning first=260 second=266 amount=-1
kerning first=84 second=115 amount=-5
kerning first=258 second=374 amount=-5
kerning first=332 second=356 amount=-2
kerning first=88 second=375 amount=-2
kerning first=120 second=99 amount=-2
kerning first=196 second=356 amount=-6
kerning first=255 second=225 amount=-1
kerning first=372 second=255 amount=-2
kerning first=196 second=376 amount=-5
kerning first=374 second=288 amount=-2
kerning first=75 second=102 amount=-1
kerning first=46 second=213 amount=-1
kerning first=332 second=376 amount=-2
kerning first=1038 second=1087 amount=-2
kerning first=312 second=353 amount=-1
kerning first=258 second=255 amount=-1
kerning first=122 second=115 amount=-1
kerning first=276 second=284 amount=-1
kerning first=8217 second=112 amount=-2
kerning first=211 second=260 amount=-1
kerning first=201 second=355 amount=-1
kerning first=89 second=257 amount=-5
kerning first=84 second=213 amount=-2
kerning first=274 second=233 amount=-1
kerning first=69 second=213 amount=-1
kerning first=8220 second=283 amount=-3
kerning first=8222 second=1090 amount=-3
kerning first=76 second=284 amount=-2
kerning first=1056 second=44 amount=-7
kerning first=221 second=242 amount=-5
kerning first=8218 second=74 amount=1
kerning first=942 second=947 amount=-1
kerning first=90 second=71 amount=-1
kerning first=1069 second=1051 amount=-2
kerning first=340 second=71 amount=-1
kerning first=356 second=224 amount=-6
kerning first=83 second=192 amount=-1
kerning first=253 second=113 amount=-1
kerning first=280 second=246 amount=-1
kerning first=74 second=192 amount=-1
kerning first=83 second=196 amount=-1
kerning first=1038 second=1074 amount=-2
kerning first=76 second=220 amount=-2
kerning first=262 second=71 amount=-1
kerning first=1114 second=1118 amount=-2
kerning first=354 second=266 amount=-1
kerning first=378 second=224 amount=-1
kerning first=348 second=8216 amount=-1
kerning first=203 second=336 amount=-1
kerning first=115 second=122 amount=-1
kerning first=338 second=228 amount=-1
kerning first=8220 second=74 amount=-3
kerning first=295 second=8216 amount=-1
kerning first=8217 second=81 amount=-3
kerning first=191 second=118 amount=-2
kerning first=354 second=250 amount=-4
kerning first=274 second=226 amount=-1
kerning first=311 second=231 amount=-2
kerning first=916 second=8216 amount=-6
kerning first=8221 second=214 amount=-3
kerning first=1043 second=1102 amount=-5
kerning first=8220 second=920 amount=-1
kerning first=340 second=279 amount=-1
kerning first=8221 second=361 amount=-2
kerning first=90 second=279 amount=-1
kerning first=221 second=299 amount=3
kerning first=274 second=199 amount=-1
kerning first=1025 second=1028 amount=-2
kerning first=8218 second=1095 amount=-3
kerning first=8216 second=289 amount=-3
kerning first=84 second=114 amount=-4
kerning first=326 second=118 amount=-1
kerning first=203 second=332 amount=-1
kerning first=1026 second=1058 amount=-5
kerning first=374 second=347 amount=-4
kerning first=349 second=253 amount=-1
kerning first=1045 second=1051 amount=-1
kerning first=372 second=331 amount=-2
kerning first=299 second=8217 amount=2
kerning first=276 second=214 amount=-1
kerning first=1168 second=1084 amount=-5
kerning first=8216 second=284 amount=-1
kerning first=82 second=118 amount=-1
kerning first=380 second=8217 amount=1
kerning first=376 second=118 amount=-2
kerning first=378 second=101 amount=-2
kerning first=8218 second=65 amount=1
kerning first=288 second=253 amount=-1
kerning first=374 second=281 amount=-5
kerning first=114 second=225 amount=-1
kerning first=46 second=356 amount=-5
kerning first=199 second=81 amount=-1
kerning first=284 second=221 amount=-1
kerning first=65 second=221 amount=-5
kerning first=225 second=355 amount=-1
kerning first=334 second=260 amount=-1
kerning first=231 second=111 amount=-1
kerning first=1058 second=1057 amount=-2
kerning first=278 second=225 amount=-1
kerning first=119 second=281 amount=-1
kerning first=1056 second=1086 amount=-1
kerning first=8218 second=84 amount=-7
kerning first=276 second=337 amount=-1
kerning first=210 second=221 amount=-2
kerning first=76 second=334 amount=-2
kerning first=200 second=101 amount=-1
kerning first=356 second=251 amount=-4
kerning first=194 second=268 amount=-1
kerning first=1036 second=1104 amount=-1
kerning first=308 second=195 amount=-1
kerning first=380 second=253 amount=-1
kerning first=290 second=118 amount=-1
kerning first=376 second=101 amount=-5
kerning first=346 second=195 amount=-1
kerning first=1168 second=1077 amount=-7
kerning first=192 second=86 amount=-3
kerning first=82 second=101 amount=-1
kerning first=224 second=118 amount=-1
kerning first=310 second=301 amount=1
kerning first=211 second=86 amount=-1
kerning first=276 second=333 amount=-1
kerning first=914 second=916 amount=-1
kerning first=201 second=231 amount=-1
kerning first=348 second=65 amount=-1
kerning first=295 second=357 amount=-1
kerning first=202 second=263 amount=-1
kerning first=8216 second=211 amount=-1
kerning first=342 second=244 amount=-1
kerning first=381 second=244 amount=-1
kerning first=194 second=118 amount=-1
kerning first=955 second=952 amount=-1
kerning first=68 second=65 amount=-1
kerning first=90 second=100 amount=-1
kerning first=202 second=253 amount=-2
kerning first=1082 second=1086 amount=-1
kerning first=372 second=326 amount=-2
kerning first=373 second=382 amount=-1
kerning first=193 second=364 amount=-1
kerning first=960 second=974 amount=-1
kerning first=312 second=281 amount=-2
kerning first=8217 second=243 amount=-5
kerning first=8221 second=333 amount=-5
kerning first=1065 second=1091 amount=-1
kerning first=63 second=8217 amount=1
kerning first=1061 second=1098 amount=-1
kerning first=99 second=97 amount=-1
kerning first=191 second=354 amount=-4
kerning first=8220 second=226 amount=-2
kerning first=1040 second=1058 amount=-4
kerning first=287 second=47 amount=3
kerning first=228 second=357 amount=-1
kerning first=379 second=297 amount=4
kerning first=82 second=79 amount=-1
kerning first=1062 second=59 amount=2
kerning first=343 second=228 amount=-1
kerning first=356 second=231 amount=-6
kerning first=197 second=199 amount=-1
kerning first=375 second=45 amount=-2
kerning first=8217 second=380 amount=-1
kerning first=275 second=122 amount=-1
kerning first=238 second=8220 amount=1
kerning first=374 second=263 amount=-6
kerning first=8217 second=263 amount=-5
kerning first=90 second=212 amount=-1
kerning first=86 second=371 amount=-2
kerning first=1038 second=1138 amount=-2
kerning first=340 second=212 amount=-1
kerning first=356 second=378 amount=-5
kerning first=377 second=264 amount=-1
kerning first=341 second=246 amount=-1
kerning first=380 second=263 amount=-2
kerning first=344 second=264 amount=-1
kerning first=89 second=301 amount=2
kerning first=375 second=285 amount=-1
kerning first=86 second=257 amount=-4
kerning first=196 second=67 amount=-1
kerning first=262 second=212 amount=-1
kerning first=955 second=969 amount=-1
kerning first=245 second=378 amount=-1
kerning first=348 second=260 amount=-1
kerning first=69 second=116 amount=-1
kerning first=1058 second=187 amount=-2
kerning first=1111 second=8216 amount=2
kerning first=1054 second=1061 amount=-1
kerning first=89 second=243 amount=-5
kerning first=70 second=265 amount=-1
kerning first=312 second=229 amount=-1
kerning first=85 second=258 amount=-2
kerning first=282 second=258 amount=-1
kerning first=1069 second=1032 amount=-1
kerning first=203 second=348 amount=-1
kerning first=193 second=214 amount=-1
kerning first=1024 second=1139 amount=-1
kerning first=213 second=193 amount=-1
kerning first=1044 second=1089 amount=-1
kerning first=75 second=286 amount=-3
kerning first=1077 second=1113 amount=-1
kerning first=89 second=259 amount=-5
kerning first=212 second=372 amount=-1
kerning first=1097 second=1089 amount=-1
kerning first=76 second=268 amount=-1
kerning first=270 second=379 amount=-1
kerning first=221 second=194 amount=-5
kerning first=8221 second=263 amount=-5
kerning first=287 second=259 amount=-1
kerning first=221 second=8221 amount=1
kerning first=376 second=291 amount=-5
kerning first=111 second=44 amount=-1
kerning first=116 second=243 amount=-1
kerning first=89 second=116 amount=-2
kerning first=197 second=211 amount=-1
kerning first=291 second=8221 amount=3
kerning first=338 second=116 amount=-1
kerning first=969 second=955 amount=-1
kerning first=355 second=8221 amount=2
kerning first=1169 second=46 amount=-3
kerning first=229 second=116 amount=-1
kerning first=86 second=235 amount=-4
kerning first=287 second=116 amount=-1
kerning first=375 second=99 amount=-1
kerning first=1043 second=1044 amount=-5
kerning first=119 second=44 amount=-6
kerning first=8216 second=46 amount=-7
kerning first=280 second=83 amount=-1
kerning first=118 second=8221 amount=2
kerning first=202 second=288 amount=-1
kerning first=338 second=275 amount=-1
kerning first=89 second=275 amount=-5
kerning first=226 second=8220 amount=-1
kerning first=1036 second=1035 amount=-1
kerning first=332 second=8220 amount=-1
kerning first=255 second=380 amount=-1
kerning first=65 second=63 amount=-2
kerning first=292 second=8220 amount=-1
kerning first=333 second=380 amount=-1
kerning first=263 second=244 amount=-1
kerning first=1046 second=1141 amount=-2
kerning first=287 second=275 amount=-1
kerning first=352 second=256 amount=-1
kerning first=70 second=339 amount=-1
kerning first=8216 second=245 amount=-2
kerning first=196 second=8220 amount=-6
kerning first=354 second=46 amount=-7
kerning first=375 second=347 amount=-1
kerning first=270 second=256 amount=-1
kerning first=116 second=116 amount=-1
kerning first=110 second=116 amount=-1
kerning first=360 second=308 amount=-1
kerning first=1116 second=1108 amount=-1
kerning first=1040 second=59 amount=1
kerning first=954 second=973 amount=-1
kerning first=280 second=229 amount=-1
kerning first=75 second=214 amount=-3
kerning first=334 second=44 amount=-2
kerning first=201 second=197 amount=-1
kerning first=253 second=269 amount=-1
kerning first=375 second=289 amount=-1
kerning first=354 second=197 amount=-6
kerning first=254 second=8220 amount=-1
kerning first=8218 second=118 amount=-5
kerning first=343 second=115 amount=-1
kerning first=8217 second=328 amount=-2
kerning first=1070 second=1058 amount=-2
kerning first=1065 second=1063 amount=-1
kerning first=1091 second=1113 amount=-2
kerning first=278 second=255 amount=-2
kerning first=101 second=8220 amount=-1
kerning first=1058 second=1096 amount=-5
kerning first=310 second=210 amount=-3
kerning first=89 second=117 amount=-3
kerning first=1092 second=1076 amount=-1
kerning first=253 second=225 amount=-1
kerning first=377 second=239 amount=2
kerning first=231 second=225 amount=-1
kerning first=8216 second=71 amount=-1
kerning first=310 second=225 amount=-1
kerning first=81 second=289 amount=2
kerning first=341 second=229 amount=-1
kerning first=197 second=81 amount=-1
kerning first=285 second=230 amount=-1
kerning first=278 second=350 amount=-1
kerning first=1044 second=1057 amount=-1
kerning first=278 second=338 amount=-1
kerning first=354 second=101 amount=-6
kerning first=1045 second=1108 amount=-2
kerning first=82 second=268 amount=-1
kerning first=376 second=268 amount=-2
kerning first=212 second=198 amount=-1
kerning first=8221 second=286 amount=-3
kerning first=1059 second=1079 amount=-2
kerning first=370 second=198 amount=-1
kerning first=223 second=8220 amount=-2
kerning first=350 second=121 amount=-1
kerning first=76 second=121 amount=-3
kerning first=121 second=233 amount=-1
kerning first=8220 second=376 amount=2
kerning first=99 second=259 amount=-1
kerning first=280 second=277 amount=-1
kerning first=90 second=245 amount=-1
kerning first=340 second=245 amount=-1
kerning first=958 second=949 amount=-1
kerning first=315 second=290 amount=-2
kerning first=8216 second=113 amount=-3
kerning first=8218 second=375 amount=-5
kerning first=200 second=290 amount=-1
kerning first=341 second=277 amount=-1
kerning first=348 second=89 amount=-1
kerning first=232 second=8216 amount=-1
kerning first=75 second=361 amount=-1
kerning first=75 second=219 amount=-1
kerning first=85 second=308 amount=-1
kerning first=1075 second=1076 amount=-4
kerning first=197 second=87 amount=-3
kerning first=102 second=103 amount=-2
kerning first=343 second=335 amount=-1
kerning first=89 second=119 amount=-2
kerning first=338 second=119 amount=-1
kerning first=195 second=362 amount=-1
kerning first=380 second=353 amount=-1
kerning first=310 second=232 amount=-1
kerning first=960 second=945 amount=-1
kerning first=1098 second=8216 amount=-4
kerning first=197 second=85 amount=-1
kerning first=1104 second=8216 amount=-1
kerning first=8217 second=353 amount=-4
kerning first=310 second=269 amount=-1
kerning first=376 second=193 amount=-5
kerning first=87 second=103 amount=-2
kerning first=8216 second=197 amount=-6
kerning first=87 second=248 amount=-3
kerning first=282 second=267 amount=-1
kerning first=269 second=226 amount=-1
kerning first=1091 second=1076 amount=-3
kerning first=1054 second=1033 amount=-2
kerning first=329 second=355 amount=-1
kerning first=102 second=248 amount=-2
kerning first=121 second=226 amount=-1
kerning first=310 second=266 amount=-3
kerning first=194 second=370 amount=-1
kerning first=1038 second=1057 amount=-2
kerning first=282 second=45 amount=-1
kerning first=929 second=8217 amount=2
kerning first=379 second=332 amount=-1
kerning first=953 second=967 amount=-1
kerning first=195 second=336 amount=-1
kerning first=79 second=260 amount=-1
kerning first=8220 second=932 amount=3
kerning first=344 second=221 amount=-1
kerning first=70 second=234 amount=-1
kerning first=375 second=246 amount=-1
kerning first=1061 second=1090 amount=-1
kerning first=376 second=79 amount=-2
kerning first=374 second=213 amount=-2
kerning first=374 second=286 amount=-2
kerning first=1040 second=1090 amount=-3
kerning first=289 second=285 amount=1
kerning first=280 second=284 amount=-1
kerning first=8217 second=250 amount=-2
kerning first=939 second=47 amount=-5
kerning first=8216 second=231 amount=-3
kerning first=1070 second=1051 amount=-2
kerning first=287 second=113 amount=-1
kerning first=221 second=102 amount=-2
kerning first=354 second=71 amount=-2
kerning first=87 second=309 amount=1
kerning first=285 second=287 amount=1
kerning first=372 second=192 amount=-3
kerning first=336 second=192 amount=-1
kerning first=89 second=113 amount=-5
kerning first=282 second=281 amount=-1
kerning first=345 second=234 amount=-1
kerning first=373 second=111 amount=-1
kerning first=338 second=113 amount=-1
kerning first=376 second=224 amount=-5
kerning first=1062 second=1063 amount=-1
kerning first=8221 second=260 amount=-9
kerning first=87 second=336 amount=-1
kerning first=345 second=351 amount=-1
kerning first=1041 second=1063 amount=-1
kerning first=99 second=111 amount=-1
kerning first=8220 second=908 amount=-1
kerning first=198 second=45 amount=-1
kerning first=75 second=333 amount=-1
kerning first=70 second=227 amount=-2
kerning first=80 second=45 amount=-2
kerning first=1139 second=1078 amount=-1
kerning first=87 second=279 amount=-3
kerning first=253 second=8216 amount=1
kerning first=70 second=351 amount=-1
kerning first=329 second=8220 amount=-1
kerning first=201 second=71 amount=-1
kerning first=353 second=118 amount=-1
kerning first=8217 second=253 amount=-2
kerning first=200 second=352 amount=-1
kerning first=340 second=348 amount=-1
kerning first=267 second=333 amount=-1
kerning first=114 second=242 amount=-1
kerning first=377 second=111 amount=-1
kerning first=193 second=8217 amount=-3
kerning first=382 second=231 amount=-2
kerning first=201 second=227 amount=-1
kerning first=376 second=352 amount=-1
kerning first=1062 second=1077 amount=-1
kerning first=354 second=227 amount=-6
kerning first=82 second=352 amount=-1
kerning first=86 second=224 amount=-4
kerning first=274 second=196 amount=-1
kerning first=241 second=118 amount=-1
kerning first=192 second=84 amount=-6
kerning first=258 second=266 amount=-1
kerning first=46 second=8217 amount=-5
kerning first=269 second=111 amount=-1
kerning first=203 second=71 amount=-1
kerning first=84 second=361 amount=-4
kerning first=200 second=334 amount=-1
kerning first=193 second=218 amount=-1
kerning first=315 second=334 amount=-2
kerning first=221 second=231 amount=-6
kerning first=191 second=221 amount=-4
kerning first=377 second=81 amount=-1
kerning first=1046 second=1060 amount=-3
kerning first=8220 second=939 amount=2
kerning first=344 second=81 amount=-1
kerning first=345 second=8216 amount=1
kerning first=89 second=115 amount=-4
kerning first=87 second=250 amount=-2
kerning first=382 second=227 amount=-1
kerning first=8217 second=252 amount=-2
kerning first=8218 second=1060 amount=-4
kerning first=372 second=248 amount=-3
kerning first=382 second=118 amount=-1
kerning first=381 second=268 amount=-1
kerning first=274 second=67 amount=-1
kerning first=331 second=253 amount=-1
kerning first=86 second=101 amount=-4
kerning first=8220 second=902 amount=-6
kerning first=71 second=374 amount=-1
kerning first=82 second=221 amount=-1
kerning first=260 second=370 amount=-1
kerning first=282 second=118 amount=-2
kerning first=264 second=81 amount=-1
kerning first=203 second=225 amount=-1
kerning first=341 second=232 amount=-1
kerning first=8216 second=227 amount=-2
kerning first=263 second=230 amount=-1
kerning first=344 second=253 amount=-1
kerning first=270 second=192 amount=-1
kerning first=1169 second=227 amount=-2
kerning first=8221 second=365 amount=-2
kerning first=202 second=348 amount=-1
kerning first=334 second=196 amount=-1
kerning first=8216 second=244 amount=-2
kerning first=352 second=192 amount=-1
kerning first=372 second=309 amount=1
kerning first=219 second=65 amount=-2
kerning first=238 second=8217 amount=3
kerning first=1090 second=1092 amount=-1
kerning first=1097 second=1086 amount=-1
kerning first=324 second=357 amount=-1
kerning first=381 second=337 amount=-1
kerning first=342 second=337 amount=-1
kerning first=1044 second=1086 amount=-1
kerning first=84 second=8217 amount=2
kerning first=1070 second=1033 amount=-2
kerning first=263 second=337 amount=-1
kerning first=118 second=382 amount=-1
kerning first=1094 second=1089 amount=-1
kerning first=1059 second=1054 amount=-2
kerning first=202 second=281 amount=-1
kerning first=285 second=8221 amount=3
kerning first=376 second=357 amount=-2
kerning first=1029 second=1091 amount=-1
kerning first=955 second=959 amount=-1
kerning first=378 second=225 amount=-1
kerning first=221 second=212 amount=-2
kerning first=326 second=357 amount=-1
kerning first=114 second=224 amount=-1
kerning first=1075 second=1084 amount=-1
kerning first=310 second=275 amount=-1
kerning first=1168 second=1044 amount=-5
kerning first=119 second=97 amount=-1
kerning first=1069 second=1063 amount=-1
kerning first=354 second=349 amount=-5
kerning first=8220 second=263 amount=-3
kerning first=89 second=120 amount=-2
kerning first=1168 second=1100 amount=-5
kerning first=280 second=199 amount=-1
kerning first=342 second=354 amount=-1
kerning first=229 second=120 amount=-1
kerning first=87 second=363 amount=-2
kerning first=253 second=275 amount=-1
kerning first=44 second=65 amount=1
kerning first=285 second=228 amount=-1
kerning first=110 second=120 amount=-1
kerning first=202 second=264 amount=-1
kerning first=1027 second=1107 amount=-5
kerning first=212 second=46 amount=-1
kerning first=75 second=366 amount=-1
kerning first=378 second=267 amount=-2
kerning first=949 second=948 amount=-1
kerning first=354 second=234 amount=-6
kerning first=270 second=308 amount=-1
kerning first=255 second=339 amount=-1
kerning first=67 second=288 amount=-1
kerning first=356 second=267 amount=-6
kerning first=86 second=122 amount=-3
kerning first=213 second=258 amount=-1
kerning first=1044 second=1139 amount=-1
kerning first=373 second=242 amount=-1
kerning first=86 second=258 amount=-3
kerning first=274 second=246 amount=-1
kerning first=352 second=255 amount=-1
kerning first=374 second=97 amount=-5
kerning first=47 second=194 amount=-2
kerning first=310 second=255 amount=-3
kerning first=379 second=113 amount=-1
kerning first=88 second=286 amount=-2
kerning first=1041 second=1026 amount=-2
kerning first=1097 second=8220 amount=-1
kerning first=1077 second=8220 amount=-1
kerning first=1168 second=1116 amount=-5
kerning first=211 second=194 amount=-1
kerning first=376 second=249 amount=-3
kerning first=1044 second=8220 amount=-1
kerning first=342 second=79 amount=-1
kerning first=368 second=194 amount=-2
kerning first=381 second=79 amount=-1
kerning first=269 second=97 amount=-1
kerning first=268 second=79 amount=-1
kerning first=69 second=348 amount=-1
kerning first=338 second=281 amount=-1
kerning first=356 second=244 amount=-6
kerning first=88 second=355 amount=-1
kerning first=8216 second=916 amount=-6
kerning first=1100 second=1118 amount=-2
kerning first=324 second=255 amount=-1
kerning first=256 second=268 amount=-1
kerning first=1025 second=1118 amount=-1
kerning first=219 second=46 amount=-1
kerning first=378 second=244 amount=-2
kerning first=1044 second=1035 amount=-1
kerning first=8221 second=44 amount=-7
kerning first=280 second=211 amount=-1
kerning first=45 second=221 amount=-4
kerning first=1168 second=46 amount=-5
kerning first=908 second=935 amount=-2
kerning first=276 second=235 amount=-1
kerning first=1056 second=1061 amount=-1
kerning first=334 second=372 amount=-1
kerning first=8221 second=335 amount=-5
kerning first=47 second=233 amount=-1
kerning first=374 second=112 amount=-3
kerning first=221 second=211 amount=-2
kerning first=375 second=353 amount=-1
kerning first=88 second=332 amount=-2
kerning first=89 second=233 amount=-5
kerning first=920 second=916 amount=-1
kerning first=208 second=195 amount=-1
kerning first=69 second=256 amount=-1
kerning first=219 second=44 amount=-1
kerning first=217 second=46 amount=-1
kerning first=354 second=194 amount=-6
kerning first=200 second=244 amount=-1
kerning first=80 second=347 amount=-1
kerning first=376 second=238 amount=2
kerning first=1027 second=1040 amount=-5
kerning first=313 second=212 amount=-2
kerning first=121 second=8220 amount=1
kerning first=1075 second=97 amount=-2
kerning first=293 second=8220 amount=-1
kerning first=86 second=281 amount=-4
kerning first=89 second=269 amount=-6
kerning first=1029 second=1038 amount=-1
kerning first=346 second=8220 amount=-1
kerning first=1078 second=1104 amount=-1
kerning first=339 second=382 amount=-1
kerning first=1139 second=1076 amount=-2
kerning first=1054 second=1076 amount=-2
kerning first=310 second=259 amount=-1
kerning first=258 second=332 amount=-1
kerning first=116 second=269 amount=-1
kerning first=87 second=305 amount=-2
kerning first=372 second=332 amount=-1
kerning first=256 second=354 amount=-6
kerning first=278 second=210 amount=-1
kerning first=231 second=259 amount=-1
kerning first=88 second=346 amount=-1
kerning first=47 second=226 amount=-1
kerning first=276 second=257 amount=-1
kerning first=8221 second=259 amount=-5
kerning first=200 second=357 amount=-1
kerning first=310 second=338 amount=-3
kerning first=253 second=259 amount=-1
kerning first=315 second=357 amount=-1
kerning first=65 second=366 amount=-1
kerning first=278 second=355 amount=-1
kerning first=1058 second=1101 amount=-6
kerning first=86 second=65 amount=-3
kerning first=1050 second=1066 amount=-1
kerning first=334 second=354 amount=-2
kerning first=198 second=193 amount=-1
kerning first=89 second=109 amount=-3
kerning first=197 second=284 amount=-1
kerning first=374 second=289 amount=-5
kerning first=213 second=372 amount=-1
kerning first=87 second=245 amount=-3
kerning first=194 second=372 amount=-3
kerning first=198 second=121 amount=-2
kerning first=226 second=255 amount=-1
kerning first=1026 second=1061 amount=-2
kerning first=1046 second=1108 amount=-1
kerning first=364 second=198 amount=-1
kerning first=961 second=955 amount=-1
kerning first=203 second=350 amount=-1
kerning first=376 second=290 amount=-2
kerning first=1168 second=1089 amount=-8
kerning first=102 second=245 amount=-2
kerning first=65 second=45 amount=-1
kerning first=1058 second=1083 amount=-7
kerning first=1060 second=1035 amount=-3
kerning first=373 second=353 amount=-1
kerning first=1090 second=1083 amount=-3
kerning first=88 second=333 amount=-1
kerning first=119 second=283 amount=-1
kerning first=372 second=103 amount=-2
kerning first=1038 second=59 amount=-2
kerning first=8221 second=375 amount=-2
kerning first=217 second=197 amount=-2
kerning first=70 second=299 amount=1
kerning first=376 second=251 amount=-3
kerning first=338 second=269 amount=-1
kerning first=119 second=289 amount=-1
kerning first=68 second=74 amount=-1
kerning first=971 second=949 amount=-1
kerning first=344 second=283 amount=-1
kerning first=377 second=283 amount=-1
kerning first=310 second=368 amount=-1
kerning first=374 second=8217 amount=1
kerning first=8222 second=198 amount=2
kerning first=112 second=378 amount=-1
kerning first=351 second=355 amount=-1
kerning first=99 second=228 amount=-1
kerning first=1038 second=1107 amount=-2
kerning first=312 second=335 amount=-2
kerning first=332 second=87 amount=-1
kerning first=310 second=326 amount=-1
kerning first=373 second=228 amount=-1
kerning first=1101 second=8216 amount=-1
kerning first=379 second=232 amount=-1
kerning first=196 second=87 amount=-3
kerning first=278 second=266 amount=-1
kerning first=107 second=45 amount=-3
kerning first=67 second=334 amount=-1
kerning first=1107 second=1103 amount=-1
kerning first=276 second=375 amount=-2
kerning first=203 second=99 amount=-1
kerning first=90 second=336 amount=-1
kerning first=282 second=210 amount=-1
kerning first=1075 second=1113 amount=-3
kerning first=1139 second=1090 amount=-1
kerning first=87 second=99 amount=-3
kerning first=70 second=242 amount=-1
kerning first=102 second=99 amount=-2
kerning first=8217 second=356 amount=1
kerning first=79 second=198 amount=-1
kerning first=193 second=213 amount=-1
kerning first=214 second=194 amount=-1
kerning first=1060 second=1066 amount=-3
kerning first=337 second=378 amount=-1
kerning first=210 second=46 amount=-1
kerning first=269 second=246 amount=-1
kerning first=192 second=217 amount=-1
kerning first=8217 second=371 amount=-2
kerning first=203 second=355 amount=-1
kerning first=355 second=230 amount=-1
kerning first=376 second=267 amount=-6
kerning first=947 second=955 amount=-2
kerning first=274 second=284 amount=-1
kerning first=297 second=8221 amount=4
kerning first=1043 second=1090 amount=-3
kerning first=8216 second=279 amount=-3
kerning first=8221 second=351 amount=-4
kerning first=212 second=356 amount=-2
kerning first=193 second=356 amount=-6
kerning first=8218 second=932 amount=-6
kerning first=276 second=196 amount=-1
kerning first=107 second=277 amount=-2
kerning first=1027 second=1082 amount=-5
kerning first=219 second=196 amount=-2
kerning first=354 second=336 amount=-2
kerning first=374 second=348 amount=-1
kerning first=372 second=113 amount=-3
kerning first=313 second=89 amount=-6
kerning first=378 second=121 amount=-1
kerning first=202 second=228 amount=-1
kerning first=200 second=121 amount=-2
kerning first=356 second=121 amount=-3
kerning first=8222 second=338 amount=-2
kerning first=66 second=376 amount=-2
kerning first=315 second=121 amount=-3
kerning first=200 second=224 amount=-1
kerning first=86 second=249 amount=-2
kerning first=343 second=8217 amount=3
kerning first=354 second=279 amount=-6
kerning first=195 second=71 amount=-1
kerning first=89 second=59 amount=-5
kerning first=201 second=279 amount=-1
kerning first=345 second=8221 amount=3
kerning first=84 second=241 amount=-4
kerning first=224 second=255 amount=-1
kerning first=8221 second=196 amount=-9
kerning first=377 second=277 amount=-1
kerning first=1058 second=1073 amount=-2
kerning first=282 second=224 amount=-1
kerning first=278 second=196 amount=-1
kerning first=342 second=214 amount=-1
kerning first=381 second=214 amount=-1
kerning first=282 second=337 amount=-1
kerning first=87 second=71 amount=-1
kerning first=379 second=266 amount=-1
kerning first=75 second=242 amount=-1
kerning first=80 second=377 amount=-1
kerning first=297 second=98 amount=1
kerning first=374 second=122 amount=-4
kerning first=8220 second=284 amount=-1
kerning first=274 second=111 amount=-1
kerning first=120 second=248 amount=-2
kerning first=315 second=370 amount=-2
kerning first=1054 second=1113 amount=-1
kerning first=382 second=279 amount=-2
kerning first=196 second=81 amount=-1
kerning first=265 second=225 amount=-1
kerning first=379 second=248 amount=-1
kerning first=102 second=231 amount=-2
kerning first=342 second=118 amount=-1
kerning first=1094 second=44 amount=3
kerning first=381 second=118 amount=-2
kerning first=65 second=357 amount=-2
kerning first=1168 second=1114 amount=-5
kerning first=1036 second=1077 amount=-1
kerning first=311 second=227 amount=-1
kerning first=265 second=248 amount=-1
kerning first=8216 second=336 amount=-1
kerning first=8221 second=236 amount=2
kerning first=213 second=221 amount=-2
kerning first=47 second=285 amount=-1
kerning first=290 second=221 amount=-1
kerning first=70 second=260 amount=-4
kerning first=194 second=221 amount=-5
kerning first=362 second=260 amount=-2
kerning first=86 second=244 amount=-3
kerning first=8222 second=256 amount=1
kerning first=196 second=253 amount=-1
kerning first=1044 second=1028 amount=-1
kerning first=332 second=195 amount=-1
kerning first=280 second=268 amount=-1
kerning first=282 second=101 amount=-1
kerning first=373 second=281 amount=-1
kerning first=1090 second=1086 amount=-1
kerning first=291 second=287 amount=1
kerning first=260 second=255 amount=-1
kerning first=8216 second=233 amount=-3
kerning first=1058 second=1086 amount=-7
kerning first=89 second=192 amount=-5
kerning first=334 second=65 amount=-1
kerning first=197 second=376 amount=-5
kerning first=203 second=100 amount=-1
kerning first=280 second=233 amount=-1
kerning first=221 second=287 amount=-5
kerning first=377 second=281 amount=-1
kerning first=8218 second=260 amount=1
kerning first=229 second=255 amount=-1
kerning first=344 second=281 amount=-1
kerning first=381 second=235 amount=-1
kerning first=254 second=382 amount=-1
kerning first=342 second=235 amount=-1
kerning first=338 second=255 amount=-2
kerning first=231 second=97 amount=-1
kerning first=1036 second=1091 amount=-2
kerning first=194 second=357 amount=-2
kerning first=193 second=264 amount=-1
kerning first=964 second=959 amount=-1
kerning first=929 second=902 amount=-5
kerning first=87 second=297 amount=4
kerning first=341 second=233 amount=-1
kerning first=1060 second=1046 amount=-2
kerning first=231 second=229 amount=-1
kerning first=72 second=297 amount=1
kerning first=192 second=212 amount=-1
kerning first=1059 second=1113 amount=-6
kerning first=110 second=255 amount=-1
kerning first=224 second=357 amount=-1
kerning first=8218 second=902 amount=1
kerning first=312 second=97 amount=-1
kerning first=953 second=947 amount=-1
kerning first=353 second=357 amount=-1
kerning first=338 second=210 amount=-1
kerning first=103 second=285 amount=1
kerning first=326 second=355 amount=-1
kerning first=8218 second=286 amount=-1
kerning first=84 second=301 amount=3
kerning first=376 second=258 amount=-5
kerning first=954 second=971 amount=-1
kerning first=8216 second=194 amount=-6
kerning first=76 second=199 amount=-1
kerning first=263 second=257 amount=-1
kerning first=923 second=8216 amount=-6
kerning first=221 second=115 amount=-4
kerning first=1061 second=1028 amount=-2
kerning first=1035 second=1058 amount=-4
kerning first=121 second=246 amount=-1
kerning first=260 second=308 amount=1
kerning first=374 second=264 amount=-2
kerning first=295 second=375 amount=-1
kerning first=260 second=67 amount=-1
kerning first=221 second=339 amount=-5
kerning first=280 second=226 amount=-1
kerning first=89 second=308 amount=-4
kerning first=8221 second=373 amount=-1
kerning first=1059 second=1097 amount=-2
kerning first=1058 second=1107 amount=-5
kerning first=348 second=375 amount=-1
kerning first=341 second=226 amount=-1
kerning first=75 second=375 amount=-3
kerning first=354 second=212 amount=-2
kerning first=107 second=347 amount=-1
kerning first=46 second=255 amount=-3
kerning first=278 second=259 amount=-1
kerning first=119 second=335 amount=-1
kerning first=276 second=102 amount=-2
kerning first=83 second=374 amount=-1
kerning first=1118 second=1104 amount=-1
kerning first=354 second=324 amount=-4
kerning first=308 second=88 amount=-1
kerning first=223 second=118 amount=-1
kerning first=8220 second=356 amount=3
kerning first=278 second=332 amount=-1
kerning first=8217 second=275 amount=-5
kerning first=114 second=259 amount=-1
kerning first=1038 second=1080 amount=-2
kerning first=122 second=243 amount=-2
kerning first=1138 second=1026 amount=-2
kerning first=356 second=258 amount=-6
kerning first=118 second=339 amount=-1
kerning first=70 second=286 amount=-1
kerning first=355 second=339 amount=-1
kerning first=200 second=258 amount=-1
kerning first=69 second=243 amount=-1
kerning first=84 second=243 amount=-6
kerning first=336 second=379 amount=-1
kerning first=372 second=365 amount=-2
kerning first=370 second=195 amount=-2
kerning first=196 second=364 amount=-1
kerning first=345 second=235 amount=-1
kerning first=82 second=244 amount=-1
kerning first=376 second=244 amount=-5
kerning first=338 second=338 amount=-1
kerning first=278 second=275 amount=-1
kerning first=120 second=224 amount=-1
kerning first=274 second=263 amount=-1
kerning first=1059 second=44 amount=-6
kerning first=76 second=211 amount=-2
kerning first=1024 second=1035 amount=-1
kerning first=941 second=948 amount=-1
kerning first=922 second=908 amount=-3
kerning first=932 second=46 amount=-8
kerning first=313 second=262 amount=-1
kerning first=379 second=100 amount=-1
kerning first=356 second=334 amount=-2
kerning first=374 second=106 amount=-2
kerning first=1038 second=1094 amount=-2
kerning first=114 second=275 amount=-1
kerning first=244 second=44 amount=-1
kerning first=243 second=382 amount=-1
kerning first=47 second=349 amount=-1
kerning first=908 second=918 amount=-1
kerning first=70 second=335 amount=-1
kerning first=84 second=288 amount=-2
kerning first=382 second=349 amount=-1
kerning first=1113 second=8220 amount=-4
kerning first=345 second=289 amount=-1
kerning first=69 second=288 amount=-1
kerning first=87 second=46 amount=-7
kerning first=214 second=46 amount=-1
kerning first=86 second=324 amount=-2
kerning first=341 second=44 amount=-6
kerning first=364 second=256 amount=-2
kerning first=70 second=333 amount=-1
kerning first=1078 second=1089 amount=-1
kerning first=79 second=256 amount=-1
kerning first=75 second=265 amount=-1
kerning first=68 second=44 amount=-2
kerning first=253 second=115 amount=-1
kerning first=1084 second=8216 amount=-2
kerning first=257 second=8220 amount=-1
kerning first=266 second=198 amount=1
kerning first=89 second=326 amount=-3
kerning first=8218 second=89 amount=-8
kerning first=354 second=112 amount=-4
kerning first=355 second=227 amount=-1
kerning first=118 second=227 amount=-1
kerning first=66 second=354 amount=-2
kerning first=291 second=227 amount=-1
kerning first=1106 second=1076 amount=-1
kerning first=345 second=333 amount=-1
kerning first=8220 second=81 amount=-1
kerning first=956 second=972 amount=-1
kerning first=87 second=197 amount=-3
kerning first=197 second=8220 amount=-6
kerning first=80 second=229 amount=-2
kerning first=198 second=229 amount=-1
kerning first=221 second=227 amount=-5
kerning first=76 second=85 amount=-2
kerning first=200 second=45 amount=-1
kerning first=356 second=45 amount=-6
kerning first=88 second=242 amount=-1
kerning first=86 second=290 amount=-1
kerning first=1045 second=1066 amount=-1
kerning first=258 second=210 amount=-1
kerning first=70 second=346 amount=-1
kerning first=8217 second=196 amount=-9
kerning first=8220 second=111 amount=-2
kerning first=958 second=959 amount=-1
kerning first=1107 second=45 amount=-2
kerning first=291 second=224 amount=-1
kerning first=282 second=268 amount=-1
kerning first=1169 second=1092 amount=-1
kerning first=260 second=338 amount=-1
kerning first=379 second=309 amount=1
kerning first=274 second=81 amount=-1
kerning first=198 second=334 amount=-1
kerning first=310 second=109 amount=-1
kerning first=372 second=225 amount=-2
kerning first=109 second=255 amount=-1
kerning first=203 second=245 amount=-1
kerning first=199 second=284 amount=-1
kerning first=8221 second=74 amount=-5
kerning first=210 second=193 amount=-1
kerning first=8220 second=374 amount=2
kerning first=82 second=372 amount=-1
kerning first=65 second=121 amount=-1
kerning first=197 second=67 amount=-1
kerning first=276 second=230 amount=-1
kerning first=84 second=198 amount=-6
kerning first=958 second=941 amount=-1
kerning first=8217 second=283 amount=-5
kerning first=356 second=58 amount=-5
kerning first=1061 second=1138 amount=-2
kerning first=80 second=277 amount=-1
kerning first=281 second=122 amount=-1
kerning first=1106 second=1090 amount=-2
kerning first=194 second=290 amount=-1
kerning first=198 second=277 amount=-1
kerning first=1050 second=1141 amount=-2
kerning first=102 second=378 amount=-1
kerning first=202 second=283 amount=-1
kerning first=374 second=335 amount=-5
kerning first=293 second=8216 amount=-1
kerning first=115 second=121 amount=-1
kerning first=259 second=8216 amount=-1
kerning first=68 second=196 amount=-1
kerning first=109 second=8216 amount=-1
kerning first=310 second=115 amount=-1
kerning first=1046 second=1139 amount=-1
kerning first=348 second=196 amount=-1
kerning first=221 second=369 amount=-3
kerning first=256 second=219 amount=-1
kerning first=377 second=373 amount=-1
kerning first=284 second=121 amount=-1
kerning first=366 second=193 amount=-2
kerning first=1027 second=1103 amount=-6
kerning first=380 second=283 amount=-2
kerning first=219 second=74 amount=-1
kerning first=1077 second=1083 amount=-1
kerning first=260 second=368 amount=-1
kerning first=377 second=334 amount=-1
  `,
  { 'calibri1.png': calibri1, 'calibri2.png': calibri2 },
  {
    normal: 0.5,
    bold: 0.2,
    bolder: 0.05,
    lighter: 0.9,
  }
);
