import couriernew1 from './assets/fonts/couriernew1.png';
import couriernew2 from './assets/fonts/couriernew2.png';
import { BMFont } from './bmfont';

export const couriernew = new BMFont(
  `
info face="Courier New" size=72 bold=0 italic=0 charset="" unicode=0 stretchH=100 smooth=1 aa=1 padding=5,5,5,5 spacing=-2,-2
common lineHeight=90 base=60 scaleW=1024 scaleH=1024 pages=2 packed=0
page id=0 file="couriernew1.png"
page id=1 file="couriernew2.png"
chars count=536
char id=0       x=520  y=701  width=48   height=54   xoffset=-2   yoffset=11   xadvance=52   page=0    chnl=0 
char id=13      x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=8    page=0    chnl=0 
char id=33      x=987  y=411  width=22   height=55   xoffset=11   yoffset=11   xadvance=51   page=0    chnl=0 
char id=35      x=136  y=347  width=45   height=63   xoffset=-2   yoffset=6    xadvance=51   page=0    chnl=0 
char id=36      x=821  y=75   width=39   height=68   xoffset=1    yoffset=6    xadvance=51   page=0    chnl=0 
char id=37      x=630  y=645  width=42   height=55   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=38      x=558  y=863  width=43   height=51   xoffset=2    yoffset=15   xadvance=51   page=0    chnl=0 
char id=39      x=1003 y=966  width=19   height=31   xoffset=12   yoffset=14   xadvance=51   page=0    chnl=0 
char id=40      x=181  y=347  width=26   height=63   xoffset=14   yoffset=11   xadvance=51   page=0    chnl=0 
char id=41      x=207  y=347  width=26   height=63   xoffset=3    yoffset=11   xadvance=51   page=0    chnl=0 
char id=47      x=592  y=0    width=41   height=69   xoffset=1    yoffset=4    xadvance=51   page=0    chnl=0 
char id=48      x=470  y=588  width=41   height=56   xoffset=1    yoffset=10   xadvance=51   page=0    chnl=0 
char id=49      x=672  y=645  width=41   height=55   xoffset=1    yoffset=10   xadvance=51   page=0    chnl=0 
char id=50      x=713  y=645  width=43   height=55   xoffset=-2   yoffset=10   xadvance=51   page=0    chnl=0 
char id=51      x=511  y=588  width=43   height=56   xoffset=0    yoffset=10   xadvance=51   page=0    chnl=0 
char id=52      x=568  y=701  width=42   height=54   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=53      x=756  y=645  width=43   height=55   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=54      x=554  y=588  width=41   height=56   xoffset=3    yoffset=10   xadvance=51   page=0    chnl=0 
char id=55      x=610  y=701  width=41   height=54   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=56      x=595  y=588  width=41   height=56   xoffset=1    yoffset=10   xadvance=51   page=0    chnl=0 
char id=57      x=636  y=588  width=41   height=56   xoffset=3    yoffset=10   xadvance=51   page=0    chnl=0 
char id=58      x=1002 y=530  width=21   height=41   xoffset=11   yoffset=24   xadvance=51   page=0    chnl=0 
char id=59      x=990  y=756  width=27   height=48   xoffset=9    yoffset=24   xadvance=51   page=0    chnl=0 
char id=60      x=601  y=863  width=51   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=62      x=652  y=863  width=52   height=51   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=63      x=620  y=756  width=39   height=53   xoffset=1    yoffset=13   xadvance=51   page=0    chnl=0 
char id=64      x=134  y=411  width=38   height=60   xoffset=1    yoffset=11   xadvance=51   page=0    chnl=0 
char id=65      x=704  y=863  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=66      x=763  y=863  width=50   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=67      x=659  y=756  width=47   height=53   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=68      x=813  y=863  width=49   height=51   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=69      x=862  y=863  width=48   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=70      x=910  y=863  width=49   height=51   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=71      x=706  y=756  width=50   height=53   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=72      x=959  y=863  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=73      x=0    y=915  width=41   height=51   xoffset=1    yoffset=14   xadvance=51   page=0    chnl=0 
char id=74      x=591  y=810  width=50   height=52   xoffset=-1   yoffset=14   xadvance=51   page=0    chnl=0 
char id=75      x=41   y=915  width=53   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=76      x=94   y=915  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=77      x=143  y=915  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=78      x=202  y=915  width=54   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=79      x=756  y=756  width=51   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=80      x=256  y=915  width=47   height=51   xoffset=-1   yoffset=14   xadvance=51   page=0    chnl=0 
char id=81      x=233  y=347  width=51   height=63   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=82      x=303  y=915  width=54   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=83      x=807  y=756  width=43   height=53   xoffset=0    yoffset=13   xadvance=51   page=0    chnl=0 
char id=84      x=357  y=915  width=47   height=51   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=85      x=641  y=810  width=53   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=86      x=404  y=915  width=57   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=87      x=461  y=915  width=60   height=51   xoffset=-8   yoffset=14   xadvance=51   page=0    chnl=0 
char id=88      x=521  y=915  width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=89      x=574  y=915  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=90      x=623  y=915  width=43   height=51   xoffset=0    yoffset=14   xadvance=51   page=0    chnl=0 
char id=91      x=284  y=347  width=26   height=63   xoffset=13   yoffset=11   xadvance=51   page=0    chnl=0 
char id=92      x=633  y=0    width=42   height=69   xoffset=0    yoffset=4    xadvance=51   page=0    chnl=0 
char id=93      x=310  y=347  width=26   height=63   xoffset=4    yoffset=11   xadvance=51   page=0    chnl=0 
char id=98      x=799  y=645  width=52   height=55   xoffset=-6   yoffset=11   xadvance=51   page=0    chnl=0 
char id=100     x=851  y=645  width=53   height=55   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=102     x=651  y=701  width=47   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=103     x=677  y=588  width=50   height=56   xoffset=-3   yoffset=23   xadvance=51   page=0    chnl=0 
char id=104     x=698  y=701  width=50   height=54   xoffset=-4   yoffset=11   xadvance=51   page=0    chnl=0 
char id=105     x=748  y=701  width=45   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=106     x=860  y=75   width=37   height=68   xoffset=1    yoffset=11   xadvance=51   page=0    chnl=0 
char id=107     x=793  y=701  width=48   height=54   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=108     x=841  y=701  width=45   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=112     x=727  y=588  width=52   height=56   xoffset=-6   yoffset=23   xadvance=51   page=0    chnl=0 
char id=113     x=779  y=588  width=53   height=56   xoffset=-3   yoffset=23   xadvance=51   page=0    chnl=0 
char id=116     x=850  y=756  width=47   height=53   xoffset=0    yoffset=13   xadvance=51   page=0    chnl=0 
char id=121     x=904  y=645  width=53   height=55   xoffset=-5   yoffset=24   xadvance=51   page=0    chnl=0 
char id=123     x=336  y=347  width=29   height=63   xoffset=7    yoffset=11   xadvance=51   page=0    chnl=0 
char id=124     x=365  y=347  width=17   height=63   xoffset=13   yoffset=11   xadvance=51   page=0    chnl=0 
char id=125     x=382  y=347  width=29   height=63   xoffset=7    yoffset=11   xadvance=51   page=0    chnl=0 
char id=160     x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=51   page=0    chnl=0 
char id=161     x=999  y=347  width=22   height=55   xoffset=11   yoffset=24   xadvance=51   page=0    chnl=0 
char id=162     x=411  y=347  width=40   height=63   xoffset=1    yoffset=5    xadvance=51   page=0    chnl=0 
char id=163     x=694  y=810  width=47   height=52   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=165     x=666  y=915  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=166     x=451  y=347  width=17   height=63   xoffset=13   yoffset=11   xadvance=51   page=0    chnl=0 
char id=167     x=172  y=411  width=47   height=60   xoffset=-2   yoffset=11   xadvance=51   page=0    chnl=0 
char id=169     x=741  y=810  width=53   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=174     x=794  y=810  width=53   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=177     x=279  y=530  width=47   height=57   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=181     x=957  y=645  width=50   height=55   xoffset=-4   yoffset=24   xadvance=51   page=0    chnl=0 
char id=182     x=219  y=411  width=45   height=60   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=188     x=326  y=530  width=52   height=57   xoffset=-5   yoffset=8    xadvance=51   page=0    chnl=0 
char id=189     x=378  y=530  width=62   height=57   xoffset=-7   yoffset=8    xadvance=51   page=0    chnl=0 
char id=190     x=832  y=588  width=52   height=56   xoffset=-5   yoffset=9    xadvance=51   page=0    chnl=0 
char id=191     x=0    y=701  width=39   height=55   xoffset=1    yoffset=24   xadvance=51   page=0    chnl=0 
char id=192     x=897  y=75   width=59   height=68   xoffset=-7   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=193     x=956  y=75   width=59   height=68   xoffset=-7   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=194     x=0    y=144  width=59   height=68   xoffset=-7   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=195     x=865  y=212  width=59   height=67   xoffset=-7   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=196     x=689  y=280  width=59   height=64   xoffset=-7   yoffset=1    xadvance=51   page=0    chnl=0 
char id=197     x=389  y=0    width=59   height=70   xoffset=-7   yoffset=-5   xadvance=51   page=0    chnl=0 
char id=198     x=715  y=915  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=199     x=285  y=280  width=47   height=65   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=200     x=59   y=144  width=48   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=201     x=107  y=144  width=48   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=202     x=155  y=144  width=48   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=203     x=748  y=280  width=48   height=64   xoffset=-4   yoffset=1    xadvance=51   page=0    chnl=0 
char id=204     x=203  y=144  width=41   height=68   xoffset=1    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=205     x=244  y=144  width=41   height=68   xoffset=1    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=206     x=285  y=144  width=41   height=68   xoffset=1    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=207     x=796  y=280  width=41   height=64   xoffset=1    yoffset=1    xadvance=51   page=0    chnl=0 
char id=208     x=774  y=915  width=51   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=209     x=924  y=212  width=54   height=67   xoffset=-6   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=210     x=675  y=0    width=51   height=69   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=211     x=726  y=0    width=51   height=69   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=212     x=777  y=0    width=51   height=69   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=213     x=326  y=144  width=51   height=68   xoffset=-4   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=214     x=332  y=280  width=51   height=65   xoffset=-4   yoffset=1    xadvance=51   page=0    chnl=0 
char id=216     x=264  y=411  width=53   height=60   xoffset=-5   yoffset=10   xadvance=51   page=0    chnl=0 
char id=217     x=828  y=0    width=53   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=218     x=881  y=0    width=53   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=219     x=934  y=0    width=53   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=220     x=383  y=280  width=53   height=65   xoffset=-5   yoffset=1    xadvance=51   page=0    chnl=0 
char id=221     x=377  y=144  width=49   height=68   xoffset=-3   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=222     x=825  y=915  width=47   height=51   xoffset=-1   yoffset=14   xadvance=51   page=0    chnl=0 
char id=223     x=39   y=701  width=47   height=55   xoffset=-4   yoffset=11   xadvance=51   page=0    chnl=0 
char id=224     x=843  y=411  width=48   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=225     x=891  y=411  width=48   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=226     x=939  y=411  width=48   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=227     x=440  y=530  width=48   height=57   xoffset=-2   yoffset=9    xadvance=51   page=0    chnl=0 
char id=228     x=886  y=701  width=48   height=54   xoffset=-2   yoffset=12   xadvance=51   page=0    chnl=0 
char id=229     x=317  y=411  width=48   height=60   xoffset=-2   yoffset=6    xadvance=51   page=0    chnl=0 
char id=231     x=86   y=701  width=47   height=55   xoffset=-1   yoffset=23   xadvance=51   page=0    chnl=0 
char id=232     x=0    y=472  width=48   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=233     x=48   y=472  width=48   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=234     x=96   y=472  width=48   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=235     x=934  y=701  width=48   height=54   xoffset=-3   yoffset=12   xadvance=51   page=0    chnl=0 
char id=236     x=488  y=530  width=45   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=237     x=533  y=530  width=45   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=238     x=578  y=530  width=45   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=239     x=897  y=756  width=45   height=53   xoffset=-1   yoffset=12   xadvance=51   page=0    chnl=0 
char id=240     x=133  y=701  width=46   height=55   xoffset=-2   yoffset=11   xadvance=51   page=0    chnl=0 
char id=241     x=884  y=588  width=49   height=56   xoffset=-3   yoffset=9    xadvance=51   page=0    chnl=0 
char id=242     x=144  y=472  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=243     x=191  y=472  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=244     x=238  y=472  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=245     x=623  y=530  width=47   height=57   xoffset=-2   yoffset=9    xadvance=51   page=0    chnl=0 
char id=246     x=0    y=756  width=47   height=54   xoffset=-2   yoffset=12   xadvance=51   page=0    chnl=0 
char id=249     x=285  y=472  width=50   height=58   xoffset=-4   yoffset=8    xadvance=51   page=0    chnl=0 
char id=250     x=335  y=472  width=50   height=58   xoffset=-4   yoffset=8    xadvance=51   page=0    chnl=0 
char id=251     x=385  y=472  width=50   height=58   xoffset=-4   yoffset=8    xadvance=51   page=0    chnl=0 
char id=252     x=47   y=756  width=50   height=54   xoffset=-4   yoffset=12   xadvance=51   page=0    chnl=0 
char id=253     x=50   y=0    width=53   height=71   xoffset=-5   yoffset=8    xadvance=51   page=0    chnl=0 
char id=254     x=426  y=144  width=52   height=68   xoffset=-6   yoffset=11   xadvance=51   page=0    chnl=0 
char id=255     x=0    y=280  width=53   height=67   xoffset=-5   yoffset=12   xadvance=51   page=0    chnl=0 
char id=256     x=572  y=347  width=59   height=62   xoffset=-7   yoffset=3    xadvance=51   page=0    chnl=0 
char id=257     x=847  y=810  width=48   height=52   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=258     x=478  y=144  width=59   height=68   xoffset=-7   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=259     x=435  y=472  width=48   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=260     x=365  y=411  width=64   height=60   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=261     x=872  y=915  width=52   height=51   xoffset=-2   yoffset=23   xadvance=51   page=0    chnl=0 
char id=262     x=0    y=75   width=47   height=69   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=263     x=483  y=472  width=47   height=58   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=264     x=47   y=75   width=47   height=69   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=265     x=530  y=472  width=47   height=58   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=266     x=537  y=144  width=47   height=68   xoffset=-2   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=267     x=670  y=530  width=47   height=57   xoffset=-1   yoffset=9    xadvance=51   page=0    chnl=0 
char id=268     x=94   y=75   width=47   height=69   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=269     x=577  y=472  width=47   height=58   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=270     x=584  y=144  width=49   height=68   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=271     x=179  y=701  width=65   height=55   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=272     x=924  y=915  width=51   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=273     x=244  y=701  width=56   height=55   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=274     x=631  y=347  width=48   height=62   xoffset=-4   yoffset=3    xadvance=51   page=0    chnl=0 
char id=275     x=895  y=810  width=48   height=52   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=276     x=633  y=144  width=48   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=277     x=624  y=472  width=48   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=278     x=53   y=280  width=48   height=67   xoffset=-4   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=279     x=717  y=530  width=48   height=57   xoffset=-3   yoffset=9    xadvance=51   page=0    chnl=0 
char id=280     x=429  y=411  width=51   height=60   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=281     x=975  y=915  width=48   height=51   xoffset=-3   yoffset=23   xadvance=51   page=0    chnl=0 
char id=282     x=681  y=144  width=48   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=283     x=672  y=472  width=48   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=284     x=141  y=75   width=50   height=69   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=285     x=103  y=0    width=50   height=71   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=286     x=191  y=75   width=50   height=69   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=287     x=153  y=0    width=50   height=71   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=288     x=729  y=144  width=50   height=68   xoffset=-2   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=289     x=448  y=0    width=50   height=70   xoffset=-3   yoffset=9    xadvance=51   page=0    chnl=0 
char id=290     x=235  y=280  width=50   height=66   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=291     x=0    y=0    width=50   height=75   xoffset=-3   yoffset=4    xadvance=51   page=0    chnl=0 
char id=292     x=779  y=144  width=49   height=68   xoffset=-3   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=293     x=241  y=75   width=50   height=69   xoffset=-4   yoffset=-4   xadvance=51   page=0    chnl=0 
char id=294     x=0    y=966  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=295     x=97   y=756  width=51   height=54   xoffset=-5   yoffset=11   xadvance=51   page=0    chnl=0 
char id=296     x=978  y=212  width=41   height=67   xoffset=1    yoffset=-2   xadvance=51   page=0    chnl=0 
char id=297     x=933  y=588  width=45   height=56   xoffset=-1   yoffset=9    xadvance=51   page=0    chnl=0 
char id=298     x=679  y=347  width=41   height=62   xoffset=1    yoffset=3    xadvance=51   page=0    chnl=0 
char id=299     x=49   y=966  width=45   height=51   xoffset=-1   yoffset=14   xadvance=51   page=0    chnl=0 
char id=300     x=828  y=144  width=41   height=68   xoffset=1    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=301     x=765  y=530  width=45   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=302     x=808  y=347  width=41   height=61   xoffset=1    yoffset=14   xadvance=51   page=0    chnl=0 
char id=303     x=837  y=280  width=43   height=64   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=304     x=101  y=280  width=41   height=67   xoffset=1    yoffset=-2   xadvance=51   page=0    chnl=0 
char id=306     x=943  y=810  width=54   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=307     x=869  y=144  width=53   height=68   xoffset=-7   yoffset=11   xadvance=51   page=0    chnl=0 
char id=308     x=291  y=75   width=50   height=69   xoffset=-1   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=309     x=203  y=0    width=37   height=71   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=310     x=436  y=280  width=53   height=65   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=311     x=922  y=144  width=48   height=68   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=313     x=970  y=144  width=49   height=68   xoffset=-3   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=314     x=341  y=75   width=45   height=69   xoffset=-1   yoffset=-4   xadvance=51   page=0    chnl=0 
char id=315     x=489  y=280  width=49   height=65   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=316     x=0    y=212  width=45   height=68   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=317     x=94   y=966  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=318     x=148  y=756  width=45   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=319     x=143  y=966  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=320     x=193  y=756  width=46   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=321     x=192  y=966  width=51   height=51   xoffset=-4   yoffset=14   xadvance=52   page=0    chnl=0 
char id=322     x=239  y=756  width=45   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=323     x=45   y=212  width=54   height=68   xoffset=-6   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=324     x=810  y=530  width=49   height=57   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=325     x=538  y=280  width=54   height=65   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=326     x=0    y=645  width=49   height=56   xoffset=-3   yoffset=23   xadvance=51   page=0    chnl=0 
char id=327     x=99   y=212  width=54   height=68   xoffset=-6   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=328     x=859  y=530  width=49   height=57   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=329     x=0    y=863  width=53   height=52   xoffset=-6   yoffset=13   xadvance=51   page=0    chnl=0 
char id=330     x=284  y=756  width=49   height=54   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=331     x=978  y=588  width=45   height=56   xoffset=-2   yoffset=23   xadvance=51   page=0    chnl=0 
char id=332     x=468  y=347  width=51   height=63   xoffset=-4   yoffset=3    xadvance=51   page=0    chnl=0 
char id=333     x=53   y=863  width=47   height=52   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=334     x=386  y=75   width=51   height=69   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=335     x=720  y=472  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=336     x=437  y=75   width=51   height=69   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=337     x=767  y=472  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=338     x=243  y=966  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=340     x=153  y=212  width=54   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=341     x=908  y=530  width=47   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=342     x=592  y=280  width=54   height=65   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=343     x=49   y=645  width=47   height=56   xoffset=-1   yoffset=23   xadvance=51   page=0    chnl=0 
char id=344     x=207  y=212  width=54   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=345     x=955  y=530  width=47   height=57   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=346     x=488  y=75   width=43   height=69   xoffset=0    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=347     x=814  y=472  width=42   height=58   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=348     x=531  y=75   width=43   height=69   xoffset=0    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=349     x=856  y=472  width=42   height=58   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=350     x=646  y=280  width=43   height=65   xoffset=0    yoffset=13   xadvance=51   page=0    chnl=0 
char id=351     x=300  y=701  width=42   height=55   xoffset=1    yoffset=23   xadvance=51   page=0    chnl=0 
char id=352     x=574  y=75   width=43   height=69   xoffset=0    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=353     x=898  y=472  width=42   height=58   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=354     x=498  y=0    width=47   height=70   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=355     x=545  y=0    width=47   height=70   xoffset=0    yoffset=13   xadvance=51   page=0    chnl=0 
char id=356     x=261  y=212  width=47   height=68   xoffset=-2   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=357     x=96   y=645  width=55   height=56   xoffset=0    yoffset=10   xadvance=51   page=0    chnl=0 
char id=358     x=302  y=966  width=47   height=51   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=359     x=942  y=756  width=48   height=53   xoffset=-1   yoffset=13   xadvance=51   page=0    chnl=0 
char id=360     x=308  y=212  width=53   height=68   xoffset=-5   yoffset=-2   xadvance=51   page=0    chnl=0 
char id=361     x=0    y=588  width=50   height=57   xoffset=-4   yoffset=9    xadvance=51   page=0    chnl=0 
char id=362     x=519  y=347  width=53   height=63   xoffset=-5   yoffset=3    xadvance=51   page=0    chnl=0 
char id=363     x=100  y=863  width=50   height=52   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=364     x=617  y=75   width=53   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=365     x=940  y=472  width=50   height=58   xoffset=-4   yoffset=8    xadvance=51   page=0    chnl=0 
char id=366     x=240  y=0    width=53   height=71   xoffset=-5   yoffset=-5   xadvance=51   page=0    chnl=0 
char id=367     x=480  y=411  width=50   height=60   xoffset=-4   yoffset=6    xadvance=51   page=0    chnl=0 
char id=368     x=670  y=75   width=53   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=369     x=0    y=530  width=50   height=58   xoffset=-4   yoffset=8    xadvance=51   page=0    chnl=0 
char id=370     x=849  y=347  width=51   height=61   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=371     x=349  y=966  width=51   height=51   xoffset=-4   yoffset=24   xadvance=51   page=0    chnl=0 
char id=372     x=361  y=212  width=60   height=68   xoffset=-8   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=373     x=50   y=588  width=53   height=57   xoffset=-5   yoffset=8    xadvance=51   page=0    chnl=0 
char id=374     x=421  y=212  width=49   height=68   xoffset=-3   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=375     x=293  y=0    width=53   height=71   xoffset=-5   yoffset=8    xadvance=51   page=0    chnl=0 
char id=376     x=880  y=280  width=49   height=64   xoffset=-3   yoffset=1    xadvance=51   page=0    chnl=0 
char id=377     x=470  y=212  width=43   height=68   xoffset=0    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=378     x=103  y=588  width=41   height=57   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=379     x=142  y=280  width=43   height=67   xoffset=0    yoffset=-2   xadvance=51   page=0    chnl=0 
char id=380     x=151  y=645  width=41   height=56   xoffset=1    yoffset=9    xadvance=51   page=0    chnl=0 
char id=381     x=513  y=212  width=43   height=68   xoffset=0    yoffset=-3   xadvance=51   page=0    chnl=0 
char id=382     x=144  y=588  width=41   height=57   xoffset=1    yoffset=8    xadvance=51   page=0    chnl=0 
char id=383     x=333  y=756  width=46   height=54   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=902     x=400  y=966  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=904     x=459  y=966  width=51   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=905     x=510  y=966  width=52   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=906     x=562  y=966  width=49   height=51   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=908     x=0    y=810  width=52   height=53   xoffset=-5   yoffset=13   xadvance=51   page=0    chnl=0 
char id=910     x=611  y=966  width=54   height=51   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=911     x=150  y=863  width=52   height=52   xoffset=-5   yoffset=13   xadvance=51   page=0    chnl=0 
char id=912     x=720  y=347  width=39   height=62   xoffset=0    yoffset=4    xadvance=51   page=0    chnl=0 
char id=913     x=704  y=863  width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=0    chnl=0 
char id=914     x=763  y=863  width=50   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=915     x=665  y=966  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=916     x=714  y=966  width=55   height=51   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=917     x=769  y=966  width=48   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=918     x=817  y=966  width=43   height=51   xoffset=0    yoffset=14   xadvance=51   page=0    chnl=0 
char id=919     x=860  y=966  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=920     x=52   y=810  width=51   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=921     x=909  y=966  width=41   height=51   xoffset=1    yoffset=14   xadvance=51   page=0    chnl=0 
char id=922     x=950  y=966  width=53   height=51   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=927     x=103  y=810  width=51   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=937     x=202  y=863  width=51   height=52   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=938     x=929  y=280  width=41   height=64   xoffset=1    yoffset=1    xadvance=51   page=0    chnl=0 
char id=939     x=970  y=280  width=49   height=64   xoffset=-3   yoffset=1    xadvance=51   page=0    chnl=0 
char id=940     x=50   y=530  width=48   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=941     x=98   y=530  width=38   height=58   xoffset=2    yoffset=8    xadvance=51   page=0    chnl=0 
char id=942     x=346  y=0    width=43   height=71   xoffset=-1   yoffset=8    xadvance=51   page=0    chnl=0 
char id=943     x=990  y=472  width=25   height=58   xoffset=11   yoffset=8    xadvance=51   page=0    chnl=0 
char id=944     x=759  y=347  width=49   height=62   xoffset=-3   yoffset=4    xadvance=51   page=0    chnl=0 
char id=946     x=556  y=212  width=39   height=68   xoffset=2    yoffset=11   xadvance=51   page=0    chnl=0 
char id=947     x=185  y=588  width=50   height=57   xoffset=-3   yoffset=24   xadvance=51   page=0    chnl=0 
char id=948     x=342  y=701  width=43   height=55   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=950     x=987  y=0    width=35   height=68   xoffset=6    yoffset=11   xadvance=51   page=0    chnl=0 
char id=951     x=192  y=645  width=43   height=56   xoffset=-1   yoffset=23   xadvance=51   page=0    chnl=0 
char id=952     x=385  y=701  width=43   height=55   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=953     x=997  y=810  width=25   height=43   xoffset=11   yoffset=23   xadvance=51   page=0    chnl=0 
char id=955     x=235  y=645  width=50   height=56   xoffset=-3   yoffset=9    xadvance=51   page=0    chnl=0 
char id=956     x=428  y=701  width=50   height=55   xoffset=-4   yoffset=24   xadvance=51   page=0    chnl=0 
char id=958     x=595  y=212  width=34   height=68   xoffset=6    yoffset=11   xadvance=51   page=0    chnl=0 
char id=961     x=285  y=645  width=44   height=56   xoffset=1    yoffset=23   xadvance=51   page=0    chnl=0 
char id=962     x=235  y=588  width=42   height=57   xoffset=1    yoffset=23   xadvance=51   page=0    chnl=0 
char id=966     x=329  y=645  width=47   height=56   xoffset=-2   yoffset=23   xadvance=51   page=0    chnl=0 
char id=967     x=376  y=645  width=50   height=56   xoffset=-3   yoffset=23   xadvance=51   page=0    chnl=0 
char id=968     x=629  y=212  width=47   height=68   xoffset=-2   yoffset=11   xadvance=51   page=0    chnl=0 
char id=970     x=982  y=701  width=32   height=54   xoffset=4    yoffset=12   xadvance=51   page=0    chnl=0 
char id=971     x=379  y=756  width=49   height=54   xoffset=-3   yoffset=12   xadvance=51   page=0    chnl=0 
char id=972     x=136  y=530  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=973     x=183  y=530  width=49   height=58   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=974     x=232  y=530  width=47   height=58   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=1025    x=0    y=347  width=48   height=64   xoffset=-4   yoffset=1    xadvance=51   page=0    chnl=0 
char id=1026    x=253  y=863  width=52   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1027    x=676  y=212  width=49   height=68   xoffset=-3   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=1028    x=154  y=810  width=48   height=53   xoffset=-3   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1029    x=202  y=810  width=43   height=53   xoffset=0    yoffset=13   xadvance=51   page=0    chnl=0 
char id=1031    x=48   y=347  width=41   height=64   xoffset=1    yoffset=1    xadvance=51   page=0    chnl=0 
char id=1032    x=305  y=863  width=50   height=52   xoffset=-1   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1033    x=355  y=863  width=52   height=52   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1036    x=725  y=212  width=52   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=1038    x=723  y=75   width=54   height=69   xoffset=-5   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=1039    x=794  y=411  width=49   height=59   xoffset=-3   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1044    x=530  y=411  width=51   height=60   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1047    x=426  y=645  width=50   height=56   xoffset=-5   yoffset=10   xadvance=51   page=0    chnl=0 
char id=1049    x=777  y=212  width=51   height=68   xoffset=-4   yoffset=-3   xadvance=51   page=0    chnl=0 
char id=1051    x=407  y=863  width=50   height=52   xoffset=-4   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1054    x=103  y=810  width=51   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1057    x=245  y=810  width=47   height=53   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1059    x=457  y=863  width=54   height=52   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1062    x=581  y=411  width=52   height=60   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1065    x=633  y=411  width=58   height=60   xoffset=-6   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1069    x=292  y=810  width=48   height=53   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1070    x=340  y=810  width=57   height=53   xoffset=-6   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1073    x=277  y=588  width=45   height=57   xoffset=-1   yoffset=9    xadvance=51   page=0    chnl=0 
char id=1081    x=322  y=588  width=51   height=57   xoffset=-5   yoffset=8    xadvance=51   page=0    chnl=0 
char id=1088    x=476  y=645  width=52   height=56   xoffset=-6   yoffset=23   xadvance=51   page=0    chnl=0 
char id=1092    x=89   y=347  width=47   height=64   xoffset=-2   yoffset=11   xadvance=51   page=0    chnl=0 
char id=1105    x=428  y=756  width=48   height=54   xoffset=-3   yoffset=12   xadvance=51   page=0    chnl=0 
char id=1106    x=777  y=75   width=44   height=69   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=1107    x=373  y=588  width=49   height=57   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=1110    x=476  y=756  width=45   height=54   xoffset=-1   yoffset=11   xadvance=51   page=0    chnl=0 
char id=1111    x=397  y=810  width=45   height=53   xoffset=-1   yoffset=12   xadvance=51   page=0    chnl=0 
char id=1112    x=828  y=212  width=37   height=68   xoffset=1    yoffset=11   xadvance=51   page=0    chnl=0 
char id=1115    x=521  y=756  width=49   height=54   xoffset=-3   yoffset=11   xadvance=51   page=0    chnl=0 
char id=1116    x=422  y=588  width=48   height=57   xoffset=-2   yoffset=8    xadvance=51   page=0    chnl=0 
char id=1118    x=185  y=280  width=50   height=67   xoffset=-3   yoffset=8    xadvance=51   page=0    chnl=0 
char id=1168    x=900  y=347  width=49   height=61   xoffset=-3   yoffset=4    xadvance=51   page=0    chnl=0 
char id=1174    x=691  y=411  width=53   height=60   xoffset=-5   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1178    x=744  y=411  width=50   height=60   xoffset=-2   yoffset=14   xadvance=51   page=0    chnl=0 
char id=1186    x=949  y=347  width=50   height=61   xoffset=-3   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1199    x=528  y=645  width=51   height=56   xoffset=-4   yoffset=24   xadvance=51   page=0    chnl=0 
char id=1201    x=579  y=645  width=51   height=56   xoffset=-4   yoffset=24   xadvance=51   page=0    chnl=0 
char id=1202    x=0    y=411  width=52   height=61   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1240    x=442  y=810  width=49   height=53   xoffset=-3   yoffset=13   xadvance=51   page=0    chnl=0 
char id=1256    x=491  y=810  width=51   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=8204    x=0    y=0    width=0    height=0    xoffset=-5   yoffset=0    xadvance=8    page=0    chnl=0 
char id=8224    x=52   y=411  width=41   height=61   xoffset=1    yoffset=11   xadvance=51   page=0    chnl=0 
char id=8225    x=93   y=411  width=41   height=61   xoffset=1    yoffset=11   xadvance=51   page=0    chnl=0 
char id=8240    x=570  y=756  width=50   height=54   xoffset=-4   yoffset=11   xadvance=51   page=0    chnl=0 
char id=8252    x=478  y=701  width=42   height=55   xoffset=0    yoffset=11   xadvance=51   page=0    chnl=0 
char id=8356    x=511  y=863  width=47   height=52   xoffset=-2   yoffset=13   xadvance=51   page=0    chnl=0 
char id=8364    x=542  y=810  width=49   height=53   xoffset=-4   yoffset=13   xadvance=51   page=0    chnl=0 
char id=34      x=196  y=279  width=34   height=31   xoffset=4    yoffset=14   xadvance=51   page=1    chnl=0 
char id=42      x=653  y=238  width=39   height=40   xoffset=2    yoffset=11   xadvance=51   page=1    chnl=0 
char id=43      x=661  y=102  width=47   height=47   xoffset=-2   yoffset=16   xadvance=51   page=1    chnl=0 
char id=44      x=230  y=279  width=24   height=31   xoffset=10   yoffset=45   xadvance=51   page=1    chnl=0 
char id=45      x=889  y=279  width=41   height=17   xoffset=1    yoffset=31   xadvance=51   page=1    chnl=0 
char id=46      x=826  y=279  width=21   height=20   xoffset=11   yoffset=46   xadvance=51   page=1    chnl=0 
char id=61      x=534  y=279  width=50   height=30   xoffset=-4   yoffset=25   xadvance=51   page=1    chnl=0 
char id=94      x=971  y=238  width=41   height=34   xoffset=1    yoffset=7    xadvance=51   page=1    chnl=0 
char id=95      x=930  y=279  width=64   height=17   xoffset=-8   yoffset=70   xadvance=51   page=1    chnl=0 
char id=96      x=778  y=279  width=24   height=21   xoffset=9    yoffset=8    xadvance=51   page=1    chnl=0 
char id=97      x=794  y=102  width=48   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=99      x=842  y=102  width=47   height=43   xoffset=-1   yoffset=23   xadvance=51   page=1    chnl=0 
char id=101     x=889  y=102  width=48   height=43   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=109     x=731  y=153  width=58   height=42   xoffset=-6   yoffset=23   xadvance=51   page=1    chnl=0 
char id=110     x=789  y=153  width=49   height=42   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=111     x=937  y=102  width=47   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=114     x=838  y=153  width=47   height=42   xoffset=-1   yoffset=23   xadvance=51   page=1    chnl=0 
char id=115     x=0    y=153  width=42   height=43   xoffset=1    yoffset=23   xadvance=51   page=1    chnl=0 
char id=117     x=885  y=153  width=50   height=42   xoffset=-4   yoffset=24   xadvance=51   page=1    chnl=0 
char id=118     x=235  y=196  width=53   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=119     x=288  y=196  width=53   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=120     x=341  y=196  width=50   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=122     x=391  y=196  width=41   height=41   xoffset=1    yoffset=24   xadvance=51   page=1    chnl=0 
char id=126     x=648  y=279  width=44   height=25   xoffset=-1   yoffset=27   xadvance=51   page=1    chnl=0 
char id=164     x=935  y=153  width=42   height=42   xoffset=0    yoffset=19   xadvance=51   page=1    chnl=0 
char id=168     x=0    y=313  width=31   height=17   xoffset=5    yoffset=10   xadvance=51   page=1    chnl=0 
char id=170     x=52   y=279  width=35   height=32   xoffset=4    yoffset=8    xadvance=51   page=1    chnl=0 
char id=171     x=692  y=238  width=46   height=39   xoffset=-3   yoffset=26   xadvance=51   page=1    chnl=0 
char id=172     x=0    y=279  width=52   height=34   xoffset=-6   yoffset=31   xadvance=51   page=1    chnl=0 
char id=175     x=31   y=313  width=64   height=17   xoffset=-8   yoffset=0    xadvance=51   page=1    chnl=0 
char id=176     x=254  y=279  width=31   height=31   xoffset=5    yoffset=3    xadvance=51   page=1    chnl=0 
char id=178     x=883  y=238  width=29   height=35   xoffset=6    yoffset=8    xadvance=51   page=1    chnl=0 
char id=179     x=912  y=238  width=30   height=35   xoffset=7    yoffset=8    xadvance=51   page=1    chnl=0 
char id=180     x=802  y=279  width=24   height=21   xoffset=9    yoffset=7    xadvance=51   page=1    chnl=0 
char id=183     x=847  y=279  width=21   height=20   xoffset=11   yoffset=27   xadvance=51   page=1    chnl=0 
char id=184     x=692  y=279  width=24   height=25   xoffset=9    yoffset=53   xadvance=51   page=1    chnl=0 
char id=185     x=942  y=238  width=29   height=35   xoffset=7    yoffset=8    xadvance=51   page=1    chnl=0 
char id=186     x=87   y=279  width=35   height=32   xoffset=4    yoffset=8    xadvance=51   page=1    chnl=0 
char id=187     x=738  y=238  width=46   height=39   xoffset=0    yoffset=26   xadvance=51   page=1    chnl=0 
char id=215     x=784  y=238  width=39   height=39   xoffset=2    yoffset=20   xadvance=51   page=1    chnl=0 
char id=230     x=42   y=153  width=59   height=43   xoffset=-7   yoffset=23   xadvance=51   page=1    chnl=0 
char id=247     x=516  y=102  width=47   height=49   xoffset=-2   yoffset=16   xadvance=51   page=1    chnl=0 
char id=248     x=563  y=102  width=49   height=49   xoffset=-3   yoffset=21   xadvance=51   page=1    chnl=0 
char id=305     x=432  y=196  width=45   height=41   xoffset=-1   yoffset=24   xadvance=51   page=1    chnl=0 
char id=312     x=477  y=196  width=50   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=339     x=101  y=153  width=58   height=43   xoffset=-7   yoffset=23   xadvance=51   page=1    chnl=0 
char id=900     x=755  y=279  width=23   height=22   xoffset=10   yoffset=7    xadvance=51   page=1    chnl=0 
char id=901     x=716  y=279  width=39   height=25   xoffset=2    yoffset=6    xadvance=51   page=1    chnl=0 
char id=903     x=868  y=279  width=21   height=20   xoffset=11   yoffset=22   xadvance=51   page=1    chnl=0 
char id=923     x=0    y=0    width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=1    chnl=0 
char id=924     x=59   y=0    width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=1    chnl=0 
char id=925     x=118  y=0    width=54   height=51   xoffset=-6   yoffset=14   xadvance=51   page=1    chnl=0 
char id=926     x=172  y=0    width=46   height=51   xoffset=-1   yoffset=14   xadvance=51   page=1    chnl=0 
char id=928     x=218  y=0    width=50   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=929     x=268  y=0    width=47   height=51   xoffset=-1   yoffset=14   xadvance=51   page=1    chnl=0 
char id=931     x=315  y=0    width=46   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=932     x=361  y=0    width=47   height=51   xoffset=-2   yoffset=14   xadvance=51   page=1    chnl=0 
char id=933     x=408  y=0    width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=934     x=457  y=0    width=47   height=51   xoffset=-2   yoffset=14   xadvance=51   page=1    chnl=0 
char id=935     x=504  y=0    width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=936     x=557  y=0    width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=945     x=159  y=153  width=48   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=949     x=984  y=102  width=38   height=43   xoffset=2    yoffset=23   xadvance=51   page=1    chnl=0 
char id=954     x=527  y=196  width=45   height=41   xoffset=0    yoffset=24   xadvance=51   page=1    chnl=0 
char id=957     x=977  y=153  width=45   height=42   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=959     x=207  y=153  width=47   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=960     x=572  y=196  width=49   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=963     x=0    y=196  width=48   height=42   xoffset=-1   yoffset=24   xadvance=51   page=1    chnl=0 
char id=964     x=48   y=196  width=37   height=42   xoffset=2    yoffset=24   xadvance=51   page=1    chnl=0 
char id=965     x=85   y=196  width=49   height=42   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=969     x=254  y=153  width=47   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1030    x=610  y=0    width=41   height=51   xoffset=1    yoffset=14   xadvance=51   page=1    chnl=0 
char id=1034    x=651  y=0    width=57   height=51   xoffset=-6   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1035    x=708  y=0    width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1040    x=761  y=0    width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1041    x=820  y=0    width=50   height=51   xoffset=-4   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1042    x=870  y=0    width=50   height=51   xoffset=-4   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1043    x=920  y=0    width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1046    x=969  y=0    width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1050    x=0    y=51   width=52   height=51   xoffset=-4   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1052    x=59   y=0    width=59   height=51   xoffset=-7   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1053    x=52   y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1055    x=101  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1056    x=150  y=51   width=47   height=51   xoffset=-1   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1058    x=197  y=51   width=47   height=51   xoffset=-2   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1060    x=244  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1061    x=293  y=51   width=53   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1063    x=346  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1064    x=395  y=51   width=54   height=51   xoffset=-5   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1066    x=449  y=51   width=55   height=51   xoffset=-6   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1067    x=504  y=51   width=60   height=51   xoffset=-7   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1068    x=564  y=51   width=50   height=51   xoffset=-4   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1071    x=614  y=51   width=54   height=51   xoffset=-6   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1072    x=301  y=153  width=48   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1074    x=621  y=196  width=47   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1075    x=668  y=196  width=49   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1076    x=150  y=102  width=49   height=50   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1077    x=349  y=153  width=48   height=43   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1078    x=717  y=196  width=53   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1079    x=748  y=102  width=46   height=46   xoffset=-3   yoffset=20   xadvance=51   page=1    chnl=0 
char id=1080    x=770  y=196  width=51   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1082    x=821  y=196  width=48   height=41   xoffset=-2   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1083    x=134  y=196  width=49   height=42   xoffset=-4   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1084    x=869  y=196  width=55   height=41   xoffset=-6   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1085    x=924  y=196  width=49   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1086    x=207  y=153  width=47   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1087    x=973  y=196  width=49   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1089    x=397  y=153  width=47   height=43   xoffset=-1   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1090    x=0    y=238  width=47   height=41   xoffset=-2   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1091    x=668  y=51   width=50   height=51   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1093    x=47   y=238  width=50   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1094    x=199  y=102  width=52   height=50   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1095    x=97   y=238  width=48   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1096    x=145  y=238  width=56   height=41   xoffset=-6   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1097    x=251  y=102  width=61   height=50   xoffset=-7   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1098    x=201  y=238  width=52   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1099    x=253  y=238  width=52   height=41   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1100    x=305  y=238  width=47   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1101    x=444  y=153  width=47   height=43   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1102    x=491  y=153  width=56   height=43   xoffset=-6   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1103    x=352  y=238  width=50   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1108    x=547  y=153  width=47   height=43   xoffset=-1   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1109    x=594  y=153  width=42   height=43   xoffset=1    yoffset=23   xadvance=51   page=1    chnl=0 
char id=1113    x=183  y=196  width=52   height=42   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1114    x=402  y=238  width=58   height=41   xoffset=-6   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1119    x=612  y=102  width=49   height=49   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1169    x=718  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1170    x=767  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1171    x=460  y=238  width=49   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1175    x=312  y=102  width=53   height=50   xoffset=-5   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1179    x=365  y=102  width=50   height=50   xoffset=-2   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1180    x=816  y=51   width=50   height=51   xoffset=-2   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1181    x=509  y=238  width=48   height=41   xoffset=-2   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1187    x=415  y=102  width=50   height=50   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1198    x=866  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1200    x=915  y=51   width=51   height=51   xoffset=-4   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1203    x=465  y=102  width=51   height=50   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1208    x=966  y=51   width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1209    x=557  y=238  width=48   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1210    x=0    y=102  width=49   height=51   xoffset=-3   yoffset=14   xadvance=51   page=1    chnl=0 
char id=1211    x=605  y=238  width=48   height=41   xoffset=-3   yoffset=24   xadvance=51   page=1    chnl=0 
char id=1241    x=636  y=153  width=48   height=43   xoffset=-3   yoffset=23   xadvance=51   page=1    chnl=0 
char id=1257    x=684  y=153  width=47   height=43   xoffset=-2   yoffset=23   xadvance=51   page=1    chnl=0 
char id=8211    x=95   y=313  width=47   height=17   xoffset=-2   yoffset=31   xadvance=51   page=1    chnl=0 
char id=8212    x=142  y=313  width=61   height=17   xoffset=-7   yoffset=31   xadvance=51   page=1    chnl=0 
char id=8213    x=203  y=313  width=50   height=17   xoffset=-4   yoffset=31   xadvance=51   page=1    chnl=0 
char id=8215    x=584  y=279  width=64   height=29   xoffset=-8   yoffset=61   xadvance=51   page=1    chnl=0 
char id=8216    x=285  y=279  width=24   height=31   xoffset=14   yoffset=13   xadvance=51   page=1    chnl=0 
char id=8217    x=309  y=279  width=24   height=31   xoffset=5    yoffset=13   xadvance=51   page=1    chnl=0 
char id=8218    x=333  y=279  width=24   height=31   xoffset=5    yoffset=45   xadvance=51   page=1    chnl=0 
char id=8219    x=285  y=279  width=24   height=31   xoffset=14   yoffset=13   xadvance=51   page=1    chnl=0 
char id=8220    x=357  y=279  width=40   height=31   xoffset=0    yoffset=13   xadvance=51   page=1    chnl=0 
char id=8221    x=397  y=279  width=40   height=31   xoffset=2    yoffset=13   xadvance=51   page=1    chnl=0 
char id=8222    x=437  y=279  width=40   height=31   xoffset=2    yoffset=46   xadvance=51   page=1    chnl=0 
char id=8226    x=122  y=279  width=32   height=32   xoffset=6    yoffset=23   xadvance=51   page=1    chnl=0 
char id=8230    x=253  y=313  width=45   height=17   xoffset=-2   yoffset=49   xadvance=51   page=1    chnl=0 
char id=8242    x=477  y=279  width=21   height=31   xoffset=13   yoffset=14   xadvance=51   page=1    chnl=0 
char id=8243    x=498  y=279  width=36   height=31   xoffset=3    yoffset=14   xadvance=52   page=1    chnl=0 
char id=8249    x=823  y=238  width=30   height=39   xoffset=4    yoffset=25   xadvance=51   page=1    chnl=0 
char id=8250    x=853  y=238  width=30   height=39   xoffset=8    yoffset=25   xadvance=51   page=1    chnl=0 
char id=8254    x=298  y=313  width=37   height=15   xoffset=3    yoffset=14   xadvance=51   page=1    chnl=0 
char id=8260    x=154  y=279  width=42   height=32   xoffset=0    yoffset=22   xadvance=51   page=1    chnl=0 
char id=8355    x=49   y=102  width=48   height=51   xoffset=-1   yoffset=14   xadvance=51   page=1    chnl=0 
char id=8359    x=97   y=102  width=53   height=51   xoffset=-6   yoffset=14   xadvance=51   page=1    chnl=0 
char id=8363    x=708  y=102  width=40   height=47   xoffset=2    yoffset=10   xadvance=51   page=1    chnl=0 
kernings count=0
 `,
  { 'couriernew1.png': couriernew1, 'couriernew2.png': couriernew2 },
  {
    normal: 0.7,
    bold: 0.4,
    bolder: 0.25,
    lighter: 0.9,
  }
);
